<template>
  <div>
    <h2><SimpleText text-key="calculation.methodselection.text.method"/></h2>
    <div class="full-width w-100" v-html="getContentfulString('calculation.methodselection.text.method.description')" /><br>

    <table :aria-label="getContentfulString('calculation.methodselection.text.method')" class="table" style="max-width: 680px">
      <thead>
      <tr class="bg-primary text-color-white py-9">
        <th scope="col" style="border-right: none">
          <SimpleText text-key="calculation.methodselection.text.method.table.emission-factor"/>
        </th>
        <th scope="col" class="text-align-left">
          <SimpleText text-key="calculation.methodselection.text.method"/>
        </th>
      </tr>
      </thead>
      <tbody v-if="results">
      <tr>
        <td style="width: 55%"><SimpleText text-key="calculation.methodselection.text.method.choice-emission-factor-electricity"/></td>
        <td style="width: 45%; text-align: right">
          <SwitchToggle
            v-if="!printMode"
            v-model:checked="methodSelection"
            :countryLocked="calculation?.companyInformation?.country !== 'DK'"
            :leftLabel="this?.electricityEnvironmentDeclarationTrue"
            :rightLabel="this?.electricityEnvironmentDeclarationFalse"
            fieldId="metodevalgId"
          />
          <span v-if="printMode">
              {{
              calculation.electricityEnvironmentDeclaration
                ? this?.electricityEnvironmentDeclarationTrue
                : this?.electricityEnvironmentDeclarationFalse
            }}
            </span>
        </td>
      </tr>
      <tr>
        <td style="width: 55%"><SimpleText text-key="calculation.methodselection.text.method.emission-factor-fly"/></td>
        <td style="width: 45%; text-align: right">
          <SwitchToggle
              v-if="!printMode"
              v-model:checked="methodSelectionRFI"
              :leftLabel="this?.rfiTrue"
              :rightLabel="this?.rfiFalse"
              fieldId="metodevalgIdRFI"
          />
          <span v-if="printMode">
              {{
              calculation.rfi
                  ? this?.rfiTrue
                  : this?.rfiFalse
            }}
            </span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { Options } from "vue-class-component"
import { GroupingCalculatedResults } from "openapi/generated-clients/climatecompass"
import SwitchToggle from "@/components/SwitchToggle.vue"
import { Prop, Watch } from "vue-property-decorator"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import SimpleText from "@/components/SimpleText.vue";

@Options({
  components: {
    SwitchToggle,
    SimpleText
  }
})
export default class MethodSelection extends BaseComponent {
  @Prop({ required: true })
  results: GroupingCalculatedResults = {} as GroupingCalculatedResults
  @Prop()
  printMode?: boolean

  methodSelection = true
  methodSelectionRFI = true

  async beforeMount() {
    this.methodSelection = this.calculation.electricityEnvironmentDeclaration
    this.methodSelectionRFI = this.calculation.rfi
  }

  @Watch("methodSelection")
  watch(newValue: any, oldValue: any) {
    if (this.results && this.results.calculationId) {

      // send event (to parent) that recalculation is needed when toggle is changed
      this.$emit("methodSelectionChanged", { calculationId: this?.results?.calculationId, isLocationBased: newValue })
    }
  }

  @Watch("methodSelectionRFI")
  watchRFI(newValue: any, oldValue: any) {
    if (this.results && this.results.calculationId) {
      // send event (to parent) that recalculation is needed when toggle is changed
      this.$emit("methodSelectionChangedRFI", { calculationId: this?.results?.calculationId, isRFI: newValue })
    }
  }

  get electricityEnvironmentDeclarationTrue() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.electricityEnvironmentDeclarationTrue')
  }

  get electricityEnvironmentDeclarationFalse() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.electricityEnvironmentDeclarationFalse')
  }

  get rfiTrue() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.rfiTrue')
  }

  get rfiFalse() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.rfiFalse')
  }

}
</script>

<style lang="scss" scoped>

.table {
  border: 1px solid $color-primary;
}

.table td,
.table th {
  padding: 0.8rem 1.2rem !important;
  border: 1px solid $color-primary;
}

.results-table tr:last-child {
  border-top: 3px solid $color-primary;
}

.text-align-right * {
  text-align: right;
}

.text-align-left * {
  text-align: left;
}

.missing-data {
  background-color: #f5f5f5;
}

.missing-data :last-child {
  font-style: italic;
}
</style>
