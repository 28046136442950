<template>
  <CalculatorTabComponent>
    <template v-slot:calculator-primary-1="slotProps">
      <CommonProcumentCalculator
        :subgroupingId="primary ? constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_FYSISKE_ENHEDER : constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_FYSISKE_ENHEDER"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
        :expanded="true"
      >
      </CommonProcumentCalculator>
    </template>
    <template v-slot:calculator-secondary-1="slotProps">
      <CommonProcumentCalculator
        :subgroupingId="primary ? constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_MONETÆRE_ENHEDER : constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_MONETÆRE_ENHEDER"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
      >
      </CommonProcumentCalculator>
    </template>
  </CalculatorTabComponent>
</template>

<script lang="ts">
import { Options, Prop } from "vue-property-decorator"
import BaseComponent from "../base-components/BaseComponent"
import CalculatorTabComponent from "../CalculatorTabComponent.vue"
import CommonProcumentCalculator from "@/components/calculator/procurement/calculators/CommonProcumentCalculator.vue"

@Options({
  components: { CommonProcumentCalculator, CalculatorTabComponent }
})
export default class OtherEmissionsView extends BaseComponent {
  @Prop()
  primary? : boolean
}
</script>

<style scoped>

</style>