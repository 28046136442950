export const getScrollPosition = () => {
  if (window.pageYOffset !== undefined) {
    return [pageXOffset, pageYOffset]
  } else {
    const d = document,
      r = d.documentElement,
      b = d.body
    const sx = r.scrollLeft || b.scrollLeft || 0
    const sy = r.scrollTop || b.scrollTop || 0
    return [sx, sy]
  }
}
