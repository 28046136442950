<template>
  <div class="container py-9">
    <h1 class="mt-5">personlige Betingelser for brug</h1>
  </div>
</template>

<script lang="ts">
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { Vue, Options, mixins } from "vue-class-component"

export default class PrivateTermsForUse extends Vue {

  get documentToHtmlString() {
    return documentToHtmlString
  }

}
</script>
