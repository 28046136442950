import * as contentful from "contentful"
import {Store} from "vuex"
//import store from "@/store"
import createCache from "vuex-cache"

const contentfulEnvironment =
    ((document.location.host.split('.')[0] === 'dev') || (document.location.host.split('.')[0] === 'dev2') || (document.location.host.split('.')[0] === 'dev4'))
        ? 'dev'
        : document.location.host.split('.')[0] === 'localhost' ||
        document.location.host.split('.')[0] === 'localhost:8079'
            ? 'dev'
            : ((document.location.host.split('.')[0] === 'test') || (document.location.host.split('.')[0] === 'test2'))
                ? 'master'
                : document.location.host.split('.')[0] === 'preprod'
                    ? 'preprod'
                    : (document.location.host.split('.')[0] === 'ci' || document.location.host.split('.')[0] === 'ci2')
                        ? 'dev'
                        : 'prod'

const client = contentful.createClient({
  host: "cdn.eu.contentful.com",
  space: process.env.VUE_APP_CONTENTFUL_SPACE,
  accessToken: process.env.VUE_APP_CONTENTFUL_KEY,
  environment: contentfulEnvironment
})

function getUserLocale() {
  return window.localStorage.getItem('locale') || 'da';
}

export const getAllEntries = async (skip = 0) => {
  const userLocale = getUserLocale();
  const entries = await client.getEntries({
    limit: 1000, skip: skip, locale: userLocale
  });
  return entries.items
}

export const getEntries = async (id: string) => {
  const userLocale = getUserLocale();
  const entries = await client.getEntries({ content_type: id, locale: userLocale })
  return entries.items
}

export const getEntriesId = async (id: string) => {
  const userLocale = getUserLocale();
  const entries = await client.getEntries({ "sys.id[in]": id, locale: userLocale })
  return entries.items
}

export const getContentfulValue = async (key: string, contentType: string) => {
  const userLocale = getUserLocale();
  const entries : any = await client.getEntries({ content_type : contentType, "fields.key[in]": key, locale: userLocale })
  if (entries && entries.items && entries.items.length > 0) {
    return entries.items[0].fields.value
  } else {
    return null
  }
}

const store = new Store({
  plugins: [
    createCache({
      timeout: 60 * 60 * 1000 // Equal to 1 hours in milliseconds.
    })
  ],
  actions: {
    GET_CONTENTFUL_PAGE: async (_, idAndLocale: string) => {
      const idSplit = idAndLocale.split(";", 2)
      const id = idSplit[0]
      const locale = JSON.parse(idSplit[1])
      return (await client.getEntry(id, locale)) ?? ""
    }
  }
})

export const getPage = async <T>(id: string) => {
  const locale = {locale: getUserLocale()}
  const entry = await store.cache.dispatch("GET_CONTENTFUL_PAGE", id + ";" + JSON.stringify(locale) )
  return entry.fields as T;
}