import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelText as _vModelText, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b43e3758"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  class: "form-label",
  for: "enable-date-checkbox"
}
const _hoisted_3 = { "aria-describedby": "example-date-field-helptext" }
const _hoisted_4 = { class: "form-label" }
const _hoisted_5 = {
  id: "end-date-field-hint",
  class: "form-hint"
}
const _hoisted_6 = { class: "date-group js-calendar-group mt-3" }
const _hoisted_7 = { class: "form-group form-group-day" }
const _hoisted_8 = {
  class: "form-label",
  for: "date-day"
}
const _hoisted_9 = ["data-error", "disabled", "pattern"]
const _hoisted_10 = { class: "form-group form-group-month" }
const _hoisted_11 = {
  class: "form-label",
  for: "date-month"
}
const _hoisted_12 = ["data-error", "disabled", "pattern"]
const _hoisted_13 = { class: "form-group form-group-year" }
const _hoisted_14 = {
  class: "form-label",
  for: "date-year"
}
const _hoisted_15 = ["data-error", "disabled", "pattern"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      id: "enable-date-checkbox",
      type: "checkbox",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.enableDateInput) = $event)),
      class: "form-checkbox"
    }, null, 512), [
      [_vModelCheckbox, _ctx.enableDateInput]
    ]),
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.getContentfulString('component.label.dateinput.enablelabel.enddate')), 1),
    _createElementVNode("fieldset", _hoisted_3, [
      _createElementVNode("legend", _hoisted_4, _toDisplayString(_ctx.getContentfulString('component.label.dateinput.example-helptext')), 1),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getContentfulString('component.label.dateinput.date-format-hint')), 1),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.getContentfulString('component.label.dateinput.day')), 1),
          _withDirectives(_createElementVNode("input", {
            id: "date-day",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.day) = $event)),
            "data-error": _ctx.getContentfulString('component.label.dateinput.endday.error'),
            disabled: !_ctx.enableDateInput,
            "aria-placeholder": "dd",
            class: "form-input",
            maxlength: "2",
            pattern: _ctx.findRegexPattern('regex.validation.date.day-of-month'),
            placeholder: "dd",
            required: "",
            type: "tel",
            inputmode: "numeric",
            autocomplete: "off",
            onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.updateDate && _ctx.updateDate(...args)))
          }, null, 40, _hoisted_9), [
            [_vModelText, _ctx.day]
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.getContentfulString('component.label.dateinput.month')), 1),
          _withDirectives(_createElementVNode("input", {
            id: "date-month",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.month) = $event)),
            "data-error": _ctx.getContentfulString('component.label.dateinput.endmonth.error'),
            disabled: !_ctx.enableDateInput,
            "aria-placeholder": "mm",
            class: "form-input js-calendar-month-input",
            maxlength: "2",
            pattern: _ctx.findRegexPattern('regex.validation.date.month-of-year'),
            placeholder: "mm",
            required: "",
            type: "tel",
            inputmode: "numeric",
            autocomplete: "off",
            onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.updateDate && _ctx.updateDate(...args)))
          }, null, 40, _hoisted_12), [
            [_vModelText, _ctx.month]
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.getContentfulString('component.label.dateinput.year')), 1),
          _withDirectives(_createElementVNode("input", {
            id: "date-year",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.year) = $event)),
            "data-error": _ctx.getContentfulString('component.label.dateinput.endyear.error'),
            disabled: !_ctx.enableDateInput,
            "aria-placeholder": "yyyy",
            class: "form-input js-calendar-year-input",
            maxlength: "4",
            pattern: _ctx.findRegexPattern('regex.validation.date.year'),
            placeholder: "yyyy",
            required: "",
            type: "tel",
            inputmode: "numeric",
            autocomplete: "off",
            onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.updateDate && _ctx.updateDate(...args)))
          }, null, 40, _hoisted_15), [
            [_vModelText, _ctx.year]
          ])
        ])
      ])
    ])
  ]))
}