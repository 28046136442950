import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-198adc90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"text-align":"center"} }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  class: "alert alert-success"
}
const _hoisted_4 = { class: "alert-body" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { ref: "chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1)
    ]),
    (_ctx.resultIsNegative)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.negativeResultHeader)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: "alert-heading",
                  innerHTML: _ctx.negativeResultHeader
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true),
            (_ctx.negativeResultText)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 1,
                  class: "alert-text",
                  innerHTML: _ctx.negativeResultText
                }, null, 8, _hoisted_6))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.results.groupingResults && !_ctx.resultIsNegative)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          style: {"margin":"0 auto"},
          class: _normalizeClass({ 'print-graph-container': _ctx.printMode })
        }, [
          _createElementVNode("canvas", _hoisted_7, null, 512)
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}