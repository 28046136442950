import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {BLOCKS} from "@contentful/rich-text-types";

export class SimpleTextData {
    key: string
    text: string

    constructor(key: string, text:string) {
        this.key = key
        this.text = text
    }
}

export default class ContentfulContent {
    renderOptions = {
        renderNode: {
        }
    }

    //Render use template instead of p tag (to avoid p tag styled with line length limit)
    renderOptionsTemplate = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: { content: any }, next: (arg0: any) => any) =>
                `<span>${next(node.content)}<p></p></span>`
        }
    }

    simpleTextMap: Map<string, string> = new Map()
    richTextMap: Map<string, object> = new Map()
    errorTextKeys: string[] = []
    useTextKeys = false
    defaultRt =  {
        nodeType: 'document',
        content: [
            {
                nodeType: 'paragraph',
                content: [
                    {
                        nodeType: 'text',
                        value: 'TextKeyPlaceholder',
                        marks: []
                    }
                ]
            }
        ]
    };

    constructor (richTexts: Map<string, object>, plainTexts: Map<string, string>, useTextKeys = false) {
        this.simpleTextMap = plainTexts
        this.richTextMap = richTexts
        this.useTextKeys = useTextKeys

        this.errorTextKeys = Array.from(this.simpleTextMap.keys()).filter( (k: string) => k.startsWith("errormap."))
        //console.log("Error text keys", this.errorTextKeys)
    }

    findSimpleText (key: string): string {
        const value = this.simpleTextMap.get(key)
        return (value != null ? this.useTextKeys ? key : value : key)
    }

    findSimpleTextOrNull (key: string): string | null {
        const value = this.simpleTextMap.get(key)
        return (value != null ? this.useTextKeys ? key : value : null)
    }

    findRichText (key: string, returnKeyOnFail = true): any {
        const value = this.richTextMap.get(key)
        if (value) {
            return this.useTextKeys ? `<p>${key}</p>` : value
        } else {
            return returnKeyOnFail ? JSON.parse(JSON.stringify(this.defaultRt).replace('TextKeyPlaceholder', key)) : null
        }
    }

    getNumberOfIterableElements(keyWithWild: string, wildDenominator = 'X') {
        let i = 1
        while(this.simpleTextMap.has(keyWithWild.replaceAll(wildDenominator, String(i + 1)))) {
            i++
        }
        return i
    }


    renderRichText(key: string, renderAsTemplate = false): string {

        const richTekst = this.findRichText(key)
        if (richTekst) {
            return  this.useTextKeys ? `<p>${key}</p>` : documentToHtmlString(richTekst, (renderAsTemplate ? this.renderOptionsTemplate : this.renderOptions));
        }
        return this.useTextKeys ? `<p>${key}</p>` : ''
    }

    renderRichTextFromJson(richTekstAsString: string, renderAsTemplate = false): string {
        if (richTekstAsString && richTekstAsString.length != 0) {
            const richTekst = JSON.parse(richTekstAsString)
            return documentToHtmlString(richTekst, (renderAsTemplate ? this.renderOptionsTemplate : this.renderOptions));
        }
        return ''
    }

    /*
    1. match mod alle httpResponseCode, errorcode, method, kontroller URL
    2. match mod både httpResponseCode og errorcode, method ANY, kontroller URL
    3. match mod errorcode, httpResponseCode ANY, method, kontroller URL
    4. match mod errorcode, httpResponseCode ANY, method ANY, kontroller URL
    5. match mod httpResponseCode, errorcode ANY, method, kontroller URL
    6. match mod httpResponseCode, errorcode ANY, method ANY, kontroller URL
    7. match mod httpResponseCode ANY, errorcode ANY, method, kontroller URL
    8. match mod httpResponseCode ANY, errorcode ANY, method ANY kontroller URL
     */

    findMatchingError(httpResponseCode: string, errorCode: string | undefined, method: string, url: string | undefined): SimpleTextData {

        let matchingKey: string|undefined = ''
        if (errorCode) {
            matchingKey = this.matchLookUpWithUrl('errormap.'+ httpResponseCode+'.'+errorCode+'.'+method, url)
            //console.log("Match 1", matchingKey)
            if (!matchingKey) {
                matchingKey = this.matchLookUpWithUrl('errormap.'+ httpResponseCode+'.'+errorCode+'.ANY', url)
                //console.log("Match 2", matchingKey)
            }
            if (!matchingKey) {
                matchingKey = this.matchLookUpWithUrl('errormap.ANY.'+errorCode+'.'+method, url)
                //console.log("Match 3", matchingKey)
            }
            if (!matchingKey) {
                matchingKey = this.matchLookUpWithUrl('errormap.ANY.'+errorCode+'.ANY', url)
                //console.log("Match 4", matchingKey)
            }
        }

        if (!matchingKey) {
            matchingKey = this.matchLookUpWithUrl('errormap.'+ httpResponseCode+'.ANY.'+method, url)
            //console.log("Match 5", matchingKey)
        }

        if (!matchingKey) {
            matchingKey = this.matchLookUpWithUrl('errormap.'+ httpResponseCode+'.ANY.ANY', url)
            //console.log("Match 6", matchingKey)
        }

        if (!matchingKey) {
            matchingKey = this.matchLookUpWithUrl('errormap.ANY.ANY.'+method, url)
            //console.log("Match 7", matchingKey)
        }

        if (!matchingKey) {
            matchingKey = 'errormap.ANY.ANY.ANY.ANY.text'
            //console.log("Match 8", matchingKey)
        }
        const errorText = this.findSimpleTextOrNull(matchingKey) || '';
        //console.log("Matched error to text [" + errorText + "] and key: [" + matchingKey + "]")
        return new SimpleTextData(matchingKey, errorText)
    }

    matchLookUpWithUrl(matchKeyPrefix: string, url: string | undefined): string|undefined {
        //console.log('matching prefix', matchKeyPrefix, this.errorTextKeys)
        const matchingKeys = this.errorTextKeys.filter((k: string) => k.startsWith(matchKeyPrefix))
        //console.log("matchingKeys",matchingKeys)
        let matchingKeysWithMatchingUrl: string[] = []
        if (url) {
            matchingKeysWithMatchingUrl = matchingKeys.filter((k: string) => this.matchingUrl(k, url))
        }
        //console.log("matchingKeysWithMatchingUrl", matchingKeysWithMatchingUrl)
        if (matchingKeysWithMatchingUrl.length > 0) {
            return matchingKeysWithMatchingUrl.sort((a, b) => (this.getUrlString(a).length - this.getUrlString(b).length))[0]
        } else {
            //console.log("Match no url ", (matchKeyPrefix + ".ANY.text"), matchingKeys.find((k: string) => k === (matchKeyPrefix + ".ANY.text")))
            return matchingKeys.find((k: string) => k === (matchKeyPrefix + ".ANY.text"))
        }
    }

    matchingUrl(keyString: string, url: string): boolean {
        const urlContent = this.getUrlString(keyString)
        //console.log("matchingUrl check", keyString, url, urlContent)
        if (urlContent !== "ANY" && url.includes(urlContent)) {
            return true
        } else {
            return false
        }
    }

    private getUrlString(keyString: string) {
        return keyString.split(".")[4];
    }

}
