<template>
  <Chart
      v-if="chartOptions.series && chartOptions.series.length > 0 "
      ref="Scope3CategoryColumnChart"
      width="100%"
      height="400"
      type="bar"
      :options="chartOptions"
      :series="chartOptions.series"
      :optimalColumnWidth="this.optimalColumnWidth">
  </Chart>
</template>

<script lang="ts">
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import {Prop, Watch} from "vue-property-decorator";
import {
  CategoryResult,
  ForecastResults,
  GroupingCalculatedResults
} from "../../../openapi/generated-clients/climatecompass";
import {Options} from "vue-class-component";
import VueApexCharts, {VueApexChartsComponent} from "vue3-apexcharts";
import SimpleText from "@/components/SimpleText.vue";
import {Constants} from "@/shared/Constants";
import {graphPrettify} from "@/shared/PrettifyNumbers";
import {watch} from "vue";

@Options({
  components: {
    Chart: VueApexCharts,
    SimpleText,
  }
})

export default class Scope3CategoryColumnChart extends BaseComponent {
  @Prop()
  rawData?: ForecastResults = {}

  @Prop()
  optimalColumnWidth?: number

  upstreamCategories = ['CATEGORY_1', 'CATEGORY_2', 'CATEGORY_3', 'CATEGORY_4', 'CATEGORY_5', 'CATEGORY_6', 'CATEGORY_7']
  downstreamCategories = ['CATEGORY_9', 'CATEGORY_10', 'CATEGORY_11', 'CATEGORY_12', 'CATEGORY_13',]

  scope3CategoriesForecast2: Array<{ [key: string]: any }> = [];

  chartOptions: {
    series: any[]
  } = { series: [] }

/*  ENABLE WATCHER to: Auto-update chart without clicking button submit.
  @Watch('rawData')
  rawDataWatch() {
    if(this.chartOptions.series && this.chartOptions.series.length > 0 ) {
      this.chartOptions = this.getChart()
     // this.$refs.Scope3CategoryColumnChart.updateOptions(this.chartOptions)
    }
  } */

  $refs!: {
    Scope3CategoryColumnChart: VueApexChartsComponent
  }


  /* JSON input data for chart customization.
for reference, visit docs at: https://apexcharts.com/docs/  */

  options = {
    tooltip: {
      theme: 'light',
      marker: {
        show: true
      },
      intersect: true,
      y: {
        formatter: function (value: any) {
          return graphPrettify(value)
        },
        title: {
          formatter: function (seriesName: any) {
            seriesName = seriesName + ': Eksakt værdi i ton CO2e: '
            return seriesName
          }
        },
      }
    },
    plotOptions: {
      bar: {
        columnWidth: String(this.optimalColumnWidth + '%'),
        borderRadius: 2,
        barHeight: '100%',
        columnGap: '5%',
        dataLabels: {
          position: 'top',
          textAnchor: 'middle',
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function(value: number) {
        return graphPrettify(value, true);
      },
      hideOverFlowingLabels: false,
      offsetY: -25,
      style: {
        colors: ['#0c0c0c'],
        fontSize: '12px',
        fontFamily: 'IBM Plex Sans, system',
        fontWeight: 600
      }
    },
    yaxis: {
      decimalsInFloat: 2,
    },
    xaxis: {
      labels: {
        rotate: -70,
        trim: true,
        minHeight: undefined,
        maxHeight: 120,
      }
    },
    legend: {
      show: false,
      position: 'bottom',
      onItemHover: {
        highlightDataSeries: true
      },
    },
    chart: {
      fontFamily: 'IBM Plex Sans, system',
      parentHeightOffset: 0,
      type: 'bar',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    colors: [
      function({seriesIndex, dataPointIndex, w}: any) {
        if (!w.config.series[0].data[dataPointIndex] && !w.config.series[1].data[dataPointIndex]) {
          return '#f69a6aC'
        }
        const name = w.config.series[0].data[dataPointIndex].x[0]
        const categoryNumber = name.split('.')[0]

        if(categoryNumber && name) {
          if (categoryNumber > 8) {
            return seriesIndex%2 === 0 ? '#E66C2C' : '#f69a6a'
          } else {
            return seriesIndex%2 === 0 ? '#3d0ab2' : '#736AFF'
          }
        }
      }
    ]
  }


  async beforeMount() {
    if (this.rawData) {
      this.retrieveCategoryResultsForBasisYear()
      this.retrieveCategoryResultsForEndYear()
      this.chartOptions = this.getChart()
    }
  }

  async updated() {
    console.log('optimalColumnWidth = ' + this.optimalColumnWidth)
  }



  getCategoryResultsForYear(targetYear: number): any[] {
    const resultsForYear: any[] = [];

    this.scope3CategoriesForecast2.forEach((group) => {
      group.forEach((entry: { forecastEndYear: number; forecastBasisYear: number; categoryResults: any; }) => {
        if (entry.forecastEndYear === targetYear || entry.forecastBasisYear === targetYear) {
          resultsForYear.push(...entry.categoryResults);
        }
      });
    });

    return resultsForYear;
  }

  // Sort/get functionality -- BEGIN //
  retrieveCategoryResultsForEndYear() {
    const resultsForBaseYear = this.getCategoryResultsForYear(this.forecastBasisYear);
    return resultsForBaseYear
  }

  retrieveCategoryResultsForBasisYear() {
    const resultsForEndYear = this.getCategoryResultsForYear(this.forecastEndYear);
    return resultsForEndYear
  }

  get forecastEndYear() {
    if (this.rawData?.calculations && this.rawData?.calculations.length > 0) {
      return Math.max(...this.rawData.calculations.map(x => x.year ?? 0))
    }
    return 0
  }

  get forecastBasisYear() {
    if (this.rawData?.calculations && this.rawData.calculations.length > 0) {
      return Math.min(...this.rawData.calculations.map(x => x.year ?? 0))

    }
    return 0
  }

  // Sort/get functionality -- END //

  updateChart(categoryFiltered?: ForecastResults) {
    if(categoryFiltered?.calculations != []) {
      this.updateChartWidth()
    }
  }

  updateChartWidth() {
    const updatedOptions = {
      plotOptions: {
        bar: {
          columnWidth: String(this.optimalColumnWidth + '%'), // Specify your new column width here
        }
      }
    };
    this.$refs.Scope3CategoryColumnChart.updateOptions(updatedOptions, false, false, false)
  }
  getChart() {
    if (!this.rawData || this.rawData.calculations?.length === 0) {
      console.log('rawData not found')
      return
    }

    //const optimalColumnWidth = String(this.optimalColumnWidth + '%')
    const newOptions = [] as any;
    const basisYearCategoryResults: CategoryResult[] = [];
    const endYearCategoryResults: CategoryResult[] = [];
    const refinedCategories: (string[] | string)[] = [];
    const refinedCategories2: (string[] | string)[] = [];
    const scope3CategorySeries: { x: string | undefined, y: number | undefined }[] = [];
    const scope3CategorySeries2: { x: string | undefined, y: number | undefined }[] = [];


    this.rawData?.calculations?.forEach((scope3Result: GroupingCalculatedResults) => {
      if(scope3Result.categoryResults) {
        if (scope3Result.year === this.forecastBasisYear && scope3Result.categoryResults) {
          basisYearCategoryResults.push(...(scope3Result.categoryResults as CategoryResult[]))
        } else if (scope3Result.year === this.forecastEndYear) {
          endYearCategoryResults.push(...scope3Result.categoryResults as CategoryResult[])
        }
      }
    });

    basisYearCategoryResults.forEach((baseYearCategoryResult: CategoryResult) => {
      const categoryName = this.asContentfulString(baseYearCategoryResult.categoryName ?? '');

      refinedCategories.push(this.categoryStringFormatter(categoryName));

      // Filter out 0 value scope3 emission categories
      if (baseYearCategoryResult.categoryResult !== 0) {
        scope3CategorySeries.push({ x: categoryName.split(','), y: baseYearCategoryResult.categoryResult });
      }
    });

    endYearCategoryResults.forEach((endYearCategoryResult: CategoryResult) => {
      const categoryName = this.asContentfulString(endYearCategoryResult.categoryName ?? '');

      // Check if categoryName is defined and present in basisYearCategoryResults
      const hasNonZeroBaseResult = basisYearCategoryResults.some(
          (baseResult) => this.asContentfulString(baseResult.categoryName ?? '') === categoryName && baseResult.categoryResult !== 0
      );

      if (endYearCategoryResult?.categoryName && hasNonZeroBaseResult) {
        scope3CategorySeries2.push({ x: categoryName.split(','), y: endYearCategoryResult.categoryResult });
        refinedCategories2.push(this.categoryStringFormatter(categoryName));
      }
    });

    console.log('1st series length = ' + scope3CategorySeries.length)
    console.log('2st series length = ' + scope3CategorySeries2.length)
    //push the two series
    newOptions.series = [
      {
        data: scope3CategorySeries,
        name: String(this.forecastBasisYear),
        plotOptions: {
          bar: {
            borderRadius: 2,
            columnWidth: scope3CategorySeries.length <= 1 && scope3CategorySeries2.length <= 1 ? '20%' : String(this.optimalColumnWidth) + '%',
            barHeight: '100%',
            columnGap: '5%',
            dataLabels: {
              position: 'center',
              textAnchor: 'middle',
            }
          }
        }
      },
      {
        data: scope3CategorySeries2,
        name: String(this.forecastEndYear),
        plotOptions: {
          bar: {
            borderRadius: 2,
            columnWidth: scope3CategorySeries2.length <= 1 && scope3CategorySeries.length <= 1 ? '20%' : String(this.optimalColumnWidth) + '%',
            barHeight: '100%',
            columnGap: '5%',
            dataLabels: {
              position: 'center',
              textAnchor: 'middle',
            }
          }
        }
      },
    ]
    console.log('Assigned width before use (series1) = ' + (scope3CategorySeries.length <= 1 && scope3CategorySeries2.length <= 1 ? '20%' : String(this.optimalColumnWidth) + '%'))
    console.log('Assigned width before use (series2) = ' + (scope3CategorySeries.length <= 1 && scope3CategorySeries2.length <= 1 ? '20%' : String(this.optimalColumnWidth) + '%'))
    console.log('chart setting assigned and series pushed.')
    return Object.assign(this.options, newOptions)
  }

  asContentfulString(value: string) {
    if (value === Constants.SCOPE3_CATEGORY_1) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category1')
    } else if (value === Constants.SCOPE3_CATEGORY_2) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category2')
    } else if (value === Constants.SCOPE3_CATEGORY_3) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category3')
    } else if (value === Constants.SCOPE3_CATEGORY_4) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category4')
    } else if (value === Constants.SCOPE3_CATEGORY_5) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category5')
    } else if (value === Constants.SCOPE3_CATEGORY_6) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category6')
    } else if (value === Constants.SCOPE3_CATEGORY_7) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category7')
    } else if (value === Constants.SCOPE3_CATEGORY_8) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category8')
    } else if (value === Constants.SCOPE3_CATEGORY_9) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category9')
    } else if (value === Constants.SCOPE3_CATEGORY_10) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category10')
    } else if (value === Constants.SCOPE3_CATEGORY_11) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category11')
    } else if (value === Constants.SCOPE3_CATEGORY_12) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category12')
    } else if (value === Constants.SCOPE3_CATEGORY_13) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category13')
    } else if (value === Constants.SCOPE3_CATEGORY_14) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category14')
    } else if (value === Constants.SCOPE3_CATEGORY_15) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category15')
    } else {
      // default just return input
      return value
    }
  }
}
</script>

<style scoped>

</style>