import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-print-none" }
const _hoisted_2 = {
  key: 0,
  style: {"font-size":"12px","position":"relative","top":"-40px","margin-left":"77px"}
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!
  const _component_SimpleText = _resolveComponent("SimpleText")!
  const _component_CypressTestElement = _resolveComponent("CypressTestElement")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Chart, {
        ref: "forecastChart",
        width: _ctx.width,
        height: "350",
        type: "bar",
        series: _ctx.chartOptions.series,
        options: _ctx.chartOptions,
        class: _normalizeClass(_ctx.additionalClass),
        onAnimationEnd: _cache[0] || (_cache[0] = ($event: any) => {_ctx.focusChart(); _ctx.prerenderPdfImage();}),
        id: _ctx.id
      }, null, 8, ["width", "series", "options", "class", "id"]),
      ((!_ctx.settings || (_ctx.settings & _ctx.constants.FORECAST_FILTER_SCOPES) > 0 || (_ctx.settings & _ctx.constants.FORECAST_FILTER_REDUCTION_TARGET) > 0))
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createTextVNode(" * = "),
            _createVNode(_component_SimpleText, { "text-key": 'forecast.resultview.chart.annotation.forecasted' }, null, 8, ["text-key"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_CypressTestElement, {
        id: "forecast-column-chart-helper",
        type: _ctx.chartType,
        state: _ctx.chartIsUpdating,
        inner: _ctx.filterSelections
      }, null, 8, ["type", "state", "inner"])
    ]),
    _createElementVNode("img", {
      style: {"max-width":"600px"},
      src: _ctx.base64ChartImage,
      class: "d-print-only",
      alt: ""
    }, null, 8, _hoisted_3)
  ], 64))
}