import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApexChart = _resolveComponent("ApexChart")!
  const _component_ReductionCategoryChartLegend = _resolveComponent("ReductionCategoryChartLegend")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_ApexChart, {
        type: "bar",
        options: _ctx.chartOptions,
        series: _ctx.chartOptions.series,
        width: "100%",
        height: "400",
        class: _normalizeClass(_ctx.additionalClass),
        ref: "vueApexChartRef"
      }, null, 8, ["options", "series", "class"])
    ]),
    _createVNode(_component_ReductionCategoryChartLegend, {
      "selected-categories": _ctx.selCatArray,
      "possible-upstream-categories": _ctx.possibleUpstreamCategories,
      "possible-downstream-categories": _ctx.possibleDownstreamCategories
    }, null, 8, ["selected-categories", "possible-upstream-categories", "possible-downstream-categories"])
  ], 64))
}