import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "disabled", "list", "name"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id + '-input',
          class: "form-label"
        }, _toDisplayString(_ctx.label), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      "data-cy-id": "datalist-input",
      id: _ctx.id+'-input',
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textSelected) = $event)),
      "data-error": '_Ukendt land',
      disabled: _ctx.disabled,
      list: _ctx.id,
      name: _ctx.id+'-input',
      class: "form-input",
      type: "text",
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
      onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('input-updated'))),
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('input-clicked')))
    }, null, 40, _hoisted_2), [
      [_vModelText, _ctx.textSelected]
    ]),
    _createElementVNode("datalist", {
      id: _ctx.id,
      "data-cy": "data-list"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createElementBlock("option", { key: index }, _toDisplayString(option.name), 1))
      }), 128))
    ], 8, _hoisted_3)
  ], 64))
}