<template>
  <label :for="fieldId" class="toggle">
    <input
      :id="fieldId"
      :aria-checked="checked"
      :checked="checked"
      type="checkbox"
      @input="
        (event) => {
          if (!countryLocked) {
            $emit('update:checked', event.target.checked)
          }
        }
      "
    />
    <span class="toggle__switch">
      <div :class="checked ? 'toggle__button--left' : 'toggle__button--right'" class="toggle__button" />
      <span :class="checked && 'active'" class="toggle__label toggle__label--left">{{ leftLabel }}</span>
      <span :class="!checked && 'active'" class="toggle__label toggle__label--right">{{ rightLabel }}</span>
    </span>
  </label>
</template>

<script lang="ts">
import { Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

/**
 * Custom SwitchToggle component based off https://designsystem.dk/komponenter/toggle/
 * The component functions as a checkbox element with a boolean value. It can be used in a form,
 * or as a standalone element to perform some other logic.
 *
 * Usage demo:
 * <template>
 *      <SwitchToggle fieldId="importantId" leftLabel="No" rightLabel="Yes" v-model:checked="myNoYesVariable" />
 * </template>
 * < script>
 *   import SwitchToggle from "@/components/SwitchToggle.vue";
 *   export default {
 *     name: "MyComponent",
 *     setup() {
 *       const myNoYesVariable = ref(false); // or true, this may depend on usecase
 *       return {
 *         myNoYesVariable,
 *       }
 *     },
 *     components: {
 *       SwitchToggle,
 *     },
 *   }
 * < /script>
 *
 * Accepts following required properties:
 * @property {string} fieldId - the element id of the hidden input field and the corresponding label's for attribute
 * @property {string} leftLabel - the label on the left, as a form checkbox it would correspond to the falsy value
 * @property {string} rightLabel - the label on the right, as a form checkbox it would correspond to the truthy value
 * @property {boolean} checked - the logical "on/off", "true/false" or "left value/right value" depending on usecase (use v-model property)
 * @property {boolean} countryLocked - feature specific lock of the toggle
 */
export default class SwitchToggle extends Vue {
  @Prop()
  fieldId?: string
  @Prop()
  leftLabel?: string
  @Prop()
  rightLabel?: string
  @Prop()
  checked?: boolean
  @Prop()
  countryLocked?: boolean
}
</script>

<style lang="scss" scoped>
.toggle {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  width: 100%;
  height: 33px;

  &__switch {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 20px;
    background-color: #f5f5f5;
    /* Prevent background color leak outs */
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__button {
    position: absolute;
    overflow: hidden;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 5px;
    left: 0;
    z-index: 20;
    transition: transform 0.3s;
    border-radius: 20px;
    height: 95%;
    width: 50%;
    background-color: $color-primary;

    &--left {
      transform: translate(3px, 3px);
      overflow: hidden;
    }

    &--right {
      transform: translate(100%, 3px);
    }
  }

  &__label {
    z-index: 21;
    position: relative;
    text-align: center;
    top: 50%;
    color: #454545;
    transform: translateY(-30%);
    white-space: nowrap; /* Prevent line breaks */
    transition: color 0.3s ease-in-out;

    &.active {
      color: white !important;
      /* Background is necessary for contrast rating - since accessibility testing frameworks
       do not read the absolutely positioned button as background for the active label */
      background-image: linear-gradient(180deg, $color-primary, $color-primary);
      background-size: 80%;
      background-repeat: no-repeat;
      background-position: 15px -10px;
    }
  }

  input[type='checkbox'] {
    position: absolute;
    left: -9999px;
    margin: 0;
    padding: 0;
    width: 1px;

    &:focus ~ .toggle__switch {
      outline: 4px solid #747474;
      outline-offset: 1px;
    }
  }
}
</style>
