<template>
  <ul v-for='item in currentAccordions' :key='item.title' class='accordion'>
    <li>
      <h3 class='d-print-only' v-html='item.title'></h3>
      <button :aria-controls='item.title.replaceAll(" ", "_") + uniqueId ?? ""' class='accordion-button d-print-none' v-html='item.title' />
      <div :id='item.title.replaceAll(" ", "_") + uniqueId ?? ""' aria-hidden='true' class='accordion-content d-print-block'>
        <p v-html='documentToHtmlString(item.description)'></p>
        <div v-if='item.url' class='accordion-image'>
          <img :src='item.url' :alt="item.description + ' image link'" />
        </div>
      </div>
    </li>
  </ul>
</template>

<script lang='ts'>
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import { Prop } from 'vue-property-decorator'
import TitleDescription from '@/types/contentful-types/TitleDescription'

export default class AccordionComponent extends BaseComponent {
  @Prop({required:true})
  accordionList?: TitleDescription[]
  @Prop({required:false})
  uniqueId?: string

  currentAccordions = this.accordionList
}
</script>

<style lang='scss' scoped>
.accordion-button {
  border: 1px solid grey;
}

:deep(div.accordion-content)  {
  display: flex;
  flex-wrap: nowrap;

  p > h3:first-of-type {
    margin-top: 10px !important;
  }
}

div > img {
  width: 100%;
  height: 50%;
}

.accordion-image {
  max-width: 40%;
  align-self: flex-start;
  padding: 4rem 2rem 2rem 4rem;
}

@media only screen and (max-width: 800px) {
  div .accordion-content {
    display: flex;
    flex-wrap: wrap-reverse;
  }

  .accordion-image {
    max-width: 100%;
    align-self: flex-start;
    padding: 1rem 1rem 1rem 1rem;
  }
}
</style>