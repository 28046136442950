import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f1ce3bf4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "guides-first-column-header" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "mt-5" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "container px-0 pb-6" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "w-percent-100 w-percent-100-child" }
const _hoisted_10 = { class: "news-content" }
const _hoisted_11 = { class: "news-label tag" }
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedHeader = _resolveComponent("SharedHeader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SharedHeader, null, {
      firstColumn: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.currentPageHeader.title), 1),
          _createElementVNode("span", {
            innerHTML: _ctx.currentPageHeader.description
          }, null, 8, _hoisted_2)
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.currentPageHeader.buttonPrimaryLink)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: _ctx.currentPageHeader.buttonPrimaryLink,
                class: "button button-primary mr-4",
                target: "_blank"
              }, _toDisplayString(_ctx.currentPageHeader.buttonPrimaryText), 9, _hoisted_4))
            : _createCommentVNode("", true),
          (_ctx.currentPageHeader.buttonSecondaryLink)
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                href: _ctx.currentPageHeader.buttonSecondaryLink,
                class: "button button-secondary",
                target: "_blank"
              }, _toDisplayString(_ctx.currentPageHeader.buttonSecondaryText), 9, _hoisted_5))
            : _createCommentVNode("", true)
        ])
      ]),
      secondColumn: _withCtx(() => [
        (_ctx.currentPageHeader.imageUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.currentPageHeader.imageUrl,
              alt: ""
            }, null, 8, _hoisted_6))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.pageTitle), 1),
      (_ctx.pageDescription !== "")
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            innerHTML: _ctx.pageDescription
          }, null, 8, _hoisted_8))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedNews, (column) => {
          return (_openBlock(), _createElementBlock("div", {
            key: column.fields.title,
            class: _normalizeClass(["news-wrapper", [( column.fields.type === 'informationsbesked' ? 'info' : ''), (column.fields.type === 'advarselsbesked' ? 'warning' : ''), (column.fields.type === 'succesbesked' ? 'success' : '')]])
          }, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.formatDate(column.sys.createdAt)) + " " + _toDisplayString(_ctx.newsTypeLabelText(column.fields.type)), 1),
              _createElementVNode("h4", null, _toDisplayString(column.fields.title), 1),
              _createElementVNode("p", {
                innerHTML: _ctx.documentToHtmlString(column.fields.text)
              }, null, 8, _hoisted_12)
            ])
          ], 2))
        }), 128))
      ])
    ])
  ], 64))
}