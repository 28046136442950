<template>
  <div v-if="richTextRendered" class="full-width">
    <div v-html="richTextRendered"/>
  </div>
</template>

<script lang="ts">
/* eslint-disable semi */
import { useStore } from 'vuex'
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { Document } from '@contentful/rich-text-types'
import { BLOCKS } from '@contentful/rich-text-types'

export default class extends Vue {
  renderOptions = {
    renderNode: {
    }
  }

  //Render use template instead of p tag (to avoid p tag styled with line length limit)
  renderOptionsTemplate = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: { content: any }, next: (arg0: any) => any) =>
          `<span>${next(node.content)}<p></p></span>`
    }
  }

  renderOptionsLineBreaks = {
      renderNode: {
          [BLOCKS.PARAGRAPH]: (node: { content: any }, next: (arg0: any) => any) =>
              `<p>${next(node.content).replace(/\n/g, `</br>`)}</p>`
      }
  }

  @Prop()
  textKey = 'ukendt-noegle'

  @Prop()
  renderAsTemplate = false

  @Prop()
  renderWithLinebreaks = false

  richText?: Document

  richTextRendered = ''

  store = useStore()

  mounted () : void {
    this.richText = this.store.getters.getContentfulContent.findRichText(this.textKey)
    let useRenderOptions = this.renderOptions
    if (this.renderWithLinebreaks) {
        useRenderOptions = this.renderOptionsLineBreaks
    }  else if (this.renderAsTemplate) {
        useRenderOptions = this.renderOptionsTemplate
    }
    if (this.richText) {
      this.richTextRendered = documentToHtmlString(this.richText, useRenderOptions)
    } else {
      this.richTextRendered = '<p>' + this.textKey + '</p>'
    }
  }
}
</script>

<style scoped>

</style>
