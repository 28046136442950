import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "pre-login-text",
  class: "container py-9"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RichText = _resolveComponent("RichText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RichText, { "text-key": "login.loginText" })
  ]))
}