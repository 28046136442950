import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-750a6f09"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  role: "row"
}
const _hoisted_2 = { style: {"padding-left":"30px"} }
const _hoisted_3 = { style: {"text-align":"right"} }
const _hoisted_4 = { style: {"text-align":"right"} }
const _hoisted_5 = { style: {"text-align":"right"} }
const _hoisted_6 = { style: {"text-align":"right"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.exist)
    ? (_openBlock(), _createElementBlock("tr", _hoisted_1, [
        _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.prettify(_ctx.getSubgroupingStringById('name'))), 1),
        _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.prettify(_ctx.getSubgroupingDataById('scope1Result'), 2) || 0), 1),
        _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.prettify(_ctx.getSubgroupingDataById('scope2Result'), 2) || 0), 1),
        _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.prettify(_ctx.getSubgroupingDataById('scope3Result'), 2) || 0), 1),
        _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.prettify(_ctx.getSubgroupingDataById('scopesResult'), 2) || 0), 1)
      ]))
    : _createCommentVNode("", true)
}