<template>
  <tr role="row" v-if="exist">
    <td headers="partial-resultghg-table-header2" style="padding-left:30px">
      {{ prettify(getSubgroupingStringById('name')) }}
    </td>
    <td headers="partial-resultghg-table-header2" style="text-align: right">
      {{ prettify(getSubgroupingDataById('outsideScopeResult'), 2, true) || 0 }}
    </td>
  </tr>
</template>

<script lang="ts">
import {Vue} from 'vue-class-component'
import {Prop} from 'vue-property-decorator'
import {prettify} from '@/shared/PrettifyNumbers'


export default class ResultRowGHG2Col extends Vue {
  @Prop()
  result?: any
  @Prop()
  id = ''
  @Prop()
  subCategoryName?: string

  exist?: boolean;

  get prettify() {
    return prettify
  }

  beforeMount() {
    this.exist = this.checkSubgroupingExistsById
  }

  get checkSubgroupingExistsById() {
    const subgroup = this.result.subgroupingsCalculated.find((obj: { subgroupingId: any; }) => {
      return obj.subgroupingId === parseInt(this.id)
    })

    if (subgroup != null) {
      return true
    } else if (subgroup === null) {
      return false
    } else {
      return false
    }
  }

  getSubgroupingStringById(dataPiece: string) {
    return this.subCategoryName
  }

  getSubgroupingDataById(dataPiece: string) {
    const subgroup = this.result.subgroupingsCalculated.find((obj: { subgroupingId: any; }) => {
      return obj.subgroupingId === parseInt(this.id)
    })


    let outsideScopeResult = 0;
    let scopesResult = 0;
    let shareInTotal = 0;
    let totalResult = 0;

    for (let i = 0; i < this.result.subgroupingsCalculated.length; i++) {
      if (this.result.subgroupingsCalculated[i]?.subgroupingId === subgroup?.subgroupingId && this.result.subgroupingsCalculated[i].calculatedResult != null) {
        outsideScopeResult = outsideScopeResult + this.result.subgroupingsCalculated[i].calculatedResult.outsideScopeResult;
        scopesResult = scopesResult + this.result.subgroupingsCalculated[i].calculatedResult.scopesResult;
        shareInTotal = shareInTotal + this.result.subgroupingsCalculated[i].calculatedResult.shareInTotal;
        totalResult = totalResult + this.result.subgroupingsCalculated[i].calculatedResult.totalResult;
      }
    }

    if (dataPiece === 'name') {
      return subgroup?.subgroupingName
    }
    if (dataPiece === 'outsideScopeResult') {
      return outsideScopeResult
    }
    if (dataPiece === 'shareInTotal') {
      return shareInTotal
    }
    if (dataPiece === 'totalResult') {
      return totalResult
    }
    if (dataPiece === 'scopesResult') {
      return scopesResult
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.table {
  border: 1px solid $color-primary;
}

.table td,
.table th {
  border-left: 1px solid $color-primary;
  border-right: 1px solid $color-primary;
}

.table tr:last-child > td,
.table tr:last-child > th {
  border-bottom: 1px solid $color-border-grey;
}

.table td,
.table th,
.table tbody tr:last-child td,
.table tbody tr:last-child th {
  padding: 0.8rem 1.2rem;
}

.text-align-right * {
  text-align: right;
}

.text-align-left * {
  text-align: left;
}

</style>
