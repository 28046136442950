<template>
  <div class="form-group">
    <input id="enable-date-checkbox" type="checkbox" v-model="enableDateInput" class="form-checkbox">
    <label class="form-label" for="enable-date-checkbox">{{ getContentfulString('component.label.dateinput.enablelabel.enddate') }}</label>
    <fieldset aria-describedby="example-date-field-helptext">
      <legend class="form-label">{{ getContentfulString('component.label.dateinput.example-helptext') }}</legend>
      <span id="end-date-field-hint" class="form-hint">{{ getContentfulString('component.label.dateinput.date-format-hint') }}</span>
      <div class="date-group js-calendar-group mt-3">
        <div class="form-group form-group-day">
          <label class="form-label" for="date-day">{{getContentfulString('component.label.dateinput.day')}}</label>
          <input
                 id="date-day"
                 v-model="day"
                 :data-error="getContentfulString('component.label.dateinput.endday.error')"
                 :disabled="!enableDateInput"
                 aria-placeholder="dd"
                 class="form-input"
                 maxlength="2"
                 :pattern="findRegexPattern('regex.validation.date.day-of-month')"
                 placeholder="dd"
                 required
                 type="tel"
                 inputmode="numeric"
                 autocomplete="off"
                 @change="updateDate"

          >
        </div>
        <div class="form-group form-group-month">
          <label class="form-label" for="date-month">{{getContentfulString('component.label.dateinput.month')}}</label>
          <input
                 id="date-month"
                 v-model="month"
                 :data-error="getContentfulString('component.label.dateinput.endmonth.error')"
                 :disabled="!enableDateInput"
                 aria-placeholder="mm"
                 class="form-input js-calendar-month-input"
                 maxlength="2"
                 :pattern="findRegexPattern('regex.validation.date.month-of-year')"
                 placeholder="mm"
                 required
                 type="tel"
                 inputmode="numeric"
                 autocomplete="off"
                 @change="updateDate"
          >
        </div>
        <div class="form-group form-group-year">
          <label class="form-label" for="date-year">{{getContentfulString('component.label.dateinput.year')}}</label>
          <input
                 id="date-year"
                 v-model="year"
                 :data-error="getContentfulString('component.label.dateinput.endyear.error')"
                 :disabled="!enableDateInput"
                 aria-placeholder="yyyy"
                 class="form-input js-calendar-year-input"
                 maxlength="4"
                 :pattern="findRegexPattern('regex.validation.date.year')"
                 placeholder="yyyy"
                 required
                 type="tel"
                 inputmode="numeric"
                 autocomplete="off"
                 @change="updateDate"
          >
        </div>
      </div>
    </fieldset>
  </div>
</template>
<script lang="ts"> import {Prop, Vue, Watch} from 'vue-property-decorator';
import {Options} from "vue-class-component";
import DateInput from '@/types/DateInput'
import {FormValidation} from "@/shared/FormValidation";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";

@Options({
  emits: ["change", "update:modelValue"]
})
export default class DateInputComponent extends BaseComponent {
  @Prop()
  modelValue?: string

  @Prop()
  isOptional = true

  enableDateInput = false

  day = ''
  month = ''
  year = ''

  @Watch('enableDateInput')
  watchEnableDateInput() {
    if (!this.enableDateInput) {
      this.day = ''
      this.month = ''
      this.year = ''
      this.updateDate()
    }
    this.$emit('change')
  }

  get isDateInputEnabled() {
    return this.enableDateInput
  }

  get selectedDate() {
    return `${this.year}-${this.month.padStart(2, '0')}-${this.day.padStart(2, '0')}`
  }
  get currentDate() {
    return new DateInput(this.day, this.month, this.year)
  }

  created() {
   this.init()
  }

  get isValidDate() {
    if (this.year.length === 4 && Number(this.month) > 0 && Number(this.month) <= 12 && Number(this.day) > 0 && Number(this.day) <= 31) {
      return new DateInput(this.day, this.month, this.year).isRealDate
    } else {
      return false
    }
  }

  init() {
    if (this.modelValue) {
      const [year, month, day] = this.modelValue.split('-')
      this.day = day
      this.month = month
      this.year = year
      this.enableDateInput = true
    }
  }



  updateDate() {
    if (this.isValidDate) {
      const newDate = `${this.year}-${this.month.padStart(2, '0')}-${this.day.padStart(2, '0')}`;
      this.$emit('update:modelValue', newDate);
    } else {
      this.$emit('update:modelValue', '')
    }
    this.$emit('change')
  }
} </script>

<style scoped>

.form-input.js-calendar-month-input {
  width: calc(0px + 15px + 25px + 2ch);
}

div.form-group.form-group-year{
  margin-left: 3px;
}


</style>
