<template>
  <SharedHeader>
    <template #firstColumn>
      <div id='guides-first-column-header'>
        <h1>{{ currentPageHeader.title }}</h1>
        <span v-html='currentPageHeader.description'></span>
      </div>
      <div class='mt-5'>
        <a v-if='currentPageHeader.buttonPrimaryLink' :href='currentPageHeader.buttonPrimaryLink'
           class='button button-primary mr-4' target='_blank'>{{ currentPageHeader.buttonPrimaryText }}</a>
        <a v-if='currentPageHeader.buttonSecondaryLink' :href='currentPageHeader.buttonSecondaryLink'
           class='button button-secondary' target='_blank'>{{ currentPageHeader.buttonSecondaryText }}</a>
      </div>
    </template>
    <template #secondColumn>
      <img v-if='currentPageHeader.imageUrl' :src='currentPageHeader.imageUrl'
           alt='' />
    </template>
  </SharedHeader>
  <div class="container px-0 pb-6">
    <h2>
      <SimpleText text-key='privacy.header'></SimpleText>
    </h2>
    <div class="w-percent-100 w-percent-100-child" v-html="getRenderedContentfulRT('privacy.description')" />
    <AccordionComponent :accordion-list='getAccordions("privacy")'></AccordionComponent>
  </div>
</template>

<script lang="ts">

import * as DKFDS from "dkfds"
import { Vue, Options, mixins } from "vue-class-component"
import SimpleText from '@/components/SimpleText.vue'
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import AccordionComponent from '@/components/AccordionComponent.vue'
import SharedHeader from "@/components/calculator/shared/SharedHeader.vue";
import {GuideHeader} from "@/components/guides/Guides.vue";

@Options({
  components: {
    SharedHeader,
    AccordionComponent,
    SimpleText
  }
})
export default class TermsForUse extends BaseComponent {
  currentPageHeader: GuideHeader = {} as GuideHeader

  pageTitle = ''
  pageDescription = ''


  mounted() {
    DKFDS.init()
  }

  updated() {
    DKFDS.init()
  }

  async beforeMount() {
    this.initPage()
    this.setDocumentTitleByKey('menu.terms-for-use.item.background')
  }
  initPage() {
    this.currentPageHeader.title = this.getContentfulString('guides.termsofuse.header.title')
    this.currentPageHeader.description = this.getRenderedContentfulRT('guides.termsofuse.header.description')
    this.currentPageHeader.buttonPrimaryLink = this.Contentful.findSimpleTextOrNull('guides.termsofuse.header.buttonprimary.link')
    this.currentPageHeader.buttonPrimaryText = this.Contentful.findSimpleTextOrNull('guides.termsofuse.header.buttonprimary.text')
    this.currentPageHeader.buttonSecondaryLink = this.Contentful.findSimpleTextOrNull('guides.termsofuse.header.buttonsecondary.link')
    this.currentPageHeader.buttonSecondaryText = this.Contentful.findSimpleTextOrNull('guides.termsofuse.header.buttonsecondary.text')
    this.currentPageHeader.imageUrl = this.Contentful.findSimpleTextOrNull('guides.termsofuse.header.imageurl')
  }
}
</script>

<style scoped>
.accordion-button {
  border: 1px solid grey;
}
</style>
