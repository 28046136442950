import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a16022e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "wrapper-div",
  id: "resultsEsg-wrapper-div"
}
const _hoisted_2 = {
  id: "section-one-container",
  class: "section-one-container"
}
const _hoisted_3 = { id: "section-one-main-header" }
const _hoisted_4 = {
  id: "main-description-text",
  class: "main-description-text"
}
const _hoisted_5 = {
  key: 0,
  id: "esg-results-table",
  class: "table results-table w-percent-100",
  "aria-describedby": "esg-result-table-header1",
  "aria-label": "e-nøgletal resultater"
}
const _hoisted_6 = { class: "bg-primary text-color-white" }
const _hoisted_7 = {
  id: "esg-result-table-header1",
  colspan: "3",
  scope: "colgroup"
}
const _hoisted_8 = {
  style: {"font-weight":"600"},
  class: "w-percent-100"
}
const _hoisted_9 = {
  headers: "esg-result-table-header1",
  style: {"width":"55%"},
  class: "py-405 align-text-left bg-alternative color-text-black",
  id: "results.esg.result-table-subheader-one"
}
const _hoisted_10 = {
  headers: "esg-result-table-header1",
  style: {"width":"15%"},
  class: "py-405 align-text-left bg-alternative color-text-black",
  id: "results.esg.result-table-subheader-two"
}
const _hoisted_11 = {
  headers: "esg-result-table-header1",
  style: {"width":"30%"},
  class: "py-405 align-text-left bg-alternative color-text-black",
  id: "results.esg.result-table-subheader-three"
}
const _hoisted_12 = { role: "rowgroup" }
const _hoisted_13 = { id: "esg-result-scope-one" }
const _hoisted_14 = { class: "align-text-left" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_17 = { id: "esg-result-scope-two" }
const _hoisted_18 = { class: "align-text-left" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_21 = { id: "esg-result-scope-three" }
const _hoisted_22 = { class: "align-text-left" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_25 = { id: "esg-result-scopes-total" }
const _hoisted_26 = { class: "align-text-left" }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_29 = { id: "esg-result-co2intensity" }
const _hoisted_30 = { class: "align-text-left" }
const _hoisted_31 = { key: 0 }
const _hoisted_32 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_33 = { id: "esg-result-energytotal" }
const _hoisted_34 = { class: "align-text-left" }
const _hoisted_35 = { key: 0 }
const _hoisted_36 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_37 = { id: "esg-result-energyintensity" }
const _hoisted_38 = { class: "align-text-left" }
const _hoisted_39 = { key: 0 }
const _hoisted_40 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_41 = { id: "esg-result-energyrenewable" }
const _hoisted_42 = { class: "align-text-left" }
const _hoisted_43 = { key: 0 }
const _hoisted_44 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_45 = { id: "esg-result-waterconsumption" }
const _hoisted_46 = { class: "align-text-left" }
const _hoisted_47 = { key: 0 }
const _hoisted_48 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_49 = { id: "esg-result-wastehazardous" }
const _hoisted_50 = { class: "align-text-left" }
const _hoisted_51 = { key: 0 }
const _hoisted_52 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_53 = { id: "esg-result-wasterecycled" }
const _hoisted_54 = { class: "align-text-left" }
const _hoisted_55 = { key: 0 }
const _hoisted_56 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_57 = {
  id: "section-two-container",
  class: "section-two-container"
}
const _hoisted_58 = { id: "section-two-main-header" }
const _hoisted_59 = {
  id: "section-two-description-text",
  class: "mw-100"
}
const _hoisted_60 = {
  id: "esg-parameter-table",
  class: "table results-table w-percent-100",
  "aria-describedby": "esg-result-table-header2",
  "aria-label": "parameter brugt til beregning af e-nøgletal"
}
const _hoisted_61 = { class: "bg-primary text-color-white" }
const _hoisted_62 = {
  id: "esg-result-table-header2",
  colspan: "3",
  scope: "colgroup"
}
const _hoisted_63 = { style: {"font-weight":"600"} }
const _hoisted_64 = {
  headers: "esg-result-table-header2",
  style: {"width":"55%"},
  class: "py-405 align-text-left bg-alternative color-text-black",
  id: "results.esg.result-table2-subheader-one"
}
const _hoisted_65 = {
  headers: "esg-result-table-header2",
  style: {"width":"15%"},
  class: "py-405 align-text-left bg-alternative color-text-black",
  id: "results.esg.result-table2-subheader-two"
}
const _hoisted_66 = {
  headers: "esg-result-table-header2",
  style: {"width":"30%"},
  class: "py-405 align-text-left bg-alternative color-text-black",
  id: "results.esg.result-table2-subheader-three"
}
const _hoisted_67 = { role: "rowgroup" }
const _hoisted_68 = { id: "esg-parameter-turnover" }
const _hoisted_69 = {
  class: "align-text-left",
  headers: "results.esg.result-table2-subheader-one"
}
const _hoisted_70 = { headers: "results.esg.result-table2-subheader-two" }
const _hoisted_71 = {
  key: 0,
  headers: "results.esg.result-table2-subheader-three"
}
const _hoisted_72 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_73 = { id: "esg-parameter-energyrenewable" }
const _hoisted_74 = {
  class: "align-text-left",
  headers: "results.esg.result-table2-subheader-one"
}
const _hoisted_75 = {
  key: 0,
  headers: "results.esg.result-table2-subheader-three"
}
const _hoisted_76 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_77 = { id: "esg-parameter-waste-total" }
const _hoisted_78 = {
  class: "align-text-left",
  headers: "results.esg.result-table2-subheader-one"
}
const _hoisted_79 = { headers: "results.esg.result-table2-subheader-two" }
const _hoisted_80 = {
  key: 0,
  headers: "results.esg.result-table2-subheader-three"
}
const _hoisted_81 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_82 = { id: "esg-parameter-hazardouswaste-total" }
const _hoisted_83 = {
  class: "align-text-left",
  headers: "results.esg.result-table2-subheader-one"
}
const _hoisted_84 = { headers: "results.esg.result-table2-subheader-two" }
const _hoisted_85 = {
  key: 0,
  headers: "results.esg.result-table2-subheader-three"
}
const _hoisted_86 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_87 = { id: "esg-parameter-recycledwaste-total" }
const _hoisted_88 = {
  class: "align-text-left",
  headers: "results.esg.result-table2-subheader-one"
}
const _hoisted_89 = { headers: "results.esg.result-table2-subheader-two" }
const _hoisted_90 = {
  key: 0,
  headers: "results.esg.result-table2-subheader-three"
}
const _hoisted_91 = {
  key: 1,
  class: "italic-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleText = _resolveComponent("SimpleText")!
  const _component_RichText = _resolveComponent("RichText")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, [
        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-main-header" })
      ]),
      _createElementVNode("p", _hoisted_4, [
        _createVNode(_component_RichText, { "text-key": "results.esg.result-main-description-text" })
      ]),
      (_ctx.results?.groupingResults)
        ? (_openBlock(), _createElementBlock("table", _hoisted_5, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_6, [
                _createElementVNode("th", _hoisted_7, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-header-one" })
                ])
              ])
            ]),
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_8, [
                _createElementVNode("th", _hoisted_9, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-subheader-one" })
                ]),
                _createElementVNode("th", _hoisted_10, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-subheader-two" })
                ]),
                _createElementVNode("th", _hoisted_11, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-subheader-three" })
                ])
              ])
            ]),
            _createElementVNode("tbody", _hoisted_12, [
              _createElementVNode("tr", _hoisted_13, [
                _createElementVNode("td", _hoisted_14, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-scope-one" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-one" })
                ]),
                (_ctx.results.esgResult?.scope1Total)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_15, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.scope1Total)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_16, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ]),
              _createElementVNode("tr", _hoisted_17, [
                _createElementVNode("td", _hoisted_18, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-scope-two" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-one" })
                ]),
                (_ctx.results.esgResult?.scope2Total)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_19, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.scope2Total)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_20, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ]),
              _createElementVNode("tr", _hoisted_21, [
                _createElementVNode("td", _hoisted_22, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-scope-three" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-one" })
                ]),
                (_ctx.results.esgResult?.scope3Total)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_23, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.scope3Total)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_24, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ]),
              _createElementVNode("tr", _hoisted_25, [
                _createElementVNode("td", _hoisted_26, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-scopes-total" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-one" })
                ]),
                (_ctx.results.ghgCalculatedResult?.totalResult)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_27, _toDisplayString(_ctx.doPrettify(_ctx.results.ghgCalculatedResult.totalResult)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_28, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ]),
              _createElementVNode("tr", _hoisted_29, [
                _createElementVNode("td", _hoisted_30, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-co2intensity" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-two" })
                ]),
                (_ctx.results.esgResult?.co2Intensity)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_31, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.co2Intensity)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_32, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ]),
              _createElementVNode("tr", _hoisted_33, [
                _createElementVNode("td", _hoisted_34, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-energytotal" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-three" })
                ]),
                (_ctx.results.esgResult?.energyTotal)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_35, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.energyTotal)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_36, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ]),
              _createElementVNode("tr", _hoisted_37, [
                _createElementVNode("td", _hoisted_38, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-energyintensity" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-four" })
                ]),
                (_ctx.results.esgResult?.energyIntensity)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_39, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.energyIntensity)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_40, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ]),
              _createElementVNode("tr", _hoisted_41, [
                _createElementVNode("td", _hoisted_42, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-energyrenewable" }),
                  _createVNode(_component_Tooltip, {
                    "aria-label": "result-energy-renewable-percentage-tooltip",
                    textKey: "results.esg.result-energy-renewable-percentage-tooltip",
                    style: {"align-content":"flex-end"},
                    size: "md-gr"
                  })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-five" })
                ]),
                (_ctx.results.esgResult?.energyRenewablePercentage)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_43, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.energyRenewablePercentage)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_44, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ]),
              _createElementVNode("tr", _hoisted_45, [
                _createElementVNode("td", _hoisted_46, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-waterconsumption" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-six" })
                ]),
                (_ctx.results.esgResult?.waterConsumption)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_47, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.waterConsumption)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_48, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ]),
              _createElementVNode("tr", _hoisted_49, [
                _createElementVNode("td", _hoisted_50, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-wastehazardous" }),
                  _createVNode(_component_Tooltip, {
                    "aria-label": "result-result-waste-hazardous-percentage-tooltip",
                    textKey: "results.esg.result-waste-hazardous-percentage-tooltip",
                    style: {"align-content":"flex-end"},
                    size: "md-gr"
                  })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-five" })
                ]),
                (_ctx.results.esgResult?.wasteHazardousPercentage)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_51, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.wasteHazardousPercentage)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_52, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ]),
              _createElementVNode("tr", _hoisted_53, [
                _createElementVNode("td", _hoisted_54, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-wasterecycled" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-five" })
                ]),
                (_ctx.results.esgResult?.wasteRecycledPercentage)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_55, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.wasteRecycledPercentage)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_56, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_57, [
      _createElementVNode("h2", _hoisted_58, [
        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-section-two-main-header" })
      ]),
      _createElementVNode("p", _hoisted_59, [
        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-section-two-main-description-text.simple" })
      ]),
      _createElementVNode("table", _hoisted_60, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", _hoisted_61, [
            _createElementVNode("th", _hoisted_62, [
              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-header-two" })
            ])
          ])
        ]),
        _createElementVNode("thead", null, [
          _createElementVNode("tr", _hoisted_63, [
            _createElementVNode("th", _hoisted_64, [
              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-subheader-one" })
            ]),
            _createElementVNode("th", _hoisted_65, [
              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-subheader-two" })
            ]),
            _createElementVNode("th", _hoisted_66, [
              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-subheader-three" })
            ])
          ])
        ]),
        _createElementVNode("tbody", _hoisted_67, [
          _createElementVNode("tr", _hoisted_68, [
            _createElementVNode("td", _hoisted_69, [
              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-turnover" })
            ]),
            _createElementVNode("td", _hoisted_70, [
              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-seven" })
            ]),
            (_ctx.results.esgResult?.turnover)
              ? (_openBlock(), _createElementBlock("td", _hoisted_71, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.turnover)), 1))
              : (_openBlock(), _createElementBlock("td", _hoisted_72, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                ]))
          ]),
          _createElementVNode("tr", _hoisted_73, [
            _createElementVNode("td", _hoisted_74, [
              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-renewableenergy-total" })
            ]),
            _createElementVNode("td", null, [
              _createVNode(_component_SimpleText, {
                "text-key": "results.esg.result-table-measurement-unit-three",
                headers: "results.esg.result-table2-subheader-two"
              })
            ]),
            (_ctx.results.esgResult?.energyRenewableTotal)
              ? (_openBlock(), _createElementBlock("td", _hoisted_75, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.energyRenewableTotal)), 1))
              : (_openBlock(), _createElementBlock("td", _hoisted_76, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                ]))
          ]),
          _createElementVNode("tr", _hoisted_77, [
            _createElementVNode("td", _hoisted_78, [
              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-waste-total" })
            ]),
            _createElementVNode("td", _hoisted_79, [
              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-eight" })
            ]),
            (_ctx.results.esgResult?.wasteTotal)
              ? (_openBlock(), _createElementBlock("td", _hoisted_80, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.wasteTotal)), 1))
              : (_openBlock(), _createElementBlock("td", _hoisted_81, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                ]))
          ]),
          _createElementVNode("tr", _hoisted_82, [
            _createElementVNode("td", _hoisted_83, [
              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-hazardouswaste-total" })
            ]),
            _createElementVNode("td", _hoisted_84, [
              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-eight" })
            ]),
            (_ctx.results.esgResult?.wasteHazardousTotal)
              ? (_openBlock(), _createElementBlock("td", _hoisted_85, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.wasteHazardousTotal)), 1))
              : (_openBlock(), _createElementBlock("td", _hoisted_86, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                ]))
          ]),
          _createElementVNode("tr", _hoisted_87, [
            _createElementVNode("td", _hoisted_88, [
              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-recycledwaste-total" })
            ]),
            _createElementVNode("td", _hoisted_89, [
              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-eight" })
            ]),
            (_ctx.results.esgResult?.wasteRecycledTotal)
              ? (_openBlock(), _createElementBlock("td", _hoisted_90, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.wasteRecycledTotal)), 1))
              : (_openBlock(), _createElementBlock("td", _hoisted_91, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                ]))
          ])
        ])
      ])
    ])
  ]))
}