<template>
  <div class="add-address">
    <div class="form-group">
      <label class="form-label"><SimpleText text-key="masterdatacompanyInformation.calculationAddressesFormLabel"/>{{
          countryLongName
        }}</label>
      <span class="form-hint"><SimpleText text-key="masterdatacompanyInformation.calculationAddressesFormHint"/></span>
      <div class="saved-addresses">
        <ul v-if="addresses">
          <li></li>
          <li v-for="(address, index) in addresses" :key="index">
            <div class="row">
              <input
                :id="`calculation-active-${index + 1}`"
                :checked="address.calculationActive ? address.calculationActive : false"
                :data-addressid="address.id"
                :disabled="isReadOnly"
                class="form-checkbox"
                type="checkbox"
                @input="toggleAddressActive"
              />
              <label :for="`calculation-active-${index + 1}`">{{ formatAddress(address) }}</label>
              <div class="address_edit">
                <button v-if="!isReadOnly" aria-label="edit address" class="edit-button" @click.prevent="toggleAddressModal(address)">
                  <svg aria-hidden="true" class="icon-svg" focusable="false">
                    <use xlink:href="#mode"></use>
                  </svg>
                </button>
              </div>
              <div class="address_delete">
                <button v-if="!isReadOnly" aria-label="delete address" class="delete-button" @click.prevent="deleteAddress(address)">
                  <svg aria-hidden="true" class="icon-svg" focusable="false">
                    <use xlink:href="#delete-outline"></use>
                  </svg>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <p>
        <button data-cy-id="add-address-button" v-if="!isReadOnly" @click.prevent="toggleAddressModal(null)">
          <SimpleText text-key="masterdatacompanyInformation.calculationAddressesAddButton"/>
        </button>
      </p>
    </div>

    <div v-if="toggleModal" aria-hidden="false" class="fds-modal">
      <div v-if="loadingAddress" class="spinner-overlay">
        <div aria-label="Indlæser" class="spinner" />
        <div class="spinner-status" role="status">
          <SimpleText text-key='loadspinner.addresscheck' />
        </div>
      </div>
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">
            <span v-if="modalAddressState === 0"><SimpleText text-key="masterdatacompanyInformation.calculationAddressesModalTitleEdit"/></span>
            <span v-else-if="modalAddressState === 1"><SimpleText text-key="masterdatacompanyInformation.calculationAddressesModalTitleAdd"/></span>
          </h2>
          <button aria-label="Luk" class="modal-close button button-unstyled" @click.prevent="handleModal">
            <svg aria-hidden="true" class="icon-svg" focusable="false">
              <use xlink:href="#close"></use>
            </svg>
          </button>
        </div>
        <div class="modal-body">
          <p>
            <strong><SimpleText text-key="masterdatacompanyInformation.calculationAddressesModalDescription"/></strong>
          </p>
          <form @change="formValidation.clearValidField($event)"
                @invalid.capture.prevent="formValidation.handleFormValidity($event, true)">
            <div v-if="showError" aria-atomic="true" class="alert alert-error" role="alert">
              <div class="alert-body">
                <p class="alert-heading">Fejl</p>
                <p class="alert-text">{{ errorMessage }}</p>
              </div>
            </div>
            <div :class="formValidation.formErrors.has('address-required') && 'form-error'" class="form-group">
              <label class="form-label"
                     for="address"><SimpleText text-key="masterdatacompanyInformation.calculationAddressesModalField1Label"/></label
              ><br />
              <label for="address"><SimpleText text-key="masterdatacompanyInformation.calculationAddressesModalField2Label"/></label>
              <input id="address" v-model="modalAddress.address" class="form-input" name="address" required
                     type="text" />
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <button class="button button-primary" @click.prevent="saveAddress">
            <span v-if="modalAddressState === 0"><SimpleText text-key="masterdatacompanyInformation.calculationAddressesModalSaveButtonEdit"/></span>
            <span v-else-if="modalAddressState === 1"><SimpleText text-key="masterdatacompanyInformation.calculationAddressesModalSaveButtonAdd"/></span>
          </button>
          <button class="button button-secondary" @click.prevent="handleModal">
            <SimpleText text-key="sharedContent.cancel"/>
          </button>
        </div>
      </div>
    </div>
    <div v-if="toggleModal" id="modal-backdrop" class="modal-backdrop"></div>
  </div>
</template>

<script lang="ts">
import { Options } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { Address, AddressInfo, Country } from "openapi/generated-clients/climatecompass"
import { FormValidation } from "@/shared/FormValidation"
import { addressClient } from "@/shared/BackendService"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import SimpleText from "@/components/SimpleText.vue";

interface ModalAddress {
  id?: number
  address: string
  state?: string
  calculationActive: boolean
}

@Options({
  components: {SimpleText},
  emits: ["saveAddress", "addressValidationWarning", "deleteAddress"]
})
export default class CompanyInformationAddresses extends BaseComponent {
  @Prop({ required: true })
  addresses: Address[] = []

  @Prop()
  pCountryCode = ""

  @Prop()
  pCountryName = ""

  toggleModal = false
  modalAddress = {} as ModalAddress
  formValidation = new FormValidation()
  ifAddressData = true
  address = ""
  selectedAddress = [{}]
  // null state = -1 | edit state = 0 | new state = 1
  modalAddressState = -1
  showError = false
  errorMessage = ""
  loadingAddress = false
  isReadOnly = this.calculation.state === "readonly"

  get countryLongName() {
    return this.pCountryName
  }

  handleModal() {
    this.toggleModal = !this.toggleModal
  }

  handleAddAddress() {
    this.handleModal()
    this.modalAddress = {} as ModalAddress
  }

  toggleAddressModal(address: Address | null | false) {
    if (address) {
      this.modalAddressState = 0
      this.modalAddress.address = address.street + (address.houseNumberFrom ? " " + address.houseNumberFrom : "") + ", "+ address.zipCode +
        " " + address.city + (address.state ? " ," + address.state : "")
      this.modalAddress.state = address.state
      this.modalAddress.id = address.id
      this.modalAddress.calculationActive = address.calculationActive || false
    } else if (!address) {
      this.modalAddressState = 1
      this.modalAddress = {} as ModalAddress
    }
    this.showError = false
    this.toggleModal = !this.toggleModal
  }

  // emits the index of the address we want to toggle active status
  async toggleAddressActive(event: { target: any }) {
    const addressId = event.target.dataset.addressid
    if (addressId) {
      const _address = this.addresses.find((address) => {
        return address.id == addressId
      })
      _address!.calculationActive = event.target.checked
      this.$emit("saveAddress", _address)
    }
  }

  formatAddressInfo(addressInfo: AddressInfo) {
    return `
        ${addressInfo.address?.freetext},
        ${addressInfo.country}
      `
  }

  async saveAddress() {
    this.loadingAddress = true
    this.formValidation.formErrors.clear()

    if (!this.modalAddress.address) {
      this.formValidation.formErrors.set("address-required", "")
      return
    } else {
      this.formValidation.formErrors.delete("address-required")
    }

    const _address: Address = {
      street: this.modalAddress.address,
      houseNumberFrom: -1,
      placeName: "",
      city: "",
      zipCode: "",
      state: "",
      calculationActive: this.modalAddress.calculationActive,
      freetext: this.modalAddress.address
    }

    // validate adress using BE service
    const addressInfo: AddressInfo = {
      address: _address,
      //country: this.country?.code,
      country: this.pCountryCode,
      vatNo: "",
      companyName: ""
    }

    const response = await addressClient.validateAddress(addressInfo)
      .finally(() => this.loadingAddress = false)
        this.showError = false
    if (response.data.verificationStatus === "None") {
      this.showError = true
      this.errorMessage = this.addressValidationNone.replaceAll("%", this.formatAddressInfo(addressInfo))
      return
    } else if (response.data.verificationStatus === "Partial") {
      this.$emit("addressValidationWarning", this.addressValidationPartial.replaceAll("%", this.formatAddressInfo(addressInfo)))
    } else if (response.data.verificationStatus === "Ambiguous") {
      this.$emit("addressValidationWarning", this.addressValidationAmbiguous.replaceAll("%", this.formatAddressInfo(addressInfo)))
    } else if (response.data.verificationStatus === "Unknown") {
      this.$emit("addressValidationWarning", this.addressValidationUnknown.replaceAll("%", this.formatAddressInfo(addressInfo)))
    } else {
      // Verified is OK, no warning
      this.$emit("addressValidationWarning", "")
    }
    _address.city = response.data.city
    _address.street = response.data.street
    _address.zipCode = response.data.zipCode
    _address.floor = response.data.floor
    _address.houseNumberFrom = response.data.houseNumberFrom
    _address.state = response.data.state

    if (this.modalAddress.id) {
      // editing existing address
      _address.id = this.modalAddress.id
    }
    this.$emit("saveAddress", _address)
    this.toggleAddressModal(false)
  }

  deleteAddress(address: Address) {
    this.$emit("deleteAddress", address)
  }

  formatAddress(address: Address) {
    return `
  ${address.street}${address.houseNumberFrom ?  ' ' + address.houseNumberFrom : ''}${address.houseNumberto ? '-' + address.houseNumberto : ''},
        ${address.floor ? address.floor + ',' : ''}
        ${address.placeName ? address.placeName + ',' : ''}
        ${address.zipCode ? address.zipCode : ''}
        ${address.city ? address.city + ',' : ''}
        ${address.state ? address.state + ',' : ''}
        ${this.country?.name ? this.country?.name : ''}
      `
  }

  get addressValidationNone() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.addressValidationNone')
  }

  get addressValidationPartial() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.addressValidationPartial')
  }

  get addressValidationAmbiguous() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.addressValidationAmbiguous')
  }

  get addressValidationUnknown() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.addressValidationUnknown')
  }

}
</script>

<style lang="scss" scoped>
.add-address {
  margin-top: 2rem;
}

.saved-addresses {
  padding: 0.6rem 0;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-top: 0;

      &::before {
        content: '';
      }

      .icon-svg {
        width: 1.8rem;
        height: 1.8rem;
      }

      .row {
        .address-check {
          //padding-right: 1rem;
          display: inline-flex;
          cursor: pointer;
          position: relative;

          span {
            padding: 0.5rem 0.25rem;
          }

          input[type='checkbox'] {
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            border: 1px solid #1a1a1a;
            border-radius: 2px;
            outline: none;
            width: 1.8rem;
            height: 1.8rem;
            cursor: pointer;
            transition-duration: 0.3s;

            &:checked {
              background-color: #1a1a1a;
            }

            &:checked + span::before {
              content: '\2713';
              display: block;
              text-align: center;
              color: #ffffff;
              position: absolute;
              left: 0.2rem;
              bottom: 2.5rem;
            }
          }
        }

        .address-name {
          flex: 2;
        }
      }
    }
  }
}

.edit-button {
  margin-left: 6px;
}

.edit-button,
.copy-button,
.delete-button {
  background: transparent;
  border: 0;
}

</style>
