export const downloadFile = function(data: Blob, fileName: string) {
  const downloadLink = document.createElement('a')
  document.body.appendChild(downloadLink)

  const content = URL.createObjectURL(data)
  downloadLink.href = content
  downloadLink.target = '_blank'
  downloadLink.download = fileName
  downloadLink.click()
  window.URL.revokeObjectURL(content)
  document.body.removeChild(downloadLink)
}
