<template>
  <div class="container">
    <div class="row py-6">
        <ReductionTarget mode="print" :forecastId="forecastId"></ReductionTarget>
    </div>
  </div>
</template>

<script lang="ts">
import Chart from "chart.js"
import { Options } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import SimpleText from "@/components/SimpleText.vue"
import ReductionTarget from "@/components/forecast/ReductionTarget.vue";

@Options({
  components: {
    ReductionTarget,
    SimpleText,
  }
})
export default class PrintReductionTarget extends BaseComponent {
  @Prop()
  forecastId?: number
  @Prop()
  behalfOfVatNr?: string

  printMediaQuery = window.matchMedia("print")

  resizeCharts() {
    for (const id in Chart.instances) {
      Chart.instances[id].resize()
    }
  }

  mediaQueryListener(mql: MediaQueryListEvent) {
    if (mql.matches) {
      //this.resizeCharts() ??
    }
  }

  // this.printMediaQuery.addListener(this.mediaQueryListener);

  async unmounted() {
    this.printMediaQuery.removeListener(this.mediaQueryListener)
    window.removeEventListener("beforeprint", this.resizeCharts)
  }

  async beforeMount() {
    this.printMediaQuery.addListener(this.mediaQueryListener)
    window.onafterprint = window.close;
    window.print()
  }
}
</script>
