<template>
  <CalculatorTabComponent
    >
    <template v-slot:calculator-primary-1="slotProps">
      <CommonProductsAndServicesCalculator
        :subgroupingId="primary ? constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER : constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER_II"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
        :expanded="true"
      >
      </CommonProductsAndServicesCalculator>
    </template>
    <template v-slot:calculator-primary-2="slotProps">
      <CommonProductsAndServicesCalculator
        :subgroupingId="primary ? constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER :  constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER_II"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
      >
      </CommonProductsAndServicesCalculator>
    </template>
    <template v-slot:calculator-secondary-1="slotProps">
      <CommonProcumentCalculator
        :subgroupingId="primary ? constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER : constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II "
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
      >
      </CommonProcumentCalculator>
    </template>
    <template v-slot:calculator-secondary-2="slotProps">
      <CommonProcumentCalculator
        :subgroupingId="primary ? constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER : constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
      >
      </CommonProcumentCalculator>
    </template>
  </CalculatorTabComponent>
</template>

<script lang="ts">
import { Options, Prop } from "vue-property-decorator"
import BaseComponent from "../base-components/BaseComponent"
import CalculatorTabComponent from "../CalculatorTabComponent.vue"
import ElectricityCalculator from "./calculators/CommonProcumentCalculator.vue"
import OtherCalculator from "./calculators/OtherCalculator.vue"
import CommonProcumentCalculator
  from "@/components/calculator/procurement/calculators/CommonProcumentCalculator.vue"
import CommonProductsAndServicesCalculator
  from "@/components/calculator/procurement/calculators/CommonProductsAndServicesCalculator.vue"

@Options({
  components: { CommonProductsAndServicesCalculator, CommonProcumentCalculator, CalculatorTabComponent }
})
export default class ProductAndServicesView extends BaseComponent {
  @Prop()
  primary? : boolean

}
</script>

<style scoped>

</style>