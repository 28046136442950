import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "mt-6" }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleText = _resolveComponent("SimpleText")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("a", {
      href: _ctx.toLink,
      class: "button button-primary mt-705",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.next($event)), ["prevent"]))
    }, [
      _createVNode(_component_SimpleText, { "text-key": "sharedContent.next" })
    ], 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: _ctx.fromLink,
        class: "back-link",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.back($event)), ["prevent"]))
      }, [
        _createVNode(_component_SimpleText, { "text-key": "sharedContent.back" })
      ], 8, _hoisted_3)
    ])
  ], 64))
}