
function getUserLocale() {
  return window.localStorage.getItem('locale') || 'da';
}

export const simpleLocaleSpecificStringToNumber = (numberInput : string): number => {
  //console.error("simpleLocaleSpecificStringToNumber numberInput=",numberInput)
  if (getUserLocale().startsWith("en")) {
    return Number(numberInput.replaceAll(",",""))
  }
  return Number(numberInput.replaceAll(".","").replace(",", "."))
}

export const simpleLocaleSpecificStringOrNumberToNumber = (numberInput : string): number => {
  //console.log('simpleLocaleSpecificStringOrNumberToNumber', numberInput, typeof numberInput)
  if (typeof numberInput === 'number') {
    return numberInput
  }
  return simpleLocaleSpecificStringToNumber(numberInput)
}

const getDecimalSeparator= (): string => {
  // returns the locale string from session storage if it is present, otherwise returns the default locale
  return getUserLocale().startsWith("en") ? '.' : ','
}

export const unprettify = (value?: string | number) => {
  //console.log("unprettify start", value, typeof value)
  if (!value) {
    return
  }
  let _value

  if (typeof value === 'number') {
    _value = value.toString()
  } else {
    _value = '' + value
  }

  //console.log("unprettify", _value, value, typeof _value, typeof value)
  return simpleLocaleSpecificStringToNumber(_value)
}

const countTrailingZeros = (value: string) => {
  const _value = Array.from(value)

  let count = 0
  let digit = _value.pop()

  if (digit !== '0') {
    return 0
  }

  while (digit === '0') {
    count++
    digit = _value.pop()
  }
  return count
}




export const prettify = (value?: string | number, forceFractionDigits?: number) => {
  if (!value && Number(value) !== 0) {
    return
  }
  let _value
  if (typeof value === 'number') {
    _value = value.toString()
  } else {
    _value = '' + value
  }
  if (isNaN(Number(_value))) {
    return value?.toString()
  }

  //console.error("prettify _value=" + _value)
  let result = Number(_value).toLocaleString(getUserLocale(), {
    minimumFractionDigits: forceFractionDigits ? forceFractionDigits : 0,
    maximumFractionDigits: forceFractionDigits || forceFractionDigits === 0 ? forceFractionDigits : 20
  })

  //console.log("prettify 1. result=" + result)

  if (_value.charAt(_value.length - 1) === '.') {
    return result + ','
  }

  //console.log("prettify 2. result=" + result)

  // Not sure exactly which situations this is used, but it causes issues when we try to force rounding to 2 decimals in some disabled fields,
  // so we disable it in those cases
  // Include all trailing decimal zeros
  if (!(forceFractionDigits || forceFractionDigits === 0) && _value.split('.')[1]) {
    if (!result.includes(getDecimalSeparator())) {
      result += getDecimalSeparator()
    }
    for (let i = 0; i < countTrailingZeros(_value.split('.')[1]); i++) {
      result += '0'
    }
  }

  //console.log("prettify 3. result=" + result)

  //Numbers should not be shown with starting "-", if value with decimals show is 0 (eg. we do not want o show "-0.00" for the number value -0.001)
  if (simpleLocaleSpecificStringToNumber(result) == 0 && result.startsWith("-")) {
    result = result.substr(1)
  }
  //console.log("prettify 4. result=" + result)

  return result
}

export const graphPrettify = (x: number, showWithDecimals = true) => {
  if (!x) return (getUserLocale().startsWith("en") ? '0.00' : '0,00')
  if (!showWithDecimals) return x.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  if (Math.abs(x) < 1 ) return prettify(x, 2);
  if (Math.abs(x) < 10 ) return prettify(x, 1);
  return prettify(x, 0);
}
