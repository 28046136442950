<template>

  <div class="wrapper-div" id="resultsEsg-wrapper-div">

    <div id="section-one-container" class="section-one-container">
      <h1 id="section-one-main-header">
        <SimpleText text-key="results.esg.result-main-header"/>
      </h1>
      <p id="main-description-text" class="main-description-text">
        <RichText text-key="results.esg.result-main-description-text"/>
      </p>
      <table id="esg-results-table" class="table results-table w-percent-100" v-if="results?.groupingResults"
             aria-describedby="esg-result-table-header1" aria-label="e-nøgletal resultater">
        <thead>
        <tr class="bg-primary text-color-white">
          <th id="esg-result-table-header1" colspan="3" scope="colgroup">
            <SimpleText text-key="results.esg.result-table-header-one"/>
          </th>
        </tr>
        </thead>
        <thead>
        <tr style="font-weight: 600" class="w-percent-100">
          <th headers="esg-result-table-header1" style="width: 55%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table-subheader-one">
            <SimpleText text-key="results.esg.result-table-subheader-one"></SimpleText>
          </th>
          <th headers="esg-result-table-header1" style="width: 15%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table-subheader-two">
            <SimpleText text-key="results.esg.result-table-subheader-two"></SimpleText>
          </th>
          <th headers="esg-result-table-header1" style="width: 30%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table-subheader-three">
            <SimpleText text-key="results.esg.result-table-subheader-three"></SimpleText>
          </th>
        </tr>
      </thead>
        <tbody role="rowgroup">
        <tr id="esg-result-scope-one">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-scope-one"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-one"></SimpleText>
          </td>
          <td v-if="results.esgResult?.scope1Total"> {{ doPrettify(results.esgResult.scope1Total) }}</td>
          <td v-else class="italic-text">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-scope-two">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-scope-two"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-one"></SimpleText>
          </td>
          <td v-if="results.esgResult?.scope2Total"> {{ doPrettify(results.esgResult.scope2Total) }}</td>
          <td v-else class="italic-text">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-scope-three">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-scope-three"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-one"></SimpleText>
          </td>
          <td v-if="results.esgResult?.scope3Total"> {{ doPrettify(results.esgResult.scope3Total) }}</td>
          <td v-else class="italic-text">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-scopes-total">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-scopes-total"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-one"></SimpleText>
          </td>
          <td v-if="results.ghgCalculatedResult?.totalResult"> {{
              doPrettify(results.ghgCalculatedResult.totalResult)
            }}
          </td>
          <td v-else class="italic-text">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-co2intensity">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-co2intensity"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-two"></SimpleText>
          </td>
          <td v-if="results.esgResult?.co2Intensity"> {{ doPrettify(results.esgResult.co2Intensity) }}</td>
          <td v-else class="italic-text">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-energytotal">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-energytotal"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-three"></SimpleText>
          </td>
          <td v-if="results.esgResult?.energyTotal"> {{ doPrettify(results.esgResult.energyTotal) }}</td>
          <td v-else class="italic-text">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-energyintensity">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-energyintensity"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-four"></SimpleText>
          </td>
          <td v-if="results.esgResult?.energyIntensity"> {{ doPrettify(results.esgResult.energyIntensity) }}</td>
          <td v-else class="italic-text">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-energyrenewable">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-energyrenewable"></SimpleText>
            <Tooltip
                aria-label="result-energy-renewable-percentage-tooltip"
                textKey="results.esg.result-energy-renewable-percentage-tooltip"
                style="align-content: flex-end"
                size="md-gr"
            ></Tooltip>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-five"></SimpleText>
          </td>
          <td v-if="results.esgResult?.energyRenewablePercentage">
            {{ doPrettify(results.esgResult.energyRenewablePercentage) }}
          </td>
          <td v-else class="italic-text">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-waterconsumption">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-waterconsumption"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-six"></SimpleText>
          </td>
          <td v-if="results.esgResult?.waterConsumption"> {{ doPrettify(results.esgResult.waterConsumption) }}</td>
          <td v-else class="italic-text">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-wastehazardous">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-wastehazardous"></SimpleText>
            <Tooltip
                aria-label="result-result-waste-hazardous-percentage-tooltip"
                textKey="results.esg.result-waste-hazardous-percentage-tooltip"
                style="align-content: flex-end"
                size="md-gr"
            ></Tooltip>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-five"></SimpleText>
          </td>
          <td v-if="results.esgResult?.wasteHazardousPercentage">
            {{ doPrettify(results.esgResult.wasteHazardousPercentage) }}
          </td>
          <td v-else class="italic-text">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-wasterecycled">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-wasterecycled"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-five"></SimpleText>
          </td>
          <td v-if="results.esgResult?.wasteRecycledPercentage"> {{
              doPrettify(results.esgResult.wasteRecycledPercentage)
            }}
          </td>
          <td v-else class="italic-text">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div id="section-two-container" class="section-two-container">
      <h2 id="section-two-main-header">
        <SimpleText text-key="results.esg.result-section-two-main-header"/>
      </h2>
      <p id="section-two-description-text" class="mw-100">
        <SimpleText text-key="results.esg.result-section-two-main-description-text.simple"/>
      </p>

      <table id="esg-parameter-table" class="table results-table w-percent-100"
             aria-describedby="esg-result-table-header2" aria-label="parameter brugt til beregning af e-nøgletal">
        <thead>
        <tr class="bg-primary text-color-white">
          <th id="esg-result-table-header2" colspan="3" scope="colgroup">
            <SimpleText text-key="results.esg.result-table-header-two"></SimpleText>
          </th>
        </tr>
        </thead>
       <thead>
        <tr style="font-weight: 600">
          <th headers="esg-result-table-header2" style="width: 55%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table2-subheader-one">
            <SimpleText text-key="results.esg.result-table-subheader-one"></SimpleText>
          </th>
          <th headers="esg-result-table-header2" style="width: 15%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table2-subheader-two">
            <SimpleText text-key="results.esg.result-table-subheader-two"></SimpleText>
          </th>
          <th headers="esg-result-table-header2" style="width: 30%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table2-subheader-three">
            <SimpleText text-key="results.esg.result-table-subheader-three"></SimpleText>
          </th>
        </tr>
       </thead>
        <tbody role="rowgroup">
        <tr id="esg-parameter-turnover">
          <td class="align-text-left" headers="results.esg.result-table2-subheader-one">
            <SimpleText text-key="results.esg.result-table-row-turnover"></SimpleText>
          </td>
          <td headers="results.esg.result-table2-subheader-two">
            <SimpleText text-key="results.esg.result-table-measurement-unit-seven"></SimpleText>
          </td>
          <td v-if="results.esgResult?.turnover" headers="results.esg.result-table2-subheader-three"> {{ doPrettify(results.esgResult.turnover) }}</td>
          <td v-else class="italic-text">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-parameter-energyrenewable">
          <td class="align-text-left" headers="results.esg.result-table2-subheader-one">
            <SimpleText text-key="results.esg.result-table-row-renewableenergy-total"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-three" headers="results.esg.result-table2-subheader-two"></SimpleText>
          </td>
          <td v-if="results.esgResult?.energyRenewableTotal" headers="results.esg.result-table2-subheader-three"> {{
              doPrettify(results.esgResult.energyRenewableTotal)
            }}
          </td>
          <td v-else class="italic-text">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-parameter-waste-total">
          <td class="align-text-left" headers="results.esg.result-table2-subheader-one">
            <SimpleText text-key="results.esg.result-table-row-waste-total"></SimpleText>
          </td>
          <td headers="results.esg.result-table2-subheader-two">
            <SimpleText text-key="results.esg.result-table-measurement-unit-eight"></SimpleText>
          </td>
          <td v-if="results.esgResult?.wasteTotal" headers="results.esg.result-table2-subheader-three"> {{ doPrettify(results.esgResult.wasteTotal) }}</td>
          <td v-else class="italic-text">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-parameter-hazardouswaste-total">
          <td class="align-text-left" headers="results.esg.result-table2-subheader-one">
            <SimpleText text-key="results.esg.result-table-row-hazardouswaste-total"></SimpleText>
          </td>
          <td headers="results.esg.result-table2-subheader-two">
            <SimpleText text-key="results.esg.result-table-measurement-unit-eight"></SimpleText>
          </td>
          <td v-if="results.esgResult?.wasteHazardousTotal" headers="results.esg.result-table2-subheader-three"> {{ doPrettify(results.esgResult.wasteHazardousTotal) }}</td>
          <td v-else class="italic-text">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-parameter-recycledwaste-total">
          <td class="align-text-left" headers="results.esg.result-table2-subheader-one">
            <SimpleText text-key="results.esg.result-table-row-recycledwaste-total"></SimpleText>
          </td>
          <td headers="results.esg.result-table2-subheader-two">
            <SimpleText text-key="results.esg.result-table-measurement-unit-eight"></SimpleText>
          </td>
          <td v-if="results.esgResult?.wasteRecycledTotal" headers="results.esg.result-table2-subheader-three"> {{ doPrettify(results.esgResult.wasteRecycledTotal) }}</td>
          <td v-else class="italic-text">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">

import {Menu} from "@/shared/MenuStructure";
import {useRouter} from "vue-router";
import {Options} from "vue-class-component";
import RichText from "@/components/RichText.vue";
import SimpleText from "@/components/SimpleText.vue";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import ContentfulContent from "@/shared/ContentfulContent";
import {Prop} from "vue-property-decorator";
import {
  GroupingCalculatedResults
} from "../../../../openapi/generated-clients/climatecompass";
import Tooltip from "@/components/Tooltip.vue";

@Options({
  components: {
    Tooltip,
    RichText,
    SimpleText,
  }
})
export default class ResultsESG extends BaseComponent {

  @Prop()
  results: GroupingCalculatedResults = {} as GroupingCalculatedResults

  @Prop()
  printMode?: boolean

  menu = {} as Menu
  router = useRouter()

  contentfulContent: ContentfulContent = this.store.getters.getContentfulContent

  async beforeMount() {
    this.scrollToTargetWithOffset('section-one-container', 10)
  }

}
</script>

<style scoped lang="scss">

.wrapper-div {
  padding-top: 3em;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.section-one-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5em;
  max-width: 100%;
  margin: 2px;
}

.section-two-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-top: 10px;
}

.main-description-text {
  max-width: 100%;
}

.italic-text {
  font-style: italic;
}

tr > th.py-405.align-text-left.bg-alternative {
  color: black;
  border-right: 1px solid $color-primary !important;
  border-bottom: 1px solid #BFBFBF !important;
}

</style>