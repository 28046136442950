<template>
  <div class="toast-container">
    <div ref="toast" :class="'toast-' + type" class="toast hide d-print-none" role="status">
      <div class="toast-icon"></div>
      <div class="toast-message">
        <p class="bold">{{ header }}</p>
        <button v-if="showCloseButton" class="toast-close" @click="onClose">Luk</button>
        <p v-html="message"></p>
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"

export default class Toast extends Vue {
  @Prop()
  type?: string
  @Prop()
  show?: boolean
  @Prop()
  header?: string
  @Prop()
  message?: string
  @Prop()
  showCloseButton = true

  toast: any = {}

  onClose() {
    this.toast.classList.remove("show")
    this.toast.classList.add("hide")
    this.$emit("toastOnClose")
  }

  onShow() {
    this.toast.classList.remove("hide")
    this.toast.classList.add("show")
  }

  onBeforeMount() {
    if (this.show) {
      this.onShow()
    }
  }

  @Watch("show")
  watchShow() {
    if (this.show) {
      this.onShow()
    } else {
      this.onClose()
    }
  }
}
</script>
