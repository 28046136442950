<template>
  <SharedHeader showCalculationRow>
    <template #firstColumn>
      <div>
        <p class="h1">
          <SimpleText text-key="results.headerTitle"/>
          {{ calculation.calculationPurpose.name }}:
          <br />
          <span class="h2">
            {{ doPrettify(totalEmission) }}
            <span v-html='getContentfulString("results.tonCo2")'></span>
          </span>
        </p>
        <!--div v-html="documentToHtmlString(pageProperties.headerText)"></div-->
        <div><RichText text-key="results.headerText"/></div>
      </div>
    </template>
  </SharedHeader>

  <SharedHeader useOneColumn="true" header-padding="py-4">
    <template #firstColumn>
      <div class="container">
        <div class="row py-0 align-content-md-center">
          <div class="col-3">
            <h6 class="mt-0 pl-3"><SimpleText text-key="sharedContent.calculation"/></h6>
            <h5 class="mt-0 pl-3">{{ calculation.calculationPurpose?.name }}</h5>
          </div>

          <div class="col-3">
            <h6 class="mt-0 pl-3"><SimpleText text-key="sharedContent.period"/></h6>
            <h5
                class="mt-0 pl-3"
                v-html="formatDates(calculation.calculationPurpose.startDate, calculation.calculationPurpose.endDate)"
            />
          </div>
          <div class="col-3">
            <h6 class="mt-0 pl-3">
              <SimpleText text-key="sharedContent.country"/>
            </h6>
            <h5 class="mt-0 pl-3">{{ country }}</h5>
          </div>
          <div class="col-3" >
            <h6 class="mt-0 pl-3">
              {{ emissionCoefficientTitle }}
            </h6>
            <h5 class="mt-0 pl-3"> {{ getCoefficientVersionYear }} </h5>
          </div>
        </div>
      </div>
    </template>
  </SharedHeader>


  <div class="container">
    <div class="row py-6">
      <div class="col-12 px-0">
        <MethodSelection :printMode="true" :results="results" />
        <div class="force-page-break"></div>
        <MainResults :printMode="true" :results="results"></MainResults>
        <div class="force-page-break"></div>
        <MainResultsGHG :printMode="true" :results="results"></MainResultsGHG>
        <div class="force-page-break"></div>
        <PartialResults :printMode="true" :results="results"></PartialResults>
        <div class="force-page-break"></div>
        <PartialResultsGHG :printMode="true" :results="results"></PartialResultsGHG>
        <div class="force-page-break"></div>
        <Scope3Results :printMode="true" :results="results"></Scope3Results>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import SharedHeader from "@/components/calculator/shared/SharedHeader.vue"
import MainResults from "@/components/calculator/results/MainResults.vue"
import MainResultsGHG from "@/components/calculator/results/MainResultsGHG.vue"
import PartialResults from "@/components/calculator/results/PartialResults.vue"
import PartialResultsGHG from "@/components/calculator/results/PartialResultsGHG.vue"
import MethodSelection from "@/components/calculator/results/MethodSelection.vue"
import { Calculation, Country, GroupingCalculatedResults } from "openapi/generated-clients/climatecompass"
import { calculationClient, countryClient } from "@/shared/BackendService"
import Chart from "chart.js"
import { Options } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import Scope3Results from "@/components/calculator/results/Scope3Results.vue";
import SimpleText from "@/components/SimpleText.vue";

@Options({
  components: {
    SimpleText,
    Scope3Results,
    SharedHeader,
    MainResults,
    MainResultsGHG,
    PartialResults,
    PartialResultsGHG,
    MethodSelection
  }
})
export default class PrintResults extends BaseComponent {
  @Prop()
  calculationId?: number
  @Prop()
  behalfOfVatNr?: string

  currentCalculation = { calculationPurpose: {} } as Calculation
  results: GroupingCalculatedResults = {} as GroupingCalculatedResults
  totalEmission = ""
  emissionCoefficientTitle = this.getContentfulString('shared.header-emission-title')
  printMediaQuery = window.matchMedia("print")

  resizeCharts() {
    for (const id in Chart.instances) {
      Chart.instances[id].resize()
    }
  }

  mediaQueryListener(mql: MediaQueryListEvent) {
    if (mql.matches) {
      this.resizeCharts()
    }
  }

  // this.printMediaQuery.addListener(this.mediaQueryListener);

  async unmounted() {
    this.printMediaQuery.removeListener(this.mediaQueryListener)
    window.removeEventListener("beforeprint", this.resizeCharts)
  }

  async beforeMount() {
    this.printMediaQuery.addListener(this.mediaQueryListener)

    this.currentCalculation = (await calculationClient.getCalculation(this.calculationId!, this.behalfOfVatNr ? this.behalfOfVatNr : 'null')).data || {}
    await this.store.dispatch("setCurrentCalculation", this.currentCalculation)

    this.results = (await calculationClient.getResultCalculations(this.calculationId!, this.behalfOfVatNr ? this.behalfOfVatNr : 'null')).data || {}

    if (this.results.groupingResults && this.results.groupingResults.length >= 2) {
      this.results.groupingResults?.sort((a, b) => {
        return a.groupingId! - b.groupingId!
      })
    }

    this.totalEmission = this.results
      .groupingResults!.reduce((sum: number, cur: any) => sum + Number(cur.calculatedResult?.scopesResult), 0)
      .toFixed(2)

    this.setDocumentTitle(`${this.headerTitle} ${this.calculation.calculationPurpose.name}`)
  }

  async updated() {
    const fetchedCountryName =
        await countryClient.fetchCountries().then((response) => {
          return response.data.countries?.find((country: Country) => {
                        return country.code === this.country
          })?.name || ""
        }).catch((error) => {
          console.warn(error)

        })

    this.$nextTick(() => {
      if (fetchedCountryName) {
        window.onafterprint = window.close;
        window.print()
      }
    })
  }
  get getCoefficientVersionYear() {
    //nødvendigt at bruge getter for korrekt hent og print denne værdi
    return this.currentCalculation.emissionCoefficientsVersion?.year
  }

  get headerTitle() {
    return this.store.getters.getContentfulContent.findSimpleText('results.headerTitle')
  }

}
</script>
