import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createTextVNode as _createTextVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8a3e2da2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "",
  style: {"width":"50%","display":"flex"}
}
const _hoisted_2 = { class: "no-line-break" }
const _hoisted_3 = { style: {"width":"50%","position":"relative","top":"-5px"} }
const _hoisted_4 = {
  class: "",
  style: {"width":"10%"}
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  class: "",
  style: {"width":"50%","display":"flex"}
}
const _hoisted_7 = { class: "no-line-break" }
const _hoisted_8 = { style: {"width":"50%","position":"relative","top":"-5px"} }
const _hoisted_9 = {
  class: "",
  style: {"width":"10%"}
}
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { key: 3 }
const _hoisted_12 = {
  key: 0,
  class: "mt-9"
}
const _hoisted_13 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleText = _resolveComponent("SimpleText")!
  const _component_SwitchToggle = _resolveComponent("SwitchToggle")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_NextAndBack = _resolveComponent("NextAndBack")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showEFSelection)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("strong", null, [
              _createVNode(_component_SimpleText, { "text-key": "sharedContent.emissionsTitle" })
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_SwitchToggle, {
              checked: _ctx.currentEF === 'Miljødeklaration',
              countryLocked: _ctx.country !== 'DK',
              leftLabel: this.electricityEnvironmentDeclarationTrue ?? '',
              rightLabel: this.electricityEnvironmentDeclarationFalse ?? '',
              fieldId: "metodevalgId",
              "onUpdate:checked": _ctx.setEF
            }, null, 8, ["checked", "countryLocked", "leftLabel", "rightLabel", "onUpdate:checked"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Tooltip, {
              text: this.calculatorPage.chooseEmissionFactorTooltip,
              size: "lg-h3"
            }, null, 8, ["text"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showRFISelection)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.pageProperties.chooseEmissionFactor)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_5, _toDisplayString(_ctx.pageProperties.chooseEmissionFactor), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("strong", null, [
                _createVNode(_component_SimpleText, { "text-key": "sharedContent.emissionsTitle" })
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_SwitchToggle, {
                checked: _ctx.currentRFI === 'includeRfi',
                countryLocked: false,
                leftLabel: this?.rfiTrue,
                rightLabel: this?.rfiFalse,
                fieldId: "metodevalgId2",
                "onUpdate:checked": _ctx.setRFI
              }, null, 8, ["checked", "leftLabel", "rightLabel", "onUpdate:checked"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_Tooltip, {
                text: _ctx.calculatorPageTransport.chooseEmissionFactorTooltip,
                size: "lg-h3"
              }, null, 8, ["text"])
            ])
          ])
        ], 64))
      : _createCommentVNode("", true),
    ((_ctx.calculatorPrimaryFirst.title || _ctx.calculatorPrimarySecond.title || _ctx.calculatorPrimaryThird.title) && _ctx.pageProperties.title)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_10, [
          _createTextVNode(_toDisplayString(_ctx.pageProperties.title) + " ", 1),
          _createVNode(_component_Tooltip, {
            text: this.getTooltip(_ctx.pageProperties.titleTooltip, this.contentfulId),
            size: "lg"
          }, null, 8, ["text"])
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "calculator-primary-1", {
      title: _ctx.calculatorPrimaryFirst.title,
      tooltip: this.getTooltip(_ctx.calculatorPrimaryFirst.titleTooltip, _ctx.calculatorPrimaryFirstContentfulId)
    }, undefined, true),
    _renderSlot(_ctx.$slots, "calculator-primary-2", {
      title: _ctx.calculatorPrimarySecond.title,
      tooltip: this.getTooltip(_ctx.calculatorPrimarySecond.titleTooltip, _ctx.calculatorPrimarySecondContentfulId)
    }, undefined, true),
    _renderSlot(_ctx.$slots, "calculator-primary-3", {
      title: _ctx.calculatorPrimaryThird.title,
      tooltip: this.getTooltip(_ctx.calculatorPrimaryThird.titleTooltip, _ctx.calculatorPrimaryThirdContentfulId)
    }, undefined, true),
    (_ctx.calculatorSecondaryFirst.title || _ctx.calculatorSecondarySecond.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          (_ctx.pageProperties.secondaryTitle)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_12, [
                _createTextVNode(_toDisplayString(_ctx.pageProperties.secondaryTitle) + " ", 1),
                _createVNode(_component_Tooltip, {
                  text: _ctx.pageProperties.secondaryTitleTooltip,
                  size: "lg"
                }, null, 8, ["text"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "w-percent-100 w-percent-100-child",
            innerHTML: _ctx.documentToHtmlString(_ctx.pageProperties.secondaryDescription)
          }, null, 8, _hoisted_13),
          _renderSlot(_ctx.$slots, "calculator-secondary-1", {
            title: _ctx.calculatorSecondaryFirst.title,
            tooltip: this.getTooltip(_ctx.calculatorSecondaryFirst.titleTooltip, _ctx.calculatorSecondaryFirstContentfulId)
          }, undefined, true),
          _renderSlot(_ctx.$slots, "calculator-secondary-2", {
            title: _ctx.calculatorSecondarySecond.title,
            tooltip: this.getTooltip(_ctx.calculatorSecondarySecond.titleTooltip, _ctx.calculatorSecondarySecondContentfulId)
          }, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_NextAndBack, {
      from: _ctx.from,
      to: _ctx.to
    }, null, 8, ["from", "to"])
  ], 64))
}