import {createStore} from 'vuex'
import * as getters from './getters'
import * as mutations from './mutations'
import actions from './actions'
import {
  AccessInfo,
  Calculation,
  CalculationPurpose,
  ComparisonOverviewInfo,
  ContactPerson,
  EMFMasterdata,
  ExcelMappingdata,
  ForecastInfo,
  Statistic
} from 'openapi/generated-clients/climatecompass'

import {VuexOidcClientSettings, vuexOidcCreateStoreModule} from 'vuex-oidc'

import {oidcConfig} from '@/config/oidc'
import {accessTokenExpiredEvent, accessTokenExpiringEvent, loginEvent, logoutEvent} from './AuthenticationEventHandler'
import {User} from 'oidc-client'
import {CombinedField} from "@/types/CombinedField"
import ThirdpartyInfo from "@/types/ThirdpartyInfo";
import ContentfulContent from "@/shared/ContentfulContent";
import {MenuStructure} from "@/shared/MenuStructure";
import Forecast from "@/components/forecast/Forecast.vue";
import {PendingForecastEntry} from "@/types/PendingForecastEntry";

export interface State {
  scopeFields: CombinedField[]
  currentCalculationPageData: any
  currentUser: User
  userClientLoadTimestamp: string
  contactPerson: ContactPerson
  currentCalculation: Calculation
  calculations: Calculation[]
  statistics: Statistic[]
  emfMasterdataList: EMFMasterdata[]
  excelMappingList: ExcelMappingdata[]
  currentAccess: AccessInfo
  thirdpartyInfo: ThirdpartyInfo
  accesses: AccessInfo[]
  givenAccesses: AccessInfo[]
  forecasts: Forecast[]
  comparisons: ComparisonOverviewInfo[]
  pendingForecasts: PendingForecastEntry[]
  currentForecastInfo: ForecastInfo

  accessTokenExpiring: boolean
  currentLocale: string
  useTextKeys: boolean
  hasConsented: boolean
  contentfulEntries: Map<string, object>
  contentfulContent: ContentfulContent
  regexPatterns: Map<string, string>
  menuStructure: MenuStructure
}

export default createStore<State>({
  strict: process.env.NODE_ENV !== 'production',

  state: {
    scopeFields: [] as CombinedField[],
    currentCalculationPageData: {},
    currentUser: {} as User,
    contactPerson: {} as ContactPerson,
    currentCalculation: {
      calculationPurpose: {} as CalculationPurpose
    } as Calculation,
    userClientLoadTimestamp: '0',
    calculations: [] as Calculation[],
    accessTokenExpiring: false,
    currentLocale: {} as string,
    useTextKeys: false,
    hasConsented: false,
    contentfulEntries: new Map<string, object>(),
    accesses: [] as AccessInfo[],
    givenAccesses: [] as AccessInfo[],
    currentAccess: {} as AccessInfo,
    thirdpartyInfo: {} as ThirdpartyInfo,
    contentfulContent: {} as ContentfulContent,
    regexPatterns: new Map<string, string>(),
    menuStructure: {} as MenuStructure,
    forecasts: [] as Forecast[],
    comparisons: [] as ComparisonOverviewInfo[],
    pendingForecasts: [] as PendingForecastEntry[],
    currentForecastInfo: {} as ForecastInfo,
    statistics: [] as Statistic[],
    emfMasterdataList: [] as EMFMasterdata[],
    excelMappingList: [] as ExcelMappingdata[],
  },
  getters,
  mutations,
  actions,

  modules: {
    oidcStore: vuexOidcCreateStoreModule(
      oidcConfig.oidcSettings as VuexOidcClientSettings,
      {
        namespaced: true,
        dispatchEventsOnWindow: true
      },
      {
        userLoaded: (user) => loginEvent(user),
        userSignedOut: () => logoutEvent(),
        accessTokenExpiring: () => accessTokenExpiringEvent(),
        accessTokenExpired: () => accessTokenExpiredEvent()
      }
    )
  }
})
