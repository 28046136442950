<template>
  <label v-if="label" :for="id + '-input'" class="form-label">{{ label }}</label>
  <input
    data-cy-id="datalist-input"
    :id="id+'-input'"
    v-model="textSelected"
    :data-error="'_Ukendt land'"
    :disabled="disabled"
    :list="id"
    :name="id+'-input'"
    class="form-input"
    type="text"
    v-on:blur="onBlur"
    @change="$emit('input-updated')"
    @click="$emit('input-clicked')"
  >
  <datalist :id="id" data-cy="data-list">
    <option v-for="(option, index) in options" :key="index">{{ option.name }}</option>
  </datalist>
</template>

<script lang="ts">
interface OptionType {
  name: string
  code?: string
  value?: string
}

import { Options, Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Options({
  emits: ["optionFoundSuccess", "value-selected", "input-updated", "input-clicked", "update:value-selected"]
})
export default class DataList extends Vue {
  @Prop()
  label?: string
  @Prop()
  valueSelected = {} as OptionType
  @Prop()
  options = [] as Array<OptionType>
  @Prop()
  disabled = false

  id = Math.floor(Math.random() * (99999 - 10000) + 10000)

  get textSelected() {
    return this.valueSelected.name
  }

  set textSelected(val) {
    if (this.options.find((option) => option.name === val)) {
      this.$emit(
        "update:value-selected",
        this.options.find((option) => option.name === val)
      )
    }
  }

  onBlur(e: any) {
    const value = e.target.value
    this.$emit("optionFoundSuccess", !!this.options.find((option) => option.name === value))
  }
}
</script>

<style scoped></style>
