<template>
  <div>
    <table :id="id" aria-label="oversigt over sammenligninger" class="table w-percent-100">
      <thead>
      <tr v-if="tableHeaders" class="bg-primary text-color-white">
        <th
            v-for="(tableHeader, index) in tableHeaders"
            :id="`table-header-${index}-${id}`"
            :key="tableHeader.sortKey"
            class="clickable"
            scope="col"
            @click="sort(tableHeader.sortKey)"
        >
          <div class="nowrap">
            {{ tableHeader.headline }}
            <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
              <path
                  :fill="currentSort === tableHeader.sortKey && currentSortDir === 'asc' ? 'white' : ''"
                  clip-rule="evenodd"
                  d="M9.99419 4.75003L6.00003 0.755859L2.00586 4.75003H9.99419Z"
                  fill-rule="evenodd"
                  stroke="white"
              />
              <path
                  :fill="currentSort === tableHeader.sortKey && currentSortDir === 'desc' ? 'white' : ''"
                  clip-rule="evenodd"
                  d="M2.00586 7.25003L6.00003 11.2442L9.99419 7.25003H2.00586Z"
                  fill-rule="evenodd"
                  stroke="white"
              />
            </svg>
          </div>
        </th>
        <!-- If the last th is an action column with no header text -->
        <th :id="`table-header-action-${id}`" scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="sortedComparisons.length === 0 || !sortedComparisons">
        <td :headers="getRowDataHeaderDefinition(id)" class="table-no-content" colspan="5">
          <SimpleText :textKey="'comparison.overview.no-items-in-list'" />
        </td>
      </tr>
      <tr v-for="comparison in sortedComparisons" :key="comparison.id" :data-cy-name="comparison.name">
        <td :headers="`table-header-0-${id}`" data-title="Navn" style="width: 25%">
          {{ comparison.name }}
        </td>
        <td :headers="`table-header-1-${id}`" data-title="Beregninger brugt" style="width: 50%">
          <template v-for="item in comparison.calculationNames" :key="item.id">
            {{ item.calculationName }}<br />
          </template>
        </td>
        <td :headers="`table-header-2-${id}`" data-title="Bemærkninger" class="align-text-right" style="width: 25%">
          {{ limitText(comparison.description, 200) }}
        </td>
        <td :headers="`table-header-action-${id}`" data-title="Handlinger">
          <div class="overflow-menu overflow-menu--open-left">
            <button
                :class="`overflow-menu-table`"
                :data-js-target="`forecast-${comparison.id}-actions-overflow`"
                aria-expanded="false"
                aria-haspopup="true"
                aria-label="handlinger"
                class="button-overflow-menu js-dropdown"
            >
              <svg aria-hidden="true" class="icon-svg" fill="white" focusable="false">
                <use xlink:href="#more-vert"></use>
              </svg>
            </button>
            <div :id="`forecast-${comparison.id}-actions-overflow`" aria-hidden="true" class="overflow-menu-inner">
              <!-- List of possible events to apply to a comparison -->
              <ul class="overflow-list">
                <li>
                  <router-link class="edit-button" to="/comparison/create-comparison" @click="$emit('edit-comparison', comparison)">
                    <SimpleText :textKey="'forecast.overview.menuitem.open'" />
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#mode"></use>
                    </svg>
                  </router-link>
                </li>
                <li>
                  <button class="delete-button" @click="$emit('delete-comparison', comparison)">
                    <SimpleText :textKey="'forecast.overview.menuitem.delete'" />
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#trash-can"></use>
                    </svg>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>


<script lang="ts">
import { Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import TableHeader from '@/types/TableHeader'
import * as DKFDS from 'dkfds'
import {ComparisonOverviewInfo} from 'openapi/generated-clients/climatecompass'
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import SimpleText from '@/components/SimpleText.vue'

@Options({
  emits: ["delete-comparison", "edit-comparison"],
  components: {
    SimpleText
  }
})
export default class ForecastOverviewTableComponent extends BaseComponent {
  @Prop({required: true})
  comparisons: ComparisonOverviewInfo[] = []

  @Prop({required: true})
  id?: string

  tableHeaders = [] as TableHeader[]
  currentSort = "name"
  currentSortDir = "asc"

  getRowDataHeaderDefinition(id: any) {
    return 'table-header-0-' + id + ' ' + 'table-header-1-' + id + ' ' + 'table-header-2-' + id + ' ' + 'table-header-3-' + id + ' ' + 'table-header-4-' + id
  }

  limitText(text: string, len: number) {
    if (text) {
      return text?.length > len ? text.substring(0, len) + "..." : text
    } else {
      return ''
    }
  }
  get sortedComparisons() {
    if (this.comparisons) {
      const _comparisons = [...this.comparisons.filter(this.isFiltered)]

      if (this.currentSort === "calculations") {
        return _comparisons.sort((a, b) => {
          let modifier = 1
          if (this.currentSortDir === "desc") {
            modifier = -1
          }
          if (a.calculationNames && b.calculationNames && a.calculationNames! < b.calculationNames!) {
            return -1 * modifier
          } else if (a.calculationNames && b.calculationNames && a.calculationNames! > b.calculationNames!) {
            return modifier
          }
          return 0
        })
      }
      if (this.currentSort === "name") {
        return _comparisons.sort((a, b) => {
          let modifier = 1
          if (this.currentSortDir === "desc") {
            modifier = -1
          }
          if (a.name && b.name) {
            return a.name.localeCompare(b.name, "da-DK") * modifier
          } else {
            return modifier
          }
        })
      }
      if (this.currentSort === "notes") {
        return _comparisons.sort((a, b) => {
          let modifier = 1
          if (this.currentSortDir === "desc") {
            modifier = -1
          }
          if (a.description && b.description && a.description! < b.description!) {
            return -1 * modifier
          } else if (a.description && b.description && a.description! > b.description!) {
            return modifier
          }
          return 0
        })
      }
      return _comparisons.sort((a, b) => {
        let modifier = 1
        if (this.currentSortDir === "desc") {
          modifier = -1
        }
        return modifier
      })
    } else {
      return 0
    }
  }

  isFiltered(compInfo: ComparisonOverviewInfo) {
    return true
  }

  mounted() {
    DKFDS.init()
  }

  updated() {
    DKFDS.init()
  }

  async beforeMount() {
    this.tableHeaders = [
      {headline: this.getContentfulString('comparison.overview.table.header.name'), sortKey: "name", id: "name"},
      {headline: this.getContentfulString('comparison.overview.table.header.used-calculation-names'), sortKey: "calculations", id: "calculations"},
      {headline: this.getContentfulString('comparison.overview.table.header.notes'), sortKey: "notes", id: "notes"},
    ]
  }

  sort(sortBy: string) {
    if (sortBy === this.currentSort) {
      this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc"
    }
    this.currentSort = sortBy
  }


}
</script>

<style lang="scss" scoped>

.clickable {
  cursor: pointer;
}

.table {
  border: 2px solid $color-primary;
}

.table th {
  border-right-color: white;
}

.table th,
.table td {
  border-bottom: 1px solid $color-primary;
}

.table th:nth-last-child(2),
.table td:nth-last-child(2) {
  border-right: none;
}

.table th:last-child,
.table td:last-child {
  color: white;
  background-color: $color-primary;
  border-right: none;
  border-bottom: 1px solid white;
  width: 1rem;
  padding: 8px 0;
}

.overflow-menu .button-overflow-menu {
  min-width: 0;
  min-height: 0;
  padding: 0;
}

.location .button-overflow-menu {
  text-decoration: underline;
}

.location .button-overflow-menu:focus {
  font-weight: bold;
}

.overflow-menu-inner li {
  text-align: left;
  padding: 0.25rem 1rem;
}

.overflow-menu-inner {
  overflow-y: auto;
  max-height: 40rem;
}

.disabled {
  opacity: 0.3;
}

#table-header-2-drafts {
  text-align: right;
}

#table-header-3-drafts {
  text-align: right;
}

</style>
