import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!

  return (_openBlock(), _createBlock(_component_Chart, {
    ref: "highestEmissionsStackedColumnChart",
    width: "100%",
    height: "400",
    type: "bar",
    options: _ctx.chartOptions,
    series: _ctx.chartOptions.series
  }, null, 8, ["options", "series"]))
}