import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container px-0" }
const _hoisted_2 = {
  id: "comparison-multiselect-scopes-label",
  class: "form-label mb-3",
  for: "multiselect-scopes"
}
const _hoisted_3 = {
  key: 0,
  class: "multiselect-wrapper",
  id: "multiselect-filter-scopes-wrapper"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleText = _resolveComponent("SimpleText")!
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("form", {
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.formValidation.clearValidField && _ctx.formValidation.clearValidField(...args))),
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.updateFilter()), ["prevent"])),
        onInvalidCapture: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.formValidation.handleFormValidity && _ctx.formValidation.handleFormValidity(...args)), ["prevent"])),
        ref: "formGroup"
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_2, [
              _createVNode(_component_SimpleText, { "text-key": "comparison.create.secondary-filter.title" })
            ]),
            (_ctx.possibleScopes.length > 0 && !_ctx.isLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_Multiselect, {
                    id: "multiselect-scopes",
                    modelValue: _ctx.selectedScopes,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedScopes) = $event)),
                      _ctx.emitSelectedScopes
                    ],
                    mode: "tags",
                    "close-on-select": false,
                    hideSelected: false,
                    canClear: false,
                    groups: true,
                    options: _ctx.scopesForSelectWithLabels,
                    valueProp: "scopeId",
                    label: "scopePresentationName",
                    ref: "comparison-scope-select",
                    class: "multiselect-calculation form-group",
                    "aria-labelledby": "comparison-multiselect-scopes-label"
                  }, null, 8, ["modelValue", "options", "onUpdate:modelValue"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, " Loading... "))
          ])
        ])
      ], 544)
    ])
  ]))
}