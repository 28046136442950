import { Log, UserManagerSettings } from 'oidc-client'
import jwtEnv from "./jwtEnv.json";
import {useStore} from "vuex";


export default class OidcConfig {
  oidcSettings: UserManagerSettings;

  constructor(options: {
    clientId: string;
    authority: string;
    redirectUri: string;
    postLogoutRedirectUri: string;
  }) {
    console.log("oidc constructor called, org.clientId: ", options.clientId, "postfix: ", getProfilePostfix())
    Log.logger = console;
    Log.level = Log.DEBUG;

    //const localClientId  = getLoginMethod() === 'NORMAL' ? options.clientId : options.clientId + '_ssi'
    const localClientId = options.clientId + getProfilePostfix()

    this.oidcSettings = {
      authority: options.authority,
      client_id: localClientId,
      redirect_uri: options.redirectUri,
      response_type: "code",
      scope: "openid",
      post_logout_redirect_uri: options.postLogoutRedirectUri,
      loadUserInfo: true,
      automaticSilentRenew: false,
      monitorSession: false,
      accessTokenExpiringNotificationTime: 5*60, // notification 5 minutes before expiration
      extraQueryParams: {claims: '{"userinfo":{"name":null,"dk:gov:cpr":null,"dk:gov:cvr":null,"dk:gov:org_name":null}}'}
    };
  }
}

function getProfilePostfix(): string {
  const method = window.sessionStorage.getItem('loginMethod')
  if (method === 'SSI') {
    return '_ssi'
  } else {
    //window.alert("getLoginMethod method: " + method)
    // default
    return ''
  }
}

function getSetting(): any {
  const currentUrl = window.location.href;
  console.log("getSetting url: ", currentUrl, ", postfix: ", getProfilePostfix())
  if (currentUrl.startsWith("http://localhost.erstdev") || currentUrl.startsWith("https://localhost.erstdev")) {
    //TODO: enable when SSI is activated
    // return getProfilePostfix() === '_ssi' ? jwtEnv.config.localhost : jwtEnv.config.localhost_ssi;
    return jwtEnv.config.localhost;
  } else if (currentUrl.startsWith('http://localhost')) {
    return jwtEnv.config.keycloak_local
  } else if (currentUrl.startsWith("https://ci")) {
    return jwtEnv.config.ci;
  } else if (currentUrl.startsWith("https://dev.")) {
    return jwtEnv.config.dev;
  } else if (currentUrl.startsWith("https://dev2.")) {
    //return getProfilePostfix() === '_ssi' ? jwtEnv.config.dev2_ssi : jwtEnv.config.dev2;
    return jwtEnv.config.dev2;
  } else if (currentUrl.startsWith("https://dev4.")) {
    return jwtEnv.config.dev4;
  } else if (currentUrl.startsWith("https://test.")) {
    return jwtEnv.config.test;
  } else if (currentUrl.startsWith("https://test2.")) {
    return jwtEnv.config.test2;
  } else if (currentUrl.startsWith("https://preprod.")) {
    return jwtEnv.config.preprod;
  } else if (currentUrl.startsWith("https://preprod2.")) {
    return jwtEnv.config.preprod2;
  } else if (currentUrl.startsWith("https://prod2.")) {
    return jwtEnv.config.prod2;
  } else {
    return jwtEnv.config.prod;
  }
}

const jwtEnvConfig = getSetting();

const options = {
  clientId: jwtEnvConfig.clientId,
  authority: jwtEnvConfig.authority,
  redirectUri: jwtEnvConfig.redirectUri,
  postLogoutRedirectUri: jwtEnvConfig.postLogoutRedirectUri,
};

export const oidcConfig = new OidcConfig(options);
