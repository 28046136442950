import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "full-width"
}
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.richTextRendered)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", { innerHTML: _ctx.richTextRendered }, null, 8, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}