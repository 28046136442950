<template>
  <div class="container px-0 pb-6">
    <h2>
      <SimpleText text-key='cookie.header.title'></SimpleText>
    </h2>
    <div class="w-percent-100 w-percent-100-child" v-html="getRenderedContentfulRT('cookie.header.description')"></div>
    <AccordionComponent :accordion-list="getAccordions('cookie')"></AccordionComponent>
  </div>
</template>

<script lang="ts">
import * as DKFDS from "dkfds"
import { Vue, Options, mixins } from "vue-class-component"
import SimpleText from '@/components/SimpleText.vue'
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import AccordionComponent from '@/components/AccordionComponent.vue'

@Options({
  components: {
    AccordionComponent,
    SimpleText
  }
})
export default class CookiePolicy extends BaseComponent {
  updated() {
    DKFDS.init()
  }


  async beforeMount() {
    this.setDocumentTitleByKey('cookie.header.title')
  }

  mounted() {
    DKFDS.init()
  }
}
</script>

<style scoped>
.accordion-button {
  border: 1px solid grey;
}
</style>
