<template>
  <ComparisonNavigation />
  <router-view />
</template>

<script lang="ts">
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { Options } from "vue-class-component"
import Navigation from "@/components/forecast/Navigation.vue"
import {useRouter} from "vue-router";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import SharedHeader from "@/components/calculator/shared/SharedHeader.vue";
import ComparisonNavigation from "@/components/comparison/ComparisonNavigation.vue";


@Options({
  components: {
    ComparisonNavigation,
  }
})
export default class Comparison extends BaseComponent {
  router = useRouter()


  get documentToHtmlString() {
    return documentToHtmlString
  }


  openLink(url: string) {
    window.open(url)
  }
}
</script>
<style scoped>
</style>
