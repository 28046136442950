<template>
  <PrintReductionTarget />
</template>

<script lang="ts">
import PrintReductionTarget from "@/components/forecast/PrintReductionTarget.vue"
import { Vue, Options, mixins } from "vue-class-component"

@Options({
  components: {
    PrintReductionTarget
  }
})
export default class ReductionTargetPrint extends Vue {
  // Empty
}
</script>
