import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!

  return (_ctx.chartOptions.series && _ctx.chartOptions.series.length > 0 )
    ? (_openBlock(), _createBlock(_component_Chart, {
        key: 0,
        ref: "Scope3CategoryColumnChart",
        width: "100%",
        height: "400",
        type: "bar",
        options: _ctx.chartOptions,
        series: _ctx.chartOptions.series,
        optimalColumnWidth: this.optimalColumnWidth
      }, null, 8, ["options", "series", "optimalColumnWidth"]))
    : _createCommentVNode("", true)
}