import router from '@/router'
import { User } from 'oidc-client'
import store from '.'

export const loginEvent = async (user: User) => {
  await store.dispatch('login', user)
}

export const logoutEvent = () => {
  store.dispatch('logout')
  if (store.getters.isAuthenticated) {
    router.push('/')
  }
}

export const accessTokenExpiringEvent = () => {
  store.dispatch('accessTokenExpiring')
}

export const accessTokenExpiredEvent = () => {
  store.dispatch('accessTokenExpired')

  logoutEvent()
}
