<template>
  <div class="container px-0">

    <div>
      <form @change="formValidation.clearValidField" @submit.prevent="updateFilter()"
            @invalid.capture.prevent="formValidation.handleFormValidity"
            ref="formGroup">
        <div>
          <div>
            <label id="comparison-multiselect-scopes-label" class="form-label mb-3" for="multiselect-scopes">
              <SimpleText text-key="comparison.create.secondary-filter.title"/>
            </label>
            <div v-if="possibleScopes.length > 0 && !isLoading" class="multiselect-wrapper" id="multiselect-filter-scopes-wrapper">
              <Multiselect
                  id="multiselect-scopes"
                  v-model="selectedScopes"
                  mode="tags"
                  :close-on-select="false"
                  :hideSelected="false"
                  :canClear="false"
                  :groups="true"
                  :options="scopesForSelectWithLabels"
                  valueProp="scopeId"
                  label="scopePresentationName"
                  ref="comparison-scope-select"
                  class="multiselect-calculation form-group"
                  aria-labelledby="comparison-multiselect-scopes-label"
                  @update:model-value="emitSelectedScopes"

              />
<!--              :placeholder="getContentfulString('comparison.create.all-scopes-selected.placeholder.text')"-->

            </div>
            <div v-else>
              Loading...
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import {Options} from "vue-class-component";
import SimpleText from "@/components/SimpleText.vue";
import RichText from "@/components/RichText.vue";
import Tooltip from "@/components/Tooltip.vue";
import Multiselect from "@vueform/multiselect";
import {Prop, Watch} from "vue-property-decorator";
import {useRouter} from "vue-router";
import ContentfulContent from "@/shared/ContentfulContent";
import {FormValidation} from "@/shared/FormValidation";
import Comparison from "@/components/comparison/Comparison.vue";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";

class ScopesForSelect {
  scopeId: string
  scopePresentationName: string

  constructor(scopeId: string, scopePresentationName: string) {
    this.scopeId = scopeId
    this.scopePresentationName = scopePresentationName
  }
}

class ScopesForSelectWithLabels {
  label: string
  options: ScopesForSelect[]

  constructor(_label: string, _options: ScopesForSelect[]) {
    this.label = _label
    this.options = _options
  }
}
@Options({
  emits: ["comparison-selected-scopes"],
  components: {
    RichText,
    SimpleText,
    Tooltip,
    Multiselect,
  }
})


export default class ComparisonFilterMultiselect extends BaseComponent {
  /** mode can be 'edit' or 'new' */
  @Prop()
  mode?: string

  @Prop()
  initialDropdownChoice?: string[]

  router = useRouter()

  /** Scopes Multiselect Variables **/

  selectedScopes = ['SCOPE1', 'SCOPE2', 'SCOPE3']
  scopesForSelectWithLabels = [] as ScopesForSelectWithLabels[]
  possibleScopes = [] as ScopesForSelect[]

  contentfulContent: ContentfulContent = this.store.getters.getContentfulContent
  resultCategorySummaryScope3 = this.getContentfulString('forecast.resultview.scope3-categories-chart.summery')

  formValidation = new FormValidation()
  isLoading = true

  $refs!: {
    scopesMultiselect: Multiselect
    formGroup: HTMLFormElement
  }
  @Watch("possibleScopes", { immediate: true, deep: true })
  //Watcher used to force component to recognize data-changes for multiSelect (initialization && update)
  onPossibleScopesChange(newVal: ScopesForSelect[]) {
    if (newVal.length > 0) {
      this.possibleScopes = newVal;
    }
  }

  emitSelectedScopes(newVal: any) {
    console.log('emittingSelectedScopes to parent..')
    this.$emit('comparison-selected-scopes', newVal);
  }

  updateFilter() {
    console.log('currently placeholder')
  }

  async beforeMount() {
    await this.initializeScopes();
  }

  async initializeScopes() {
    if (this.initialDropdownChoice) {
      this.selectedScopes = this.initialDropdownChoice
    }
    this.possibleScopes = [
      new ScopesForSelect('SCOPE1', this.contentfulContent.findSimpleText('comparison.resultview.scope1.text')),
      new ScopesForSelect('SCOPE2', this.contentfulContent.findSimpleText('comparison.resultview.scope2.text')),
      new ScopesForSelect('SCOPE3', this.contentfulContent.findSimpleText('forecast.resultview.scope3.text')),
      new ScopesForSelect('UDENFOR SCOPES', this.contentfulContent.findSimpleText('comparison.resultview.outside-scope.text'))
    ];
    this.scopesForSelectWithLabels.push(new ScopesForSelectWithLabels("Vælg/Fravælg Alle", this.possibleScopes));

    console.log('Initializing possibleScopes:', this.possibleScopes, "initialScope:", this.initialDropdownChoice)
    this.isLoading = false;
  }

  /* hasGrouping(groupingId: number) {
     if(this.forecast && this.forecast.calculations && this.forecast.calculations[0]) {
       return this.forecast.calculations[0].groupingResults?.some((x: GroupingCalculatedResult) =>
           x.groupingId === groupingId
       )
     }
     return false
   }

   get chartSettings() {
     let setting = 0
     if(this.selectedScopes.includes('Scope1')) {
       setting += Constants.FORECAST_FILTER_SCOPE1
     }
     if(this.selectedScopes.includes('Scope2')) {
       setting += Constants.FORECAST_FILTER_SCOPE2
     }
     if(this.selectedScopes.includes('Scope3')) {
       setting += Constants.FORECAST_FILTER_SCOPE3
     }

     if(this.selectedForecastCriteria.includes('SCOPES') ) {
       setting += Constants.FORECAST_FILTER_SCOPES
     }
     if(this.selectedForecastCriteria.includes('CATEGORIES') ) {
       setting += Constants.FORECAST_FILTER_CATEGORIES
     }
     if(this.selectedForecastCriteria.includes('ENERGY') ) {
       setting += Constants.FORECAST_FILTER_CAT_ENERGY
     }
     if(this.selectedForecastCriteria.includes('PROCUREMENT') ) {
       setting += Constants.FORECAST_FILTER_CAT_PROCUREMENT
     }
     if(this.selectedForecastCriteria.includes('TRANSPORT') ) {
       setting += Constants.FORECAST_FILTER_CAT_TRANSPORT
     }
     if(this.selectedForecastCriteria.includes('TRASH') ) {
       setting += Constants.FORECAST_FILTER_CAT_TRASH
     }
     if(this.selectedForecastCriteria.includes('PRODUCTS') ) {
       setting += Constants.FORECAST_FILTER_CAT_PRODUCTS
     }
     return setting
   }

   refreshChart() {
     this.$refs.columnChart.updateChart(this.chartSettings)
   }  */

}

</script>


<style src="@vueform/multiselect/themes/default.css">
</style>

<style lang="scss">
.multiselect-calculation {
  --ms-font-size: 1.6rem;
  --ms-border-color: #747474;
  --ms-border-width: 1px;
  --ms-option-font-size: 1.6rem;
  --ms-option-line-height: 1.8rem;
  --ms-option-py: 7px;
  --ms-option-px: 34px;
  --ms-tag-font-size: 1.6rem;
  --ms-line-height: 2.4rem;
  --ms-tag-line-height: 2.4rem;
  --ms-px: 4px;
  --ms-py: 15px;
  --ms-tag-bg: black;
  --ms-tag-color: white;
  padding: 5px var(--ms-px) 5px var(--ms-px);

  --ms-max-height: 30rem;

  --ms-option-bg-selected-pointed: #F2F2F2;
  --ms-option-color-selected-pointed: black;
  --ms-option-bg-pointed: #F2F2F2;

  --ms-group-label-py: 7px;
  --ms-group-label-line-height: 1.8rem;
  --ms-group-label-bg: #D7D7D7;
  --ms-group-label-bg-pointed: #C7C7C7;
  --ms-group-label-bg-selected: #D7D7D7;
  --ms-group-label-color-selected: black;
  --ms-group-label-bg-selected-pointed: #C7C7C7;
  --ms-group-label-bg-selected-disabled: #D7D7D7;
}

#multiselect-scope3-categories-label {
  margin-bottom: 8px;
}

.forecast-column-chart-placeholder {
  display: flex; justify-content: center; height: 350px;
}

.multiselect-calculation.form-group .multiselect-placeholder {
  color: black !important;
}

.py-4.row.form-group > .col-md-3.form-group:nth-of-type(2) {
  margin-top: 0 !important;
}

.py-4.row {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.py-3 .p-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}



</style>