<template>
  <div class="wrapper-div" id="wrapper-div">

    <div class="section1-container" id="scope3-section1">
      <h2><SimpleText text-key="results.scope3.result-main-header"/></h2>
      <p id="main-description-text">
        <RichText text-key="results.scope3.result-main-description-text"/>
      </p>

      <Scope3ResultsColumnChart
          v-if="filteredCategoryResults"
          id="scope3-column-chart"
          ref="scope3ResultColumnChart"
          :raw-data="filteredCategoryResults"
      >
      </Scope3ResultsColumnChart>

      <div class="row">
        <div class="column invisible"></div>
        <div class="row">
          <p v-if="upstreamTotal" class="category-box upstream-color" style="font-size: small"><SimpleText text-key="results.scope3.result-upstream-box-text"></SimpleText></p>
          <p v-if="downstreamTotal" class="category-box downstream-color" style="font-size: small"><SimpleText text-key="results.scope3.result-downstream-box-text"></SimpleText></p>
        </div>
        <div class="column invisible"></div>
      </div>
    </div>

    <div class="section2-container" id="scope3-section2">
      <table class="table results-table w-percent-100" v-if="results?.groupingResults" aria-describedby="scope3-result-table-header1">
        <thead>
        <tr class="bg-primary">
          <th id="scope3-result-table-header1" colspan="3" scope="colgroup"><SimpleText text-key="results.scope3.result-table-header1"/></th>
        </tr>
        </thead>

        <thead>
        <tr style="font-weight: 600">
          <th id="scope3-result-sub-header-1" headers="scope3-result-table-header1" style="width: 53%" class="py-405"><SimpleText text-key="results.scope3.result-table-header2"/></th>
          <th id="scope3-result-sub-header-2" headers="scope3-result-table-header1" style="width: 23%" class="py-405"><SimpleText text-key="results.scope3.result-table-header3"/></th>
          <th id="scope3-result-sub-header-3" headers="scope3-result-table-header1" style="width: 23%" class="py-405"><SimpleText text-key="results.scope3.result-table-header4"/></th>
        </tr>
      </thead>
        <tbody role="rowgroup">
        <tr id="category-1-table-row">
          <td headers="scope3-result-sub-header-1" style="text-align: left" class="upstream-category-result-td" >
            <span><SimpleText text-key="calculation.dropdown.possiblevalue.unit.category1"/></span>
          </td>
          <td headers="scope3-result-sub-header-2">
            <span> {{ doPrettify(displayTableResults('CATEGORY_1')) }} </span>
          </td>
          <td headers="scope3-result-sub-header-3"/>
        </tr>

        <tr id="category-2-table-row">
          <td headers="scope3-result-sub-header-1" style="text-align: left">
            <span><SimpleText text-key="calculation.dropdown.possiblevalue.unit.category2"/></span>
          </td>
          <td headers="scope3-result-sub-header-2">
            <span> {{ doPrettify(displayTableResults('CATEGORY_2')) }} </span>
          </td>
          <td headers="scope3-result-sub-header-3"/>
        </tr>

        <tr id="category-3-table-row">
          <td headers="scope3-result-sub-header-1"  style="text-align: left">
            <span><SimpleText text-key="calculation.dropdown.possiblevalue.unit.category3"/></span>
          </td>
          <td headers="scope3-result-sub-header-2">
            <span> {{ doPrettify(displayTableResults('CATEGORY_3')) }} </span>
          </td>
          <td headers="scope3-result-sub-header-3"/>
        </tr>

        <tr id="category-4-table-row">
          <td headers="scope3-result-sub-header-1" style="text-align: left">
            <span><SimpleText text-key="calculation.dropdown.possiblevalue.unit.category4"/></span>
          </td>
          <td headers="scope3-result-sub-header-2">
            <span> {{ doPrettify(displayTableResults('CATEGORY_4')) }} </span>
          </td>
          <td headers="scope3-result-sub-header-3"/>
        </tr>

        <tr id="category-5-table-row">
          <td headers="scope3-result-sub-header-1" style="text-align: left">
            <span><SimpleText text-key="calculation.dropdown.possiblevalue.unit.category5"/></span>
          </td>
          <td headers="scope3-result-sub-header-2">
            <span> {{ doPrettify(displayTableResults('CATEGORY_5')) }} </span>
          </td>
          <td headers="scope3-result-sub-header-3"/>
        </tr>

        <tr id="category-6-table-row">
          <td headers="scope3-result-sub-header-1" style="text-align: left">
            <span><SimpleText text-key="calculation.dropdown.possiblevalue.unit.category6"/></span>
          </td>
          <td headers="scope3-result-sub-header-2">
            <span> {{ doPrettify(displayTableResults('CATEGORY_6')) }} </span>
          </td>
          <td headers="scope3-result-sub-header-3"/>
        </tr>

        <tr id="category-7-table-row">
          <td headers="scope3-result-sub-header-1" style="text-align: left">
            <span><SimpleText text-key="calculation.dropdown.possiblevalue.unit.category7"/></span>
          </td>
          <td headers="scope3-result-sub-header-2">
            <span> {{ doPrettify(displayTableResults('CATEGORY_7')) }} </span>
          </td>
          <td headers="scope3-result-sub-header-3"/>
        </tr>

        <tr id="category-8-table-row" class="border-bottom-solid-black">
          <td headers="scope3-result-sub-header-1" style="text-align: left">
            <span><SimpleText text-key="calculation.dropdown.possiblevalue.unit.category8"/></span>
          </td>
          <td headers="scope3-result-sub-header-2">
            <span> {{ doPrettify(displayTableResults('CATEGORY_8')) }} </span>
          </td>
          <td headers="scope3-result-sub-header-3"/>
        </tr>

        <tr id="category-9-table-row">
          <td headers="scope3-result-sub-header-1" style="text-align: left">
            <span><SimpleText text-key="calculation.dropdown.possiblevalue.unit.category9"/></span>
          </td>
          <td headers="scope3-result-sub-header-2"/>
          <td headers="scope3-result-sub-header-3">
            <span> {{ doPrettify(displayTableResults('CATEGORY_9')) }} </span>
          </td>
        </tr>

        <tr id="category-10-table-row">
          <td headers="scope3-result-sub-header-1" style="text-align: left">
            <span><SimpleText text-key="calculation.dropdown.possiblevalue.unit.category10"/></span>
          </td>
          <td headers="scope3-result-sub-header-2"/>
          <td headers="scope3-result-sub-header-3">
            <span> {{ doPrettify(displayTableResults('CATEGORY_10')) }} </span>
          </td>
        </tr>

        <tr id="category-11-table-row">
          <td headers="scope3-result-sub-header-1" style="text-align: left">
            <span><SimpleText text-key="calculation.dropdown.possiblevalue.unit.category11"/></span>
          </td>
          <td headers="scope3-result-sub-header-2"/>
          <td headers="scope3-result-sub-header-3">
            <span> {{ doPrettify(displayTableResults('CATEGORY_11')) }} </span>
          </td>
        </tr>

        <tr id="category-12-table-row">
          <td headers="scope3-result-sub-header-1" style="text-align: left">
            <span><SimpleText text-key="calculation.dropdown.possiblevalue.unit.category12"/></span>
          </td>
          <td headers="scope3-result-sub-header-2"/>
          <td headers="scope3-result-sub-header-3">
            <span> {{ doPrettify(displayTableResults('CATEGORY_12')) }} </span>
          </td>
        </tr>

        <tr id="category-13-table-row">
          <td headers="scope3-result-sub-header-1" style="text-align: left">
            <span><SimpleText text-key="calculation.dropdown.possiblevalue.unit.category13"/></span>
          </td>
          <td headers="scope3-result-sub-header-2"/>
          <td headers="scope3-result-sub-header-3">
            <span> {{ doPrettify(displayTableResults('CATEGORY_13')) }} </span>
          </td>
        </tr>

        <tr id="total-results-table-row" class="text-align-right" style="font-weight: 600">
          <td class="scope3-total" style="text-align: left"><SimpleText text-key="results.scope3.result-total-split"/></td>
          <td class="scope3-total" v-if="results.categoryResults">{{ this.calculateUpstreamTotal ===0? '0,00' :doPrettify(this.calculateUpstreamTotal)}}</td>
          <td class="scope3-total" v-if="results.categoryResults">{{ this.calculateDownstreamTotal ===0? '0,00' :doPrettify(this.calculateDownstreamTotal)}}</td>
        </tr>

        <tr id="grand-total-results-table-row" class="text-align-right" style="font-weight: 600">
          <td class="scope3-total" style="text-align: left"><SimpleText text-key="results.scope3.result-grand-total"/></td>
          <td class="scope3-total"></td>
          <td class="scope3-total" v-if="results.categoryResults">{{ this.calculateDownstreamTotal + this.calculateUpstreamTotal ===0? '0,00' :doPrettify(this.calculateDownstreamTotal + this.calculateUpstreamTotal)}}</td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>

</template>

<script lang="ts">

import {CategoryResult, GroupingCalculatedResults} from "openapi/generated-clients/climatecompass"
import {Options} from "vue-class-component"
import {Prop} from "vue-property-decorator";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import {Constants} from "@/shared/Constants";
import Scope3ResultsColumnChart from "@/components/calculator/results/Scope3ResultsColumnChart.vue";
import SimpleText from "@/components/SimpleText.vue";
import RichText from "@/components/RichText.vue";

@Options({
  components: {
    RichText,
    SimpleText,
    Scope3ResultsColumnChart,
  }
})
export default class Scope3Results extends BaseComponent {
  @Prop()
  results: GroupingCalculatedResults = {} as GroupingCalculatedResults

  @Prop()
  printMode?: boolean

  rawData?: CategoryResult[]
  upstreamCategories = ['CATEGORY_1', 'CATEGORY_2', 'CATEGORY_3', 'CATEGORY_4', 'CATEGORY_5', 'CATEGORY_6', 'CATEGORY_7', 'CATEGORY_8']
  downstreamCategories = [ 'CATEGORY_9', 'CATEGORY_10', 'CATEGORY_11', 'CATEGORY_12', 'CATEGORY_13']
  upstreamTotal = 0
  downstreamTotal = 0

  $refs!: {
    scope3ResultColumnChart : Scope3ResultsColumnChart
  }

  async beforeMount() {
    this.calculateUpstreamTotal
    this.calculateDownstreamTotal
    this.scrollToTargetWithOffset('scope3-section1', -45)
  }

  setBlackBorder(resultCategory: string) {
    let isUpstream = false
    let isLastOfUpstream = false
    let hasDownstream = false
    isUpstream = this.upstreamCategories.includes(resultCategory)
    hasDownstream = this.results.categoryResults?.some(x => x.categoryName && this.downstreamCategories.includes(x.categoryName))?? false
    if(isUpstream) {
      const indexOfCurrentCategory = this.upstreamCategories.indexOf(resultCategory)
      isLastOfUpstream = this.results.categoryResults?.every(x => x.categoryName && this.upstreamCategories.indexOf(x.categoryName) <= indexOfCurrentCategory)?? false
    }
    return isUpstream && hasDownstream && isLastOfUpstream
  }

  get calculateUpstreamTotal(){
    const upstreamTotalArr: number[] = []

    this.results.categoryResults?.forEach((result : CategoryResult) => {
      if(this.upstreamCategories.includes(result.categoryName?? '')) {
        upstreamTotalArr.push(Number(result.categoryResult))
        this.upstreamTotal = upstreamTotalArr.reduce<number>((acc, obj) => {
          return acc + obj
        }, 0)
      }
    })
    return this.upstreamTotal
  }
  get calculateDownstreamTotal() {
    const downstreamTotalArr: number[] = []

    this.results.categoryResults?.forEach((result : CategoryResult) => {
      if(this.downstreamCategories.includes(result.categoryName?? '')) {
        downstreamTotalArr.push(Number(result.categoryResult))
        this.downstreamTotal = downstreamTotalArr.reduce<number>((acc, obj) => {
          return acc + obj
        }, 0)
      }
    })
    return this.downstreamTotal
  }

  //To determine results.CategoryResults contain values for each table row. If not returns '-'
  displayTableResults(category : string) {
    const nameToCheck = category
    let tableCellData: string | number | undefined
    tableCellData = '-'

    this.results.categoryResults?.forEach((categoryResult : CategoryResult) => {
        if (categoryResult.categoryName === nameToCheck) {
          tableCellData = categoryResult.categoryResult
          if(tableCellData === 0) {
            tableCellData = '-'
          }
        }
    })
    return tableCellData
  }


  //To filter out the 3 categories that shouldn't be displayed in graph.
  get filteredCategoryResults() {
    const foundFilteredCategories = [] as any
    this.results.categoryResults?.forEach((categoryResult : CategoryResult) => {
      if((categoryResult.categoryResult??0) > 0) {
        if (categoryResult.categoryName !== ('CATEGORY_14' || 'CATEGORY_15')) {
          foundFilteredCategories.push(categoryResult)
        }
      }
    })
    return foundFilteredCategories
  }

  asContentfulString(value: string) {
    if (value === Constants.SCOPE3_CATEGORY_1) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category1')
    } else if (value === Constants.SCOPE3_CATEGORY_2) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category2')
    } else if (value === Constants.SCOPE3_CATEGORY_3) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category3')
    } else if (value === Constants.SCOPE3_CATEGORY_4) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category4')
    } else if (value === Constants.SCOPE3_CATEGORY_5) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category5')
    } else if (value === Constants.SCOPE3_CATEGORY_6) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category6')
    } else if (value === Constants.SCOPE3_CATEGORY_7) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category7')
    } else if (value === Constants.SCOPE3_CATEGORY_8) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category8')
    } else if (value === Constants.SCOPE3_CATEGORY_9) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category9')
    } else if (value === Constants.SCOPE3_CATEGORY_10) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category10')
    } else if (value === Constants.SCOPE3_CATEGORY_11) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category11')
    } else if (value === Constants.SCOPE3_CATEGORY_12) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category12')
    } else if (value === Constants.SCOPE3_CATEGORY_13) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category13')
    } else if (value === Constants.SCOPE3_CATEGORY_14) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category14')
    } else if (value === Constants.SCOPE3_CATEGORY_15) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category15')
    } else {
      // default just return input
      return value
    }
  }
}


</script>
<style lang="scss" scoped>

* {
  -webkit-print-color-adjust: exact;
}

.wrapper-div {
  padding-top: 3em;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.section1-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5em;
  max-width: 100%;
  margin: 2px;
}

.section2-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-top: 10px;
}

thead:first-of-type {
  border-right: 2px solid $color-primary;
  border-left: 2px solid $color-primary;
}

table td:last-child {
  border-left: 2px solid $color-primary;
}
table tr td:first-of-type {
  border-left: 2px solid $color-primary;
}

table tr td:not(td:first-of-type) {
  text-align: right;
  border-right: 2px solid $color-primary;
  border-left: 2px solid $color-primary;
}

.scope3-total {
  border-left-color: $color-primary;
  border-right-color: $color-primary;
  background-color: $color-primary !important;
  color: white;
}

.category-box {
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
}
.category-box:before {
  position: absolute;
  left: -25px;
  content: "";
  height:15px;
  width:15px;
  margin-bottom:5px;
  margin-top: 5px;
  border:1px solid black;

}
.category-box.upstream-color:before {
  background-color: $color-upstream !important;
}

.category-box.downstream-color:before {
  background-color: $color-primary !important;
}
.border-bottom-solid-black {
  border-bottom: 2px solid $color-border-grey;
}

.row {
  display: inline-flex;
}

.column {
  flex: 33%;
}
.invisible {
  visibility: hidden;
}

#main-description-text {
  max-width: 100%;
}
</style>