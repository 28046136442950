<template>
  <Navigation />
  <router-view />
</template>

<script lang="ts">
import { Options } from "vue-class-component"
import Navigation from "@/components/config/Navigation.vue"
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import SharedHeader from '@/components/calculator/shared/SharedHeader.vue'


@Options({
  components: {
    Navigation,
    SharedHeader
  }
})
export default class Guides extends BaseComponent {

}
</script>
