<template>
    <Navigation />
    <router-view />
</template>

<script lang="ts">
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { Options } from "vue-class-component"
import Navigation from "@/components/forecast/Navigation.vue"
import {useRouter} from "vue-router";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";


@Options({
  components: {
    Navigation
  }
})
export default class Forecast extends BaseComponent {
  router = useRouter()


  get documentToHtmlString() {
    return documentToHtmlString
  }


  openLink(url: string) {
    window.open(url)
  }
}
</script>
