<template>
  <SharedHeader>
    <template #firstColumn>
      <h1><SimpleText text-key="forecast.calculate-forecast.title" /></h1>
      <span><RichText text-key="forecast.calculate-forecast.header-text" /></span>
    </template>
  </SharedHeader>

  <div class="container px-0 pb-705 pt-0">
    <h2>
      <SimpleText :textKey="'forecast.overview.title'"/>
    </h2>
    <ForecastOverviewTableComponent
        id="drafts"
        ref="forecastTable"
        :forecasts="combinedInfo"
        :pending-forecast-ids="pendingForecasts.map(x => {return x.forecastId})"
        @edit-forecast="editForecast($event)"
        @delete-forecast="confirmDeleteForecast($event)"
        @reduction-forecast="editReductionTarget($event)"
    />
    <br>
    <router-link to="/forecast/calculate-forecast" class="button button-primary mr-4" @click="createNew()" >
      <SimpleText :textKey="'forecast.overview.button.new'"/>
    </router-link>
  </div>

  <!-- Delete confirmation modal START-->
  <template v-if="forecastToDelete.forecastId && forecastToDelete.forecastId > 0">
    <AcceptModal
        :value-object="forecastToDelete"
        :title-key="'forecast.dialog.delete.title'"
        :buttons-disabled="disableDeleteModalButtons"
        :button-ok-text-key="'forecast.dialog.delete.button.ok'"
        :button-reject-text-key="'forecast.dialog.delete.button.cancel'"
        @select-ok="deleteForecast($event)"
        @select-reject="resetForecastToDelete()"
      >
      <p>
        <strong><SimpleText :textKey="'forecast.dialog.delete.text1'" /></strong>
        <br/>
        <br/>
        {{ forecastToDelete.name }}
        <br/>
        <br/>
        <SimpleText :textKey="'forecast.dialog.delete.text2'" />
      </p>
    </AcceptModal>
  </template>
  <!-- Delete confirmation modal END-->

</template>

<script lang="ts">
import { Options } from 'vue-class-component'
import ForecastOverviewTableComponent, {
  CombinedForecastReduction
} from '@/components/forecast/ForecastOverviewTableComponent.vue'
import SharedHeader from '@/components/calculator/shared/SharedHeader.vue'
import { useRouter } from 'vue-router'
import { ForecastInfo, ReductionInfoScopeTypeEnum } from '../../../openapi/generated-clients/climatecompass'
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import SimpleText from '@/components/SimpleText.vue'
import RichText from '@/components/RichText.vue'
import AcceptModal from '@/components/AcceptModal.vue'
import { PendingForecastEntry } from '@/types/PendingForecastEntry'
import { reductionTargetClient } from '@/shared/BackendService'


@Options({
  components: {
    AcceptModal,
    ForecastOverviewTableComponent,
    RichText,
    SharedHeader,
    SimpleText
  }
})
export default class ForecastsOverview extends BaseComponent {
  router = useRouter()
  forecastToDelete: ForecastInfo = {} as ForecastInfo

  $refs!: {
    forecastTable : ForecastOverviewTableComponent
  }

  disableDeleteModalButtons = false

  combinedInfo: CombinedForecastReduction[] = []

  async getCombinedForecastReductionInfo(){
    const result: CombinedForecastReduction[] = []
    if (this.forecasts) {
      await Promise.all(this.forecasts.map(async (forecast: ForecastInfo) => {
        if (forecast.forecastId) {
          const combined = { ...forecast } as CombinedForecastReduction

          const scope12ReductionInfo = forecast.reductionInfos?.find(x => x.scopeType === ReductionInfoScopeTypeEnum.Scope12)
          const scope3ReductionInfo = forecast.reductionInfos?.find(x => x.scopeType === ReductionInfoScopeTypeEnum.Scope3)

          if (scope12ReductionInfo) {
            combined.reductionInfoScope12 = scope12ReductionInfo
          }
          if (scope3ReductionInfo) {
            combined.reductionInfoScope3 = scope3ReductionInfo
          }
          result.push(combined)
        }
      }))
    }


    this.combinedInfo = result
  }

  get forecasts() {
    return this.store.getters.getForecasts
  }

  get pendingForecasts() {
    return this.store.getters.getPendingForecasts.filter((x: PendingForecastEntry) => !x.pollingCompleted)
  }

  async beforeMount() {
    this.eventHub.emit("start-loading", "ForecastOverview")
    await this.store.dispatch("loadForecasts").finally(() => {
      this.eventHub.emit("stop-loading", "ForecastOverview")
    })

    this.eventHub.on('pending-forecast-completed', async () => {
      this.eventHub.emit("start-loading", "ForecastOverview")
      await this.store.dispatch("loadForecasts").finally(() => {
        this.eventHub.emit("stop-loading", "ForecastOverview")
      })
    })
    //xdocument.title = "Overblik | Klimakompasset"
    await this.getCombinedForecastReductionInfo()
  }

  resetForecastToDelete() {
    this.forecastToDelete = {} as ForecastInfo
  }

  async editForecast(forecastInfo: ForecastInfo) {
    await this.router.push('calculate-forecast?mode=edit&forecastId=' + forecastInfo?.forecastId)
    /* works local but not on slow dev
    await this.store.dispatch("setCurrentForecastInfo", forecastInfo).then(async () => {
      await this.router.push({name: 'CalculateForecast', params: {mode: 'edit' }})
    })
     */
  }


  confirmDeleteForecast(forecastInfo: ForecastInfo) {
    this.forecastToDelete = forecastInfo
  }

  async editReductionTarget(forecastInfo: ForecastInfo) {
    await this.router.push('reduction-objectives?forecastId=' + forecastInfo.forecastId)
    /* works local but not on slow dev
    await this.store.dispatch("setCurrentForecastInfo", forecastInfo).then(async () => {
      await this.router.push({name: 'ReductionObjectives', params: {mode: 'edit', forecastId: forecastInfo.forecastId }})
    })*/
  }

  async deleteForecast(forecastInfo?: ForecastInfo) {
    if (!forecastInfo) {
      return
    }
    this.disableDeleteModalButtons = true
    this.eventHub.emit("start-loading", "ForecastOverview")
    await this.store.dispatch("deleteForecast", forecastInfo)
    await this.store.dispatch("loadForecasts").finally(() => {
      this.disableDeleteModalButtons = false
      this.eventHub.emit("stop-loading", "ForecastOverview")
    })
    this.forecastToDelete = {} as ForecastInfo
    await this.getCombinedForecastReductionInfo()
  }

  async createNew() {
    await this.store.dispatch("setCurrentForecastInfo")
    await this.router.push('/forecast/calculate-forecast')
  }

}
</script>

<style lang="scss" scoped>

input[name="copy"] {
  accent-color: black;
}

</style>
