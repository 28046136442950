import {ForecastInfoMessageStatusEnum} from "../../openapi/generated-clients/climatecompass";

export class PendingForecastEntry {
    forecastId = 0
    forecastName = ''

    createdTime: string

    pollingStarted = false
    pollingCompleted = false
    currentInterval = 0
    currentTimeout = 0
    currentRetryCount = 0
    currentDataSize = 0

    errorArray: {code:  ForecastInfoMessageStatusEnum, message: string}[] = []

    failed = this.pollingCompleted && this.errorArray.length > 0

    constructor(forecastId: number, forecastName: string, createdTime: string) {
        this.forecastId = forecastId
        this.forecastName = forecastName
        this.createdTime = createdTime
    }
}