<template>
  <SharedHeader :headerPadding="'pt-8 pb-505'">
    <template #firstColumn>
      <div><RichText text-key="calculationsoverview.headerLeft"/> </div>
    </template>

    <template #secondColumn>
      <div>
        <!--img v-if="pageProperties.headerRight" :src="`https:${pageProperties.headerRight.fields.file.url}`" alt=""
             style="width: 50%; height: 50%;" /-->
        <img :src="this.headerRight" alt="" style="width: 50%; height: 50%;" />
      </div>
    </template>
  </SharedHeader>
  <div v-if="calculations" class="container px-0 pb-705 pt-0">
    <h2>
      <SimpleText text-key="calculationsoverview.draftsTitle"/>
    </h2>

    <OverviewTableComponent
      id="drafts"
      :calculations="calculations"
      :versions="versions"
      :showOnly="'draft'"
      @edit-calculation="editCalculation($event)"
      @update-ecv-calculation="updateECVCalculationConfirm($event)"
      @delete-calculation="confirmDeleteCalculation($event)"
      @complete-calculation="completeCalculation($event)"
      @copy-calculation="confirmCopyCalculation($event)"
    />

    <h2><SimpleText text-key="calculationsoverview.finishedTitle"/></h2>
    <OverviewTableComponent
      id="finished"
      :calculations="calculations"
      :versions="versions"
      :showOnly="'finished'"
      @reopen-calculation="confirmReopenCalculation($event)"
      @update-ecv-calculation="updateECVCalculationConfirm($event)"
      @show-calculation="showCalculation($event)"
      @copyCalculation="confirmCopyCalculation($event)"/>
  </div>

  <!-- Delete confirmation modal START-->
  <template v-if="calculationToDelete.id && calculationToDelete.id > 0">
    <AcceptModal
        :value-object="calculationToDelete"
        :title-key="'calculation.overview.delete-modal.title'"
        :buttons-disabled="disableDeleteModalButtons"
        :button-ok-text-key="'calculation.overview.delete-modal.button.ok'"
        :button-reject-text-key="'calculation.overview.delete-modal.button.cancel'"
        @select-ok="deleteCalculation($event)"
        @select-reject="resetCalculationToDelete()"
    >
      <p>
        <strong><SimpleText :text-key="'calculation.overview.delete-modal.text1'"/></strong>
        <br />
        <br />
        {{ calculationToDelete.calculationPurpose.name }}
        <br />
        <br />
        <SimpleText :text-key="'calculation.overview.delete-modal.text2'"/>
      </p>
    </AcceptModal>
  </template>
  <!-- Delete confirmation modal END-->

  <!-- copy confirmation modal START-->
  <template v-if="calculationToCopy.id && calculationToCopy.id > 0">
    <AcceptModal
        :value-object="calculationToCopy"
        :title-key="'calculation.overview.copy-modal.title'"
        :buttons-disabled="disableCopyModalButtons"
        :button-ok-text-key="'calculation.overview.copy-modal.button.ok'"
        :button-reject-text-key="'calculation.overview.copy-modal.button.cancel'"
        @select-ok="copyCalculation($event)"
        @select-reject="resetCalculationToCopy()"
    >
      <p>
        <SimpleText :text-key="'calculation.overview.copy-modal.text1'"/>
        <br />
        <br />
        <strong><SimpleText :text-key="'calculation.overview.copy-modal.text2'"/></strong>
      </p>

      <ul class="nobullet-list">
        <li>
          <div class="form-group-radio">
            <input type="radio" class="form-radio" name="copy" id="yes" value="Yes" v-model="includeFilesWhenCopy" />
            <label for="yes"><SimpleText :text-key="'calculation.overview.copy-modal.radio-button.option1'"/></label>
          </div>
        </li>
        <li>
          <div class="form-group-radio">
            <input type="radio" class="form-radio" name="copy" id="no" value="No" v-model="includeFilesWhenCopy" />
            <label for="no"><SimpleText :text-key="'calculation.overview.copy-modal.radio-button.option2'"/></label>
          </div>
        </li>
      </ul>
    </AcceptModal>

  </template>
  <!-- Copy confirmation modal END-->

  <!-- Reopen confirmation modal START-->
  <template v-if="calculationToReopen.id && calculationToReopen.id > 0">
    <AcceptModal
        :value-object="calculationToReopen"
        :title-key="'calculation.overview.reopen-modal.title'"
        :buttons-disabled="disableReopenModalButtons"
        :button-ok-text-key="'calculation.overview.reopen-modal.button.ok'"
        :button-reject-text-key="'calculation.overview.reopen-modal.button.cancel'"
        @select-ok="unlockCalculation($event)"
        @select-reject="resetCalculationToReopen()"
    >
      <p>
        <strong><SimpleText :text-key="'calculation.overview.reopen-modal.text1'"/></strong>
        <br />
        <br />
        {{ calculationToReopen.calculationPurpose.name }}
        <br />
        <br />
        <SimpleText :text-key="'calculation.overview.reopen-modal.text2'"/>
      </p>
    </AcceptModal>
  </template>
  <!-- Reopen confirmation modal END-->
  <!-- update confirmation modal-->
  <template v-if="calculationToUpdateVersion.id && calculationToUpdateVersion.id > 0">
    <AcceptModal
        :value-object="calculationToUpdateVersion"
        :title-key="'purpose.emf.update.dialog.title'"
        :buttons-disabled="disableUpdateVersionModalButtons"
        :button-ok-text-key="'purpose.emf.update.dialog.button.ok'"
        :button-reject-text-key="'purpose.emf.update.dialog.button.cancel'"
        @select-ok="updateECVCalculation($event)"
        @select-reject="resetVersion()"
    >
      <p v-html="updateVersionText(calculationToUpdateVersion)"/>
    </AcceptModal>
  </template>

</template>

<script lang="ts">
import { Vue, Options, mixins } from "vue-class-component"
import OverviewTableComponent from "@/components/calculator/OverviewTableComponent.vue"
import SharedHeader from "@/components/calculator/shared/SharedHeader.vue"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { useRouter } from "vue-router"
import {Calculation, CalculationStateEnum, Versions} from "../../openapi/generated-clients/climatecompass"
import {Cloneable} from "@/shared/DeepCopyHelper"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import {calculationClient, versionClient} from "@/shared/BackendService";
import SimpleText from "@/components/SimpleText.vue";
import RichText from "@/components/RichText.vue";
import AcceptModal from "@/components/AcceptModal.vue";


@Options({
  components: {
    AcceptModal,
    OverviewTableComponent,
    SharedHeader,
    SimpleText,
    RichText
  }
})
export default class CalculationsOverview extends BaseComponent {
  router = useRouter()
  calculationToDelete: Calculation = {} as Calculation
  calculationToCopy: Calculation = {} as Calculation
  calculationToReopen: Calculation = {} as Calculation
  calculationToUpdateVersion: Calculation = {} as Calculation
  includeFilesWhenCopy = "No"
  versions = {} as Versions
  disableDeleteModalButtons = false
  disableCopyModalButtons = false
  disableReopenModalButtons = false
  disableUpdateVersionModalButtons = false

  resetVersion() {
    this.calculationToUpdateVersion = {} as Calculation
  }


  //calculations: any[] = []

  get calculations() {
    return this.store.getters.getCalculations
  }

  get documentToHtmlString() {
    return documentToHtmlString
  }

  async beforeMount() {
    try {
      this.versions = (await versionClient.fetchVersions()).data
    } catch (e) {
      console.error("Unable to fetch version",)
    }
    this.eventHub.emit("start-loading", "CalculationsOverview")
    await this.store.dispatch("loadCalculations").finally(() => {
      this.eventHub.emit("stop-loading", "CalculationsOverview")
    })

    //this.calculations = this.store.getters.getCalculations
    //xdocument.title = "Overblik | Klimakompasset"
  }

  resetCalculationToDelete() {
    this.calculationToDelete = {} as Calculation
  }

  resetCalculationToCopy() {
    this.calculationToCopy = {} as Calculation
  }

  resetCalculationToReopen() {
    this.calculationToReopen = {} as Calculation
  }

  async editCalculation(calculation: Calculation) {
    await this.store.dispatch("setCurrentCalculation", calculation).then(async () => {
      if (!calculation.companyInformation) {
        await this.router.push("/calculator/master-data/company-information")
      } else {
        await this.router.push("/calculator/energy-and-processes")
      }
    })
  }

  get thirdpartyInfo() {
    return this.store.getters.getThirdpartyInfo
  }

  async updateECVCalculationConfirm(calculation: Calculation) {
    this.calculationToUpdateVersion = calculation
  }

  async updateECVCalculation(calculation: Calculation) {
    if (calculation.emissionCoefficientsVersion?.year && calculation.id) {
      this.disableUpdateVersionModalButtons = true
      const newEmissionCoefficientsVersion = this.getVersionByYear(this.versions, calculation.emissionCoefficientsVersion?.year)
      this.calculationToUpdateVersion = {} as Calculation
      await calculationClient.updateCalculationWithEmissionCoefficientVersion(
          calculation.id,
          calculation.emissionCoefficientsVersion.year,
          newEmissionCoefficientsVersion,
          this.thirdpartyInfo && this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null'
      )
      this.addEmissionUpdateToast()
      this.eventHub.emit("start-loading", "CalculationsOverview")
      await this.store.dispatch("loadCalculations").finally(() => {
        this.eventHub.emit("stop-loading", "CalculationsOverview")
        this.disableUpdateVersionModalButtons = false
      })
    }
  }

  async showCalculation(calculation: Calculation) {
    const _calculation = { ...calculation, state: "readonly" as CalculationStateEnum.Readonly }
    await this.store.dispatch("setCurrentCalculation", _calculation)

    if (!_calculation.companyInformation) {
      await this.router.push("/calculator/master-data/company-information")
    } else {
      await this.router.push("/calculator/energy-and-processes")
    }
  }

  async copyCalculation(calculation: Calculation) {
    this.disableCopyModalButtons = true
    this.eventHub.emit("start-loading", "CalculationsOverview")
    //await calculationClient.copyCalculation(calculation);
    const calculationCopy = Cloneable.deepCopy<Calculation>(calculation)
    calculationCopy.copyFiles = (this.includeFilesWhenCopy === 'Yes')
    this.calculationToCopy = {} as Calculation
    await this.store.dispatch("copyCalculation", calculationCopy)
    await this.store.dispatch("loadCalculations").finally(() => {
      this.disableCopyModalButtons = false
      this.eventHub.emit("stop-loading", "CalculationsOverview")
    })

    this.addToast("fromOverviewTableComponent", "success",
        this.toastPropertiesToastHeader,
        this.toastPropertiesToastMessage.replace("#name", calculation.calculationPurpose.name),
        true, false)
    this.addToast("fromOverviewTableComponent2", "info",
        this.toastPropertiesMarkerToastHeader, this.toastPropertiesMarkerToastMessage,
        true, false)
  }

  async completeCalculation(calculation: Calculation) {
    const _calculation = { ...calculation, state: "finished" as CalculationStateEnum.Finished }
    this.eventHub.emit("start-loading", "CalculationsOverview")
    await this.store.dispatch("setCalculation", _calculation)
    await this.store.dispatch("loadCalculations").finally(() => {
      this.eventHub.emit("stop-loading", "CalculationsOverview")
    })
  }

  confirmDeleteCalculation(calculation: Calculation) {
    this.calculationToDelete = calculation
  }

  confirmCopyCalculation(calculation: Calculation) {
    this.calculationToCopy = calculation
  }

  async deleteCalculation(calculation?: Calculation) {
    if (!calculation) {
      return
    }
    this.disableDeleteModalButtons = true
    this.eventHub.emit("start-loading", "CalculationsOverview")
    const _calculation = { ...calculation, state: "deleted" as CalculationStateEnum.Deleted }
    await this.store.dispatch("setCalculation", _calculation)
    await this.store.dispatch("loadCalculations").finally(() => {
      this.disableDeleteModalButtons = false
      this.eventHub.emit("stop-loading", "CalculationsOverview")
    })
    this.calculationToDelete = {} as Calculation
  }

  confirmReopenCalculation(calculation: Calculation) {
    this.calculationToReopen = calculation
  }

  async unlockCalculation(calculation?: Calculation) {
    if (!calculation) {
      return
    }
    this.disableReopenModalButtons = true
    this.eventHub.emit("start-loading", "CalculationsOverview")
    const _calculation = { ...calculation, state: "draft" as CalculationStateEnum.Draft }
    await this.store.dispatch("setCalculation", _calculation)
    await this.store.dispatch("loadCalculations").finally(() => {
      this.disableReopenModalButtons = false
      this.eventHub.emit("stop-loading", "CalculationsOverview")
    })
    this.calculationToReopen = {} as Calculation
  }

  updateVersionText(calculation: Calculation) {
    if (calculation) {
      return this.store.getters.getContentfulContent.renderRichText('purpose.emf.update.dialog.text')
          .replace('#YEAR#', this.getStartYear(calculation))
          .replace('#CALCULATION#', calculation.calculationPurpose.name)
    } else {
      return ""
    }
  }

  get toastPropertiesToastHeader() {
    return this.store.getters.getContentfulContent.findSimpleText('calculationsoverview.toastPropertiesToastHeader')
  }

  get toastPropertiesToastMessage() {
    return this.store.getters.getContentfulContent.findSimpleText('calculationsoverview.toastPropertiesToastMessage')
  }

  get toastPropertiesMarkerToastHeader() {
    return this.store.getters.getContentfulContent.findSimpleText('calculationsoverview.toastPropertiesMarkerToastHeader')
  }

  get toastPropertiesMarkerToastMessage() {
    return this.store.getters.getContentfulContent.findSimpleText('calculationsoverview.toastPropertiesMarkerToastMessage')
  }

  get headerRight() {
    return this.store.getters.getContentfulContent.findSimpleText('calculationsoverview.headerRight')
  }

}
</script>

<style lang="scss" scoped>

input[name="copy"] {
  accent-color: black;
}

</style>
