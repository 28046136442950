<template>
  <div class="container py-9">
    <div class="row">
      <div class="col-4">
        <form @submit.prevent="login()">
          <!-- Felt start: Login -->
          <div class="form-group">
            <label class="form-label" for="password">
              Indtast CVR- eller CPR-nummer:
            </label>

            <input
              id="password"
              v-model="inputAdminSecret"
              class="form-input validity"
              maxlength="10"
              minlength="8"
              name="password"
              :pattern="findRegexPattern('regex.validation.admin-secret-password')"
              required
              type="text"
            />
          </div>
          <!-- Felt slut: Navn -->

          <!-- Knap start -->
          <button id="submitButton" class="button button-primary mt-2" type="submit">
            Log Ind
          </button>
          <!-- Knap slut -->
        </form>
      </div>
      <!--div class="col-8" v-html="documentToHtmlString(pageProperties.loginText)"></div-->
      <div class="col-8"><RichText text-key="login.loginText"/></div>
    </div>
  </div>
</template>

<script lang="ts">
import router from "@/router"
import { useStore } from "vuex"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { useRoute } from "vue-router"
import { Vue, Options, mixins } from "vue-class-component"
import RichText from "@/components/RichText.vue";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";

@Options({
  components: {
    RichText
  }
})
export default class Login extends BaseComponent {
  inputAdminSecret = ""
  route = useRoute()
  store = useStore()

  get documentToHtmlString() {
    return documentToHtmlString
  }

  get isAuthenticated() {
    return this.store.getters.isAuthenticated
  }

  get getContactPerson() {
    return this.store.getters.getContactPerson
  }

  async login() {
    await this.store.dispatch("login", this.inputAdminSecret)
    await this.store.dispatch("loadContactPerson")

    this.inputAdminSecret = ""
    if (this.isAuthenticated === true) {
      if (!this.getContactPerson.firstName) {
        await router.push("/contact-information")
      } else {
        await router.push({
          path: (this.route.params.fromPath ?? "/").toString()
        })
      }
    } else {
      alert("Wrong password, please try again")
    }
  }
}
</script>
