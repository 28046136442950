import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApexChart = _resolveComponent("ApexChart")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ApexChart, {
      type: "bar",
      options: _ctx.chartOptions,
      series: _ctx.chartOptions.series,
      width: "100%",
      height: "400",
      ref: "vueApexChartRef"
    }, null, 8, ["options", "series"])
  ]))
}