<template>
  <div class="container px-0 py-9" id="wrapper-div" v-if="pageAccess">
    <h1>Import af Emissionsfaktorer</h1>
    <h2>Emissionscoefficienter allerede indlæst i systemet</h2>
    <form @submit.prevent="importEmissionsFactor">
      <div class="d-flex align-items-center mb-4" id="check-box-section">
        <input type="checkbox" id="mono-lingual-select" v-model="onlyDanishEMF" @click="confirmCheckboxDKValue()"
               class="form-checkbox checkbox-medium">
        <label for="mono-lingual-select" class="ml-2">
          Kun Danske
        </label>

        <input type="checkbox" id="newest-version-select" v-model="onlyNewestVersion"
               @click="confirmCheckboxNewestVersionValue()" class="form-checkbox checkbox-medium">
        <label for="newest-version-select" class="ml-2">
          Nyeste version
        </label>
      </div>
    </form>
    <div id="section1" class="py-9">
      <div class="table--responsive-scroll" style="max-height: 550px; min-width: 680px; overflow: auto;">
        <table class="table" id="existing-emf-data-table">
          <caption>Ekisterende imports</caption>
          <thead>
          <tr @click="sortBy('year')">
            <th scope="col">År</th>
            <th scope="col">Version</th>
            <th scope="col">Land</th>
            <th scope="col">Antal</th>
            <th scope="col">Fremskrevet</th>
          </tr>
          </thead>
          <tbody v-if="existingEmfImport">
          <tr v-for="(item, index) in existingEmfImport" :key="`item_${item.year}_${index}`">
            <td>{{ item.year }}</td>
            <td>{{ item.version }}</td>
            <td>{{ item.countryCode }}</td>
            <td>{{ item.numberEMC }}</td>
            <td>{{ item.actual ? 'Nej' : 'Ja' }}</td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr aria-colspan="5">
            <td colspan="5">Ingen rækker at vise</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div id="section2" class="py-9">
      <div class="d-flex justify-content-center mt-4">
        <button class="button button-secondary adjust-button-size"
                onclick="document.getElementById('upload-file2').click()">Vælg en excelfil</button>
        &nbsp;&nbsp;
        <input type='file' id="upload-file2" style="display:none" @change="addFile">
        {{ this.excelFileName.fileName }}
        <button class="button button-primary adjust-button-size" @click="validateFile">Valider</button>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <input type="checkbox" id="same-version-all-year" v-model="isForecast" @click="isForecastCheckbox()" class="form-checkbox checkbox-medium" title="Er import en fremskrinving">
        <label for="same-version-all-year" class="ml-2">Importer som fremskrevet</label>&nbsp;
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="form-input-1">År:</label>
          <input type="text" id="form-input-1" class="form-control" v-model="year" placeholder="f.eks. 2015">
        </div>
        <div class="col-md-3">
          <label for="form-input-2">Version:</label>
          <input type="text" id="form-input-2" class="form-control" v-model="version" placeholder="f.eks. 2">&nbsp;
        </div>
        <div class="d-flex justify-content-center mt-4">
          <button class="button button-secondary adjust-button-size" v-if="canImport" :disabled="disableImport()" @click="importEmissionsFactor">Importer</button>
          <button class="button button-secondary adjust-button-size" @click="exportToSqlString">Export til SQL</button>&nbsp;
          <button class="button button-secondary adjust-button-size" @click="deleteEMC()">Slet</button>
        </div>
      </div>
    </div>

    <p><b>{{ this.status }}</b></p>

    <div v-if="validationErrors.length > 0">
      <h2 v-if="this.excelFileName.fileName">Validerings Log ({{ validationErrors.length }}) </h2>
      <h2 v-else>Validerings Log</h2>
      <div class="table--responsive-scroll" style="height: 550px; overflow: auto">
        <table class="table w-percent-100">
          <caption></caption>
          <thead>
          <tr>
            <th id="excel-file-name" colspan="5" v-if="this.excelFileName.fileName" style="text-align: center;">
              {{ this.excelFileName.fileName }}
            </th>
          </tr>
          </thead>
          <thead>
          <tr>
            <th style="width: 15%" id="validate_error_type">Type</th>
            <th style="width: 40%" id="validate_error_description">Beskrivelse</th>
            <th style="width: 45%" id="validate_error_value">Værdi</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in validationErrors" :key="item">
            <td style="width: 15%">{{ item.name }}</td>
            <td style="width: 40%">{{ item.value }}</td>
            <td style="width: 45%">{{ item.description }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
      <p v-if="this.excelFileName.fileName"> {{ this.importSucces ? 'Import done!!' : '' }}<br>
      </p>
    </div>
  </div>
  <div class="container px-0 py-9" v-else>Din brugerprofil har ikke adgang til dette skærmbillede</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import {configurationClient, emissionsfactorImportClient} from "@/shared/BackendService";
import DKFDS from "dkfds";
import {
  EMFMasterdata,
  EmissionCoefficientOverview,
  ExcelRow,
  FileMessage,
  ValidationError
} from "../../../openapi/generated-clients/climatecompass";
import * as XLSX from 'xlsx'


export default class EmissionFactorImport extends BaseComponent {

  //variables
  actual = '1'
  actualCheckbox = false
  year = '2022'
  version = '2'
  currentSqlStringArray = []
  canImport = false

  sortKey = '' // Initial sorting key
  sortDirection = 'asc' // Initial sorting direction

  onlyDanishEMF = false
  onlyNewestVersion = false
  isForecast = false

  importedExcel = {} as File
  excelFile = {} as File
  excelFileName = {} as Partial<FileMessage>

  existingEmfImport: EmissionCoefficientOverview[] = []
  validationErrors: ValidationError[] = []
  importSucces = false
  status = ''
  pageAccess = false

  async beforeMount() {
    DKFDS.init()
    this.loadEMCList()
    this.loadImportPermission()
    await configurationClient
        .getImportAccess()
        .then((response) => {
          this.pageAccess = response.data as unknown as boolean
        })
        .catch((error) => {
          console.error(error)
        })
  }

  updated() {
    DKFDS.init()
  }

  async actualToggle() {
    this.actualCheckbox = !this.actualCheckbox
    this.actual = this.actualCheckbox ? '0' : '1'
    //console.log('actual value = ' + this.actual)
  }

  disableImport() {
    const realErrors = this.validationErrors?.filter((e) => e.name !== 'Advarsel')
    if (realErrors.length !== 0) {
      return true
    }
    if (this.excelFileName.fileName === undefined) {
      return true
    }
    return false
  }

  async importEmissionsFactor() {
    //console.log("importEmissionsFactor", this.importSucces)
    //console.log('Import Data: excelFile = ' + this.excelFile + ' | year = ' + this.year)
    this.status = 'Importere...'
    // need file : string($binary) && forecastFromYear : string && year : number
    this.validationErrors = (await emissionsfactorImportClient.importEMF(
        this.excelFile,
        this.year,
        this.isForecast ? '1' : '0',
        this.version)).data || []
    if (this.validationErrors.length === 0) {
      this.loadEMCList()
      this.importSucces = true
    } else {
      this.importSucces = false
    }
    return this.validationErrors
  }

  async loadImportPermission() {
    await emissionsfactorImportClient.getImportPermission().then((response) => {
      if (response.data) {
        this.canImport = response.data.canImport || false
      }
    })
  }

  async exportToSqlString() {
    let result = {}

    await emissionsfactorImportClient.exportEMF(Number(this.year), Number(this.version)).then((response) => {
      if (response.data) {
        result = response.data
      }
    })
    const win = window.open('', 'sql string to export', 'height=600,width=800')
    if (win) {
      win.document.write('<p>' + result.toString().replaceAll('\n', '<br>') + '</p>')
      win.alert('SQL til import af emissionsfaktorer, skal typisk puttes i liquibase fil og bygges ind i release')
    }
    return result = this.currentSqlStringArray
  }

  async deleteEMC() {
    if (window.confirm("Er du helt sikker, denne operation kan ikke fortrydes")) {
      await emissionsfactorImportClient.deleteEMC(parseInt(this.year), parseInt(this.version)).then((resonse) => {
        if (resonse.data) {
          this.status = resonse.data
          this.loadEMCList()
        }
      })
    }
  }

  async addFile(e: Event) {
    const element = e.target as HTMLInputElement
    if (!this.excelFile) {
      //this.excelFile = {}
    }
    if (!this.excelFileName) {
      this.excelFileName = {}
    }
    if (!element.files) return
    this.excelFile = element.files[0]
    this.excelFileName.fileName = element.files[0].name
    this.excelFileName.fileType = element.files[0].type
    element.form?.reset()
  }

  async validateFile() {
    this.importSucces = false
    console.log('Excel file : ' + this.excelFileName.fileName)

    if (this.excelFileName.fileName === undefined) {
      window.alert("Der skal vælges en Excel fil")
    } else {
      this.status = 'Validere...'
      this.validationErrors = (await emissionsfactorImportClient.validateExcel(this.excelFile)).data || []
      if (this.validationErrors.length === 0) {
        this.status = 'Validation OK'
      } else {
        this.status = ''
      }
    }

  }

  /// ------ Async-Functions end ------///

  /// ------  Sorting-Functions   ------///

  sortBy(key: keyof EmissionCoefficientOverview) {
    // Toggles sorting direction between ascending and descending when header is clicked
    if (key === this.sortKey) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
    } else {
      this.sortDirection = 'asc'
    }
    this.sortKey = key

    // Sort the table data based on year
    this.existingEmfImport.sort((a, b) => {
      const aValue = a[key]
      const bValue = b[key]

      if (this.sortDirection === 'asc') {
        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return aValue - bValue
        }
      } else {
        // Reverse order for descending sort
        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return bValue - aValue
        }
      }
      return 0; // No comparison - maintain current order
    })
  }

  /// ------ Sorting-Functions end ------///


  confirmCheckboxDKValue() {
    this.onlyDanishEMF = !this.onlyDanishEMF
    this.loadEMCList()
    //console.log(this.onlyDanishEMF + '<-- onlyDanishEMF value')
  }

  confirmCheckboxNewestVersionValue() {
    this.onlyNewestVersion = !this.onlyNewestVersion
    this.loadEMCList()
  }

  isForecastCheckbox() {
    this.isForecast = !this.isForecast
  }

  async loadEMCList() {
    let result: EmissionCoefficientOverview[] = []
    this.existingEmfImport = result
    await emissionsfactorImportClient.getEMF(this.onlyDanishEMF, this.onlyNewestVersion).then((response) => {
      if (response.data) {
        result = response.data
      }
    })
    return this.existingEmfImport = result
  }

  dummyUploadFile() {
    const uploadInput = document.getElementById(`map-fileinput`) as HTMLInputElement
    if (uploadInput) {
      uploadInput.click()
    }
  }
}

</script>

<style lang="scss" scoped>

.sql-log {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
}

#section1 {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

#section1 .form-group {
  flex: 1;
  margin-right: 10px;
}

#section2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

#button-section {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}


</style>
