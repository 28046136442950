<template>
  <nav class="nav bg-alternative d-print-none">
    <div class="navbar navbar-primary">
      <div class="navbar-inner container px-0">
        <ul class="nav-primary" role="menu">
          <li v-for="menuItem in menu.menuItems" :key="menuItem.label" role="none">
            <router-link
                :id="`${menuItem.id}-link`"
                :active-class="'current bold'"
                :exact="menuItem.exact"
                :to="menuItem.link"
                class="nav-link"
                role="menuitem"
                :data-cy-inner="menuItem.label"
            >
              <span>
                {{ menuItem.label }}
              </span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script lang="ts">
import {Menu} from "@/shared/MenuStructure"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"

export default class Navigation extends BaseComponent {
  menu: Menu = {} as Menu

  async beforeMount() {
    this.menu = this.store.getters.getMenuStructure.setupMenu
  }
}
</script>

<style lang="scss" scoped>

.nav-primary .nav-link:hover span,
.nav-primary .current span {
  color: $color-primary-dark;
  border-bottom: 7px solid transparent;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.disabled:hover {
  opacity: 0.5;
  pointer-events: none;
  color: #454545;
}
</style>
