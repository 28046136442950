<template>
  <CalculatorTabComponent>
    <template v-slot:calculator-primary-1="slotProps">
      <DistrictHeatingCalculator
        :subgroupingId="constants.GROUP_FJERNVARME"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
        :expanded="true"
      >
      </DistrictHeatingCalculator>
    </template>

    <template v-slot:calculator-primary-2="slotProps">
      <FuelCalculator
        :subgroupingId="constants.GROUP_BRÆNDSLER"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip">
      </FuelCalculator>
    </template>

    <template v-slot:calculator-secondary-1="slotProps">
      <OtherCalculator
        :subgroupingId="constants.GROUP_VARME_OG_PROCESENERGI_MED_EGNE_EMISSIONSFAKTORER"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
      >
      </OtherCalculator>
    </template>
  </CalculatorTabComponent>
</template>

<script lang="ts">
import { Options, Prop } from "vue-property-decorator"
import BaseComponent from "../base-components/BaseComponent"
import CalculatorTabComponent from "../CalculatorTabComponent.vue"
import ElectricityCalculator from "./calculators/ElectricityCalculator.vue"
import OtherCalculator from "./calculators/OtherCalculator.vue"
import DistrictHeatingCalculator
  from "@/components/calculator/energy-and-processes/calculators/HeatCalculator.vue"
import FuelCalculator from "@/components/calculator/energy-and-processes/calculators/FuelCalculator.vue"

@Options({
  components: { FuelCalculator, DistrictHeatingCalculator, OtherCalculator, ElectricityCalculator, CalculatorTabComponent }
})
export default class HeatView extends BaseComponent {

}
</script>

<style scoped>

</style>