<template>
  <a :href="toLink" class="button button-primary mt-705" @click.prevent="next($event)">
    <SimpleText text-key="sharedContent.next"/>
  </a>

  <div class="mt-6">
    <a :href="fromLink" class="back-link" @click.prevent="back($event)">
      <SimpleText text-key="sharedContent.back"/>
    </a>
  </div>
</template>

<script lang="ts">
import { useRouter } from "vue-router"
import { Prop } from "vue-property-decorator"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import SimpleText from "@/components/SimpleText.vue";
import {Options} from "vue-class-component";

@Options({ components: {SimpleText } })
export default class NextAndBack extends BaseComponent {
  @Prop({ required: true })
  from = ""
  @Prop({ required: true })
  to: any = ""

  router = useRouter()
  get toLink() {
    return this.router.resolve(this.to).href
  }
  get fromLink() {
    return this.router.resolve(this.from).href
  }

  getParentContainerElement(element: HTMLElement): HTMLElement {
    if (element.classList.contains("container")) {
      return element
    } else {
      return this.getParentContainerElement(element.parentElement!)
    }
  }

  scroll(element: HTMLElement) {
    this.$nextTick(() => this.getParentContainerElement(element).parentElement?.scrollIntoView(true))
  }

  next(e: Event) {
    this.router.push(this.to)
    this.scroll(e.target as HTMLElement)
  }

  back(e: Event) {
    this.router.push(this.from)
    this.scroll(e.target as HTMLElement)
  }
}
</script>
