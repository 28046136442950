<template>
  <CalculatorTabComponent>
    <template v-slot:calculator-primary-1="slotProps">
      <OtherSoldProductsCalculator
        :subgroupingId="constants.GROUP_ANDET_VED_SOLGTE_PRODUKTER"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
        :expanded="true"
      >
      </OtherSoldProductsCalculator>
    </template>
  </CalculatorTabComponent>
</template>

<script lang="ts">
import { Options, Prop, Watch } from "vue-property-decorator"
import BaseComponent from "../base-components/BaseComponent"
import CalculatorTabComponent from "../CalculatorTabComponent.vue"
import OtherSoldProductsCalculator
  from "@/components/calculator/sold-products/calculators/OtherSoldProductsCalculator.vue"
import UseCalculator from "@/components/calculator/sold-products/calculators/UseCalculator.vue"

@Options({
  components: { UseCalculator, OtherSoldProductsCalculator, CalculatorTabComponent }
})
export default class OtherSoldProductsView extends BaseComponent {

}
</script>

<style scoped>

</style>