import {Constants} from "@/shared/Constants";

export default class ToastMessage {
    id: string
    type: string
    show: boolean
    header: string
    message: string
    showCloseButton = true
    removeOnTimeout = true
    onlyOneToastPerId = false
    renderAsHtml = false
    timeout = Constants.TOAST_DEFAULT_TIMEOUT

    constructor(id: string, type: string, show: boolean, header: string, message: string,
                showCloseButton: boolean, removeOnTimeout: boolean, onlyOneToastPerId = false,
                renderAsHtml = false,
                stringReplacements : {key: string, value: string}[] | undefined = undefined,
                stringReplacements2 : string | undefined = undefined) {
        this.id = id
        this.type = type
        this.show = show
        this.header = header
        this.message = message
        if (stringReplacements2) {
            const jsonObj = JSON.parse(stringReplacements2)
            Object.entries(jsonObj).forEach((entry) => {
                const [key, value] = entry;
                //console.log("key-value:", `${key} ${value}`);
                this.message = this.message.replaceAll(key, value as string)
            });
        }
        if(stringReplacements) {
            stringReplacements.forEach((stringReplacement) => {
                this.header = this.header.replaceAll(stringReplacement.key, stringReplacement.value)
                this.message = this.message.replaceAll(stringReplacement.key, stringReplacement.value)
            })
        }
        this.showCloseButton = showCloseButton
        this.removeOnTimeout = removeOnTimeout
        this.onlyOneToastPerId = onlyOneToastPerId
        this.renderAsHtml = renderAsHtml
    }
}