import store from "@/store";
import {getEntriesId} from "@/shared/ContentfulService";


export const getPage = async <T>(id: string) => {
    const locale = {locale: window.localStorage.getItem('locale') || 'da'};

    const contentfulEntries = store.getters.getContentfulEntries as Map<string, object>
    let entry: any = ""
    if (contentfulEntries?.has(id)) {
        entry = contentfulEntries.get(id);
    } else {
        console.warn("getPage(): no hit try slow single lookup with id:", id)
        await getEntriesId(id).then((result) =>{
            entry = result[0];
            if (result === undefined) {
                console.log("result", entry)
            }
        }).catch(() => {
            console.warn("Error Fetching entry from store  with id='" + id + "', locale=" + JSON.stringify(locale))
        })
    }
    return entry ? entry.fields : null;
};

export const getPageFull = async <T>(id: string) => {
    const locale = {locale: window.localStorage.getItem('locale') || 'da'};

    const contentfulEntries = store.getters.getContentfulEntries as Map<string, object>
    let entry: any = ""
    if (contentfulEntries?.has(id)) {
        entry = contentfulEntries.get(id);
    } else {
        console.warn("getPage(): no hit try slow single lookup with id:", id)
        await getEntriesId(id).then((result) =>{
            entry = result[0];
            if (result === undefined) {
                console.log("result", entry)
            }
        }).catch(() => {
            console.warn("Error Fetching entry from store  with id='" + id + "', locale=" + JSON.stringify(locale))
        })
    }
    return entry ? entry : null;
};
