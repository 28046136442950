import { nextTick } from 'vue'

export class FormValidation {
  public formErrors = new Map<string, string>()
  public getParentContainerElement: any
  private readonly formErrorToken

  constructor() {
    this.formErrorToken = 'form-error'
    this.getParentContainerElement = (element: HTMLElement) => {
      if (element.classList.contains('container')) {
        return element
      } else {
        return this.getParentContainerElement(element.parentElement!)
      }
    }
  }

  private static getElementTarget(e: Event) {
    return e.target as HTMLInputElement
  }

  public clearValidField(e: Event) {
    const element = FormValidation.getElementTarget(e)
    const firstParent = element.parentElement

    if (
      firstParent?.classList.contains('form-group-day') ||
      firstParent?.classList.contains('form-group-month') ||
      firstParent?.classList.contains('form-group-year')
    ) {
      firstParent?.parentElement?.parentElement?.parentElement?.classList.remove(this.formErrorToken)
    } else if (element.classList.contains('form-select')) {
      if(firstParent && firstParent.classList.contains('form-group')) {
        firstParent.classList.remove(this.formErrorToken)
      } else {
        firstParent?.parentElement?.classList.remove(this.formErrorToken)
      }
    } else {
      if(firstParent && firstParent.classList.contains('form-input-wrapper')) {
        firstParent.parentElement?.classList.remove(this.formErrorToken)
      } else {
        firstParent?.classList.remove(this.formErrorToken)
      }
    }

    if (element.validity.valid) {
      this.formErrors.delete(element.id)
    }
  }

  public handleFormValidity(e: Event, autoScrollEnabled = true) {
    const element = FormValidation.getElementTarget(e)
    const firstParent = element.parentElement
    if (autoScrollEnabled) {
      nextTick(() => this.getParentContainerElement(element).scrollIntoView(true))
    }

    if (
      firstParent?.classList.contains('form-group-day') ||
      firstParent?.classList.contains('form-group-month') ||
      firstParent?.classList.contains('form-group-year')
    ) {
      firstParent?.parentElement?.parentElement?.parentElement?.classList.add(this.formErrorToken)
    } else if (element.classList.contains('form-select')) {

      //Check for default DKFDS nesting

      if(firstParent && firstParent.classList.contains('form-group')) {
        firstParent.classList.add(this.formErrorToken)
      } else {
        firstParent?.parentElement?.classList.add(this.formErrorToken)
      }

    } else {
      if(firstParent && firstParent.classList.contains('form-input-wrapper')) {
        firstParent.parentElement?.classList.add(this.formErrorToken)
      } else {
        firstParent?.classList.add(this.formErrorToken)
      }


    }
    if (firstParent?.classList.contains('date-picker__wrapper') && !element.getAttribute('data-error')) {
      // TODO contentful
      this.formErrors.set(element.id, 'ugyldig dato')
    } else {
      this.formErrors.set(element.id, element.getAttribute('data-error') as string)
    }
  }
}
