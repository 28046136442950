<template>
  <div class="container px-0 py-9" v-if="pageAccess">

    <template v-if="hasAccess">
      <h1 class="h2"><SimpleText text-key="configuration.title" /></h1>
      <div class="py-3">

        <form id="configuration" @submit.prevent="submitForm">
          <div class="py-4 my-0 form-group">
            <fieldset role="radiogroup">
              <legend aria-describedby="configuration"></legend>
            <label class="form-label " for="cardboard-input">
              <SimpleText text-key="configuration.cardboard.button.name" />
            </label>
              <div class="form-group-radio">
                <input type="radio" id="radiogroup-option1" name="cardboard-input"
                       class="form-radio radio-large" v-model="cardboard" value="false">
                <label class="form-label" for="radiogroup-option1"><SimpleText text-key="configuration.cardboard.button.off" /></label>
              </div>

              <div class="form-group-radio">
                <input type="radio" id="radiogroup-option2" name="cardboard-input"
                       class="form-radio radio-large" v-model="cardboard" value="true">
                <label class="form-label" for="radiogroup-option2"><SimpleText text-key="configuration.cardboard.button.on" /></label>
              </div>
            </fieldset>
          </div>
          <button id="submit-button" class="button button-primary px-8 my-4 saveButton" type="submit" :disabled="submitButtonDisabled">
            <SimpleText text-key="configuration.button.submit.name" />
          </button>

        </form>
      </div>
      </template>
  </div>
  <div class="container px-0 py-9" v-else>Din brugerprofil har ikke adgang til dette skærmbillede</div>
</template>

<script lang="ts">
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import {configurationClient, noneCachedConfigurationClient} from '@/shared/BackendService'
import {ConfigValue} from "../../../openapi/generated-clients/climatecompass";
import {Options} from "vue-class-component";
import SimpleText from "@/components/SimpleText.vue";

@Options({
  components: {
    SimpleText
  }
})
export default class Config extends BaseComponent {
  hasAccess = true
  cardboard = false
  submitButtonDisabled = false
  pageAccess = false

  async beforeMount() {
    await noneCachedConfigurationClient
      .getConfigurationAccess()
      .then((response) => {
        this.hasAccess = (response.data as unknown) as boolean
      })
      .catch((error) => {
        console.error(error)
      })
    await noneCachedConfigurationClient
      .getKey('CARDBOARD_ENABLE', true, true)
      .then((response) => {
        this.cardboard = (response.data as unknown) as boolean
      })
      .catch((error) => {
        console.error(error)
      })
    await configurationClient
        .getCardboardAccess()
        .then((response) => {
          this.pageAccess = response.data as unknown as boolean
        })
        .catch((error) => {
          console.error(error)
        })
  }

  async submitForm(e?: Event) {
    //console.log('submitform', e)
    await noneCachedConfigurationClient
      .updateKey('CARDBOARD_ENABLE', {configuration: String(this.cardboard)} as ConfigValue)
      .then((response) => {
        this.addToastFromMessageKeys('cfgUpdateOk', 'info', 'configuration.update-toast.ok.title', 'configuration.update-toast.ok.text')
      })
      .catch((error) => {
        console.error("Update configuration error " + error)
        this.addToastFromMessageKeys('cfgUpdateError', 'error', 'configuration.update-toast.error.title', 'configuration.update-toast.error.text')
      })
  }
}
</script>

<style scoped></style>
