<template>
  <div>
    <ApexChart
        type="bar"
        :options="chartOptions"
        :series="chartOptions.series"
        width="100%"
        height="400"
        ref="vueApexChartRef"
    ></ApexChart>
  </div>
</template>

<script lang="ts">
import VueApexCharts, {VueApexChartsComponent} from 'vue3-apexcharts';
import { Constants } from '@/shared/Constants';
import {Options} from "vue-class-component";
import CypressTestElement from "@/components/testhelpers/CypressTestElement.vue";
import SimpleText from "@/components/SimpleText.vue";
import {
  ComparisonObject
} from "../../../openapi/generated-clients/climatecompass";
import ComparisonBase from "@/components/comparison/ComparisonBase";
import {graphPrettify, prettify} from "@/shared/PrettifyNumbers";

@Options({
  components: {
    CypressTestElement,
    ApexChart: VueApexCharts,
    SimpleText
  }
})
export default class ComparisonChartScope extends ComparisonBase {
  selectedComparisonCriteria: string | undefined
  selectedComparisonScopeCriteria: string[] | undefined
  comparison = {} as ComparisonObject

  chartOptions: any

  $refs!: {
    vueApexChartRef: VueApexChartsComponent
  }

  beforeMount() {
    console.log("ComparisonChartScopes beforeMount", this.selectedComparisonCriteria)
    this.initChart()
    if (this.selectedComparisonCriteria === 'SCOPES') {
      this.getScopesChart()
    }
    //console.log("ComparisonChart beforeMount end", this.selectedComparisonCriteria)
  }

  mounted() {
      this.$nextTick(() => {
        console.log("Scope chart emit mounted")
        this.$emit('mounted');
      })
  }

  updateChart(newSelectedComparisonCriteria: string, newSelectedComparisonScopeCriteria: string[], newComparison: ComparisonObject) {
    console.log("Scope Chart updateChart: update graph", newSelectedComparisonCriteria, newSelectedComparisonScopeCriteria, newComparison)
    if (newSelectedComparisonCriteria && newSelectedComparisonScopeCriteria && newComparison) {

      this.selectedComparisonCriteria = newSelectedComparisonCriteria
      this.selectedComparisonScopeCriteria = this.getOrderedScopes(newSelectedComparisonScopeCriteria)
      this.comparison = newComparison
      if (this.selectedComparisonCriteria === 'SCOPES') {
        this.getScopesChart()
      }
    }
  }

  getOrderedScopes(newSelectedComparisonScopeCriteria: string[]): string[] {
    const ordereredScopes = [] as string[]
    if (newSelectedComparisonScopeCriteria.includes("SCOPE1")) { ordereredScopes.push("SCOPE1") }
    if (newSelectedComparisonScopeCriteria.includes("SCOPE2")) { ordereredScopes.push("SCOPE2") }
    if (newSelectedComparisonScopeCriteria.includes("SCOPE3")) { ordereredScopes.push("SCOPE3") }
    if (newSelectedComparisonScopeCriteria.includes("UDENFOR SCOPES")) { ordereredScopes.push("UDENFOR SCOPES") }
    return ordereredScopes
  }

  getScopesChart() {
    console.log("getScopesChart")
    if (this.comparison && this.comparison.calculations) {
      //console.log("getScopesChart calculations", this.comparison)
      const calculationList = this.comparison.calculations.map(c => (c.calculation?.calculationPurpose.name ? c.calculation?.calculationPurpose.name : ''))
      const newSeries = [] as any[]
      if (this.selectedComparisonScopeCriteria) {
        //console.log("getscopes newSeries init", newSeries)
        for (const scope of this.selectedComparisonScopeCriteria) {
          if (this.comparison.calculations) {
            let name = "NA"
            let data = [] as number[]
            for (const calculation of this.comparison.calculations) {
              if (scope === 'SCOPE1') {
                name = this.getContentfulString('comparison.resultview.scope1.text')
                data.push(Math.round((calculation.totals?.find(t => t.key === 'ALL')?.scope1 ?? 0) * 100) / 100)
              }
              if (scope === 'SCOPE2') {
                name = this.getContentfulString('comparison.resultview.scope2.text')
                data.push(Math.round((calculation.totals?.find(t => t.key === 'ALL')?.scope2 ?? 0) * 100) / 100)
              }
              if (scope === 'SCOPE3') {
                name = this.getContentfulString('comparison.resultview.scope3.text')
                data.push(Math.round((calculation.totals?.find(t => t.key === 'ALL')?.scope3 ?? 0) * 100) / 100)
              }
              if (scope === 'UDENFOR SCOPES') {
                name = this.getContentfulString('comparison.resultview.outside-scope.text')
                data.push(Math.round((calculation.totals?.find(t => t.key === 'ALL')?.outsideScope ?? 0) * 100) / 100)
              }
            }
            let item = {name: name, data: data};
            newSeries.push(item)
            //console.log("getscopes add item", scope, item, newSeries)
          }
        }
      }
      if (newSeries.length === 0) {
        newSeries.push({name: "NA", data: []})
      }
      const optionsUpdate = {
        xaxis: {
          categories: calculationList,
        },
        series: newSeries,
      }
      let optionsForChart = Object.assign(optionsUpdate, this.scopeDefaultChartOptions);
      console.log("getScopesChart newSeries", newSeries, optionsForChart);
      this.$refs.vueApexChartRef.updateOptions(optionsForChart, true, true, true)
    }
  }

  initChart() {
    this.chartOptions = {
      chart: {
        type: 'bar',
        stacked: false,
        height: 350,
      },
      tooltip: {
        theme: 'light',
        marker: {
          show: true,
        },
        intersect: true,
        y: {
          formatter: (value: number) => prettify(value, 2), // Show decimals on hover
          title: {
            formatter: (seriesName: string) => {
              return seriesName + ': Eksakt værdi i ton CO2e: ';
            }
          },
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '40%',
          endingShape: 'rounded',
        },
      },
      xaxis: {
        categories: ['x'],
      },
      yaxis: {
        title: {
          text: this.getContentfulString('shared.ton-co2e.no-format'),
        },
        labels: {
          formatter: (value: number) => graphPrettify(value)
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value: number) => graphPrettify(value)
      },
      legend: {
        position: 'bottom',
      },
      fill: {
        opacity: 1,
      },
      colors: [
        Constants.SCOPE1_COLOR_HEX,
        Constants.SCOPE2_COLOR_HEX,
        Constants.SCOPE3_COLOR_HEX,
        Constants.OUTSIDE_SCOPES_COLOR_HEX,
      ],
      series: [
        {
          name: this.getContentfulString('comparison.resultview.scope1.text'),
          data: [0],
        }
      ]
    }
  }


  scopeDefaultChartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      height: 350,
    },
    stroke: {
      width: 2,
      colors: ['#fff']
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '40%',
        endingShape: 'rounded',
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '11px',
              fontWeight: 600,
              decimalsInFloat: 0
            },
          },
        },
      }
    },
    dataLabels: {
      enabled: false
    },
    yaxis: {
      title: {
        text: this.getContentfulString('shared.ton-co2e.no-format'),
      },
      decimalsInFloat: 2
    },
    legend: {
      position: 'bottom',
    },
    fill: {
      opacity: 1,
    },
    colors: [
      Constants.SCOPE1_COLOR_HEX,
      Constants.SCOPE2_COLOR_HEX,
      Constants.SCOPE3_COLOR_HEX,
      Constants.OUTSIDE_SCOPES_COLOR_HEX,
    ]
  }
}

</script>

<style scoped>
</style>