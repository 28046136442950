<template>
  <div class="container px-0 py-9" id="wrapper-div" v-if="pageAccess">
    <h1>Validering af Emissionsfaktorer fra excelfil</h1>

    <div class="col-md-3" style="padding-top: 20px; padding-bottom: 20px">
      <!--label for="upload-file">Upload Excel:</label-->
      <!--input type="file" id="upload-file" name="file" @change="addFile"-->
      <button style="display:block;width:300px; height:30px;" onclick="document.getElementById('upload-file2').click()">Vælg en excelfil for validering</button>
      <input type='file' id="upload-file2" style="display:none" @change="addFile">
    </div>
    {{ this.excelFileName.fileName }}
    <div class="d-flex justify-content-center mt-4">
      <button class="button button-secondary adjust-button-size" @click="validateFile">Valider</button>
    </div>


    <div v-if="validationErrors.length > 0">
      <h2 v-if="this.excelFileName.fileName">Validerings Log ({{ validationErrors.length }}) </h2>
      <h2 v-else>Validerings Log</h2>
      <div class="table--responsive-scroll" style="height: 550px; overflow: auto">
        <table class="table w-percent-100">
          <caption></caption>
          <thead>
          <tr>
            <th id="excel-file-name" colspan="5" v-if="this.excelFileName.fileName" style="text-align: center;">
              {{ this.excelFileName.fileName }}
            </th>
          </tr>
          </thead>
          <thead>
          <tr>
            <th style="width: 15%" id="validate_error_type">Type</th>
            <th style="width: 40%" id="validate_error_description">Beskrivelse</th>
            <th style="width: 45%" id="validate_error_value">Værdi</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in validationErrors" :key="item">
            <td style="width: 15%">{{ item.name }}</td>
            <td style="width: 40%">{{ item.value }}</td>
            <td style="width: 45%">{{ item.description }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
        {{ this.status }}
    </div>
    {{ this.deleteError }}
  </div>
  <div class="container px-0 py-9" v-else>Din brugerprofil har ikke adgang til dette skærmbillede</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import {configurationClient, emissionsfactorImportClient} from "@/shared/BackendService";
import DKFDS from "dkfds";
import {
  EmissionCoefficientOverview,
  FileMessage,
  ValidationError
} from "../../../openapi/generated-clients/climatecompass";
import * as XLSX from 'xlsx'


export default class EmissionFactorValidate extends BaseComponent {

  //variables
  importedExcel = {} as File
  excelFile = {} as File
  excelFileName = {} as Partial<FileMessage>

  existingEmfImport: EmissionCoefficientOverview[] = []
  validationErrors: ValidationError[] = []
  deleteError = ''
  status = ''
  pageAccess = false

  async beforeMount() {
    DKFDS.init()
    await configurationClient
        .getExcelValidationAccess()
        .then((response) => {
          this.pageAccess = response.data as unknown as boolean
        })
        .catch((error) => {
          console.error(error)
        })
  }

  async addFile(e: Event) {
    const element = e.target as HTMLInputElement
    if (!this.excelFile) {
      //this.excelFile = {}
    }
    if (!this.excelFileName) {
      this.excelFileName = {}
    }
    if (!element.files) return
    this.excelFile = element.files[0]
    this.excelFileName.fileName = element.files[0].name
    this.excelFileName.fileType = element.files[0].type
    element.form?.reset()
    //await this.validateFile()
  }

  async validateFile() {
    console.log('Excel file : ' + this.excelFileName.fileName)
    this.status = 'Validere...'
    if (this.excelFileName.fileName === undefined) {
      window.alert("Der skal vælges en Excel fil")
    } else {
      this.validationErrors = (await emissionsfactorImportClient.validateExcel(this.excelFile)).data || []
    }
    if (this.validationErrors.length === 0) {
      this.status = "OK"
    } else {
      this.status = ''
    }
  }

  /// ------ Async-Functions end ------///

  dummyUploadFile() {
    const uploadInput = document.getElementById(`map-fileinput`) as HTMLInputElement
    if (uploadInput) {
      uploadInput.click()
    }
  }
}

</script>

<style lang="scss" scoped>

.sql-log {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
}

#section1 {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

#section1 .form-group {
  flex: 1;
  margin-right: 10px;
}

#section2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

#button-section {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}


</style>
