import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "chartContainer",
    style: _normalizeStyle({ width: _ctx.adjustedWidth + 'px' })
  }, [
    (_ctx.chartOptions.series && _ctx.chartOptions.series.length > 0)
      ? (_openBlock(), _createBlock(_component_Chart, {
          key: 0,
          ref: "netZeroComparePieChart",
          type: "pie",
          options: _ctx.chartOptions,
          series: _ctx.chartOptions.series,
          width: "100%"
        }, null, 8, ["options", "series"]))
      : _createCommentVNode("", true)
  ], 4))
}