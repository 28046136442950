<template>
  <CalculatorTabComponent>
    <template v-slot:calculator-primary-1="slotProps">
      <ProcessCalculator
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
        :expanded="true"
      >
      </ProcessCalculator>
    </template>
    <template v-slot:calculator-secondary-1="slotProps">
      <OtherCalculator
        :subgroupingId="constants.GROUP_PROCESUDLEDNING_MED_EGNE_EMISSIONSFAKTORER"

        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
      >
      </OtherCalculator>
    </template>
  </CalculatorTabComponent>
</template>

<script lang="ts">
import { Options, Prop } from "vue-property-decorator"
import BaseComponent from "../base-components/BaseComponent"
import CalculatorTabComponent from "../CalculatorTabComponent.vue"
import ElectricityCalculator from "./calculators/ElectricityCalculator.vue"
import OtherCalculator from "./calculators/OtherCalculator.vue"
import ProcessCalculator from "@/components/calculator/energy-and-processes/calculators/ProcessCalculator.vue"

@Options({
  components: { ProcessCalculator, OtherCalculator, ElectricityCalculator, CalculatorTabComponent }
})
export default class ProcessView extends BaseComponent {

}
</script>

<style scoped>

</style>