import ContentfulContent from "@/shared/ContentfulContent";

export class Menu {
    nameKey: string
    name: string
    menuItems: MenuItem[]

    constructor(nameKey: string, menuItems: MenuItem[] = []) {
        this.nameKey = nameKey
        this.name = nameKey
        this.menuItems = menuItems
    }

    initText(contentfulContent: ContentfulContent) {
        this.name = contentfulContent.findSimpleText(this.nameKey)
        this.menuItems.forEach(mi => mi.initText(contentfulContent))
    }
}

export class MenuItem {
    id: string
    labelKey: string
    label: string
    link: string
    exact: boolean
    showToPrivateUser: boolean


    constructor(id: string, labelKey: string, link: string, exact = false, showToPrivateUser = true) {
        this.id = id;
        this.labelKey = labelKey;
        this.label = labelKey;
        //this.label = store.getters.getContentfulContent.findSimpleText(labelKey)
        this.link = link;
        this.exact = exact;
        this.showToPrivateUser = showToPrivateUser;
    }

    initText(contentfulContent: ContentfulContent) {
        this.label = contentfulContent.findSimpleText(this.labelKey)
    }
}

export class MenuStructure {

    private static instance: MenuStructure

    mainMenu: Menu

    calculatorMenu: Menu

    masterDataTabs: Menu
    energyAndProcessesTabs: Menu
    procurementTabs: Menu
    transportTabs: Menu
    trashAndRecyclingTabs: Menu
    soldProductsTabs: Menu

    resultTabs: Menu

    forecastMenu: Menu
    forecastTabs: Menu

    comparisonMenu: Menu
    //comparisonTabs: Menu

    guideTabs: Menu

    accessTabs: Menu
    setupMenu: Menu

    configTabs: Menu


    menuStructureMap: Map<string, Menu> = new Map()
    menuItemByLinkMap: Map<string, MenuItem> = new Map()

    constructor(contentfulContent?: ContentfulContent) {
        this.mainMenu = new Menu('menu.mainmenu.name')
        this.mainMenu.menuItems.push(new MenuItem('calculation-overview', 'menu.mainmenu.item.calculation-overview', '/overview'))
        this.mainMenu.menuItems.push(new MenuItem('new-calculation', 'menu.mainmenu.item.new-calculation', '/calculator'))
        this.mainMenu.menuItems.push(new MenuItem('forecast', 'menu.mainmenu.item.forecast', '/forecast'))
        this.mainMenu.menuItems.push(new MenuItem('comparison', 'menu.mainmenu.item.comparison', '/comparison'))
        this.mainMenu.menuItems.push(new MenuItem('guides', 'menu.mainmenu.item.guides', '/guides'))
        this.mainMenu.menuItems.push(new MenuItem('setup', 'menu.mainmenu.item.setup', '/setup'))
        this.mainMenu.menuItems.push(new MenuItem('admin', 'menu.mainmenu.item.admin', '/admin/config'))
        this.menuStructureMap.set('mainMenu', this.mainMenu)

        this.calculatorMenu = new Menu('menu.calculatormenu.name')
        this.calculatorMenu.menuItems.push(new MenuItem('master-data', 'menu.calculatormenu.item.master-data', '/calculator/master-data'))
        this.calculatorMenu.menuItems.push(new MenuItem('energy-and-processes', 'menu.calculatormenu.item.energy-and-processes', '/calculator/energy-and-processes'))
        this.calculatorMenu.menuItems.push(new MenuItem('procurement', 'menu.calculatormenu.item.procurement', '/calculator/procurement'))
        this.calculatorMenu.menuItems.push(new MenuItem('transport', 'menu.calculatormenu.item.transport', '/calculator/transport'))
        this.calculatorMenu.menuItems.push(new MenuItem('trash-and-recycling', 'menu.calculatormenu.item.trash-and-recycling', '/calculator/trash-and-recycling'))
        this.calculatorMenu.menuItems.push(new MenuItem('sold-products', 'menu.calculatormenu.item.sold-products', '/calculator/sold-products'))
        this.calculatorMenu.menuItems.push(new MenuItem('results', 'menu.calculatormenu.item.results', '/calculator/results'))
        this.menuStructureMap.set('calculatorMenu', this.calculatorMenu)

        this.masterDataTabs = new Menu('menu.masterdatatabs.name')
        this.masterDataTabs.menuItems.push(new MenuItem('purpose', 'menu.masterdatatabs.item.purpose', '/calculator/master-data/purpose'))
        this.masterDataTabs.menuItems.push(new MenuItem('company-information', 'menu.masterdatatabs.item.company-information', '/calculator/master-data/company-information'))
        this.menuStructureMap.set('masterDataTabs', this.masterDataTabs)

        this.energyAndProcessesTabs = new Menu('menu.energyandprocessestabs.name')
        this.energyAndProcessesTabs.menuItems.push(new MenuItem('electricity', 'menu.energyandprocessestabs.item.electricity', '/calculator/energy-and-processes/electricity'))
        this.energyAndProcessesTabs.menuItems.push(new MenuItem('heat-and-process-energy', 'menu.energyandprocessestabs.item.heat-and-process-energy', '/calculator/energy-and-processes/heat'))
        this.energyAndProcessesTabs.menuItems.push(new MenuItem('process', 'menu.energyandprocessestabs.item.process', '/calculator/energy-and-processes/process'))
        this.energyAndProcessesTabs.menuItems.push(new MenuItem('other-energy-and-processes', 'menu.energyandprocessestabs.item.other-energy-and-processes', '/calculator/energy-and-processes/other'))
        this.menuStructureMap.set('energyAndProcessesTabs', this.energyAndProcessesTabs)

        this.procurementTabs = new Menu('menu.procurementtabs.name')
        this.procurementTabs.menuItems.push(new MenuItem('materials-primary', 'menu.procurementtabs.item.materials-primary', '/calculator/procurement/materials-primary'))
        this.procurementTabs.menuItems.push(new MenuItem('products-and-services-primary', 'menu.procurementtabs.item.products-and-services-primary', '/calculator/procurement/products-and-services-primary'))
        this.procurementTabs.menuItems.push(new MenuItem('other-primary', 'menu.procurementtabs.item.other-primary', '/calculator/procurement/other-primary'))
        this.procurementTabs.menuItems.push(new MenuItem('materials-secondary', 'menu.procurementtabs.item.materials-secondary', '/calculator/procurement/materials-secondary'))
        this.procurementTabs.menuItems.push(new MenuItem('products-and-services-secondary', 'menu.procurementtabs.item.products-and-services-secondary', '/calculator/procurement/products-and-services-secondary'))
        this.procurementTabs.menuItems.push(new MenuItem('other-secondary', 'menu.procurementtabs.item.other-secondary', '/calculator/procurement/other-secondary'))
        this.menuStructureMap.set('procurementTabs', this.procurementTabs)

        this.transportTabs = new Menu('menu.transporttabs.name')
        this.transportTabs.menuItems.push(new MenuItem('own-and-leased', 'menu.transporttabs.item.own-and-leased', '/calculator/transport/own-and-leased'))
        this.transportTabs.menuItems.push(new MenuItem('employee-transportation', 'menu.transporttabs.item.employee-transportation', '/calculator/transport/employee-transportation'))
        this.transportTabs.menuItems.push(new MenuItem('product-transportation', 'menu.transporttabs.item.product-transportation', '/calculator/transport/product-transportation'))
        this.transportTabs.menuItems.push(new MenuItem('customer-transportation', 'menu.transporttabs.item.customer-transportation', '/calculator/transport/customer-transportation'))
        this.transportTabs.menuItems.push(new MenuItem('other-transport', 'menu.transporttabs.item.other-transport', '/calculator/transport/other-transport'))
        this.menuStructureMap.set('transportTabs', this.transportTabs)

        this.trashAndRecyclingTabs = new Menu('menu.trashandrecyclingtabs.name')
        this.trashAndRecyclingTabs.menuItems.push(new MenuItem('trash', 'menu.trashandrecyclingtabs.item.trash', '/calculator/trash-and-recycling/trash'))
        this.trashAndRecyclingTabs.menuItems.push(new MenuItem('other-trash-and-recycling', 'menu.trashandrecyclingtabs.item.other-trash-and-recycling', '/calculator/trash-and-recycling/other'))
        this.menuStructureMap.set('trashAndRecyclingTabs', this.trashAndRecyclingTabs)

        this.soldProductsTabs = new Menu('menu.soldproductstabs.name')
        this.soldProductsTabs.menuItems.push(new MenuItem('processing', 'menu.soldproductstabs.item.processing', '/calculator/sold-products/processing'))
        this.soldProductsTabs.menuItems.push(new MenuItem('use-of-products', 'menu.soldproductstabs.item.use-of-products', '/calculator/sold-products/use-of-products'))
        this.soldProductsTabs.menuItems.push(new MenuItem('end-of-life-treatment', 'menu.soldproductstabs.item.end-of-life-treatment', '/calculator/sold-products/end-of-life-treatment'))
        this.soldProductsTabs.menuItems.push(new MenuItem('other-sold-products', 'menu.soldproductstabs.item.other-sold-products', '/calculator/sold-products/other-sold-products'))
        this.menuStructureMap.set('soldProductsTabs', this.soldProductsTabs)

        this.resultTabs = new Menu('menu.resulttabs.name')
        this.resultTabs.menuItems.push(new MenuItem('main-results', 'menu.resulttabs.item.main-results', '/calculator/results/main-results'))
        this.resultTabs.menuItems.push(new MenuItem('partial-results', 'menu.resulttabs.item.partial-results', '/calculator/results/partial-results'))
        this.resultTabs.menuItems.push(new MenuItem('main-results-ghg', 'menu.resulttabs.item.main-results-ghg', '/calculator/results/main-results-ghg'))
        this.resultTabs.menuItems.push(new MenuItem('partial-results-ghg', 'menu.resulttabs.item.partial-results-ghg', '/calculator/results/partial-results-ghg'))
        this.resultTabs.menuItems.push(new MenuItem('scope3-results', 'menu.resulttabs.item.scope3-results', '/calculator/results/scope3-results'))
        this.resultTabs.menuItems.push(new MenuItem('results-esg', 'menu.resulttabs.item.results-esg', '/calculator/results/results-esg'))
        this.resultTabs.menuItems.push(new MenuItem('HighestEmissionResults.vue', 'menu.resulttabs.item.highest-emission-results', '/calculator/results/highest-emission-results'))
        this.menuStructureMap.set('resultTabs', this.resultTabs)

        this.forecastMenu = new Menu('menu.forecastmenu.name')
        this.forecastMenu.menuItems.push(new MenuItem('forecast-overview', 'menu.forecastMenu.item.forecast-overview', '/forecast/forecast-overview'))
        this.forecastMenu.menuItems.push(new MenuItem('calculate-forecast', 'menu.forecastMenu.item.calculate-forecast', '/forecast/calculate-forecast'))
        this.forecastMenu.menuItems.push(new MenuItem('reduction-objectives', 'menu.forecastMenu.item.reduction-objectives', '/forecast/reduction-objectives'))
        this.forecastMenu.menuItems.push(new MenuItem('forecast-guide', 'menu.forecastMenu.item.forecast-guide', '/forecast/forecast-guide'))
        //this.forecastMenu.menuItems.push(new MenuItem('reduction-actions', 'menu.forecastMenu.item.reduction-actions', '/forecast/reduction-actions'))
        this.menuStructureMap.set('forecastMenu', this.forecastMenu)

        this.forecastTabs = new Menu('menu.forecasttabs.name')
        this.forecastTabs.menuItems.push(new MenuItem('scope123', 'menu.forecasttabs.item.scope123', '/forecast/scope123'))
        this.forecastTabs.menuItems.push(new MenuItem('table-view', 'menu.forecasttabs.item.table-view', '/forecast/table-view'))
        this.menuStructureMap.set('forecastTabs', this.forecastTabs)

        this.comparisonMenu = new Menu('menu.comparisonmenu.name')
        this.comparisonMenu.menuItems.push(new MenuItem('comparison-overview', 'menu.comparisonMenu.item.comparison-overview', '/comparison/comparison-overview'))
        this.comparisonMenu.menuItems.push(new MenuItem('create-comparison', 'menu.comparisonMenu.item.create-comparison', '/comparison/create-comparison'))
        this.menuStructureMap.set('comparisonMenu', this.comparisonMenu)

        this.guideTabs = new Menu('menu.guidetabs.name')
        this.guideTabs.menuItems.push(new MenuItem('getstarted', 'menu.guidetabs.item.getstarted', '/guides/getstarted'))
        this.guideTabs.menuItems.push(new MenuItem('news', 'menu.guidetabs.item.news', '/guides/news'))
        this.guideTabs.menuItems.push(new MenuItem('faq', 'menu.guidetabs.item.faq', '/guides/faq'))
        this.guideTabs.menuItems.push(new MenuItem('terms', 'menu.guidetabs.item.terms', '/guides/terms'))
        this.guideTabs.menuItems.push(new MenuItem('background', 'menu.guidetabs.item.background', '/guides/background'))
        this.guideTabs.menuItems.push(new MenuItem('terms-for-use', 'menu.terms-for-use.item.background', '/guides/terms-for-use'))
        this.menuStructureMap.set('guideTabs', this.guideTabs)

        this.accessTabs = new Menu('menu.accesstabs.name')
        this.accessTabs.menuItems.push(new MenuItem('access-to', 'menu.accesstabs.item.access-to', '/setup/access/access-to'))
        this.accessTabs.menuItems.push(new MenuItem('create-access', 'menu.accesstabs.item.create-access', '/setup/access/create-access'))
        this.menuStructureMap.set('accessTabs', this.accessTabs)

        this.setupMenu = new Menu('menu.setupmenu.name')
        this.setupMenu.menuItems.push(new MenuItem('access', 'menu.setupmenu.item.access', '/setup/access'))
        this.setupMenu.menuItems.push(new MenuItem('showaccess', 'menu.setupmenu.item.showaccess', '/setup/showaccess'))
        this.setupMenu.menuItems.push(new MenuItem('profile', 'menu.setupmenu.item.profile', '/contact-information'))
        this.menuStructureMap.set('setupMenu', this.setupMenu)

        this.configTabs = new Menu('menu.config.name')
        this.configTabs.menuItems.push(new MenuItem('cardboard', 'menu.config.item.cardboard', '/admin/config'))
        this.configTabs.menuItems.push(new MenuItem('transfer', 'menu.config.item.transfer', '/admin/transfer'))
        this.configTabs.menuItems.push(new MenuItem('statistics', 'Statistik', '/admin/statistics'))
        this.configTabs.menuItems.push(new MenuItem('excelmappings', 'Excel Mapping', '/admin/excelmappings'))
        this.configTabs.menuItems.push(new MenuItem('emf-import', 'Emissionsfaktor import', '/admin/emf-import'))
        this.configTabs.menuItems.push(new MenuItem('emf-validate', 'Emissionsfaktor validering', '/admin/emf-validate'))
        this.configTabs.menuItems.push(new MenuItem('misc', 'Misc', '/admin/misc'))

        this.menuStructureMap.set('configMenu', this.configTabs)

        if (contentfulContent) {
            this.initText(contentfulContent)
        }
        this.menuStructureMap.forEach( menu => menu.menuItems.forEach(menuItem => this.menuItemByLinkMap.set(menuItem.link, menuItem)))
    }

    initText(contentfulContent: ContentfulContent) {
        this.menuStructureMap.forEach(menu => menu.initText(contentfulContent))
    }
}


