import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)
export default class DateInput {
  constructor(public day: string, public month: string, public year: string) {}

  get toIsoStringWithTime() {
    return `${this.year}-${this.month}-${this.day}T00:00:00.000Z`
  }

  get getDate() {
    return dayjs.utc(this.toIsoStringWithTime)
  }

  get isRealDate() {
    try {
      return this.getDate.toISOString() === this.toIsoStringWithTime
    } catch (error) {
      return false
    }
  }

  get toIsoString() {
    return `${this.year}-${this.month}-${this.day}`
  }
}
