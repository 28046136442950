<template>
  <SharedHeader :tabMenu="menu">
    <template #firstColumn>
      <!--div v-html="documentToHtmlString(this.masterDataText)"></div-->
      <RichText text-key="masterdata.masterDataText"/>
    </template>

    <template #secondColumn>
      <div aria-atomic="true" aria-label="Beskedbox der viser information" class="alert alert-info alert--show-icon"
           role="alert">
        <div class="alert-body">
          <p class="alert-heading">{{ this.title }}</p>
          <!--p class="alert-text" v-html="documentToHtmlString(this.masterDataImportantInfo)" /-->
          <p class="alert-text"><RichText text-key="masterdata.masterDataImportantInfo"/></p>
        </div>
      </div>
    </template>
  </SharedHeader>

  <div class="container py-6 px-0">
    <router-view />
  </div>
</template>

<script lang="ts">
import { Options } from "vue-class-component"
import SharedHeader from "@/components/calculator/shared/SharedHeader.vue"
import { Menu } from "@/shared/MenuStructure"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import RichText from "@/components/RichText.vue";

@Options({
  components: {
    RichText,
    SharedHeader
  }
})
export default class MasterData extends BaseComponent {
  menu = {} as Menu

  async beforeMount() {
    this.menu = this.store.getters.getMenuStructure.masterDataTabs
  }

  get title() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdata.title')
  }

}
</script>
