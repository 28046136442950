<template>
    <Navigation />
    <router-view />
</template>

<script lang="ts">
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { Vue, Options } from "vue-class-component"
import Navigation from "@/components/thirdparty/Navigation.vue"
import {useRouter} from "vue-router";


@Options({
  components: {
    Navigation
  }
})
export default class Setup extends Vue {
  router = useRouter()


  get documentToHtmlString() {
    return documentToHtmlString
  }

  openLink(url: string) {
    window.open(url)
  }
}
</script>
