<template>
  <div class="sections">

  <div class="section">
    <div class="container px-0 py-6">
      <div class="row">
        <div class="col-12">
          <div id='error-cardboard' style="max-width: 1200px; max-height: 676px;">
            <div class="section3-content"  style="max-width: 650px;">
              <template v-if="cardboardType === Constants.CARDBOARD_UPGRADE" >
                  <RichText text-key="cardboard.upgrade.text" render-with-linebreaks="true"/>
              </template>
              <template v-else-if="cardboardType === Constants.CARDBOARD_NETWORK_ERROR" >
                  <RichText text-key="cardboard.network_error.text" render-with-linebreaks="true"/>
              </template>
              <template v-else>
                  <RichText text-key="cardboard.backend_down.text" render-with-linebreaks="true"/>
              </template>
              <RichText text-key="cardboard.contact.text" render-with-linebreaks="true"/>
            </div>
            <svg fill="none" viewBox="0 0 1200 676" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M125.39 393.978C133.929 324.686 210.305 374.994 196.073 292.888C170.597 120.552 344.081 356.01 351.197 297.634C351.197 297.634 223.734 7.47804 374.301 27.6402C504.897 60.8074 395.314 177.559 539.861 53.2687C753.474 -141.848 641.346 290.948 810.733 249.28C942.828 216.852 599.885 37.0505 873.492 1.95686C1077 -24.146 847.876 221.698 1081.75 97.8267C1277.19 1.48246 1183.26 286.17 1131.08 346.043C1078.9 405.916 1150.64 354.586 1183.26 483.203C1209.5 586.613 1036.07 666.455 1036.07 666.455C611.899 666.455 334.927 631.333 334.927 631.333C54.7074 544.427 125.39 393.978 125.39 393.978Z"
                  fill="#EDF6F8"
              />
              <rect fill="white" height="603.186" stroke="black" stroke-width="10" width="762.296" x="290.732"
                    y="61.0615" />
              <path
                  d="M114.721 507.412C119.109 523.057 142.285 608.828 163.324 674.001H195.277C177.501 544.444 160.849 436.273 154.774 371.439L133.51 370.876C133.51 370.989 82.8811 394.401 114.721 507.412Z"
                  fill="#078856"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-width="3"
              />
              <path
                  d="M122.258 267.883C122.258 267.883 143.522 269.346 159.499 265.294C160.511 265.069 203.039 311.218 239.717 333.843C239.717 333.843 225.203 352.078 219.916 356.693C219.916 356.693 172.1 340.934 153.086 327.54C145.323 322.024 127.996 285.667 122.258 267.883Z"
                  fill="black"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-width="3"
              />
              <path
                  d="M77.2558 372.451L115.846 264.168H133.848C132.947 263.38 166.7 371.663 166.7 371.663L154.774 371.551L77.2558 372.451Z"
                  fill="#078856"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-width="3"
              />
              <path
                  d="M154.774 371.438L114.271 507.524C113.821 508.649 74.4427 602.299 33.2647 674L2.99997 672.312C2.99997 672.312 29.1019 598.698 53.9662 532.062C60.2667 515.065 58.9166 371.438 58.9166 371.438H154.774Z"
                  fill="black"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-width="3"
              />
              <path
                  d="M86.3691 203.611C87.3817 198.433 91.5445 176.822 93.3446 167.029C94.5822 159.825 84.0064 164.553 72.4181 159.15C60.1547 153.522 53.1791 139.677 52.1666 137.538C44.8535 122.455 48.0037 107.147 49.2413 103.545C54.5292 87.7867 66.2301 79.5698 71.293 76.3055C74.5557 74.2794 98.8575 61.335 122.822 70.2273C127.21 71.9157 145.999 81.821 150.836 94.7654C153.537 101.857 152.074 121.104 144.648 120.317C144.648 120.317 147.461 130.785 147.574 142.041C147.686 148.569 147.686 154.535 144.086 160.388C142.961 162.302 141.498 164.665 138.91 166.129C131.147 170.518 116.296 161.063 116.296 161.063C115.171 162.864 120.009 203.611 120.009 203.611H86.3691Z"
                  fill="black"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-width="3"
              />
              <path
                  d="M120.233 202.148C153.423 202.26 183.575 206.65 250.068 189.091C250.068 189.091 255.918 210.477 256.706 218.694C256.706 218.694 196.401 256.514 160.849 264.956C146.223 268.446 137.447 268.671 122.371 267.883C122.371 267.883 126.421 288.932 130.696 323.037C135.984 365.248 125.859 387.084 122.371 392.487C115.62 402.955 109.77 404.981 106.395 405.432C101.557 406.107 47.1028 405.994 37.9896 405.994C29.7765 405.994 31.6892 287.018 80.5178 201.81C80.7428 201.36 86.5932 202.26 86.5932 202.26L120.233 202.148Z"
                  fill="#078856"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-width="3"
              />
              <path
                  d="M250.631 190.779C252.093 190.442 255.919 190.104 258.506 188.528C270.207 181.437 279.658 172.319 287.983 170.068C289.334 169.73 291.134 169.843 291.921 170.631C293.159 171.869 291.584 174.683 290.459 175.696C284.271 181.099 276.958 189.654 276.733 190.442C276.395 191.792 278.42 192.355 279.883 193.368C282.021 195.057 285.508 195.169 287.421 195.844C288.434 196.182 289.784 196.857 289.334 197.983C288.096 201.81 276.283 198.771 260.081 203.161C257.944 203.724 255.806 206.875 254.794 207.213L250.631 190.779Z"
                  fill="black"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-width="3"
              />
              <path
                  d="M237.805 336.207C251.081 347.576 270.545 347.238 277.971 351.515C279.096 352.191 280.333 353.541 280.333 354.667C280.333 356.355 277.183 357.256 275.72 357.143C267.507 356.58 256.369 357.481 255.581 357.931C254.344 358.606 255.469 360.52 255.694 362.208C256.031 364.91 258.394 367.386 259.294 369.3C259.744 370.313 260.194 371.663 259.182 372.226C255.581 374.027 242.981 356.018 228.354 347.576L237.805 336.207Z"
                  fill="#43A972"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-width="3"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import {Prop} from "vue-property-decorator";
import {Constants} from "@/shared/Constants";
import RichText from "@/components/RichText.vue";

@Options({
    computed: {
        Constants() {
            return Constants
        }
    },
  components: {
        RichText
  }
})
export default class Home extends BaseComponent {

    @Prop()
    cardboardType = Constants.CARDBOARD_BACKEND_DOWN

    async beforeMount() {
      this.setDocumentTitle()
    }

}
</script>

<style lang="scss" scoped>
.accordion-button,
.tabnav > button {
  border: 1px solid $color-border-grey;
}

.section2-list,
.section2-list p {
  max-width: 100% !important;
}

.section2-list p {
  align-self: center;
  margin: 0;
  padding-left: 50px;
}

.section2-list li {
  display: flex;
  padding-left: 0;
}

.section2-list li svg {
  order: 0;
  height: 34px;
  width: 34px;
}

.section2-list li::before {
  content: '';
}

.section3-content {
  position: absolute;
  top: 85px;
  left: 380px;
}

.section3-content li {
  display: flex;
  padding: 0;
  margin-top: 30px;
}

.section3-content li svg {
  order: 0;
  height: 46px;
  width: 46px;
}

.section3-content li p {
  order: 1;
  max-width: 560px !important;
  padding-left: 20px;
  align-self: center;
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
}

.section3-content li::before {
  content: '';
}

.section {
  margin: 0;
  padding: 80px 0 50px 0;
  border-bottom: 1px solid $color-border-grey !important;
}

.section:nth-child(even) {
  background-color: $background-alternative !important;
}

.section:last-child {
  border-bottom: none !important;
  margin-bottom: -32px;
  padding-bottom: 64px;
}

.section h3 {
  margin-top: 0;
}

.flex-center {
  flex: auto;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 260px;
}

.d-flex-col img {
  height: 110px;
}

.center-items {
  justify-content: space-between;
}
</style>
