import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45014099"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav bg-alternative d-print-none" }
const _hoisted_2 = { class: "navbar navbar-primary" }
const _hoisted_3 = { class: "navbar-inner container px-0" }
const _hoisted_4 = {
  class: "nav-primary",
  role: "menu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.menuItems, (menuItem) => {
            return (_openBlock(), _createElementBlock("li", {
              key: menuItem.label,
              role: "none"
            }, [
              _createVNode(_component_router_link, {
                id: `${menuItem.id}-link`,
                "active-class": 'current bold',
                exact: menuItem.exact,
                to: menuItem.link,
                class: "nav-link",
                role: "menuitem",
                "data-cy-inner": menuItem.labelKey
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(menuItem.label), 1)
                ]),
                _: 2
              }, 1032, ["id", "exact", "to", "data-cy-inner"])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}