<template>
  <div>
    <h1>Page not found</h1>
  </div>
</template>
<script lang="ts">
import { Vue, Options, mixins } from "vue-class-component"

export default class PageNotFound extends Vue {
}
</script>