import store from '@/store'
//import { mapActions } from "vuex";
import {createRouter, createWebHistory, RouteRecordRaw, RouterScrollBehavior} from 'vue-router'
import {vuexOidcCreateRouterMiddleware} from 'vuex-oidc'
import ElectricityView from '@/components/calculator/energy-and-processes/ElectricityView.vue'
import Home from "@/views/Home.vue";
import Auth from "@/views/Auth.vue";
import Redirect from "@/views/Redirect.vue";
import CookiePolicy from "@/views/CookiePolicy.vue";
import ContactInformation from "@/views/ContactInformation.vue";
import AcceptTermsComponent from "@/views/AcceptTerms.vue";
import Login from "@/views/Login.vue";
import CalculationsOverview from "@/views/CalculationsOverview.vue";
import Guides from "@/components/guides/Guides.vue";
import Admin from "@/components/config/Admin.vue";
import GetStarted from "@/components/guides/GetStarted.vue";
import Faq from "@/components/guides/Faq.vue";
import Terms from "@/components/guides/Terms.vue";
import Background from "@/components/guides/Background.vue";
import Calculator from "@/views/Calculator.vue";
import MasterData from "@/components/calculator/master-data/MasterData.vue";
import CompanyInformationComponent from "@/components/calculator/master-data/CompanyInformation.vue";
import Purpose from "@/components/calculator/master-data/Purpose.vue";
import Results from "@/components/calculator/results/Results.vue";
import MainResults from "@/components/calculator/results/MainResults.vue";
import MainResultsGHG from "@/components/calculator/results/MainResultsGHG.vue";
import PartialResults from "@/components/calculator/results/PartialResults.vue";
import PartialResultsGHG from "@/components/calculator/results/PartialResultsGHG.vue";
import CalculatorPrint from "@/views/CalculatorPrint.vue";
import TermsForUse from "@/views/TermsForUse.vue";
import PrivateTermsForUse from "@/views/PrivateTermsForUse.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import CalculatorPageView from "@/components/calculator/CalculatorPageView.vue"
import BaseCalculatorTab from "@/components/calculator/BaseCalculatorTab.vue"
import {Constants} from "@/shared/Constants"
import HeatView from "@/components/calculator/energy-and-processes/HeatView.vue"
import ProcessView from "@/components/calculator/energy-and-processes/ProcessView.vue"
import MaterialsView from "@/components/calculator/procurement/MaterialsView.vue"
import ProductsAndServicesView from "@/components/calculator/procurement/ProductsAndServicesView.vue"
import OtherEmissionsView from "@/components/calculator/procurement/OtherEmissionsView.vue"
import {Vue} from "vue-class-component"
import OwnAndLeasedView from "@/components/calculator/transport/OwnAndLeasedView.vue"
import EmployeeTransportationView from "@/components/calculator/transport/EmployeeTransportationView.vue"
import ProductTransportationView from "@/components/calculator/transport/ProductTransportationView.vue"
import CustomerTransportationView from "@/components/calculator/transport/CustomerTransportationView.vue"
import OtherTransportationView from "@/components/calculator/transport/OtherTransportationView.vue"
import TrashView from "@/components/calculator/trash/TrashView.vue"
import OtherTrashView from "@/components/calculator/trash/OtherTrashView.vue"
import ProcessingView from "@/components/calculator/sold-products/ProcessingView.vue"
import UseOfProductsView from "@/components/calculator/sold-products/UseOfProductsView.vue"
import EndOfLifeView from "@/components/calculator/sold-products/EndOfLifeView.vue"
import OtherSoldProductsView from "@/components/calculator/sold-products/OtherSoldProductsView.vue"
import OtherEnergyAndProcessView from "@/components/calculator/energy-and-processes/OtherEnergyAndProcessView.vue"
import Setup from "@/views/Setup.vue";
import Access from "@/components/thirdparty/Access.vue";
import HasAccessTo from "@/components/thirdparty/HasAccessTo.vue";
import CreateAccess from "@/components/thirdparty/CreateAccess.vue";
import WasGivenAccessTo from "@/components/thirdparty/WasGivenAccessTo.vue";
import News from "@/components/guides/News.vue";
import CalculateForecast from "@/components/forecast/CalculateForecast.vue";
import Forecast from "@/components/forecast/Forecast.vue";
import ForecastsOverview from "@/components/forecast/ForecastsOverview.vue";
import Scope123 from "@/components/forecast/Scope123.vue";
import ForecastGuides from "@/components/forecast/ForecastGuides.vue";
import ForecastTableView from "@/components/forecast/ForecastTableView.vue";
import ToastMessage from "@/types/ToastMessage";
import Scope3Results from "@/components/calculator/results/Scope3Results.vue";
import ResultsESG from "@/components/calculator/results/ResultsESG.vue";
import ReductionTarget from "@/components/forecast/ReductionTarget.vue";
import ReductionTargetPrint from "@/views/ReductionTargetPrint.vue";
import Config from "@/components/config/Config.vue";
import HighestEmissionResults from "@/components/calculator/results/HighestEmissionResults.vue";
import Transfer from '@/components/config/Transfer.vue'
import Statistics from '@/components/config/Statistics.vue'
import Misc from '@/components/config/Misc.vue'
import ExcelMappings from "@/components/config/ExcelMappings.vue";
import EmissionFactorImport from '@/components/config/EmissionFactorImport.vue'
import EmissionFactorValidate from "@/components/config/EmissionFactorValidate.vue";
import Comparison from "@/components/comparison/Comparison.vue";
import ComparisonOverview from "@/components/comparison/ComparisonOverview.vue";
import CreateComparison from "@/components/comparison/CreateComparison.vue";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const eventHub = require('tiny-emitter/instance')


const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/auth',
        name: 'auth',
        component: Auth,
        meta: {
            title: 'Logger ind',
            isPublic: true
        }
    },
    {
        path: '/redirect',
        name: 'redirect',
        component: Redirect,
        meta: {
            title: 'Redirecting',
            isPublic: true
        }
    },
    {
        path: '/cookie-policy',
        name: 'CookiePolicy',
        component: CookiePolicy,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/contact-information',
        name: 'ContactInformation',
        component: ContactInformation
    },
    {
        path: '/accept-terms',
        name: 'AcceptTerms',
        component: AcceptTermsComponent
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/overview',
        name: 'CalculationsOverview',
        component: CalculationsOverview
    },
    {
        path: '/forecast',
        name: 'Forecast',
        component: Forecast,
        children: [
            {
                path: '',
                redirect: '/forecast/forecast-overview',
            },
            {
                path: 'forecast-guide',
                name: 'ForecastGuides',
                component: ForecastGuides
            },
            {
                path: 'calculate-forecast',
                name: 'CalculateForecast',
                component: CalculateForecast,
                props: true,
                children: [
                    {
                        path: '/forecast/scope123',
                        name: 'Scope123',
                        component: Scope123
                    },
                    {
                        path: '/forecast/table-view',
                        name: 'TableView',
                        component: ForecastTableView
                    },
                ]
            },
            {
                path: 'forecast-overview',
                name: 'ForecastOverview',
                component: ForecastsOverview
            },
            {
                path: 'reduction-objectives',
                name: 'ReductionObjectives',
                component: ReductionTarget,
                props: true
            },
            {
                path: 'reduction-actions',
                name: 'ReductionActions',
                component: Terms
            },
        ]
    },

    {
        path: '/guides',
        name: 'Guides',
        component: Guides,
        children: [
            {
                path: '',
                redirect: '/guides/getstarted',
            },
            {
                path: 'GetStarted',
                name: 'GetStarted',
                component: GetStarted
            },
            {
                path: 'news',
                name: 'News',
                component: News
            },
            {
                path: 'faq',
                name: 'FAQ',
                component: Faq
            },
            {
                path: 'terms',
                name: 'Terms',
                component: Terms
            },
            {
                path: 'background',
                name: 'Background',
                component: Background
            }
            ,
            {
                path: 'getstarted',
                name: 'GetStarted',
                component: GetStarted
            },
            {
                path: 'terms-for-use',
                name: 'TermsForUseGuides',
                component: TermsForUse
            }
        ]
    },
    {
        path: '/calculator',
        name: 'Calculator',
        component: Calculator,
        children: [
            {
                path: '',
                redirect: '/calculator/master-data'
            },
            {
                path: 'master-data',
                name: 'MasterData',
                component: MasterData,
                children: [
                    {
                        path: '',
                        redirect: '/calculator/master-data/purpose'
                    },
                    {
                        path: 'company-information',
                        name: 'CompanyInformation',
                        component: CompanyInformationComponent
                    },
                    {
                        path: 'purpose',
                        name: 'Purpose',
                        component: Purpose
                    }
                ]
            },

            {
                path: 'energy-and-processes',
                name: 'EnergyAndProcesses',
                component: CalculatorPageView,
                props: {menuSysId: 'energyAndProcessesTabs', pagePropertiesSysId: 'A0MRWFEtnWTI4Zf1n2fUJ'},
                meta: {
                    daName: 'Energi og processor'
                },
                children: [
                    {
                        path: '',
                        redirect: '/calculator/energy-and-processes/electricity'
                    },
                    {
                        path: 'electricity',
                        name: 'Electricity',
                        component: ElectricityView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_FORBRUG_AF_ELEKTRICITET,
                            calculatorSecondaryFirstSysId: Constants.SYSID_CALC_FORBRUG_AF_ELEKTRICITET_MED_EGNE_EMISSIONSFAKTORER,
                            pagePropertiesSysId: Constants.SYSID_TAB_ELECTRICITET,
                            showEFSelection: true,
                            from: '/calculator/master-data/company-information',
                            to: 'heat'
                        },
                        meta: {
                            daName: 'Elektricitet',
                        }
                    },
                    {
                        path: 'heat',
                        name: 'Heat',
                        component: HeatView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_FJERNVARME,
                            calculatorPrimarySecondSysId: Constants.SYSID_CALC_BRÆNDSLER,
                            calculatorSecondaryFirstSysId: Constants.SYSID_CALC_VARME_OG_PROCES_MED_EGNE_EMSSIONSFAKTORER,
                            pagePropertiesSysId: Constants.SYSID_TAB_VARME_OG_PROCESENERGI,
                            from: 'electricity',
                            to: 'process'
                        },
                        meta: {
                            daName: 'Varme og processenergi'
                        }
                    },
                    {
                        path: 'process',
                        name: 'Process',
                        component: ProcessView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_PROCESDUDLEDNING,
                            calculatorSecondaryFirstSysId: Constants.SYSID_CALC_PROCESDUDLEDNING_MED_EGNE_EMSSIONSFAKTORER,
                            pagePropertiesSysId: Constants.SYSID_TAB_PROCESUDLEDNING,
                            from: 'heat',
                            to: 'other'
                        },
                        meta: {
                            daName: 'Procesudledning'
                        }
                    },
                    {
                        path: 'other',
                        name: 'OtherEnergyAndProcesses',
                        component: OtherEnergyAndProcessView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_ANDET_UDLEDNING_VED_ENERGI_OG_PROCESSER,
                            pagePropertiesSysId: Constants.SYSID_TAB_ANDET_UDLEDNING_VED_ENERGI_OG_PROCESSER,
                            from: 'process',
                            to: '/calculator/procurement'
                        },
                        meta: {
                            daName: 'Andet (energi og processer)'
                        }
                    }
                ]
            },
            {
                path: 'procurement',
                name: 'Procurement',
                component: CalculatorPageView,
                props: {menuSysId: Constants.SYSID_PAGE_INDKOEB_MENU, pagePropertiesSysId: Constants.SYSID_PAGE_INDKOEB},
                children: [
                    {
                        path: '',
                        redirect: '/calculator/procurement/materials-primary'
                    },
                    {
                        path: 'materials-primary',
                        name: 'MaterialsPrimary',
                        component: MaterialsView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_INDKØB_MATERIALER_FYSISKE,
                            calculatorPrimarySecondSysId: Constants.SYSID_CALC_INDKØB_MATERIALER_MONETÆRE,
                            calculatorSecondaryFirstSysId: Constants.SYSID_CALC_INDKØB_FYSISKE_MED_EGNE_EMISSIONSFAKTORER,
                            calculatorSecondarySecondSysId: Constants.SYSID_CALC_INDKØB_MONETÆRE_MED_EGNE_EMISSIONSFAKTORER,
                            pagePropertiesSysId: Constants.SYSID_TAB_MATERIALER_PRIMÆRE,
                            primary: true,
                            from: '/calculator/energy-and-processes/other',
                            to: 'products-and-services-primary'
                        },
                        meta: {
                            daName: 'Materialer'
                        }
                    },
                    {
                        path: 'materials-secondary',
                        name: 'MaterialsSecondary',
                        component: MaterialsView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_INDKØB_MATERIALER_FYSISKE,
                            calculatorPrimarySecondSysId: Constants.SYSID_CALC_INDKØB_MATERIALER_MONETÆRE,
                            calculatorSecondaryFirstSysId: Constants.SYSID_CALC_INDKØB_FYSISKE_MED_EGNE_EMISSIONSFAKTORER,
                            calculatorSecondarySecondSysId: Constants.SYSID_CALC_INDKØB_MONETÆRE_MED_EGNE_EMISSIONSFAKTORER,
                            pagePropertiesSysId: Constants.SYSID_TAB_MATERIALER_SEKUNDÆRE,
                            primary: false,
                            from: 'other-primary',
                            to: 'products-and-services-secondary'
                        },
                        meta: {
                            daName: 'Materialer (sekundære)'
                        }
                    },
                    {
                        path: 'products-and-services-primary',
                        name: 'ProductsAndServicesPrimary',
                        component: ProductsAndServicesView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_INDKØB_PRODUKTER_FYSISKE,
                            calculatorPrimarySecondSysId: Constants.SYSID_CALC_INDKØB_PRODUKTER_MONETÆRE,
                            calculatorSecondaryFirstSysId: Constants.SYSID_CALC_INDKØB_FYSISKE_MED_EGNE_EMISSIONSFAKTORER,
                            calculatorSecondarySecondSysId: Constants.SYSID_CALC_INDKØB_MONETÆRE_MED_EGNE_EMISSIONSFAKTORER,
                            pagePropertiesSysId: Constants.SYSID_TAB_PRODUKTER_PRIMÆRE,
                            primary: true,
                            from: 'materials-primary',
                            to: 'other-primary'
                        },
                        meta: {
                            daName: 'Produkter og services'
                        }
                    },
                    {
                        path: 'products-and-services-secondary',
                        name: 'ProductsAndServicesSecondary',
                        component: ProductsAndServicesView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_INDKØB_PRODUKTER_FYSISKE,
                            calculatorPrimarySecondSysId: Constants.SYSID_CALC_INDKØB_PRODUKTER_MONETÆRE,
                            calculatorSecondaryFirstSysId: Constants.SYSID_CALC_INDKØB_FYSISKE_MED_EGNE_EMISSIONSFAKTORER,
                            calculatorSecondarySecondSysId: Constants.SYSID_CALC_INDKØB_MONETÆRE_MED_EGNE_EMISSIONSFAKTORER,
                            pagePropertiesSysId: Constants.SYSID_TAB_PRODUKTER_SEKUNDÆRE,
                            primary: false,
                            from: 'materials-secondary',
                            to: 'other-secondary'
                        },
                        meta: {
                            daName: 'Produkter og services (sekundære)'
                        }
                    },
                    {
                        path: 'other-primary',
                        name: 'OtherPrimary',
                        component: OtherEmissionsView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_INDKØB_FYSISKE_MED_EGNE_EMISSIONSFAKTORER,
                            calculatorSecondaryFirstSysId: Constants.SYSID_CALC_INDKØB_MONETÆRE_MED_EGNE_EMISSIONSFAKTORER,
                            pagePropertiesSysId: Constants.SYSID_TAB_ANDET_PRIMÆRE,
                            primary: true,
                            from: 'products-and-services-primary',
                            to: 'materials-secondary'
                        },
                        meta: {
                            daName: 'Andet'
                        }
                    },
                    {
                        path: 'other-secondary',
                        name: 'OtherSecondary',
                        component: OtherEmissionsView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_INDKØB_FYSISKE_MED_EGNE_EMISSIONSFAKTORER,
                            calculatorSecondaryFirstSysId: Constants.SYSID_CALC_INDKØB_MONETÆRE_MED_EGNE_EMISSIONSFAKTORER,
                            pagePropertiesSysId: Constants.SYSID_TAB_ANDET_SEKUNDÆRE,
                            primary: false,
                            from: 'products-and-services-secondary',
                            to: '/calculator/transport/own-and-leased'
                        },
                        meta: {
                            daName: 'Andet (sekundære)'
                        }
                    }
                ],
                meta: {
                    daName: 'Indkøb'
                }
            },
            {
                path: 'trash-and-recycling',
                name: 'TrashAndRecycling',
                component: CalculatorPageView,
                props: {menuSysId: Constants.SYSID_PAGE_AFFALD_MENU, pagePropertiesSysId: Constants.SYSID_PAGE_AFFALD},
                children: [
                    {
                        path: '',
                        redirect: '/calculator/trash-and-recycling/trash'
                    },
                    {
                        path: 'trash',
                        name: 'Trash',
                        component: TrashView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_AFFALD_AFFALD,
                            calculatorSecondaryFirstSysId: Constants.SYSID_CALC_AFFALD_ANDET_UDLEDNING_VED_AFFALD_MED_EGNE_EMISSIONSFAKTORER,
                            pagePropertiesSysId: Constants.SYSID_TAB_AFFALD,
                            from: '/calculator/transport/other-transport',
                            to: 'other'
                        },
                        meta: {
                            daName: 'Affald'
                        }
                    },
                    {
                        path: 'other',
                        name: 'OtherTrashAndRecycling',
                        component: OtherTrashView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_AFFALD_ANDET_UDLEDNING_VED_AFFALD_MED_EGNE_EMISSIONSFAKTORER,
                            pagePropertiesSysId: Constants.SYSID_TAB_ANDET_AFFALD,
                            from: 'trash',
                            to: '/calculator/sold-products/processing'
                        },
                        meta: {
                            daName: 'Andet (Affald)'
                        }
                    }
                ],
                meta: {
                    daName: 'Affald og genbrug'
                }
            },
            {
                path: 'sold-products',
                name: 'SoldProducts',
                component: CalculatorPageView,
                props: {menuSysId: Constants.SYSID_PAGE_SOLGTE_PRODUKTER_MENU, pagePropertiesSysId: Constants.SYSID_PAGE_SOLGTE_PRODUKTER},
                children: [
                    {
                        path: '',
                        redirect: '/calculator/sold-products/processing'
                    },
                    {
                        path: 'processing',
                        name: 'Processing',
                        component: ProcessingView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_SOLGTE_PRODUKTER_FORARBEJDNING_OG_PROCESSERING,
                            pagePropertiesSysId: Constants.SYSID_TAB_FORARBEJDNING_OG_PROCESSERING,
                            from: '/calculator/trash-and-recycling/other',
                            to: 'use-of-products'
                        },
                        meta: {
                            daName: 'Forarbejdning og processering'
                        }
                    },
                    {
                        path: 'use-of-products',
                        name: 'UseOfProducts',
                        component: UseOfProductsView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_SOLGTE_PRODUKTER_ENERGIFORBRUG_VED_ANVENDELSE,
                            calculatorSecondaryFirstSysId: Constants.SYSID_CALC_SOLGTE_PRODUKTER_ENERGIFORBRUG_VED_ANVENDELSE_MED_EGNE_EMISSIONSFAKTORER,
                            pagePropertiesSysId: Constants.SYSID_TAB_BRUG_AF_PRODUKTER,
                            from: 'processing',
                            to: 'end-of-life-treatment'
                        },
                        meta: {
                            daName: 'Brug af solgte og udlejede produkter'
                        }
                    },
                    {
                        path: 'end-of-life-treatment',
                        name: 'EndOfLifeTreatment',
                        component: EndOfLifeView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_SOLGTE_PRODUKTER_END_OF_LIFE,
                            calculatorSecondaryFirstSysId: Constants.SYSID_CALC_SOLGTE_PRODUKTER_END_OF_LIFE_MED_EGNE_EMISSIONSFAKTORER,
                            pagePropertiesSysId: Constants.SYSID_TAB_END_OF_LIFE,
                            from: 'use-of-products',
                            to: 'other-sold-products'
                        },
                        meta: {
                            daName: 'End-of-life behandling'
                        }
                    },
                    {
                        path: 'other-sold-products',
                        name: 'OtherSoldProducts',
                        component: OtherSoldProductsView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_SOLGTE_PRODUKTER_ANDET_UDLEDNING,
                            pagePropertiesSysId: Constants.SYSID_TAB_ANDET_SOLGTE_PRODUKTER,
                            from: 'end-of-life-treatment',
                            to: '/calculator/results'
                        },
                        meta: {
                            daName: 'Andet (Solgte produkter)'
                        }
                    }
                ],
                meta: {
                    daName: 'Solgte produkter'
                }
            },
            {
                path: 'transport',
                name: 'Transport',
                component: CalculatorPageView,
                props: {menuSysId: Constants.SYSID_PAGE_TRANSPORT_MENU, pagePropertiesSysId: Constants.SYSID_PAGE_TRANSPORT},
                children: [
                    {
                        path: '',
                        redirect: '/calculator/transport/own-and-leased'
                    },
                    {
                        path: 'own-and-leased',
                        name: 'OwnAndLeased',
                        component: OwnAndLeasedView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_TRANSPORT_EGNE_OG_LEASEDE_KØRETØJER,
                            calculatorPrimarySecondSysId: Constants.SYSID_CALC_TRANSPORT_FLY_OG_SKIBE,
                            calculatorSecondaryFirstSysId: Constants.SYSID_CALC_TRANSPORT_EGNE_OG_LEASEDE_MED_EGNE_EMISSIONSFAKTORER,
                            pagePropertiesSysId: Constants.SYSID_TAB_EGNE_OG_LEASEDE,
                            showRFISelection: true,
                            from: '/calculator/procurement/other-secondary',
                            to: 'employee-transportation'
                        },
                        meta: {
                            daName: 'Egne og leasede transportmidler'
                        }
                    },
                    {
                        path: 'employee-transportation',
                        name: 'EmployeeTransportation',
                        component: EmployeeTransportationView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_TRANSPORT_PENDLING,
                            calculatorPrimarySecondSysId: Constants.SYSID_CALC_TRANSPORT_REJSER_I_FORBINDELSE_MED_ARBEJDE_FYSISKE,
                            calculatorPrimaryThirdSysId: Constants.SYSID_CALC_TRANSPORT_REJSER_I_FORBINDELSE_MED_ARBEJDE_MONETÆRE,
                            calculatorSecondaryFirstSysId: Constants.SYSID_CALC_TRANSPORT_MEDARBEJDER_TRANSPORT_MED_EGNE_EMISSIONSFAKTORER,
                            pagePropertiesSysId: Constants.SYSID_TAB_MEDARBEJDERTRANSPORT,
                            showRFISelection: true,
                            from: 'own-and-leased',
                            to: 'product-transportation'
                        },
                        meta: {
                            daName: 'Medarbejdertransport'
                        }
                    },
                    {
                        path: 'product-transportation',
                        name: 'ProductTransportation',
                        component: ProductTransportationView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_TRANSPORT_VARETRANSPORT_I_FYSISKE,
                            calculatorPrimarySecondSysId: Constants.SYSID_CALC_TRANSPORT_VARETRANSPORT_I_MONETÆRE,
                            calculatorSecondaryFirstSysId: Constants.SYSID_CALC_TRANSPORT_VARETRANSPORT_TIL_VIRKSOMHED_MED_EGNE_EMISSIONSFAKTORER,
                            pagePropertiesSysId: Constants.SYSID_TAB_TRANSPORT_TIL_VIRKSOMHED,
                            showRFISelection: true,
                            from: 'employee-transportation',
                            to: 'customer-transportation'
                        },
                        meta: {
                            daName: 'Varetransport til virksomhed'
                        }
                    },
                    {
                        path: 'customer-transportation',
                        name: 'CustomerTransportation',
                        component: CustomerTransportationView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_TRANSPORT_VARETRANSPORT_I_FYSISKE,
                            calculatorPrimarySecondSysId: Constants.SYSID_CALC_TRANSPORT_VARETRANSPORT_I_MONETÆRE,
                            calculatorSecondaryFirstSysId: Constants.SYSID_CALC_TRANSPORT_VARETRANSPORT_TIL_KUNDE_MED_EGNE_EMISSIONSFAKTORER,
                            pagePropertiesSysId: Constants.SYSID_TAB_TRANSPORT_TIL_KUNDE,
                            showRFISelection: true,
                            from: 'product-transportation',
                            to: 'other-transport'
                        },
                        meta: {
                            daName: 'Varetransport til kunde'
                        }
                    },
                    {
                        path: 'other-transport',
                        name: 'OtherTransport',
                        component: OtherTransportationView,
                        props: {
                            calculatorPrimaryFirstSysId: Constants.SYSID_CALC_TRANSPORT_ANDET_UDLEDNING_VED_TRANSPORT,
                            pagePropertiesSysId: Constants.SYSID_TAB_ANDET_TRANSPORT,
                            showRFISelection: true,
                            from: 'customer-transportation',
                            to: '/calculator/trash-and-recycling'
                        },
                        meta: {
                            daName: 'Andet (Transport)'
                        }
                    }
                ],
                meta: {
                    daName: 'Transport'
                }
            },
            {
                path: 'results',
                name: 'Results',
                component: Results,
                children: [
                    {
                        path: '',
                        redirect: '/calculator/results/main-results'
                    },
                    {
                        path: 'main-results',
                        name: 'MainResults',
                        component: MainResults
                    },
                    {
                        path: 'partial-results',
                        name: 'PartialResults',
                        component: PartialResults
                    },
                    {
                        path: 'main-results-ghg',
                        name: 'MainResultsGHG',
                        component: MainResultsGHG
                    },
                    {
                        path: 'partial-results-ghg',
                        name: 'PartialResultsGHG',
                        component: PartialResultsGHG
                    },
                    {
                        path:'scope3-results',
                        name: 'Scope3Results',
                        component: Scope3Results
                    },
                    {
                        path:'results-esg',
                        name: 'ResultsESG',
                        component: ResultsESG
                    },
                    {
                        path:'highest-emission-results',
                        name: 'HighestEmissionResults',
                        component: HighestEmissionResults
                    },
                ]
            }
        ]
    },
    {
        path: '/comparison',
        name: 'Comparison',
        component: Comparison,
        children: [
            {
                path: '',
                redirect: '/comparison/comparison-overview'
            },
            {
                path: '/comparison/comparison-overview',
                name: 'ComparisonOverview',
                component: ComparisonOverview,
            },
            {
                path: '/comparison/create-comparison',
                name: 'CreateComparison',
                component: CreateComparison,
                props: true
            },
        ]
    },
    {
        path: '/setup',
        name: 'Setup',
        component: Setup,
        children: [
            {
                path: '',
                redirect: '/setup/access/access-to'
            },
            {
                path: '/setup/access',
                redirect: '/setup/access/access-to',
                name: 'Access'
            },
            {
                path: '/setup/access/create-access',
                name: 'CreateAccess',
                component: CreateAccess,
                props: {mode: 'new'}
            },
            {
                path: '/setup/access/edit-access',
                name: 'EditAccess',
                component: CreateAccess,
                props: {mode: 'edit'}
            },
            {
                path: '/setup/access/access-to',
                name: 'HasAccessTo',
                component: HasAccessTo
            },
            {
                path: '/setup/showaccess',
                name: 'WasGivenAccessTo',
                component: WasGivenAccessTo
            },
        ]
    },

    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        children: [
            {
                path: '',
                redirect: '/admin/config'
            },
            {
                path: '/admin/config',
                name: 'Config',
                component: Config,
            },
            {
                path: '/admin/transfer',
                name: 'Transfer',
                component: Transfer,
            },
            {
                path: '/admin/statistics',
                name: 'Statistics',
                component: Statistics,
            },
            {
                path: '/admin/excelmappings',
                name: 'ExcelMappings',
                component: ExcelMappings,
            },
            {
                path: '/admin/emf-import',
                name: 'EmissionFactorImport',
                component: EmissionFactorImport,
            },
            {
                path: '/admin/emf-validate',
                name: 'EmissionFactorValidate',
                component: EmissionFactorValidate,
            },
            {
                path: '/admin/misc',
                name: 'Misc',
                component: Misc,
            },
        ]
    },
    {
        path: '/print-results/:calculationId/:behalfOfVatNr',
        name: 'PrintResults',
        component: CalculatorPrint,
        props: (route) => (
            {
                calculationId: parseInt(route.params.calculationId as string),
                behalfOfVatNr: route.params.behalfOfVatNr as string
            }

        )
    },
    {
        path: '/print-reduction-target/:forecastId/:behalfOfVatNr',
        name: 'PrintReductionTarget',
        component: ReductionTargetPrint,
        props: (route) => (
            {
                forecastId: parseInt(route.params.forecastId as string),
                behalfOfVatNr: route.params.behalfOfVatNr as string
            }

        )
    },

    {
        path: '/terms-for-use',
        name: 'TermsForUse',
        component: TermsForUse,
        meta: {
            isPublic: true
        }
    },

    {
        path: '/private-terms-for-use',
        name: 'PrivateTermsForUse',
        component: PrivateTermsForUse,
        meta: {
            isPublic: true
        }
    },

    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: PageNotFound,
    }
]

const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
    if (savedPosition) {
        return savedPosition
    } else {
        const position: any = {}
        if (to.hash) {
            position.selector = to.hash
            return false
        }

    }
    return Promise.resolve({top: 0, left: 0})
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior
})

function showNoContactPersonToast() {
    if (store.getters.getContentfulContent
      && "findSimpleText" in store.getters.getContentfulContent) { //Guard to void exception in case contentful text have not yet been loaded
        const toast = new ToastMessage(
          Constants.NO_CONTACT_PERSON_ADDED_TOAST_ID, "info",
          true,
          store.getters.getContentfulContent.findSimpleText('shared.flow.error.no-contact-person-added.title'),
          store.getters.getContentfulContent.findSimpleText('shared.flow.error.no-contact-person-added.text'),
          true,
          false,
          true)
        eventHub.emit("add-toast", toast)
    }
    return false
}

function setDocumentTitleByKey(subsectionKey = "") {
    if (store.getters.getContentfulContent
      && "findSimpleText" in store.getters.getContentfulContent) { //Guard to void exception in case contentful text have not yet been loaded
        if (subsectionKey) {
            document.title = `${store.getters.getContentfulContent.findSimpleText(subsectionKey)} | ` + store.getters.getContentfulContent.findSimpleText("title.klimakompasset")
        } else {
            document.title = store.getters.getContentfulContent.findSimpleText("title.klimakompasset")
        }
    }
}

router.afterEach(async (to, from) => {
    //console.log("Router: After switch", to)
    // This functionality guards against users directly entering a URL (to fx. overview) in order to circumvent/avoid consenting and entering contact information

    // Also adds a default document title set from the text used in the menu item
    // Default document title can be overridden on the individual page by using setDocumentTitle* from BaseComponent

    if (store.getters.getMenuStructure?.menuItemByLinkMap) {
        const toMenuItem = store.getters.getMenuStructure.menuItemByLinkMap.get(to.path)
        if (toMenuItem) {
            setDocumentTitleByKey(toMenuItem.labelKey)
        } else {
            setDocumentTitleByKey()
        }
    }

    if (to.name !== 'TermsForUse' && to.name !== 'AcceptTerms' && !store.getters.hasConsented && store.getters.isAuthenticated) {
    //if (to.name !== 'TermsForUse' && to.name !== 'AcceptTerms' && !store.getters.hasConsented && store.getters.isAuthenticated) {
        // Just making absolutely sure
        await store.dispatch('loadConsent')
        if (!store.getters.hasConsented) {
            return router.push('/accept-terms')
        }
    }
    if (to.name !== 'TermsForUse' && to.name !== 'AcceptTerms' && to.name !== 'ContactInformation'  && store.getters.isAuthenticated && !store.getters.getContactPerson.termsEnabled) {
        await store.dispatch('loadContactPerson')
        if (!store.getters.getContactPerson?.termsEnabled) {
            showNoContactPersonToast()
            return router.push('/contact-information')
        }
    }
})



router.beforeEach(async (to, from) => {
    //console.log("Router: Before switch", to)
    // this functionality guards against users moving away from consenting and entering contact information before it has been completed

    
    const allowedFrom = from.name === 'TermsForUse' || from.name === 'AcceptTerms'
    /*
     * from AcceptTerms|TermsForUse && to private
     */
    if (allowedFrom && !store.getters.hasConsented) {
        return false
    }
    /*
     * Not allowed to change away from contact information before it has been completed
     */
    if (to.name !== 'TermsForUse' && to.name !== 'AcceptTerms' && from.name === "ContactInformation" && store.getters.isAuthenticated && !store.getters.getContactPerson?.termsEnabled) {
        showNoContactPersonToast()
        return false
    }
})

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'))

export default router
