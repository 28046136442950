<template>
  {{simpleText}}
</template>

<script lang="ts">
import {useStore} from "vuex"
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator';

export default class extends Vue {
  @Prop()
  textKey = 'ukendt-noegle'

  store = useStore()

  simpleText = ''

  mounted () : void {
    this.simpleText = this.store.getters.getContentfulContent.findSimpleText(this.textKey)
  }
}
</script>

<style scoped>

</style>
