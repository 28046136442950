<template>
  <div style="text-align: center">
    <p class="title">
      {{ title }}
    </p>
  </div>
  <div v-if="resultIsNegative" class="alert alert-success">
    <div class="alert-body">
      <p v-if="negativeResultHeader" class="alert-heading" v-html="negativeResultHeader"></p>
      <p v-if="negativeResultText" class="alert-text" v-html="negativeResultText"></p>
    </div>
  </div>

  <div v-if="results.groupingResults && !resultIsNegative" style="margin: 0 auto;"
       v-bind:class="{ 'print-graph-container': printMode }">
    <canvas ref="chart"></canvas>
  </div>
</template>

<script lang="ts">
import { Prop, Watch } from "vue-property-decorator"
import Chart from "chart.js"
import "chartjs-plugin-labels"
import { GroupingCalculatedResults } from "openapi/generated-clients/climatecompass"
import { prettify } from "@/shared/PrettifyNumbers"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import {Constants} from "@/shared/Constants";

export default class PieChart extends BaseComponent {
  @Prop()
  title?: string
  @Prop({ required: true })
  results: GroupingCalculatedResults = {} as GroupingCalculatedResults

  @Prop()
  showOnlyScopes?: boolean
  @Prop()
  printMode?: boolean
  @Prop()
  negativeResultHeader?: string
  @Prop()
  negativeResultText?: string

  chart = {} as HTMLCanvasElement
  context: CanvasRenderingContext2D = {} as CanvasRenderingContext2D

  resultIsNegative = false

  publicCategoryTheme(themeName: number, groupName: string) {
    switch (themeName) {
      case Constants.THEME_NUMBER_ENERGY:         return this.getContentfulString('theme.text.category.energy');
      case Constants.THEME_NUMBER_PURCHASES:      return this.getContentfulString('theme.text.category.purchase');
      case Constants.THEME_NUMBER_TRANSPORT:      return this.getContentfulString('theme.text.category.transport');
      case Constants.THEME_NUMBER_TRASH:          return this.getContentfulString('theme.text.category.waste');
      case Constants.THEME_NUMBER_SOLDPRODUCTS:   return this.getContentfulString('theme.text.category.soldproducts');
      default:                                  return groupName;
    }
  }

  buildChart() {
    let dataLabels
    let data

    if (this.showOnlyScopes) {
      data = this.results.groupingResults?.map((e) => e.calculatedResult?.scopesResult)

      dataLabels = this.results.groupingResults?.map((e) => {
        if (e.calculatedResult?.scopesResult && this.results.scoresTotalResult) {
          this.resultIsNegative = e.calculatedResult.scopesResult < 0

          return `${this.publicCategoryTheme(e.groupingId!, e.groupingName!)} (${prettify(((e.calculatedResult.scopesResult / this.results.scoresTotalResult) * 100).toFixed(1))}%)`
        }
        return this.publicCategoryTheme(e.groupingId!, e.groupingName!) + " (0,0%)"
      })
    } else {
      data = this.results.groupingResults?.map((e) => e.calculatedResult?.totalResult)

      dataLabels = this.results.groupingResults?.map((e) => {
        if (e.calculatedResult?.totalResult && this.results.totalResult) {
          this.resultIsNegative = e.calculatedResult.totalResult < 0

          /* eslint-disable  @typescript-eslint/no-extra-non-null-assertion */
          return `${this.publicCategoryTheme(e.groupingId!!, e.groupingName!)} (${prettify(((e.calculatedResult.totalResult / this.results.totalResult) * 100).toFixed(1))}%)`
        }
        return this.publicCategoryTheme(e.groupingId!, e.groupingName!) + " (0,0%)"
      })
    }

    if (this.resultIsNegative) {
      return
    }

    const animationOptions: Chart.ChartAnimationOptions | undefined = this.printMode ? { duration: 0 } : {
      duration: 1000,
      easing: "easeOutQuart"
    }

    /* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
    this.context = this.chart?.getContext("2d")!
    const chartObj = new Chart(this.context, {
      type: "pie",
      data: {
        labels: dataLabels,
        datasets: [
          {
            data: data,
            backgroundColor: [this.constants.GROUPING1_COLOR_HEX, this.constants.GROUPING2_COLOR_HEX, this.constants.GROUPING3_COLOR_HEX, this.constants.GROUPING4_COLOR_HEX, this.constants.GROUPING5_COLOR_HEX],
            borderColor: "#fff",
            borderWidth: 3,
            borderAlign: "center",
            hoverBorderColor: "#fff"
          }
        ]
      },

      options: {
        tooltips: { enabled: false },
        hover: { mode: undefined },
        animation: animationOptions,
        plugins: {
          labels: {
            render: function() {
              return ""
            }
          }
        },

        legend: {
          labels: {
            padding: 13,
            boxWidth: 13,
            fontSize: 12.5
          },
          position: "bottom"
        }
      }
    })
  }

  mounted() {
    this.buildChart()
  }

  @Watch("results")
  watchResults(newValue: GroupingCalculatedResults, oldValue: GroupingCalculatedResults) {
    this.buildChart()
  }
}
</script>

<style scoped>
.title {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 550;
}
</style>
