<template>
  <CalculatorTabComponent>
    <template v-slot:calculator-primary-1="slotProps">
      <OtherSoldProductsCalculator
        :subgroupingId="constants.GROUP_FORARBEJDNING_OG_PROCESSERING_AF_SOLGTE_PRODUKTER"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
        :expanded="true"
      >
      </OtherSoldProductsCalculator>
    </template>
  </CalculatorTabComponent>
</template>

<script lang="ts">
import { Options, Prop, Watch } from "vue-property-decorator"
import BaseComponent from "../base-components/BaseComponent"
import CalculatorTabComponent from "../CalculatorTabComponent.vue"
import OtherSoldProductsCalculator
  from "@/components/calculator/sold-products/calculators/OtherSoldProductsCalculator.vue"

@Options({
  components: { OtherSoldProductsCalculator, CalculatorTabComponent }
})
export default class ProcessingView extends BaseComponent {

}
</script>

<style scoped>

</style>