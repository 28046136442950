<template>
  <div v-if="calculation && calculation.calculationPurpose" v-show='!isTestFramework'  :class="tabsAreVisible && 'hide'"
       class="breadcrumbs-container">
    <div class="container px-0">
      <nav aria-label="Brødkrumme" class="breadcrumbs d-print-none">
        <ol class="breadcrumbs__list">
          <li class="breadcrumbs__list-item breadcrumbs__list-item--calculation">
            <span>{{ calculation.calculationPurpose.name }} {{ currentCalculationTitleYear }}</span>
          </li>
          <li class="breadcrumbs__list-item breadcrumbs__list-item--route">
            <span>{{ currentRouteBreadcrumb }}</span>
          </li>
          <li v-if="currentTabBreadcrumb.length > 0" class="breadcrumbs__list-item breadcrumbs__list-item--tab">
            <span>{{ currentTabBreadcrumb }}</span>
          </li>
          <li
            v-if="currentTabBreadcrumb.length > 0 && currentCalculatorInputBreadcrumb.length > 0"
            class="breadcrumbs__list-item breadcrumbs__list-item--input"
          >
            <span>{{ currentCalculatorInputBreadcrumb }}</span>
          </li>
        </ol>
      </nav>
    </div>
  </div>
</template>

<script lang="ts">
import { Menu } from "@/shared/MenuStructure"
import { getCookie } from "@/shared/CookieHelper"
import router from "@/router"

import DateInput from "@/types/DateInput"
import { Options } from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import { useRouter } from "vue-router"

interface Breadcrumb {
  label: string
  link: string
}

@Options({})
export default class Breadcrumbs extends BaseComponent {
  @Prop()
  tabsAreVisible?: boolean
  @Prop({ required: true })
  tabMenu?: Menu
  @Prop()
  scrollPosition = []

  router = useRouter()



  menu = {} as Menu
  currentCalculationTitleYear = ""
  currentCalculationBreadcrumb = ""
  currentRouteBreadcrumb = ""
  currentTabBreadcrumb = ""
  currentCalculatorInputBreadcrumb = ""
  currentCalculatorHeaderStrings: string[] = []
  currentCalculatorHeaderElements: HTMLElement[] = []

  searchingHeaders = false

  get startYear() {
    return new Date(this.calculation.calculationPurpose.startDate).getFullYear()
  }

  // this is wrong. endDate is in format mm-dd
  get endYear() {
    return new Date(this.calculation.calculationPurpose.endDate).getFullYear()
  }

  // do it the way its done all other places
  get _endDate() {
    return this.calculation.calculationPurpose!.endDate.split("-")
  }

  get endYear2() {
    return new DateInput(this._endDate[1], this._endDate[0], this.startYear + "").year
  }

  mounted() {
    this.currentCalculationTitleYear = `(${this.startYear})`

    if (this.calculation.calculationPurpose.endDate && this.startYear !== parseInt(this.endYear2)) {
      this.currentCalculationTitleYear = `(${this.startYear} - ${this.endYear} - ${this.endYear2})`
    }
    this.store.subscribe((mutation, state) => {
      if (mutation.type === "setCurrentCalculationPageData") {
        this.calculateHeaders([...state.currentCalculationPageData])
      }
    })
    this.eventHub.on("calculator-mounted", async (arg: string) => {
      await this.calculateHeaders(this.store.state.currentCalculationPageData)
    })

  }

  async beforeMount() {
    this.menu = this.store.getters.getMenuStructure.calculatorMenu
    await this.calculateBreadcrumbs()
    await this.calculateHeaders(this.store.state.currentCalculationPageData)
  }

  calculateBreadcrumbsByRouter() {
    if (router.currentRoute.value.matched[1]?.meta?.daName) {
      this.currentRouteBreadcrumb = router.currentRoute.value.matched[1].meta.daName as string
    }
    if (router.currentRoute.value.matched[2]?.meta?.daName) {
      this.currentTabBreadcrumb = router.currentRoute.value.matched[2].meta.daName as string
    }
  }

  calculateBreadcrumbsByMenu() {
    if (this.menu.menuItems && this.tabMenu?.menuItems) {
      const routeTemp = this.menu.menuItems.find((menuItem) => this.route.fullPath.indexOf(menuItem.link) > -1)
      this.currentRouteBreadcrumb = routeTemp?.label || ""

      const tabTemp = this.tabMenu?.menuItems.find((menuItem) => this.route.fullPath.indexOf(menuItem.link) > -1)
      this.currentTabBreadcrumb = tabTemp?.label || ""
    }
  }

  async calculateBreadcrumbs() {
    this.calculateBreadcrumbsByRouter()
    this.calculateBreadcrumbsByMenu()
  }

  async calculateHeaders(headers: Array<string>) {
    if (headers[0] === this.currentCalculatorInputBreadcrumb[0]) {
      return
    }
    this.currentCalculatorHeaderStrings = headers
    const temp = []
    for (const header of headers) {
      const id = "calculator-section-" + header //.toUpperCase() // Name and case MUST match accordion id in BaseCalculator
      const el = document.getElementById(id)
      if (el) {
        temp.push(el)
      }
    }
    this.currentCalculatorHeaderElements = temp
    this.updateCurrentCalculatorInputBreadcrumb()
  }

  @Watch("scrollPosition")
  watchScrollPosition() {
    if (this.searchingHeaders && this.currentCalculatorHeaderElements) {
      return
    }
    this.searchingHeaders = true
    // seems to be needed as variables not always updated
    this.calculateBreadcrumbs()
    this.updateCurrentCalculatorInputBreadcrumb();
    this.searchingHeaders = false
  }

  private updateCurrentCalculatorInputBreadcrumb() {
    this.currentCalculatorHeaderElements.forEach((headerEl: HTMLElement, index) => {
      const correspondingFormId: string = headerEl.getAttribute("aria-controls") || ""
      const correspondingFormEl = document.getElementById(correspondingFormId)
      const bottom = correspondingFormEl?.getBoundingClientRect().bottom
      if (bottom) {
        const {top} = headerEl.getBoundingClientRect()
        if (top < window.innerHeight / 3 && bottom > 0) {
          this.currentCalculatorInputBreadcrumb = this.capitalizeFirstLetter(this.currentCalculatorHeaderStrings[index].toLowerCase())
        }
      }
    })
  }

// program to convert first letter of a string to uppercase
  capitalizeFirstLetter(str : string) {
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
    return capitalized;
  }

  @Watch("router")
  watchRoute() {
    this.calculateBreadcrumbs()
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs-container {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  opacity: 1;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: opacity 0.3s linear;
  border-bottom: 1px solid #bfbfbf;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}

.breadcrumbs {
  position: relative;

  &__list {
    max-width: 100vw !important;
    display: flex;
    white-space: nowrap;
  }

  &__list-item {
    &--calculation span {
      min-width: 30ch;
    }

    &--input {
      display: flex;
      flex: 1;
      min-width: 0;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        //text-transform: capitalize;
      }
    }
  }
}
</style>