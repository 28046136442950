<template>
  <SharedHeader :tabMenu="menu">
    <template #firstColumn>
      <h1><SimpleText text-key="thirdparty.has-access-to.headerTitle"/></h1>
      <p><SimpleText text-key="thirdparty.has-access-to.headerText"/></p>
    </template>
  </SharedHeader>

  <div v-if="accesses.some(x=>(x.expireDate>currentDate && !x.revoked) || (!x.expireDate && !x.revoked))" class="container px-0 py-9">
    <h1><SimpleText text-key="thirdparty.has-access-to.headerList"/></h1>
    <table id="id" aria-label="oversigt rådgiver som har adgang" class="table w-percent-100">
      <thead>
      <tr v-if="tableHeaders" class="bg-primary text-color-white">
        <template v-for="tableHeader in tableHeaders">
          <th style="width:5%"
              v-if="tableHeader.sortKey"
              :id="`table-header-${tableHeader.id}-${id}`"
              :key="tableHeader.sortKey"
              class="clickable"
              scope="col"
              @click="sort(tableHeader.sortKey)"
          >
            {{ tableHeader.headline }}
            <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
              <path
                  :fill="currentSort === tableHeader.sortKey && currentSortDirection === 'asc' ? 'white' : ''"
                  clip-rule="evenodd"
                  d="M9.99419 4.75003L6.00003 0.755859L2.00586 4.75003H9.99419Z"
                  fill-rule="evenodd"
                  stroke="white"
              />
              <path
                  :fill="currentSort === tableHeader.sortKey && currentSortDirection === 'desc' ? 'white' : ''"
                  clip-rule="evenodd"
                  d="M2.00586 7.25003L6.00003 11.2442L9.99419 7.25003H2.00586Z"
                  fill-rule="evenodd"
                  stroke="white"
              />
            </svg>
          </th>
          <th
              v-if="!tableHeader.sortKey"
              :id="`table-header-${tableHeader.id}-${id}`"
              :key="tableHeader.id"
              class="clickable"
              scope="col"
          >
            {{ tableHeader.headline }}
          </th>
        </template>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="acc in sortedAccesses.filter(x=>(x.expireDate>currentDate && !x.revoked) || (!x.expireDate && !x.revoked))" :key="acc.id">
        <td data-title="Cvr" style="width: 10%">
          {{ acc.vatId }}
        </td>
        <td data-title="CompanyName" style="width: 25%">
          {{ acc.companyName }}
          <div v-if="hasPendingRequestAccess(acc)" id="access-request-notification-wrapper" class="flex-column">
            <div class="access-actions-container clickable-container" @click="accessRequestModal(acc)">
              <div :class="{ 'icon-svg': !iconToggle, 'icon-svg-2': iconToggle }">
                <svg class="icon-svg" focusable="false" aria-hidden="true">
                  <use xlink:href="#reply" ></use>
                </svg>
              </div>
              <div class="access-action-pending">
                {{ this.getContentfulString('thirdparty.step2.notification.access-actions-pending') }}
              </div>
            </div>
          </div>
        </td>
        <td style="width: 25%; word-break: break-word">
          {{ acc.contantPersonName }} <br> {{ acc.contactPersonEmail }}
        </td>
        <td v-if="acc.expireDate"
            data-title="EndData"
            style="width: 10%"
            v-html="formatDate(acc.expireDate)"
        />
        <td v-else
            data-title="EndData"
            style="width: 10%"
        />
        <td data-title="Calculations" style="width: 35%">
          <template v-if="hasDraft(acc)">
          <strong><SimpleText text-key="thirdparty.has-access-to.draft"/></strong> <br>
          </template>
          <div v-for="calc in acc.calculationInfoList" :key="calc.calculationId">
            <span v-if="calc.isKladde" class="tab">{{ `${calc.calculationName}` }}</span>
          </div>
          <strong v-if="hasCompleted(acc)"><SimpleText text-key="thirdparty.has-access-to.completed"/></strong> <br>
          <div v-for="calc in acc.calculationInfoList" :key="calc.calculationId">
            <span v-if="!calc.isKladde" class="tab">{{ `${calc.calculationName}` }}</span>
          </div>
          <!-- Kan indkommenteres for nemmere test af afvisning / anmodning.
          MEDARBEJDERS ADGANG
          <div v-for="individual in acc.individualsAccessInfoList" :key="individual.id">
            <div v-if="individual.status?.toString() !== 'ACCESS_DELETED'">
              {{ `${individual.name}` }}&nbsp;
              <div v-if="individual.status?.toString() === 'ACCESS_REVOKED'">3. part har afvist anmodning</div>
              <button v-if="individual.status?.toString() === 'ACCESS_PENDING'" @click="grantIndividualAccess(individual.id)">Giv adgang</button>
              <button v-if="individual.status?.toString() !== 'ACCESS_PENDING'" @click="deleteIndividualAccess(individual.id)">Fjern adgang</button>
              <button v-if="individual.status?.toString() !== 'ACCESS_REVOKED'" @click="rejectIndividualAccess(individual.id)">Afvis anmodning</button>
            </div>
          </div>-->
        </td>
        <td data-title="Handlinger">
          <div class="overflow-menu overflow-menu--open-left">
            <button
                :class="`overflow-menu-table`"
                :data-js-target="`access-${acc.id}-actions-overflow`"
                aria-expanded="false"
                aria-haspopup="true"
                aria-label="handlinger"
                class="button-overflow-menu js-dropdown "
            >
              <svg aria-hidden="true" class="icon-svg" fill="white" focusable="false">
                <use xlink:href="#more-vert"></use>
              </svg>
            </button>
            <div :id="`access-${acc.id}-actions-overflow`" aria-hidden="true" class="overflow-menu-inner">
              <!-- List of possible events to apply to a row -->
              <ul class="overflow-list">
                <li>
                  <router-link :class="hasPendingRequestAccess(acc) ? 'request-pending edit-button' : 'edit-button'" to="" @click="accessRequestModal(acc)">
                    <SimpleText text-key="thirdparty.has-access-to.access-request-modal-menu-button"/>
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#how-to-reg"></use>
                    </svg>
                  </router-link>
                </li>
                <li>
                  <router-link class="edit-button" to="/setup/access/edit-access" @click="editAccess(acc)">
                    <SimpleText text-key="thirdparty.has-access-to.edit"/>
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#mode"></use>
                    </svg>
                  </router-link>
                </li>
                <li>
                  <button class="delete-button" @click="confirmDeleteAccess(acc)">
                    <SimpleText text-key="thirdparty.has-access-to.delete"/>
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#trash-can"></use>
                    </svg>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <br>
    <router-link data-cy-id="opret-adgang" class="button button-primary mr-4" to="/setup/access/create-access" @click="createAccess">
      <SimpleText text-key="thirdparty.has-access-to.titleAdd"/>
    </router-link>
  </div>
  <div id="no-calculation-added-message" v-else class="container px-0 py-9">
    <p><SimpleText text-key="thirdparty.has-access-to.noCalculationsAdded"/> </p>
    <router-link data-cy-id="opret-adgang" class="button button-primary mr-4" to="/setup/access/create-access" @click="createAccess">
      <SimpleText text-key="thirdparty.has-access-to.titleAdd"/>
    </router-link>
  </div>

  <!-- Following table filters and shows expired access only  -->

  <div v-if="accesses.some(x=>x.expireDate<=currentDate || x.revoked)" class="container px-0 py-9">
    <h1><SimpleText text-key="thirdparty.has-access-to.expiredAccessTableHeader"/></h1>
    <table id="expired-table" aria-label="oversigt rådgiver som har adgang" class="table w-percent-100">
      <thead>
      <tr v-if="tableHeaders" class="bg-primary text-color-white">
        <template v-for="tableHeader in tableHeaders">
          <th style="width:5%"
              v-if="tableHeader.sortKey"
              :id="`table-header-${tableHeader.id}-${id}`"
              :key="tableHeader.sortKey"
              class="clickable"
              scope="col"
              @click="sort(tableHeader.sortKey)"
          >
            {{ tableHeader.headline }}
            <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
              <path
                  :fill="currentSort === tableHeader.sortKey && currentSortDirection === 'asc' ? 'white' : ''"
                  clip-rule="evenodd"
                  d="M9.99419 4.75003L6.00003 0.755859L2.00586 4.75003H9.99419Z"
                  fill-rule="evenodd"
                  stroke="white"
              />
              <path
                  :fill="currentSort === tableHeader.sortKey && currentSortDirection === 'desc' ? 'white' : ''"
                  clip-rule="evenodd"
                  d="M2.00586 7.25003L6.00003 11.2442L9.99419 7.25003H2.00586Z"
                  fill-rule="evenodd"
                  stroke="white"
              />
            </svg>
          </th>
          <th
              v-if="!tableHeader.sortKey"
              :id="`table-header-${tableHeader.id}-${id}`"
              :key="tableHeader.id"
              class="clickable"
              scope="col"
          >
            {{ tableHeader.headline }}
          </th>
        </template>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="acc in sortedAccesses.filter(x=>x.expireDate<=currentDate || x.revoked)" :key="acc.id">
        <td data-title="Cvr" style="width: 10%">
          {{ acc.vatId }}
        </td>
        <td data-title="CompanyName" style="width: 25%">
          {{ acc.companyName }}
          <p><strong>{{ acc.revoked ? this.thirdPartyRequestRejected + ' ' + formatDate(acc.revokedDate) : ''}}</strong></p>
        </td>
        <td style="width: 25%; word-break: break-word">
          {{ acc.contantPersonName }} <br> {{ acc.contactPersonEmail }}
        </td>
        <td v-if="acc.expireDate"
            data-title="EndData"
            style="width: 10%">

          {{ formatDate(acc.expireDate) }}
          <br/>
          <span v-if="acc.expireDate<=currentDate" class="badge badge-small"><SimpleText text-key="thirdparty.has-access-to.expiredTag"/></span>
          <span v-if="acc.revoked" class="badge badge-small"><SimpleText text-key="thirdparty.has-access-to.rejectedTag"/></span>
        </td>
        <td v-else
            data-title="EndData"
            style="width: 10%"
        >
          <span v-if="acc.revoked" class="badge badge-small"><SimpleText text-key="thirdparty.has-access-to.rejectedTag"/></span>
        </td>
        <td data-title="Calculations" style="width: 35%">
          <template v-if="hasDraft(acc)">
            <strong><SimpleText text-key="thirdparty.has-access-to.draft"/></strong> <br>
          </template>
          <div v-for="calc in acc.calculationInfoList" :key="calc.calculationId">
            <span v-if="calc.isKladde" class="tab">{{ `${calc.calculationName}` }}</span>
          </div>
          <strong v-if="hasCompleted(acc)"><SimpleText text-key="thirdparty.has-access-to.completed"/></strong> <br>
          <div v-for="calc in acc.calculationInfoList" :key="calc.calculationId">
            <span v-if="!calc.isKladde" class="tab">{{ `${calc.calculationName}` }}</span>
          </div>
        </td>
        <td data-title="Handlinger">
          <div class="overflow-menu overflow-menu--open-left">
            <button
                :class="`overflow-menu-table`"
                :data-js-target="`access-${acc.id}-actions-overflow`"
                aria-expanded="false"
                aria-haspopup="true"
                aria-label="handlinger"
                class="button-overflow-menu js-dropdown "
            >
              <svg aria-hidden="true" class="icon-svg" fill="white" focusable="false">
                <use xlink:href="#more-vert"></use>
              </svg>
            </button>
            <div :id="`access-${acc.id}-actions-overflow`" aria-hidden="true" class="overflow-menu-inner">
              <!-- List of possible events to apply to a row -->
              <ul class="overflow-list">
                <li v-if="!acc.revoked">
                  <router-link class="edit-button" to="/setup/access/edit-access" @click="editAccess(acc)">
                    <SimpleText text-key="thirdparty.has-access-to.edit"/>
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#mode"></use>
                    </svg>
                  </router-link>
                </li>
                <li>
                  <button class="delete-button" @click="confirmDeleteAccess(acc)">
                    <SimpleText text-key="thirdparty.has-access-to.delete"/>
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#trash-can"></use>
                    </svg>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <br>

  </div>

  <!-- Delete confirmation modal START-->
  <template v-if="accessToDelete.id && accessToDelete.id > 0">
    <AcceptModal
        :value-object="accessToDelete"
        :title-key="'thirdparty.has-access-to.delete-modal.title'"
        :buttons-disabled="disableDeleteModalButtons"
        :button-ok-text-key="'thirdparty.has-access-to.delete-modal.button.ok'"
        :button-reject-text-key="'thirdparty.has-access-to.delete-modal.button.cancel'"
        @select-ok="deleteAccess($event)"
        @select-reject="resetAccessToDelete()"
    >
      <div class="modal-body">
        <p>
          <strong><SimpleText :text-key="'thirdparty.has-access-to.delete-modal.text1'"/></strong>
          <br><br>{{ accessToDelete.companyName }} (CVR={{ accessToDelete.vatId }})<br><br>
          <SimpleText :text-key="'thirdparty.has-access-to.delete-modal.text2'"/>
        </p>
      </div>
    </AcceptModal>
  </template>
  <!-- Delete confirmation modal END-->

  <template v-if="showHandleAccessRequestModal">
    <div aria-hidden="false" class="fds-modal">
      <div class="modal-content" style="max-width: 1000px">
        <div class="modal-header">
          <div id="modal-description-wrapper">
            <h2 class="modal-title" id="access-request-modal-heading">
              <SimpleText text-key="thirdparty.access.handle-access-request-heading"/>
            </h2>
          <p id="access-request-modal-description" v-html="documentToHtmlString(changeAccessRequestModalDescription)"></p>
          </div>
          <button aria-label="Luk" class="modal-close button button-unstyled" @click="cancelHandleAccessRequest()">
            <svg aria-hidden="true" class="icon-svg" focusable="false">
              <use xlink:href="#close"></use>
            </svg>
          </button>
        </div>

        <!-- Individual Access Mangement Modal START-->
        <div class="modal-body">
          <h3>
            <simple-text text-key="thirdparty.access.handle-access-modal-header"></simple-text>
          </h3>
          <div
              v-for="(access, index) in sortedAccesses.filter(x => (x.expireDate > currentDate && !x.revoked) || (!x.expireDate && !x.revoked))"
              :key="access.id">
            <div class="row change-access-item pt-3"
                 :style="{'border-bottom': index !== accessInfos.length - 1 ? '1px solid #ccc' : 'none' }">
              <div class="col-10 form-group-acceses">
                <div class="button-unstyled" @click="toggleAccordion(index)">
                  <span v-if="activeAccordion === index" class="icon-container"><i class="icon icon-minus"></i></span>
                  <span v-else class="icon-container"><i class="icon icon-plus"></i></span>
                  <h4 class="access-heading mb-4"> {{ access.companyName }} | {{ this.getContentfulString('thirdparty.access-request-modal-cvr.text') + '' }} {{ access.vatId }} </h4>
                </div>
              </div>

              <div v-show="activeAccordion === index" class="individuals-container">
                <div class="row-6 full-width pb-4">
                  <div class="individual-item" v-for="individual in access.individualsAccessInfoList"
                       :key="individual.id" :id="'individual-access-request-' + individual.id"
                       :data-cy-id="individual.id">
                    <div v-if="individual.status?.toString() !== 'ACCESS_DELETED'">
                      <p v-if="individual.status?.toString() === 'ACCESS_PENDING' && individual.status?.toString() !== 'ACCESS_REVOKED'">
                        <strong> {{ individual.name }} </strong> <br> {{ this.getContentfulString('thirdparty.access-request-modal.individual-request.text') + '' }}
                        <strong> {{ access.companyName }} </strong>
                      </p>
                      <p v-else-if="individual.status?.toString() === 'ACCESS_GRANTED'">
                        <strong> {{ individual.name }} </strong> <br> {{ this.getContentfulString('thirdparty.access-request-modal.access-active.text') }}
                      </p>
                      <p v-else-if="individual.status?.toString() === 'ACCESS_REJECTED'">
                        <strong>{{ individual.name }} </strong> <br> {{ this.getContentfulString('thirdparty.access-request-modal.access-rejected.text') }}
                      </p>
                      <p v-else-if="individual.status?.toString() === 'ACCESS_REVOKED'">
                        <strong> {{ individual.name }} </strong> <br> {{ this.getContentfulString('thirdparty.access-request-modal.access-revoked.text') }}
                      </p>
                      <!-- giv adgang -->
                      <button v-if="individual.status?.toString() === 'ACCESS_PENDING'" class="button button-primary"
                              @click="grantIndividualAccess(individual.id)"> {{ this.getContentfulString('menu.setupmenu.item.access') }}
                      </button>
                      <!-- fjern adgang -->
                      <button class="button button-secondary"
                              @click="deleteIndividualAccess(individual.id)"> {{ this.getContentfulString('access.remove-access') }}
                      </button>
                      <button
                          v-if="individual.status?.toString() !== 'ACCESS_REVOKED' && individual.status?.toString() === 'ACCESS_PENDING'"
                          class="button button-secondary" @click="rejectIndividualAccess(individual.id)"> {{ this.getContentfulString('thirdparty.access-request-modal.reject-button.text') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Individual Access Mangement Modal END -->
        <div class="modal-footer">
          <button class="button button-secondary" data-cy-id="modal-cancel" @click="cancelHandleAccessRequest()">
            <SimpleText text-key="thirdparty.access-request-modal.finish-button"/>
          </button>
        </div>
      </div>
    </div>
    <div id="modal-backdrop" class="modal-backdrop"/>
  </template>
</template>

<script lang="ts">
import {useRouter} from "vue-router"
import {Options} from "vue-class-component"
import Navigation from "@/components/thirdparty/Navigation.vue"
import SharedHeader from "@/components/calculator/shared/SharedHeader.vue";
import {Menu} from "@/shared/MenuStructure"
import TableHeader from "@/types/TableHeader"
import * as DKFDS from "dkfds"
import {AccessIndividualInfo, AccessInfo} from "openapi/generated-clients/climatecompass"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import AcceptModal from "@/components/AcceptModal.vue";
import SimpleText from "@/components/SimpleText.vue";
import ToastMessage from "@/types/ToastMessage";
import {accessClient} from "@/shared/BackendService";
import {indexOf} from "lodash";



@Options({
  components: {
    Navigation,
    SharedHeader,
    AcceptModal,
    SimpleText
  },
  emits: ["activate-access-request-modal, deactivate-access-request-modal"]
})

export default class HasAccessTo extends BaseComponent {
  menu = {} as Menu
  router = useRouter()
  tableHeaders = [] as TableHeader[]
  accessToDelete: AccessInfo = {} as AccessInfo
  disableDeleteModalButtons = false
  currentSort = "cvr"
  currentSortDirection = "asc"
  accessInfos: AccessInfo[] = []
  id = "has-access-to-table"
  iconToggle = false
  showHandleAccessRequestModal = false
  activeAccordion = 0


  async beforeMount() {
    this.menu = this.store.getters.getMenuStructure.accessTabs
    this.tableHeaders = [
      {headline: this.labelThCvr, sortKey: "cvr", id: "cvr"},
      {headline: this.labelThCompanyname, sortKey: "companyName", id: "companyName"},
      {headline: this.labelThContactinfo, sortKey: "contactInfo", id: "contactInfo"},
      {headline: this.labelThEnddate, sortKey: "endDate", id: "period"},
      {headline: this.labelThCalculations, sortKey: "", id: "calculations"}
    ]
    this.getAccessFromDb()
  }

  mounted() {
    DKFDS.init()
  }

  updated() {
    DKFDS.init()
  }

  toggleAccordion(index: any) {
    this.activeAccordion = this.activeAccordion === index ? 0 : index;
  }


  accessRequestModal(acc : AccessInfo) {
    const indexToToggle = this.sortedAccesses.indexOf(acc)? this.sortedAccesses.indexOf(acc) : 0;
    console.log('indexToToggle = ' + indexToToggle)

    this.iconToggle = !this.iconToggle

    if(this.iconToggle) {
      this.toggleAccordion(indexToToggle)
      this.initHandleAccessRequest()
    } else if(!this.iconToggle) {
      this.cancelHandleAccessRequest()
      this.toggleAccordion(0)
    }
  }

  grantIndividualAccess(individualId: number | undefined) {
    if (individualId) {
      accessClient.grantIndividualAccess(individualId)
      this.getAccessFromDb()
    }
  }

  deleteIndividualAccess(individualId: number | undefined) {
    if (individualId) {
      accessClient.deleteIndividualAccess(individualId)
      this.getAccessFromDb()
    }
  }

  rejectIndividualAccess(individualId: number | undefined) {
    if (individualId) {
      accessClient.rejectIndividualAccess(individualId)
      this.getAccessFromDb()
    }
  }

  hasPendingRequestAccess(access : AccessInfo) {
    if (access && access.individualsAccessInfoList) {
      for (const individualAccessInfo of access.individualsAccessInfoList) {
        if (individualAccessInfo.status === 'ACCESS_PENDING') {
          return true;
        }
      }
    }
    return false;
  }

  handleAccessRequest() {
    const accessRadios = document.querySelectorAll<HTMLInputElement>('input[name="access-request-group"]')
    accessRadios.forEach(radio => {
      const access = this.accessInfos.find(x => x.id! === Number(radio.id.replace('radiogroup-access-request', '')))
      if(radio.checked && access) {
        return
      }
    })

    this.showHandleAccessRequestModal = false
  }

  initHandleAccessRequest() {
    this.showHandleAccessRequestModal = true
  }
  cancelHandleAccessRequest() {
    this.showHandleAccessRequestModal = false
    this.iconToggle = false
    this.toggleAccordion(undefined)
  }

  get changeAccessRequestModalDescription() {
    return this.store.getters.getContentfulContent.findRichText('thirdparty.access.handle-access-modal-content')
  }


  get edit() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.edit')
  }

  get thirdPartyRequestRejected() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.thirdPartyRequestRejected')
  }

  get labelThCvr() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.labelThCvr')
  }

  get labelThCompanyname() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.labelThCompanyname')
  }

  get labelThContactinfo() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.labelThContactinfo')
  }

  get labelThEnddate() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.labelThEnddate')
  }

  get labelThCalculations() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.labelThCalculations')
  }

  getAccessFromDb() {
    this.store.dispatch("loadAccesses").finally(() => {
      this.eventHub.emit("stop-loading", "HasAccessTo")
    })
    this.accessInfos = this.store.getters.getAccesses
  }

  get accesses() {
    this.accessInfos = this.store.getters.getAccesses
    return this.accessInfos
  }

  async editAccess(access: AccessInfo) {
    await this.store.dispatch("setCurrentAccess", access).then(async () => {
      await this.router.push("/setup/access/edit-access")
    })
  }

  async createAccess() {
    await this.router.push("/setup/access/create-access")
  }

  createAccessLink() {
    this.router.push("/setup/access/create-access")
  }

  confirmDeleteAccess(access: AccessInfo) {
    this.accessToDelete = access
  }

  async deleteAccess(access?: AccessInfo) {
    if (!access) {
      return
    }
    this.disableDeleteModalButtons = true
    this.eventHub.emit("start-loading", "HasAccessTo")
    await this.store.dispatch("deleteAccess", access)
    await this.store.dispatch("loadAccesses").finally(() => {
      this.eventHub.emit("stop-loading", "HasAccessTo")
      this.disableDeleteModalButtons = false
    })
    this.resetAccessToDelete()
  }

  resetAccessToDelete() {
    this.accessToDelete = {} as AccessInfo
  }

  hasDraft(acc: AccessInfo) {
    if (acc.calculationInfoList) {
      for (const c of acc.calculationInfoList) {
        if (c.isKladde)
          return true
      }
    }
    return false
  }

  hasCompleted(acc: AccessInfo) {
    if (acc.calculationInfoList) {
      for (const calc of acc.calculationInfoList) {
        if (!calc.isKladde)
          return true
      }
    }
    return false
  }

  get currentDate() {
    return new Date().toISOString().substr(0, 10)
  }

  get sortedAccesses() {
    return [...this.accessInfos].sort(this.compare)
  }

  compare(a: AccessInfo, b: AccessInfo) {
    const locale =  this.store.getters.getLocale
    let sortOrderModifier = 1
    if (this.currentSortDirection === "desc") {
      sortOrderModifier = -1
    }
    if (this.currentSort === "cvr") {
      if (a.vatId && b.vatId) {
        if (a.vatId < b.vatId) {
          return 1 * sortOrderModifier
        }
        if (a.vatId > b.vatId) {
          return -1 * sortOrderModifier
        }
      }
      return 0
    }
    if (this.currentSort === "companyName") {
      if (a.companyName && b.companyName) {
        return a.companyName.localeCompare(b.companyName, locale) * sortOrderModifier
      }
      return 0
    }
    if (this.currentSort === "contactInfo") {
      if (a.contantPersonName && b.contantPersonName) {
        return a.contantPersonName.localeCompare(b.contantPersonName, locale) * sortOrderModifier
      }
      return 0
    }
    if (this.currentSort === "endDate") {
      if (a.expireDate && b.expireDate) {
        if (a.expireDate < b.expireDate) {
          return 1 * sortOrderModifier
        }
        if (a.expireDate > b.expireDate) {
          return -1 * sortOrderModifier
        }
      }
      return 0
    }
    return 0
  }

  sort(sortBy: string) {
    if (sortBy === this.currentSort) {
      this.currentSortDirection = this.currentSortDirection === "asc" ? "desc" : "asc"
    }
    this.currentSort = sortBy
  }

}
</script>

<style lang="scss" scoped>

.tab {
  margin-left: 20px;
}

.clickable {
  cursor: pointer;
}

.table {
  border: 2px solid $color-primary;
}

.table th {
  border-right-color: white;
}

.table th,
.table td {
  border-bottom: 1px solid $color-primary;
}

.table th:nth-last-child(2),
.table td:nth-last-child(2) {
  border-right: none;
}

.table th:last-child,
.table td:last-child {
  color: white;
  background-color: $color-primary;
  border-right: none;
  border-bottom: 1px solid white;
  width: 1rem;
  padding: 8px 0;
}

.overflow-menu .button-overflow-menu {
  min-width: 0;
  min-height: 0;
  padding: 0;
}

.location .button-overflow-menu {
  text-decoration: underline;
}

.location .button-overflow-menu:focus {
  font-weight: bold;
}

.overflow-menu-inner li {
  text-align: left;
  padding: 0.25rem 1rem;
}

.overflow-menu-inner {
  overflow-y: auto;
  max-height: 40rem;
}

.disabled {
  opacity: 0.3;
}

.access-actions-container {
  display: flex;
  align-items: self-start;
  width: 80%;
}

.button-access-request {
  display: flex;
  align-items: self-start;
  justify-content: left;
  border: 0;
}

.clickable-container {
  cursor: pointer;
  transition: background-size 0.3s ease, border-radius 0.3s ease;

  &:hover {
    background-size: 200% 100%; /* Double the width in order to fill half */
    background-clip: content-box;
    border-radius: 4px;
    /* Start filling from the right */
    background: linear-gradient(to right, #f5ac00 85%, transparent 15%) right;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}

.icon-svg-2 {
  float: right;
  margin: 0;
  fill: $color-primary;
}

.icon.icon-plus,
.icon.icon-minus {
  margin-bottom: 1rem;
}

.button-unstyled {
  display: flex;
  align-items: center;
}

.icon-container {
  margin-right: 5px;
}

.access-heading {
  margin: 0;
  padding: 0;
}

.individual-item {
  margin-bottom: 10px;
}

.individuals-container {
  margin-left: 7rem;
}

.request-pending {
  background-color: #f8e5be !important;
  border-left: 1rem solid #febb30 !important;
}

.overflow-list li {
  //to ensure icons are aligned with menu item text.
  display: flex;
  align-items: center;
}

.overflow-list li router-link {
  flex-grow: 1;
}
.overflow-menu-inner .overflow-list {
  padding: 8px 16px;
}

.overflow-list li .icon-svg {
 align-items: end;
}

</style>

