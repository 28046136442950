import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-008ee684"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["aria-controls", "innerHTML"]
const _hoisted_3 = ["id"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "accordion-image"
}
const _hoisted_6 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentAccordions, (item) => {
    return (_openBlock(), _createElementBlock("ul", {
      key: item.title,
      class: "accordion"
    }, [
      _createElementVNode("li", null, [
        _createElementVNode("h3", {
          class: "d-print-only",
          innerHTML: item.title
        }, null, 8, _hoisted_1),
        _createElementVNode("button", {
          "aria-controls": item.title.replaceAll(" ", "_") + _ctx.uniqueId ?? "",
          class: "accordion-button d-print-none",
          innerHTML: item.title
        }, null, 8, _hoisted_2),
        _createElementVNode("div", {
          id: item.title.replaceAll(" ", "_") + _ctx.uniqueId ?? "",
          "aria-hidden": "true",
          class: "accordion-content d-print-block"
        }, [
          _createElementVNode("p", {
            innerHTML: _ctx.documentToHtmlString(item.description)
          }, null, 8, _hoisted_4),
          (item.url)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: item.url,
                  alt: item.description + ' image link'
                }, null, 8, _hoisted_6)
              ]))
            : _createCommentVNode("", true)
        ], 8, _hoisted_3)
      ])
    ]))
  }), 128))
}