<template>
  <SharedHeader>
    <template #firstColumn>
      <h1>
        <SimpleText text-key="forecast.calculate-forecast.title"/>
      </h1>
      <span><RichText text-key="forecast.calculate-forecast.header-text"/></span>
    </template>
    <template #secondColumn>
      <div class="flex flex-lg-row flex-column result-buttons column-row-gap-10">
        <div class="result-buttons__button-group flex">
          <a
              id="result-export-excel-link"
              href="javascript:void(0)"
              class="button button-secondary"
              @click="exportToExcel"
              :data-cy-id="'klimakompasset-resultat-' + forecast.forcastHeader?.forecastId"
          >
            <svg aria-hidden="true" class="icon-svg" focusable="false">
              <use xlink:href="#file-download"></use>
            </svg>
            <SimpleText text-key='forecast.reduction-target.button.download'/>
          </a>
          <Tooltip :style="`vertical-align: middle;`" :textKey="'forecast.download-excel-tooltip'" size="lg"/>
        </div>

        <div class='result-buttons__button-group'>
          <a
              id='download_pdf_button'
              class="button button-secondary flex-grow-equally"
              role="button"
              target="_blank"
              onclick='window.print()'
          >
            <svg aria-hidden="true" class="icon-svg" focusable="false">
              <use xlink:href="#file-download"></use>
            </svg>
            <SimpleText text-key='forecast.reduction-target.button.pdf'/>
          </a>
        </div>
      </div>
    </template>
  </SharedHeader>

  <div class="container px-0 py-9">
    <h2>
      <SimpleText text-key="forecast.calculate-forecast.sub-title"/>
    </h2>

    <div class="py-1">
      <RichText text-key="forecast.calculate-forecast.sub-header-text"/>
    </div>

    <div
        v-if="formValidation.formErrors.size"
        aria-atomic="true"
        class="alert alert-error"
        ref="formErrorsAlert"
        role="alert"
    >
      <div class="alert-body">
        <p class="alert-heading">
          <SimpleText text-key="generic.input.error.title"/>
        </p>
        <ul class="alert-text">
          <li v-for="[key, value] of formValidation.formErrors" :key="key" :data-cy="'error_' + key">
            <a href="javascript:void(0)" @click.prevent="focusInput(key)" style="text-decoration: none">
              {{ value }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="py-3">
      <form ref="formGroup">
        <div class="py-4 row form-group">


          <div class="col-md-3 form-group" id="choose-calculation-div" style=" margin-top: 24px;">
            <label class="form-label" for="input-choose-calculation">
              <SimpleText text-key="forecast.calculate-forecast.field.choose-calculation.name"/>
            </label><span class="ml-2"><Tooltip :text-key="'forecast.calculate-forecast.field.choose-calculation.tooltip'"/></span>
            <select
                aria-labelledby="choose-calculation-div"
                class=" form-select"
                name="options"
                id="input-choose-calculation"
                v-model="selectedCalculationId"
                v-if="!editExistingForecast"
                @focusout="watchCalculationId()"
            >
              <option disabled selected value="">
                <SimpleText text-key="generic.input.dropdown.option-select.name"/>
              </option>
              <option v-for="calculation in [...possibleCalculations.sort((a, b) => a.calculationPurpose.name.localeCompare(b.calculationPurpose.name))]"
                      :key="calculation.calculationPurpose.name" :value="calculation.id" :id="calculation.calculationPurpose.name">
                {{ calculation.calculationPurpose.name }}
              </option>
            </select>
            <input class="form-input" v-else type="text" :value="calculationName" readonly aria-readonly="true" aria-labelledby="choose-calculation-div"/>
          </div>

          <div class="col-md-2 form-group" id="yearly-growth-rate-div">
            <label class="form-label " for="input-yearly-growth-rate">
              <SimpleText text-key="forecast.calculate-forecast.field.yearly-growth-rate.name"/>
            </label>
            <div class="form-input-wrapper form-input-wrapper--suffix">
              <input
                  @focusout="watchYearlyGrowthRate()"
                  @keydown="validateLocalizedNumberInput($event, 'DECIMAL')"
                  id="input-yearly-growth-rate"
                  v-model="yearlyGrowthRate"
                  :data-error="yearlyGrowthRateError"
                  class="form-input"
                  name="input-yearly-growth-rate"
                  :pattern="findRegexPattern('regex.validation.nummeric.signed.max-3-digits.max-2-decimals')"
                  size="9"
                  required
              />
              <div class="form-input-suffix" aria-hidden="true">%</div>
            </div>

          </div>

          <div class="col-md-2 form-group">
            <label class="form-label " for="input-type-growth-rate">
              <SimpleText text-key="forecast.calculate-forecast.field.type-growth-rate.name"/>
            </label>
            <select class=" form-select" name="options" id="input-type-growth-rate" v-model="typeGrowthRate">
              <option :value="enumExponential">
                <SimpleText text-key="forecast.calculate-forecast.field.type-growth-rate.option.exponential.name"/>
              </option>
              <option :value="enumLinear">
                <SimpleText text-key="forecast.calculate-forecast.field.type-growth-rate.option.linear.name"/>
              </option>
            </select>
          </div>

          <div class="col-md-3 form-group" id="forecast-name-div">
            <label class="form-label " for="input-forecast-name">
              <SimpleText text-key="forecast.calculate-forecast.field.forecast-name.name"/>
            </label>
            <input
                id="input-forecast-name"
                v-model="forecastName"
                :data-error="forecastNameError"
                class="form-input"
                name="input-forecast-name"
                size="50"
                maxlength="50"
                minlength="2"
                required
                type="text"
                @focusout="watchInputForecastName()"
            />
          </div>
          <div class="col-md-2 form-group">
            <div v-if="isUpdateForecastAllowed()">
              <button id="submit-button-forecast" style="margin-top: 31px" @click.prevent="submitForm" :disabled="isLoadingPartially" class="button button-primary adjust-button-size" role="button"
                      :name="getContentfulString('forecast.calculate-forecast.button.submit.name')">
                <SimpleText :text-key="'forecast.calculate-forecast.button.submit.name'"/>
              </button>
            </div>
            <div v-else>
              <Tooltip :textKey="'forecast.ec-version-not-up-to-date'"/>
              <button style="margin-top: 8px" id="submit-button-forecast" disabled="true" class="disabled no-pointer-events button button-primary adjust-button-size" role="button"
                      :name="getContentfulString('forecast.calculate-forecast.button.submit.name')">
                <SimpleText :text-key="'forecast.calculate-forecast.button.submit.name'"/>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="w-percent-100" v-if="forecast.forcastHeader">

      <CypressTestElement id="forecast-result-data-helper" :state="dataRenderState" :inner="dataContentSize"></CypressTestElement>
      <h2 id="forecast-result-header" style="margin-top: 50px;">
        <SimpleText text-key="forecast.result.header"/>
      </h2>
      <span id="forecast-result-main-text"><RichText text-key="forecast.result.main-text"/></span>

      <div
          aria-atomic="true"
          class="alert alert-info"
          role="alert"
          style="max-width: 100%; display: flex;"
          id="info-box-wrapper-div"
      >
        <div style="flex: 0 0 3%;">
          <svg aria-hidden="true" class="info-icon-size" focusable="false">
            <use xlink:href="#info"></use>
          </svg>
        </div>
        <div style="flex: 1;">
         <p>
          <strong>
            <SimpleText textKey='calculate-forecast.important-header'/>
          </strong>
         </p>
          <span> <RichText text-key='calculate-forecast.important-message2'/> </span>
        </div>
      </div>

      <SharedHeader v-if="forecast.forcastHeader" :tabMenu="menu" :additional-css-class="'forecast-tabs'" :additional-background-css-class="'bg-alternative-green'"
                    :headerPadding="'py-4'" use-one-column="true">
        <template #firstColumn>
          <div data-cy-id="forecast-result-summary" v-if="partialLoadingHasData || isCompleted" style="padding-left: 65px;" v-html="resultHeadlineSummary"></div>
          <div v-else-if="partialLoadingHasFailed" style="padding-left: 65px">
            <SimpleText text-key="forecast.resultview.errorcalculatingforecast.messageshort"></SimpleText>
          </div>
          <div v-else-if="isLoadingPartially" style="padding-left: 65px;">
            <SimpleText text-key="forecast.calculate-forecast.headline.pendingload"></SimpleText>
          </div>
        </template>
      </SharedHeader>

      <div class="pt-6" id="forecast-result-section">
        <router-view :forecast="forecast"/>
        <div v-if="partialLoadingHasData || isCompleted">
          <div class="container px-0 d-print-pagebreak">

            <h3>
              <SimpleText :text-key="'forecast.resultview.scope1chart.header'"></SimpleText>
            </h3>
            <div data-cy-id="forecast-result-summary-scope1" class="bg-alternative-green">
              <div style="padding: 20px;" v-html="resultHeadlineSummaryScope1"></div>
            </div>
            <h4>
              <SimpleText text-key="forecast.resultview.chartheader"/>
            </h4>
            <ForecastColumnChart ref="scope1ColumnChart" :width="'66%'" id="scope1-column-chart"
                                 :raw-data="forecast.calculations?.sort((a, b) => a.year - b.year)"
                                 :settings="constants.FORECAST_FILTER_STATICSCOPE + constants.FORECAST_FILTER_SCOPE1"></ForecastColumnChart>
          </div>
          <div class="container px-0 d-print-pagebreak">

            <h3>
              <SimpleText :text-key="'forecast.resultview.scope2chart.header'"></SimpleText>
            </h3>

            <div
                v-if="!this.calculationForecasted.electricityEnvironmentDeclaration"
                aria-atomic="true"
                class="alert alert-info"
                role="alert"
                style="max-width: 100%; display: flex;"
                id="info-box-declaration-wrapper-div"
            >
              <div style="flex: 0 0 3%;">
                <svg aria-hidden="true" class="info-icon-size" focusable="false">
                  <use xlink:href="#info"></use>
                </svg>
              </div>
              <div style="flex: 1;">
                <p>
                  <strong>
                    <SimpleText textKey='calculate-forecast.declaration-info.header'/>
                  </strong>
                </p>
                <span> <SimpleText textKey='calculate-forecast.declaration-electricity.message'/> </span>
              </div>
            </div>

            <div
                v-else-if="this.calculationForecasted.electricityEnvironmentDeclaration"
                aria-atomic="true"
                class="alert alert-info"
                role="alert"
                style="max-width: 100%; display: flex;"
                id="info-box-declaration2-wrapper-div"
            >
              <div style="flex: 0 0 3%;">
                <svg aria-hidden="true" class="info-icon-size" focusable="false">
                  <use xlink:href="#info"></use>
                </svg>
              </div>
              <div style="flex: 1;">
                <p>
                  <strong>
                    <SimpleText textKey='calculate-forecast.declaration-info.header'/>
                  </strong>
                </p>
                <span> <SimpleText textKey='calculate-forecast.declaration-environment.message'/> </span>
              </div>
            </div>

            <div data-cy-id="forecast-result-summary-scope2" class="bg-alternative-green">
              <div style="padding: 20px;" v-html="resultHeadlineSummaryScope2"></div>
            </div>
            <h4>
              <SimpleText text-key="forecast.resultview.chartheader"/>
            </h4>
            <ForecastColumnChart ref="scope2ColumnChart" :width="'66%'" id="scope2-column-chart"
                                 :raw-data="forecast.calculations?.sort((a, b) => a.year - b.year)"
                                 :settings="constants.FORECAST_FILTER_STATICSCOPE + constants.FORECAST_FILTER_SCOPE2"></ForecastColumnChart>
          </div>
          <div class="container px-0 d-print-pagebreak">
            <h3>
              <SimpleText :text-key="'forecast.resultview.scope3chart.header'"></SimpleText>
            </h3>
            <div data-cy-id="forecast-result-summary-scope3" class="bg-alternative-green">
              <div style="padding: 20px;" v-html="resultHeadlineSummaryScope3"></div>
            </div>
            <h4>
              <SimpleText text-key="forecast.resultview.chartheader"/>
            </h4>
            <ForecastColumnChart ref="scope3ColumnChart" :width="'66%'" id="scope3-column-chart"
                                 :raw-data="forecast.calculations?.sort((a, b) => a.year - b.year)"
                                 :settings="constants.FORECAST_FILTER_STATICSCOPE + constants.FORECAST_FILTER_SCOPE3"></ForecastColumnChart>
          </div>


          <Scope3CategorySelect
              v-if="forecast"
              id="scope3-category-select"
              ref="categoriesMultiselect"
              :forecast="forecast"
          />

          <div v-if="forecast.forcastHeader.isCreationCompleted">
            <div class="row justify-content-center">
              <div class="column invisible"></div>

              <div v-if="this.containsUpstreams && this.chartContainsUpstreams" class="flex-md-column text-center" style="padding-right: 20px">
                <h6 style="margin-right: 8px">
                  <SimpleText text-key="calculate-forecast.category-graph.upstream-label"/>
                </h6>
                <p class="category-box upstream-color-base" style="font-size: small; margin-bottom: 0">
                  {{ this.forecastBasisYear }}
                </p>
                <p class="category-box upstream-color-end" style="font-size: small; margin-top: 0">
                  {{ this.forecastEndYear }}
                </p>
              </div>

              <div v-if="this.containsDownstreams && chartContainsDownstreams" class="flex-md-column text-center" style="padding-right: 20px">
                <h6>
                  <SimpleText text-key="calculate-forecast.category-graph.downstream-label"/>
                </h6>
                <p class="category-box downstream-color-base" style="font-size: small; margin-bottom: 0">
                  {{ this.forecastBasisYear }}
                </p>
                <p class="category-box downstream-color-end" style="font-size: small; margin-top: 0">
                  {{ this.forecastEndYear }}
                </p>
              </div>

              <div class="column invisible"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container px-0">
    <a class="button button-primary mr-4" href='javascript:void(0)' @click="reductionTarget()">
      <SimpleText text-key="forecast.next.reductiontarget.button"/>
    </a>
  </div>
</template>

<script lang="ts">
import {useRouter} from "vue-router"
import {Options} from "vue-class-component"
import Navigation from "@/components/thirdparty/Navigation.vue"
import SharedHeader from "@/components/calculator/shared/SharedHeader.vue"
import {Menu} from "@/shared/MenuStructure"
import {FormValidation} from "@/shared/FormValidation";
import {documentToHtmlString} from '@contentful/rich-text-html-renderer'
import {
  Calculation,
  CalculationStateEnum,
  CategoryResult,
  ForecastHeaderResult,
  ForecastInfo,
  ForecastInfoGrowthTypeEnum,
  ForecastInfoMessageStatusEnum,
  ForecastResults, GroupingCalculatedResults,
  Versions
} from "../../../openapi/generated-clients/climatecompass";
import {Prop, Watch} from "vue-property-decorator";
import DKFDS from "dkfds";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import SimpleText from "@/components/SimpleText.vue";
import RichText from "@/components/RichText.vue";
import Tooltip from "@/components/Tooltip.vue";
import ContentfulContent from "@/shared/ContentfulContent";
import {calculationClient, forecastClient, versionClient} from "@/shared/BackendService";
import {prettify, unprettify} from "@/shared/PrettifyNumbers";
import {AxiosResponse} from "axios";
import ForecastColumnChart from "@/components/forecast/ForecastColumnChart.vue";
import {PendingForecastEntry} from "@/types/PendingForecastEntry";
import CypressTestElement from "@/components/testhelpers/CypressTestElement.vue";
import {downloadFile} from "@/shared/FileHelper";
import Scope3CategoryColumnChart from "./Scope3CategoryColumnChart.vue"
import Scope3CategorySelect from "@/components/forecast/Scope3CategorySelect.vue";

@Options({
  components: {
    Scope3CategorySelect,
    CypressTestElement,
    ForecastColumnChart,
    Scope3CategoryColumnChart,
    RichText,
    SimpleText,
    Tooltip,
    Navigation,
    SharedHeader
  }
})
export default class CalculateForecast extends BaseComponent {
  /** mode can be 'edit' or 'new' */
  @Prop()
  mode = 'new'
  @Prop()
  forecastId = 0

  menu = {} as Menu
  router = useRouter()

  contentfulContent: ContentfulContent = this.store.getters.getContentfulContent

  possibleCalculations: Calculation[] = []
  selectedCalculationId = ''
  calculationName = ''
  yearlyGrowthRate = '0'
  typeGrowthRate = ''
  forecastName = ''

  enumLinear = ForecastInfoGrowthTypeEnum.Linear
  enumExponential = ForecastInfoGrowthTypeEnum.Exponential

  chooseCalculationError = ''
  yearlyGrowthRateError = ''
  typeGrowthRateError = ''
  forecastNameError = ''

  calculationForecasted  = {} as Calculation
  scope3CategoriesForecast: Array<{ CategoryResults: any, ForecastBasisYear?: any, ForecastEndYear?: any }> = [];
  scope3CategoriesForecast2: Array<{ [key: string]: any }> = [];


  graphDisplayCriteria: string | undefined
  graphDisplayScope1: boolean | undefined
  graphDisplayScope2: boolean | undefined
  graphDisplayScope3: boolean | undefined

  formValidation = new FormValidation()
  showSuccess = false
  contentMessage = ""

  // to make sure resetForm are only performed once when in mode New
  initNew = false

  editExistingForecast = false
  forecast: ForecastResults = {}

  resultHeadlineSummary: any = {}
  resultHeadlineSummaryScope1 = ''
  resultHeadlineSummaryScope2 = ''
  resultHeadlineSummaryScope3 = ''

  $refs!: {
    formGroup: HTMLFormElement
  }

  versions = {} as Versions

  partialLoadCompleted = true
  partialLoadedForecastDataSize = 0

  chartContainsUpstreams = false
  chartContainsDownstreams = false

  // Cypress helpers
  dataRenderState = 'UPDATING'
  dataContentSize = '0'
  static forecast: ForecastResults

  //Variable used to limit updated lifecycle and nothing else.
  prevForecast = this.forecast

  @Watch("isCompleted")
  async watchMultiSelectOptions() {
    this.containsDownstreams()
    this.containsUpstreams()
  }

  get partialLoadingHasFailed() {
    if (this.forecast && this.forecast.forcastHeader) {
      return this.forecast.forcastHeader.isCreationCompleted && this.forecast.forcastHeader.messageStatus !== ForecastInfoMessageStatusEnum.UNKNOWN
    }
    return false
  }

  get partialLoadingHasData() {
    if (this.forecast && this.forecast.forcastHeader && this.forecast.calculations) {
      return !this.forecast.forcastHeader?.isCreationCompleted &&
          this.forecast.forcastHeader.messageStatus === ForecastInfoMessageStatusEnum.UNKNOWN &&
          this.forecast.calculations?.length > 0
    }
    return false
  }

  get isLoadingPartially() {
    if (this.forecast && this.forecast.forcastHeader) {
      return !this.forecast.forcastHeader?.isCreationCompleted &&
          this.forecast.forcastHeader.messageStatus === ForecastInfoMessageStatusEnum.UNKNOWN &&
          this.forecast.calculations?.length === 0
    }
    return false
  }

  get isCompleted() {
    if (this.forecast && this.forecast.forcastHeader) {
      return this.forecast.forcastHeader.isCreationCompleted
    }
    return false
  }

  get pendingForecasts() {
    return this.store.getters.getPendingForecasts
  }

  /***
   * Function called with
   */

  async onPendingForecastsSubscription() {
    if (this.partialLoadCompleted) return
    if (this.pendingForecasts.some((x: PendingForecastEntry) => x.forecastId === this.forecast.forcastHeader?.forecastId)) {
      const selfPendingEntry = this.pendingForecasts.find((x: PendingForecastEntry) => x.forecastId === this.forecast.forcastHeader?.forecastId)
      if (selfPendingEntry.pollingCompleted || selfPendingEntry.currentDataSize > this.partialLoadedForecastDataSize) {
        this.partialLoadedForecastDataSize = selfPendingEntry.currentDataSize
        await this.setupForecastInfoResult(selfPendingEntry.forecastId)
      }
    }
  }

  get forecastPercentage() {
    const sortedCalculations = this.forecast.calculations?.sort((a, b) => (a.year ?? 0) - (b.year ?? 0));
    if (sortedCalculations && sortedCalculations.length > 0) {
      const startTotal = sortedCalculations[0]?.scoresTotalResult ?? 0;
      const endTotal = sortedCalculations[sortedCalculations.length - 1]?.scoresTotalResult ?? 0;
      if (startTotal !== 0) {
        return ((endTotal - startTotal) * 100 / startTotal).toFixed(2);
      }
    }
    return 0;
  }

  get forecastEndYear() {
    if (this.forecast.calculations && this.forecast.calculations.length > 0) {
      return Math.max(...this.forecast.calculations.map(x => x.year ?? 0))
    }
    return 0
  }

  get forecastBasisYear() {
    if (this.forecast.calculations && this.forecast.calculations.length > 0) {
      return Math.min(...this.forecast.calculations.map(x => x.year ?? 0))
    }
    return 0
  }

  async reductionTarget() {
    await this.router.push('reduction-objectives?forecastId=' + this.forecast.forcastHeader?.forecastId)
    /*
    await this.store.dispatch("setCurrentForecastInfo", this.forecast.forcastHeader).then(async () => {
      await this.router.push({name: 'ReductionObjectives', params: {mode: 'edit', forecastId: this.forecast.forcastHeader?.forecastId }})
    })*/
  }

  async beforeMount() {
    const elem = document.getElementById('calculate-forecast-link')
    //console.log("CalculateForecast.vue, elem:", elem, "mode", this.mode, 'forecastId', this.forecastId)
    if (elem) {
      elem.addEventListener('click', async () => {
        await this.store.dispatch("setCurrentForecastInfo")
        this.editExistingForecast = false
        await this.setupForecastInfoResult();
      })
    }

    try {
      this.versions = (await versionClient.fetchVersions()).data
    } catch (e) {
      console.error("Unable to fetch version",)
    }
    this.eventHub.on('chart-rendered', () => {
      window.scroll(0, document.body.scrollHeight)
    })
    this.menu = this.store.getters.getMenuStructure.forecastTabs
    this.eventHub.emit("start-loading", "CalculateForecast")
    await this.store.dispatch("loadCalculations").finally(() => {
      this.eventHub.emit("stop-loading", "CalculateForecast")
    })

    this.setupPossibleCalculations()

    this.eventHub.on('forecast-results-selected-forecast-criteria', async (arg: string) => {
      this.updatedSelectedForecastCriteria(arg)
    })

    this.eventHub.on('forecast-results-selected-scopes', async (arg: string[]) => {
      this.updatedSelectedScopes(arg)
    })

    this.chooseCalculationError = this.contentfulContent.findSimpleText('forecast.calculate-forecast.field.choose-calculation.error')
    this.yearlyGrowthRateError = this.contentfulContent.findSimpleText('forecast.calculate-forecast.field.yearly-growth-rate.error')
    this.typeGrowthRateError = this.contentfulContent.findSimpleText('forecast.calculate-forecast.field.type-growth-rate.error')
    this.forecastNameError = this.contentfulContent.findSimpleText('forecast.calculate-forecast.field.forecast-name.error')

    if (this.route.query.forecastId) {
      const showForecastId = this.route.query.forecastId
      const forecastInfo = {} as ForecastInfo
      forecastInfo.forecastId = Number(showForecastId)
      await this.store.dispatch('setCurrentForecastInfo', forecastInfo)
      this.editExistingForecast = true
    } else {
      this.editExistingForecast = this.mode === 'edit'
    }
    await this.setupForecastInfoResult();
    this.scrollToTargetWithOffset('forecast-result-header', 20)
  }

  private async setupForecastInfoResult(forecastId: number | undefined = undefined) {
    if (this.editExistingForecast) {

      const existingOrPartialForecastId = forecastId ?? this.store.getters.getCurrentForecastInfo.forecastId
      if (existingOrPartialForecastId) {
        this.dataRenderState = 'UPDATING'
        await forecastClient.getForecast(existingOrPartialForecastId,
            this.thirdpartyInfo && this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null').then((result: AxiosResponse<ForecastResults>) => {
          if (result.data && result.data.forcastHeader) {

            this.forecast = result.data
            this.setFormFromExistingForecast(result.data.forcastHeader)
            this.partialLoadCompleted = result.data.forcastHeader.isCreationCompleted ?? false
            if (this.partialLoadCompleted) {
              this.dataContentSize = String(result.data.calculations?.length)
              this.dataRenderState = 'COMPLETED'
            }
          }
        })
        if (this.route.path.includes('calculate-forecast')) {
          await this.router.push('/forecast/scope123#tabs')
        }
      }
    } else {
      this.resetForm()
    }
  }

  get thirdpartyInfo() {
    return this.store.getters.getThirdpartyInfo
  }

  openLink(url: string) {
    window.open(url)
  }

  get consentMessage() {
    return this.contentMessage
  }

  containsUpstreams() {
    const parentList = document.querySelector("#multiselect-scope3-categories-multiselect-options");
    if (!parentList) return false;

    const childElements = parentList.querySelectorAll(".multiselect-option");

    for (const element of childElements) {
      const match = element.textContent?.trim().match(/^\d+/);
      if (match) {
        const number = parseInt(match[0], 10);
        if (!isNaN(number) && number <= 8) {
          this.chartContainsUpstreams = true;
          return true; // Found an upstream value, no need to continue iterations
        }
      }
    }
    //No upstreams were found
    this.chartContainsUpstreams = false;
    return false;
  }


  containsDownstreams() {
    const parentList = document.querySelector("#multiselect-scope3-categories-multiselect-options");
    if (!parentList) return false;

    const childElements = parentList.querySelectorAll(".multiselect-option");

    for (const element of childElements) {
      const match = element.textContent?.trim().match(/^\d+/);
      if (match) {
        const number = parseInt(match[0], 10);
        if (!isNaN(number) && number > 8) {
          this.chartContainsDownstreams = true;
          return true; // Found a downstream value , no need to continue iterations
        }
      }
    }
    //No downstreams were found
    this.chartContainsDownstreams = false;
    return false;
  }


  get documentToHtmlString() {
    return documentToHtmlString
  }

  setupPossibleCalculations() {
    const cals = this.store.getters.getCalculations
    const currentYear = new Date().getFullYear()
    this.possibleCalculations = cals
        .filter((cal: Calculation) => (cal.state === CalculationStateEnum.Finished || cal.state === CalculationStateEnum.FinishedDuplicated || cal.state === CalculationStateEnum.Readonly))
        .filter((cal: Calculation) => (this.isCalculationEcVersionAllowedForRecalculation(cal)))
        .filter((cal: Calculation) => (this.timePeriodInRange(cal, currentYear)))
        //.filter((cal: Calculation) => (cal.electricityEnvironmentDeclaration))

    return this.possibleCalculations
  }

  updatedSelectedForecastCriteria(selectedForecastCriteria: string) {
    this.graphDisplayCriteria = selectedForecastCriteria
  }

  updatedSelectedScopes(selectedScopes: string[]) {
    this.graphDisplayScope1 = selectedScopes.includes('Scope1')
    this.graphDisplayScope2 = selectedScopes.includes('Scope2')
    this.graphDisplayScope3 = selectedScopes.includes('Scope3')
  }

  timePeriodInRange(cal: Calculation, currentYear: number): boolean {
    const year: number = +cal?.calculationPurpose?.startDate?.substr(0, 4)
    if (isNaN(year)) {
      return false
      //Only allow forecast where the calculation year has (any) EMC version with EMC actual set to true/null
    } else if (this.versions.versions?.some(x => x.year === year)) {
      return true
    } else {
      return false
    }
  }

  resetForm() {
    if (this.$refs.formGroup) {
      this.$refs.formGroup.reset();
    }
    this.selectedCalculationId = ''
    this.yearlyGrowthRate = '0'
    this.typeGrowthRate = ForecastInfoGrowthTypeEnum.Linear
    this.forecastName = ''
    this.forecast = {}
  }

  async setFormFromExistingForecast(forecastInfo: ForecastInfo) {
    this.selectedCalculationId = (forecastInfo.sourceCalculationId ? forecastInfo.sourceCalculationId.toString() : '0')
    this.calculationName = (forecastInfo.sourceCalculationName ? forecastInfo.sourceCalculationName : '')
    const prettyGrowthRate: string = prettify(forecastInfo.growthRate, 2)?.toString() ?? '0'
    this.yearlyGrowthRate = prettyGrowthRate
    this.typeGrowthRate = forecastInfo.growthType?.toString() ?? ForecastInfoGrowthTypeEnum.Linear
    this.forecastName = forecastInfo.name ?? ''
    this.calculationForecasted = (await calculationClient.getCalculation(forecastInfo.sourceCalculationId!, this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null')).data || {}

    const rt: string = this.store.getters.getContentfulContent.renderRichText('forecast.result.headline-summary', true)
    const tavText: string = this.getContentfulString('forecast.calculate-forecast.field.type-growth-rate.option.' + forecastInfo.growthType + '.short-name')
    const exp: string = this.getContentfulString('forecast.calculate-forecast.field.type-growth-rate.option.exponential.short-name')
    const lin: string = this.getContentfulString('forecast.calculate-forecast.field.type-growth-rate.option.linear.short-name')
    const trendIncreasing: string = this.getContentfulString('forecast.result.growth-trend-increasing')
    const trendDecreasing: string = this.getContentfulString('forecast.result.growth-trend-decreasing')
    const trendForecasting: string = this.getContentfulString('forecast.result.growth-trend-forecasting')
    let displayedTrendScope1: string
    let displayedTrendScope2: string
    let displayedTrendScope3: string
    let displayedTrendScope123: string

    const firstYear = this.forecast.calculations ? this.forecast.calculations.sort((a, b) => (a.year ?? 0) - (b.year ?? 0))[0] : {}
    const lastYear = this.forecast.calculations ? this.forecast.calculations.sort((a, b) => (a.year ?? 0) - (b.year ?? 0))[this.forecast.calculations.length - 1] : {}
    if (!firstYear || !lastYear) return

    const percentageScope123 = firstYear.ghgCalculatedResult?.totalResult === 0 ? 0 : ((((lastYear.ghgCalculatedResult?.totalResult ?? 0) - (firstYear.ghgCalculatedResult?.totalResult ?? 0)) * 100) / (firstYear.ghgCalculatedResult?.totalResult ?? 1)).toFixed(0)
    if (percentageScope123 < 0) {
      displayedTrendScope123 = trendDecreasing
    } else if (percentageScope123 > 0) {
      displayedTrendScope123 = trendIncreasing
    } else {
      displayedTrendScope123 = trendForecasting
    }

    const percentageScope1 = firstYear.ghgCalculatedResult?.scope1Result === 0 ? 0 : ((((lastYear.ghgCalculatedResult?.scope1Result ?? 0) - (firstYear.ghgCalculatedResult?.scope1Result ?? 0)) * 100) / (firstYear.ghgCalculatedResult?.scope1Result ?? 1)).toFixed(0)
    if (percentageScope1 < 0) {
      displayedTrendScope1 = trendDecreasing
    } else if (percentageScope1 > 0) {
      displayedTrendScope1 = trendIncreasing
    } else {
      displayedTrendScope1 = trendForecasting
    }

    const percentageScope2 = firstYear.ghgCalculatedResult?.scope2Result === 0 ? 0 : ((((lastYear.ghgCalculatedResult?.scope2Result ?? 0) - (firstYear.ghgCalculatedResult?.scope2Result ?? 0)) * 100) / (firstYear.ghgCalculatedResult?.scope2Result ?? 1)).toFixed(0)
    if (percentageScope2 < 0) {
      displayedTrendScope2 = trendDecreasing
    } else if (percentageScope2 > 0) {
      displayedTrendScope2 = trendIncreasing
    } else {
      displayedTrendScope2 = trendForecasting
    }

    const percentageScope3 = firstYear.ghgCalculatedResult?.scope3Result === 0 ? 0 : ((((lastYear.ghgCalculatedResult?.scope3Result ?? 0) - (firstYear.ghgCalculatedResult?.scope3Result ?? 0)) * 100) / (firstYear.ghgCalculatedResult?.scope3Result ?? 1)).toFixed(0)
    if (percentageScope3 < 0) {
      displayedTrendScope3 = trendDecreasing
    } else if (percentageScope3 > 0) {
      displayedTrendScope3 = trendIncreasing
    } else {
      displayedTrendScope3 = trendForecasting
    }

    this.resultHeadlineSummary = rt.replace('[GVR]', prettyGrowthRate)
        .replace('[TAV]', tavText)
        .replace('#GROWTH_TREND#', displayedTrendScope123)
        .replace('[V2030]', (String((prettify(this.forecastPercentage, 2))) ?? '0'))
        .replace('[ENDYEAR]', String(this.forecastEndYear))
    this.store.dispatch('setCurrentForecastInfo', forecastInfo)

    this.resultHeadlineSummaryScope1 = this.getRenderedContentfulRT('forecast.calculate-forecast.result.scope1chart', true)
        .replace('#GROWTH_TREND#', displayedTrendScope1)
        .replace('#PERCENT#', percentageScope1)
        .replace('#END_YEAR#', String(this.forecastEndYear))

    this.resultHeadlineSummaryScope2 = this.getRenderedContentfulRT('forecast.calculate-forecast.result.scope2chart', true)
        .replace('#GROWTH_TREND#', displayedTrendScope2)
        .replace('#PERCENT#', percentageScope2)
        .replace('#END_YEAR#', String(this.forecastEndYear))
        .replace('#BASIS_YEAR#', String(this.forecastBasisYear))

    this.resultHeadlineSummaryScope3 = this.getRenderedContentfulRT('forecast.calculate-forecast.result.scope3chart', true)
        .replace('#GROWTH_TREND#', displayedTrendScope3)
        .replace('#PERCENT#', percentageScope3)
        .replace('#TYPE_GROWTH#', this.typeGrowthRate === ForecastInfoGrowthTypeEnum.Linear ? lin : exp)
        .replace('#GROWTH_PCT#', prettify(this.yearlyGrowthRate, 2))
        .replace('#END_YEAR#', String(this.forecastEndYear))


  }

  isUpdateForecastAllowed() : boolean {
    if (!this.editExistingForecast) {
      return true //Create new forecast update allowed is handled by availability i dropdown
    }
    if (this.calculationForecasted) {
      return this.isCalculationEcVersionAllowedForRecalculation(this.calculationForecasted)
    }
    return true;
  }

  validateForm() {
    if (!this.selectedCalculationId) {
      this.formValidation.formErrors.set("calculation-not-selected", this.chooseCalculationError)
      const errorElem = document.getElementById('choose-calculation-div')
      errorElem?.classList.add('form-error')
    } else if (this.selectedCalculationId && this.selectedCalculationId !== '') {
      const errorElem = document.getElementById('choose-calculation-div')
      this.formValidation.formErrors.delete("calculation-not-selected")
      errorElem?.classList.remove('form-error')
    }

    const nonPrettyYearlyGrowthRate = unprettify(this.yearlyGrowthRate) ?? '0'
    if (Number(nonPrettyYearlyGrowthRate) < -99.99 || Number(nonPrettyYearlyGrowthRate) > 999.99) {
      const errorElem = document.getElementById('yearly-growth-rate-div')
      this.formValidation.formErrors.set("yearly-growth-rate-illegal-value", this.yearlyGrowthRateError)
      errorElem?.classList.add('form-error')
    } else if (Number(nonPrettyYearlyGrowthRate) > -99.99 && Number(nonPrettyYearlyGrowthRate) < 999.99) {
      const errorElem = document.getElementById('yearly-growth-rate-div')
      this.formValidation.formErrors.delete("yearly-growth-rate-illegal-value")
      errorElem?.classList.remove('form-error')
    }

    if (!this.getEnumKeyByEnumValue(ForecastInfoGrowthTypeEnum, this.typeGrowthRate)) {
      this.formValidation.formErrors.set("type-growth-rate-not-selected", this.typeGrowthRateError)
    } else {
      this.formValidation.formErrors.delete("type-growth-rate-not-selected")
    }

    if (!this.validForecastName(this.forecastName)) {
      const errorElem = document.getElementById('forecast-name-div')
      this.formValidation.formErrors.set("invalid-forecast-name", this.forecastNameError)
      errorElem?.classList.add('form-error')
    } else {
      const errorElem = document.getElementById('forecast-name-div')
      this.formValidation.formErrors.delete("invalid-forecast-name")
      errorElem?.classList.remove('form-error')
    }
  }

  requestPending = false

  async submitForm(e: Event) {

    const nonPrettyYearlyGrowthRate = unprettify(this.yearlyGrowthRate) ?? '0'
    this.validateForm()

    if (this.formValidation.formErrors.size === 0) {
      const element = e.target as HTMLFormElement
      if (element.reportValidity()) {
        document.querySelectorAll(".form-group").forEach(function (element) {
          element.classList.remove('form-error')
        })
        this.requestPending = true
        this.formValidation.formErrors.clear()
        this.showSuccess = true
        const growthTypeEnumValue: ForecastInfoGrowthTypeEnum = ForecastInfoGrowthTypeEnum[this.getEnumKeyByEnumValue(ForecastInfoGrowthTypeEnum, this.typeGrowthRate) as keyof typeof ForecastInfoGrowthTypeEnum]
        const forecastInfo: ForecastInfo = {
          name: this.forecastName,
          sourceCalculationId: +this.selectedCalculationId,
          growthRate: +nonPrettyYearlyGrowthRate,
          growthType: growthTypeEnumValue,
          thirdpartyVatNo: this.thirdpartyInfo.behalfOfVatNr,
          graphDisplayCriteria: this.graphDisplayCriteria,
          graphDisplayScope1: this.graphDisplayScope1,
          graphDisplayScope2: this.graphDisplayScope2,
          graphDisplayScope3: this.graphDisplayScope3
        }
        const existingForecastSavedUnderNewName = this.editExistingForecast && this.forecastName != this.forecast.forcastHeader?.name
        if (this.editExistingForecast && !existingForecastSavedUnderNewName && this.forecast.forcastHeader) {
          //Update existing forecast if name not changed
          forecastInfo.forecastId = this.store.getters.getCurrentForecastInfo.forecastId
          await forecastClient.updateForecast(forecastInfo).then((result: AxiosResponse<ForecastHeaderResult>) => {
            this.createUpdateForecastOK(true, forecastInfo);
          }).catch((error) => {
            this.createUpdateForecastError();
          }).finally(() => {
            this.requestPending = false
          })
        } else {
          await forecastClient.createForecast(forecastInfo).then((result: AxiosResponse<ForecastHeaderResult>) => {
            forecastInfo.forecastId = result.data.forcastId
            this.createUpdateForecastOK(false, forecastInfo);
          }).catch((error) => {
            this.createUpdateForecastError();
          }).finally(() => {
            this.requestPending = false
          })
        }
      }
    }
  }

  private createUpdateForecastError() {
    this.addToastFromMessageKeys('CreateForecastError', 'error',
        'forecast.calculate-forecast.toast.save-error.header', 'forecast.calculate-forecast.toast.save-error.message',
        true, false)
  }

  private async createUpdateForecastOK(isUpdate: boolean, forecastInfo: ForecastInfo) {
    this.forecast.forcastHeader = forecastInfo
    await this.store.dispatch('setCurrentForecastInfo', forecastInfo)

    if (forecastInfo.forecastId) {
      await this.store.dispatch(isUpdate ? 'updatePendingForecast' : 'addToPendingForecasts',
          new PendingForecastEntry(forecastInfo.forecastId, forecastInfo.name ?? '',
              forecastInfo.createdTime ?? new Date().toISOString())).then(async () => {
        this.editExistingForecast = true
        await this.setupForecastInfoResult(forecastInfo.forecastId)
      })
    }
  }

  validForecastName(name: string): boolean {
    if (!name) {
      return false
    }
    if (name.length < 2
        && name.length > 50) {
      return false
    }
    return true
  }

  getEnumKeyByEnumValue<T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T | undefined {
    const keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : undefined;
  }

  mounted() {
    DKFDS.init()
    this.store.subscribe((mutation, state) => {
      if (mutation.type.includes("setPendingForecasts")) {
        this.onPendingForecastsSubscription()
      }
    })
  }

  updated() {
    DKFDS.init()
    if (this.forecast !== this.prevForecast) {
      this.containsDownstreams();
      this.containsUpstreams();
      this.prevForecast = this.forecast;
    }
   // this.containsUpstreams()
   // this.containsDownstreams()
  }

  /* Following watch functions evaluates form values and removes error-tokens and messages if valid on focusout*/
  watchYearlyGrowthRate() {
    const defaultValue = '0'
    const nonPrettyYearlyGrowthRate = unprettify(this.yearlyGrowthRate)
    const errorElem = document.getElementById('yearly-growth-rate-div')
    if (this.yearlyGrowthRate === '') {
      this.yearlyGrowthRate = defaultValue
    }
    if (Number(nonPrettyYearlyGrowthRate) > -99.99 && Number(nonPrettyYearlyGrowthRate) < 999.99) {
      this.formValidation.formErrors.delete("yearly-growth-rate-illegal-value")
      errorElem?.classList.remove('form-error')
    }
  }

  watchCalculationId() {
    const errorElem = document.getElementById('choose-calculation-div')
    if (this.selectedCalculationId !== '') {
      this.formValidation.formErrors.delete("calculation-not-selected")
      errorElem?.classList.remove('form-error')
    }
  }

  watchInputForecastName() {
    const errorElem = document.getElementById('forecast-name-div')
    if (this.validForecastName(this.forecastName)) {
      this.formValidation.formErrors.delete('invalid-forecast-name')
      errorElem?.classList.remove('form-error')
    }
  }

  async exportToExcel(event: any) {
    const countryCode = this.useTextKeys ? 'TE' :  (this.store.getters.getLocale === 'da' ? 'DK' : 'US')
    const env = document.location.host.split(".")[0]
    /* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
    const response = await forecastClient.exportToExcel2(this.forecast.forcastHeader?.forecastId!, this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null', countryCode, env, {responseType: "blob"})
    const blob = new Blob([response.data], {type: response.headers["content-type"]})
    // due to CORS we cannot access Content-Disposition header here, so we rebuild filename
    const filename = `klimakompasset-resultat-${this.forecast.forcastHeader?.forecastId}.xlsx`
    downloadFile(blob, filename)
  }
}
</script>


<style lang="scss" scoped>
.container .px-0 {
  padding-top: 0 !important;
}

.adjust-button-size {
  display: block;
  margin-top: 31px;
}

.bg-alternative {
  background-color: #e8f2ee !important;
}

.form-input .form-group .form-error * {
  border-left: 4px solid #CC0000 !important;
  padding-left: calc(16px - 4px);
}

.form-select .form-group .form-error * {
  border-left: 4px solid #CC0000 !important;
  padding-left: calc(16px - 4px);
}

p * {
  width: 100%;
}

#info-box-wrapper-div p,
#info-box-declaration-wrapper-div p,
#info-box-declaration2-wrapper-div p {
  //used to overwrite inherited p margin.
  margin: 0 0 0 0;
}

.info-icon-size {
  width: 100%;
  max-height: 25px;
  min-height: 25px;
  max-width: 30px;
  min-width: 30px;
}

.column-row-gap-10 {
  column-gap: 10px;
  row-gap: 10px;
}

.flex-grow-equally {
  flex-grow: 1;
  flex-basis: 0;
}

.invisible {
  visibility: hidden;
}

.category-box.upstream-color-base:before {
  background-color: #3d0ab2 !important;
}
.category-box.upstream-color-end:before {
  background-color: #736AFF !important;
}

.category-box.downstream-color-base:before {
  background-color: #E66C2C !important;
}
.category-box.downstream-color-end:before {
  background-color: #f69a6a !important;
}

.category-box {
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
}
.category-box:before {
  position: absolute;
  left: -18px;
  content: "";
  height:15px;
  width:15px;
  margin-bottom:5px;
  margin-top: 5px;
  margin-right: 5px;
  border:1px solid black;

}


</style>
