import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-cy-readystate", "data-cy-inner", "data-cy-type", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isTestFramework)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        "data-cy-readystate": _ctx.state,
        "data-cy-inner": _ctx.inner,
        "data-cy-type": _ctx.type,
        id: _ctx.identifier,
        style: {"display":"none"}
      }, null, 8, _hoisted_1))
    : _createCommentVNode("", true)
}