import {CalculationFull} from "../../../openapi/generated-clients/climatecompass";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";


export default class ComparisonBase extends BaseComponent {


  subgroupByGroupMap = new Map<string, string[]>([
    ['ENERGY', ['SG_1', 'SG_2', 'SG_3', 'SG_4', 'SG_5', 'SG_6', 'SG_7', 'SG_8']],
    ['PROCUREMENT', ['SG_9', 'SG_10', 'SG_11', 'SG_12', 'SG_13', 'SG_14', 'SG_15', 'SG_16', 'SG_17', 'SG_18', 'SG_19', 'SG_20', 'SG_21', 'SG_22', 'SG_23', 'SG_24', 'SG_25', 'SG_26', 'SG_27', 'SG_28']],
    ['TRANSPORT', ['SG_39', 'SG_40', 'SG_41', 'SG_42', 'SG_43', 'SG_44', 'SG_45', 'SG_46', 'SG_47', 'SG_48', 'SG_49', 'SG_50', 'SG_51', 'SG_52']],
    ['TRASH', ['SG_29', 'SG_30', 'SG_31']],
    ['PRODUCTS', ['SG_32', 'SG_33', 'SG_34', 'SG_35', 'SG_36', 'SG_37', 'SG_38']],
  ]);

  mergedGroupsByGroupMap = new Map<string, string[]>([
    ["ENERGY",["group.text.category.groupelectricity", "group.text.category.groupheat", "group.text.category.groupprocess", "group.text.category.groupheatprocessother"]],
    ["PROCUREMENT",["group.text.category.groupmaterialsprimary", "group.text.category.groupproductsservicesprimary", "group.text.category.groupotherprimary",
      "group.text.category.groupmaterialssecondary", "group.text.category.groupproductsservicessecondary", "group.text.category.groupothersecondary"]],
    ["TRANSPORT",["group.text.category.groupownleasedtransports", "group.text.category.groupemployeetransport", "group.text.category.grouptransporttocompany",
      "group.text.category.grouptransporttocustomer", "group.text.category.groupothertransport"]],
    ["TRASH",["group.text.category.grouptrash", "group.text.category.groupothertrash"]],
    ["PRODUCTS",["group.text.category.groupprocesssoldproducts", "group.text.category.groupusedproducts", "group.text.category.groupendoflife", "group.text.category.groupothersoldproducts"]],
])

  subgroupByMergedGroup = new Map<string, string[]>([
    ["group.text.category.groupelectricity", ["SG_1", "SG_2"]],
    ["group.text.category.groupheat", ["SG_3", "SG_4", "SG_5"]],
    ["group.text.category.groupprocess", ["SG_6", "SG_7"]],
    ["group.text.category.groupheatprocessother", ["SG_8"]],
    ["group.text.category.groupmaterialsprimary", ["SG_9", "SG_10", "SG_11", "SG_12"]],
    ["group.text.category.groupproductsservicesprimary", ["SG_13", "SG_14", "SG_15", "SG_16"]],
    ["group.text.category.groupotherprimary", ["SG_17", "SG_18"]],
    ["group.text.category.groupmaterialssecondary", ["SG_19", "SG_20", "SG_21", "SG_22"]],
    ["group.text.category.groupproductsservicessecondary", ["SG_23", "SG_24", "SG_25", "SG_26"]],
    ["group.text.category.groupothersecondary", ["SG_27", "SG_28"]],
    ["group.text.category.grouptrash", ["SG_29", "SG_30"]],
    ["group.text.category.groupothertrash", ["SG_31"]],
    ["group.text.category.groupprocesssoldproducts", ["SG_32", "SG_38"]],
    ["group.text.category.groupusedproducts", ["SG_33", "SG_34"]],
    ["group.text.category.groupendoflife", ["SG_35", "SG_36"]],
    ["group.text.category.groupothersoldproducts", ["SG_37"]],
    ["group.text.category.groupownleasedtransports", ["SG_39", "SG_40", "SG_41"]],
    ["group.text.category.groupemployeetransport", ["SG_42", "SG_43", "SG_44", "SG_45"]],
    ["group.text.category.grouptransporttocompany", ["SG_46", "SG_47", "SG_48"]],
    ["group.text.category.grouptransporttocustomer", ["SG_49", "SG_50", "SG_51"]],
    ["group.text.category.groupothertransport", ["SG_52"]]])

  getMergedGroupsByGroup(group: string): string[] {
    return this.mergedGroupsByGroupMap.get(group) ?? []
  }

  getEmissionValueGroup(calculation: CalculationFull, group: string, selectedComparisonScopeCriterias: string[]) {
    const subgroups = this.subgroupByGroupMap.get(group) ?? []
    let total = 0
    //console.log("getEmissionValueDataGroup", subgroups)
    for (const subgroup of subgroups) {
      total += this.getEmissionValueSubGroup(calculation, subgroup, selectedComparisonScopeCriterias)
    }
    return total
  }

  getEmissionValueMergedGroup(calculation: CalculationFull, mergedGroup: string, selectedComparisonScopeCriterias: string[]) {
    const subgroups = this.subgroupByMergedGroup.get(mergedGroup) ?? []
    const sumPerGroup = subgroups.map(sg => this.getEmissionValueSubGroup(calculation, sg, selectedComparisonScopeCriterias))
    return this.sumArray(sumPerGroup)
  }

  getEmissionValueSubGroup(calculation: CalculationFull, filter: string, selectedComparisonScopeCriterias: string[]) {
    if (calculation && calculation.totals) {
      for (const key of calculation.totals) {
        if (key.key === filter) {
          let total = 0
          if (this.hasScopeValue(selectedComparisonScopeCriterias, 'SCOPE1')) {
            //console.log("collect scope1", key.scope1)
            total += (key.scope1 ? key.scope1 : 0)
          }
          if (this.hasScopeValue(selectedComparisonScopeCriterias, 'SCOPE2')) {
            //console.log("collect scope2", key.scope2)
            total += (key.scope2 ? key.scope2 : 0)
          }
          if (this.hasScopeValue(selectedComparisonScopeCriterias, 'SCOPE3')) {
            //console.log("collect scope3", key.scope3)
            total += (key.scope3 ? key.scope3 : 0)
          }
          if (this.hasScopeValue(selectedComparisonScopeCriterias, 'UDENFOR SCOPES')) {
            //console.log("collect outside scope", key.outsideScope)
            total += (key.outsideScope ? key.outsideScope : 0)
          }
          //console.log("getEmissionValueSG result", total, calculation.calculation?.calculationPurpose.name, filter, selectedComparisonScopeCriterias)
          return total
        }
      }
    }
    return 0
  }

  sumArray(numbers: number[]): number {
    //console.log("sumArray", numbers)
    return numbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  hasScopeValue(secondDDItems: string | string[], scopeValue: string) {
    for (let i=0; i<secondDDItems.length; i++) {
      if (secondDDItems[i] === scopeValue) {
        return true;
      }
    }
    return false
  }

  getMergedGroupContentfulKeyBySubgroup(subgroup: string): string {
    for (const cfKey of this.subgroupByMergedGroup.keys()) {
      if (this.subgroupByMergedGroup.get(cfKey)?.includes(subgroup)) {
        return cfKey
      }
    }
    return "NA"
  }

}