<template>
  <h2><SimpleText text-key="calculation.mainresult.text.mainresult"/></h2>
  <div class="full-width w-100" v-html="getContentfulString('calculation.mainresult.text.mainresult.description')" /><br>
  <table v-if="results?.groupingResults" :aria-label="getContentfulString('calculation.mainresult.text.mainresult')"
         class="table results-table w-percent-100">
    <colgroup span="5"></colgroup>
    <thead>
    <tr class="bg-primary text-color-white py-9">
      <th colspan="5" scope="colgroup" v-html="getContentfulString('calculation.mainresult.text.mainresult.table1.title')"></th>
    </tr>
    </thead>
    <tbody>
    <tr style="font-weight: 600">
      <td class="align-text-left" style="width: 22%" v-html="getContentfulString('calculation.mainresult.text.mainresult.table1.maincategory')"></td>
      <td class="align-text-right" style="width: 17%" v-html="getContentfulString('calculation.mainresult.text.mainresult.table1.emission-scope1-3')"></td>
      <td class="align-text-right" style="width: 17%" v-html="getContentfulString('calculation.mainresult.text.mainresult.table1.share-emission-scope1-3')"></td>
      <td class="align-text-right" style="width: 22%" v-html="getContentfulString('calculation.mainresult.text.mainresult.table1.emission-outsidescope')"></td>
    </tr>
    <tr v-for="result in results.groupingResults" :key="result.groupingName">
      <td class="align-text-left">{{ publicCategoryTheme(result.groupingName) }}</td>
      <td class="align-text-right">{{ doPrettify(result.calculatedResult?.scopesResult) || 0 }}</td>
      <td class="align-text-right">
        {{
          result.calculatedResult?.scopesResult && results?.scoresTotalResult
            ? doPrettify(((result.calculatedResult?.scopesResult / results.scoresTotalResult) * 100).toFixed(2))
            : 0
        }}%
      </td>
      <td class="align-text-right">
        {{ doPrettify(result.calculatedResult?.outsideScopeResult, 2) || 0 }}
      </td>
    </tr>
    <tr style="font-weight: 600">
      <td class="align-text-left"><SimpleText text-key="shared.text.total"/></td>
      <td class="align-text-right">{{ doPrettify(results.scoresTotalResult) || 0 }}</td>
      <td class="align-text-right">{{ doPrettify(100) }}%</td>
      <td class="align-text-right">{{ doPrettify(results.outsideScoresTotalResult, 2) || 0 }}</td>
    </tr>
    </tbody>
  </table>

  <h2 v-html="getContentfulString('calculation.mainresult.text.summary')"></h2>
  <div class="full-width w-100" v-html="getContentfulString('calculation.mainresult.text.summary.description')"></div>

  <div v-if="results?.groupingResults" class="row mt-605">
    <div :class="printMode ? 'col-12' : 'col-6'">
      <BarChart :printMode="printMode" :results="results" :showOnlyScopes="true"
                :title="getContentfulString('calculation.mainresult.text.figure1')"></BarChart>
    </div>
    <div :class="printMode ? 'col-12 mt-605' : 'col-6'">
      <PieChart
        :negativeResultHeader="getContentfulString('calculation.mainresult.text.negative-result-messageheader')"
        :negativeResultText="getContentfulString('calculation.mainresult.text.negative-result-messagetext')"
        :printMode="printMode"
        :results="results"
        :showOnlyScopes="true"
        :title="getContentfulString('calculation.mainresult.text.figure2')"
      ></PieChart>
    </div>
  </div>
  <div v-if="results?.groupingResults" class="row mt-605 print-break-before">
    <div :class="printMode ? 'col-12' : 'col-6'">
      <BarChart :printMode="printMode" :results="results" :showOnlyOutsideScopes="true" :title="getContentfulString('calculation.mainresult.text.figure3')"></BarChart>
    </div>
  </div>

  <h2><SimpleText text-key="calculation.mainresult.text.keynumbers"/></h2>
  <div class="full-width w-100" v-html="getContentfulString('calculation.mainresult.text.keynumbers.description')" /><br>
  <table :aria-label="getContentfulString('calculation.mainresult.text.keynumbers')" class="table" style="width: 555px">
    <thead>
    <tr class="bg-primary text-color-white py-9">
      <th scope="col" style="border-right: none">
        <SimpleText text-key="calculation.mainresult.text.keynumbers.calculations"/>
      </th>
      <th scope="col" class="align-text-right" v-html="getContentfulString('calculation.mainresult.text.keynumbers.co2')" />
    </tr>
    </thead>
    <tbody v-if="results">
    <tr :class="{ 'missing-data': !results.resultOnNumberOfEmployees }">
      <td style="width: 55%" v-html="getContentfulString('calculation.mainresult.text.keynumbers.co2-per-employee')"></td>
      <td class="align-text-right" style="width: 45%">
        {{ doPrettify(results.resultOnNumberOfEmployees?.toFixed(2)) || getContentfulString('calculation.mainresult.text.no-master-data') }}
      </td>
    </tr>
    <tr :class="{ 'missing-data': !results.resultOnTurnover }">
      <td v-html="getContentfulString('calculation.mainresult.text.keynumbers.co2-per-turnover')"></td>
      <td class="align-text-right"
          v-html="doPrettify(results.resultOnTurnover?.toFixed(2)) || getContentfulString('calculation.mainresult.text.no-master-data')"></td>
    </tr>
    <tr :class="{ 'missing-data': !results.resultOnNumberOfSquareMeters }">
      <td v-html="getContentfulString('calculation.mainresult.text.keynumbers.co2-per-area')"></td>
      <td class="align-text-right">
        {{ doPrettify(results.resultOnNumberOfSquareMeters?.toFixed(2)) || getContentfulString('calculation.mainresult.text.no-master-data') }}
      </td>
    </tr>
    </tbody>
  </table>

  <h2><SimpleText text-key="calculation.mainresult.text.own-notes"/></h2>
  <div v-if="!printMode" id="text-area-description" class="full-width w-100"
       v-html="getContentfulString('calculation.mainresult.text.own-notes.description')"></div>

  <div v-if="printMode" class="full-width w-100 preserve-linebreaks print-remarks-container">
    {{ calculation.resultComment }}
  </div>
  <form v-if="!printMode" @submit.prevent="submitComment">
    <div>
      <textarea
        id="input-area"
        v-model="comment"
        :disabled="this.calculation.state !== 'draft' && this.calculation.state !== 'duplicated'"
        aria-labelledby="text-area-description"
        class="form-input"
        maxlength="500"
        name="input-area"
        rows="5"
        style="max-width: 555px"
      ></textarea>
    </div>

    <!-- Knap start -->
    <button id="submitButton" class="button button-primary mt-5" type="submit">
      <SimpleText text-key="sharedContent.save"/>
    </button>
    <!-- Knap slut -->
  </form>
</template>

<script lang="ts">
import {Options} from "vue-class-component"
import BaseResults from "@/components/calculator/results/BaseResults";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import {Prop} from "vue-property-decorator"
import PieChart from "@/components/calculator/results/PieChart.vue"
import BarChart from "@/components/calculator/results/BarChart.vue"
import {GroupingCalculatedResults} from "openapi/generated-clients/climatecompass"
import SimpleText from "@/components/SimpleText.vue";

@Options({
  components: {
    PieChart,
    BarChart,
    SimpleText
  }
})
export default class MainResults extends BaseResults {
  @Prop()
  results: GroupingCalculatedResults = {} as GroupingCalculatedResults
  @Prop()
  printMode?: boolean

  comment = ""

  async beforeMount() {
    await this.init()

    if (this.calculation.resultComment !== null) {
      this.comment = this.calculation.resultComment
    }

    if (!this.printMode) {
      this.setDocumentTitleByKey('calculation.mainresult.text.mainresult')
    }
  }

  async submitComment() {
    const _calculation = {
      ...this.calculation,
      resultComment: this.comment
    }
    await this.store.dispatch("setCalculation", _calculation)
    //await this.store.dispatch("loadCalculations")
    this.addToast("MainResultsToast", "success",
        this.getContentfulString('calculation.mainresult.text.toastheader'),
        this.getContentfulString('calculation.mainresult.text.toastmessage'))
    this.resizeCommentField()
  }
}
</script>
<style lang="scss" scoped>

.table {
  border: 1px solid $color-primary;
}

.table td,
.table th {
  padding: 0.8rem 1.2rem !important;
  border: 1px solid $color-primary;
}

.results-table tr:last-child {
  border-top: 3px solid $color-primary;
}

.text-align-right * {
  text-align: right !important;
}

.text-align-left * {
  text-align: left !important;
}

.missing-data {
  background-color: #f5f5f5;
}

.missing-data :last-child {
  font-style: italic;
}
</style>
