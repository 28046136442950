import {prettify} from "@/shared/PrettifyNumbers";

export class DecimalConfiguration {
     static defaultDecimalSetting = 2

    /**
     * @param num Number to format (either number or string type)
     * @param decimals Number of decimal places (default = 2)
     * Parses the number using Number.parseFloat and fixing decimal places with Number.toFixed
    */
    static formatNumber(num: any, decimals = this.defaultDecimalSetting) : string {
        if(!num) return ""
        if(isNaN(num)) return num
        return parseFloat(String(num)).toFixed(decimals)
    }
}
