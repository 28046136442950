import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a8ec8b00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container px-0" }
const _hoisted_2 = {
  "aria-label": "Brødkrumme",
  class: "breadcrumbs d-print-none"
}
const _hoisted_3 = { class: "breadcrumbs__list" }
const _hoisted_4 = { class: "breadcrumbs__list-item breadcrumbs__list-item--calculation" }
const _hoisted_5 = { class: "breadcrumbs__list-item breadcrumbs__list-item--route" }
const _hoisted_6 = {
  key: 0,
  class: "breadcrumbs__list-item breadcrumbs__list-item--tab"
}
const _hoisted_7 = {
  key: 1,
  class: "breadcrumbs__list-item breadcrumbs__list-item--input"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.calculation && _ctx.calculation.calculationPurpose)
    ? _withDirectives((_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([_ctx.tabsAreVisible && 'hide', "breadcrumbs-container"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("nav", _hoisted_2, [
            _createElementVNode("ol", _hoisted_3, [
              _createElementVNode("li", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(_ctx.calculation.calculationPurpose.name) + " " + _toDisplayString(_ctx.currentCalculationTitleYear), 1)
              ]),
              _createElementVNode("li", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(_ctx.currentRouteBreadcrumb), 1)
              ]),
              (_ctx.currentTabBreadcrumb.length > 0)
                ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.currentTabBreadcrumb), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.currentTabBreadcrumb.length > 0 && _ctx.currentCalculatorInputBreadcrumb.length > 0)
                ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.currentCalculatorInputBreadcrumb), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ], 2)), [
        [_vShow, !_ctx.isTestFramework]
      ])
    : _createCommentVNode("", true)
}