<template>
  <PrintResults />
</template>

<script lang="ts">
import PrintResults from "@/components/calculator/results/PrintResults.vue"
import { Vue, Options, mixins } from "vue-class-component"

@Options({
  components: {
    PrintResults
  }
})
export default class CalculatorPrint extends Vue {
  // Empty
}
</script>
