<template>
  <BaseCalculator
    :expanded="expanded"
    :subgroupingId="subgroupingId"
    :title="title"
    :tooltip="tooltip"
    :showEFSelection="true"
  >
    <template v-slot:basecalculator-row-1-col-2="scopeProps">

      <GenericInput
        v-if="scopeProps.fields[1]"
        :id="`calculator-${subgroupingId}-field-${scopeProps.fields[1].id}`"
        v-model="scopeProps.fields[1].value"
        :disabled="scopeProps.isReadOnly ?? false"
        :doubleLineHeight="true"
        :fieldId="scopeProps.fields[1].id"
        :lable="scopeProps.fields[1].lable"
        :max="scopeProps.fields[1].maximumValue"
        :min="scopeProps.fields[1].minimumValue"
        :possibleValues="scopeProps.fields[1].possibleValues"
        :regex="findRegexPattern(scopeProps.fields[1].validationRegex)"
        :required="scopeProps.fields[1].required"
        :step="scopeProps.fields[1].step"
        :tooltip="scopeProps.fields[1].tooltip"
        :type="scopeProps.fields[1].valueType"
        :validationError="scopeProps.fields[1].error"
        @selectedDependents="(arg1, arg2) => scopeProps.setSelectedDependent(arg1, arg2)"
        @update:modelValue="scopeProps.applyConditions"
        @value-invalid="(arg1, arg2) => scopeProps.applyValidationError(arg1, arg2)"
        @value-valid="(arg1) => scopeProps.applyValidationOk(arg1)"
        @input="scopeProps.setCalculationChanged"
        @focusout="scopeProps.setCalculationChanged"
      />
    </template>
    <template v-slot:basecalculator-row-1-col-3="scopeProps">

      <GenericInput
        v-if="scopeProps.fields[2]"
        :id="`calculator-${subgroupingId}-field-${scopeProps.fields[2].id}`"
        v-model="scopeProps.fields[2].value"
        :disabled="scopeProps.isReadOnly ?? false"
        :doubleLineHeight="true"
        :lable="scopeProps.fields[2].lable"
        :max="scopeProps.fields[2].maximumValue"
        :min="scopeProps.fields[2].minimumValue"
        :possibleValues="scopeProps.fields[2].possibleValues"
        :regex="findRegexPattern(scopeProps.fields[2].validationRegex)"
        :required="scopeProps.fields[2].required"
        :step="scopeProps.fields[2].step"
        :tooltip="scopeProps.fields[2].tooltip"
        :type="scopeProps.fields[2].valueType"
        :validationError="scopeProps.fields[2].error"
        @update:modelValue="scopeProps.applyConditions"
        @value-invalid="(arg1, arg2) => scopeProps.applyValidationError(arg1, arg2)"
        @value-valid="(arg1) => scopeProps.applyValidationOk(arg1)"
        @input="scopeProps.setCalculationChanged"
        @focusout="scopeProps.setCalculationChanged"
      />
    </template>
    <template v-slot:basecalculator-row-1-col-4="scopeProps">

      <GenericInput
        v-if="scopeProps.fields[3]"
        :id="`calculator-${subgroupingId}-field-${scopeProps.fields[3].id}`"
        v-model="scopeProps.fields[3].value"
        :disabled="scopeProps.isReadOnly ?? false"
        :doubleLineHeight="true"
        :lable="scopeProps.fields[3].lable"
        :max="scopeProps.fields[3].maximumValue"
        :min="scopeProps.fields[3].minimumValue"
        :possibleValues="scopeProps.fields[3].possibleValues"
        :regex="findRegexPattern(scopeProps.fields[3].validationRegex)"
        :required="scopeProps.fields[3].required"
        :step="scopeProps.fields[3].step"
        :tooltip="scopeProps.fields[3].tooltip"
        :type="scopeProps.fields[3].valueType"
        :validationError="scopeProps.fields[3].error"
        @update:modelValue="scopeProps.applyConditions"
        @value-invalid="(arg1, arg2) => scopeProps.applyValidationError(arg1, arg2)"
        @value-valid="(arg1) => scopeProps.applyValidationOk(arg1)"
        @input="scopeProps.setCalculationChanged"
        @focusout="scopeProps.setCalculationChanged"
      />
    </template>
  </BaseCalculator>
</template>

<script lang="ts">
import BaseCalculatorComponent from "@/components/calculator/base-components/BaseCalculatorComponent"
export default class ElectricityCalculator extends BaseCalculatorComponent {

}
</script>

<style scoped>

</style>