<template>
  <div class="form-group">
    <span v-if="type !== 'NOT_RELEVANT'" :class="{ 'double-line-height': doubleLineHeight }" class="form-label" >
      <label v-if="type !== 'NOT_RELEVANT'" :for="id" v-html="lable" />&nbsp;
      <Tooltip v-if="tooltip" :text="tooltip" />
    </span>
    <span v-if="type === 'NOT_RELEVANT'" :class="{ 'double-line-height': doubleLineHeight }" class="form-label"
          v-html="lable" />

    <span v-if="showOptionalLabel" class="weight-normal ml-2" v-html="this.optionalLabel" />
    <span v-if="hintText" class="form-hint" v-html="hintText" />
    <!--span v-if="tooltip" style="margin-left: 0.86rem; vertical-align: top">X<Tooltip :text="tooltip" /></span-->

    <div v-if="type === 'NOT_RELEVANT'" class="p-3" style="text-align: right">
      <em><SimpleText text-key="sharedContent.notRelevant"/></em>
    </div>

    <input
        v-if="type !== 'DROPDOWN' && type !== 'UNIT' && type !== 'DROPDOWN_DEPENDENT' && type !== 'NOT_RELEVANT'"
        :id="id"
        ref="input"
        :data-error="validationError"
        :disabled="disabled"
        :max="max"
        :maxlength="type === 'STRING' ? max : ''"
        :min="min"
        :pattern="regex"
        :required="required"
        :step="step"
        :style="type !== 'STRING'
          ? 'text-align: right'
          : 'text-align: left'
      "
        :value="currentValue()"
        class="form-input"
        name="input-type-text"
        autocomplete="off"
        @focusout.self.prevent="checkIfChanged($event.target.value, 'focusout')"
        @input.self.prevent="checkIfChanged($event.target.value, 'input')"
        @change.self.prevent="checkIfChanged($event.target.value, 'change'); emitUpdate($event.target.value)"
        @keydown="validateLocalizedNumberInput($event, type)"
    />
    <div v-if="(type === 'DROPDOWN' || type === 'UNIT' || type === 'DROPDOWN_DEPENDENT') && !currentPossibleValues?.length">
      <select v-if="!currentPossibleValues?.length" :id="id" ref="input" :data-error="validationError" :required="required"
              class="form-select"
              disabled name="units">
        <option disabled selected value><SimpleText text-key="calculation.dropdown.select"/></option>
      </select>
    </div>

    <div v-if="(type === 'DROPDOWN' || type === 'UNIT') && currentPossibleValues">
      <select
          v-if="currentPossibleValues.length > 1"
          :id="id"
          ref="input"
          :data-error="validationError"
          :disabled="disabled"
          :required="required"
          :value="modelValue"
          class="form-select"
          name="units"
          @input="$emit('update:modelValue', $event.target.value)"
      >
        <option :selected="!modelValue" value><SimpleText text-key="calculation.dropdown.select"/></option>
        <option v-for="item in sortedPossibleValues" :key="item.id" :disabled="item.disabled" :value="constants.FIELD_ENUM_ID_PREFIX + item.id">
          <span v-if="!item.disabled">&nbsp;</span> {{ item.name }}
        </option>
      </select>
      <select
          v-if="currentPossibleValues.length === 1"
          :id="id"
          ref="input"
          :data-error="validationError"
          :disabled="true"
          :required="required"
          :value="modelValue"
          class="form-select"
          name="units"
          :title="currentPossibleValues[0].name"
      >
        <option :selected="true" :value="constants.FIELD_ENUM_ID_PREFIX + currentPossibleValues[0].id">
          {{ this.parseFieldNameFromDb(currentPossibleValues[0].name, currentPossibleValues[0].contentfulId) }}
        </option>
      </select>
    </div>

    <div v-if="type === 'DROPDOWN_DEPENDENT'">
      <select
          v-if="currentPossibleValues && currentPossibleValues.length > 1"
          :id="id"
          ref="input"
          :data-error="validationError"
          :disabled="disabled"
          :required="required"
          :value="modelValue"
          class="form-select"
          name="units"
          @input="emitWithDependents($event)"
      >
        <option :selected="!modelValue" value><SimpleText text-key="calculation.dropdown.select"/></option>
        <template v-if="!isSpecialUnitList">
          <option
              v-for="item in sortedCurrentPossibleValues"
              :key="item.id"
              :data-dependents="JSON.stringify(item.dependent)"
              :disabled="item.disabled"
              :value="constants.FIELD_ENUM_ID_PREFIX + item.id"
          >
            <span v-if="!item.disabled">&nbsp;</span> {{ parseFieldNameFromDb(item.name, item.contentfulId) }}
          </option>
        </template>
        <template v-if="isSpecialUnitList">
          <option
              v-for="item in currentPossibleValues"
              :key="item.id"
              :data-dependents="JSON.stringify(item.dependent)"
              :disabled="item.disabled"
              :value="constants.FIELD_ENUM_ID_PREFIX + item.id"
          >
            <span v-if="!item.disabled">&nbsp;</span> {{ parseFieldNameFromDb(item.name, item.contentfulId) }}
          </option>
        </template>
      </select>
      <select
          v-if="currentPossibleValues && currentPossibleValues.length === 1"
          :id="id"
          ref="input"
          :data-error="validationError"
          :disabled="true"
          :required="true"
          :value="modelValue"
          class="form-select"
          name="units"
      >
        <option :value="constants.FIELD_ENUM_ID_PREFIX + currentPossibleValues[0].id" selected>
          {{ parseFieldNameFromDb(currentPossibleValues[0].name, currentPossibleValues[0].contentfulId) }}
        </option>
      </select>
    </div>
  </div>
</template>

<script lang="ts">
import {prettify, simpleLocaleSpecificStringToNumber, unprettify} from "@/shared/PrettifyNumbers"
import DKFDS from 'dkfds'
import _ from "lodash"
import { Prop, Watch } from "vue-property-decorator"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import { Options } from "vue-class-component"
import {Constants} from "@/shared/Constants";
import SimpleText from "@/components/SimpleText.vue";
import { FieldEnum } from '../../../openapi/generated-clients/climatecompass'


@Options({
  emits: ['update:modelValue', 'selectedDependents', 'valueInvalid', 'valueValid'],
  components: {
    SimpleText,
  }
})
export default class GenericInput extends BaseComponent {
  @Prop()
  id?: string
  @Prop()
  fieldId?: number
  @Prop()
  lable?: string
  @Prop()
  required?: boolean
  @Prop()
  modelValue?: string | number
  @Prop()
  type?: string
  @Prop({default: false})
  blankSetsDefaultValue?: boolean
  @Prop()
  regex?: string
  @Prop()
  min?: number
  @Prop()
  max?: number
  @Prop()
  step?: number
  @Prop()
  validationError?: string
  @Prop()
  doubleLineHeight?: boolean
  @Prop()
  disabled?: boolean
  @Prop()
  possibleValues?: FieldEnum[]
  @Prop()
  showOptionalLabel?: boolean
  @Prop()
  hintText?: string
  @Prop()
  tooltip?: string
  @Prop()
  allowedDecimals?: number
  @Prop({default: false})
  avoidAutoFormattingDecimals?: boolean
  selectedFieldEnumId?: number

  currentValue() {
    if(!this.modelValue && typeof this.modelValue != "number") {
      return ""
    }
    if((this.type !== 'DECIMAL' &&
        this.type !== 'INPUT' &&
        this.type !== 'SCOPE1_OWN' &&
        this.type !== 'SCOPE2_OWN' &&
        this.type !== 'SCOPE3_OWN' &&
        this.type !== 'SCOPE1' &&
        this.type !== 'SCOPE2' &&
        this.type !== 'SCOPE3' &&
        this.type !== 'SCOPE' &&
        this.type !== 'OUTSIDE_SCOPE' &&
        this.type !== 'OUTSIDE_SCOPE_OWN')  || this.avoidAutoFormattingDecimals) {
      return this.modelValue
    } else if(this.step === 1 && this.type === 'DECIMAL' ) {
      return Math.round(typeof this.modelValue === "string" ? simpleLocaleSpecificStringToNumber(this.modelValue) : Number(this.modelValue))
    }
    return this.doPrettify(this.modelValue, this.allowedDecimals)
  }

  sortFieldEnumValues(unsortedValues: FieldEnum[] | undefined) {
    let sortedValues: FieldEnum[] = []
    if(unsortedValues) {
      sortedValues = [...unsortedValues].map((x: FieldEnum) => {
        const orgName = x.name
        const name = this.parseFieldNameFromDb(x.name, x.contentfulId ?? "")
        return { ...x, name, orgName }
      })
    }
    return sortedValues.sort((a, b) => new Intl.Collator("da").compare(a.name?.trim(), b.name?.trim()));
  }

  get isSpecialUnitList() {
    return (this.type === 'DROPDOWN_DEPENDENT' && this.fieldId === 47)  //field id 47 has type DROPDOWN_DEPENDENT even though it contains units, this list should not me sorted
  }

  get sortedPossibleValues() {
    return this.sortFieldEnumValues(this.possibleValues)
  }

  get sortedCurrentPossibleValues() {
    return this.sortFieldEnumValues(this.currentPossibleValues)
  }

  get currentPossibleValues() {
    return this.possibleValues
  }

  $refs!: {
    input: HTMLElement
  }


  checkIfChanged(e: any, type: string) {
    if (type === 'focusout') {
      const inputElement = this.$refs.input as HTMLInputElement
      if (!inputElement.validity.valid) {
        this.$emit("valueInvalid", inputElement, this.validationError)
      } else {
        this.$emit("valueValid", inputElement)
      }
    }
    if(this.type !== 'STRING') {
      if(prettify(e, 2) !== prettify(this.modelValue, 2)) {
        this.$refs.input.classList.add("changed")
      } else {
        this.$refs.input.classList.remove("changed")
      }
    } else {
      if(e !== this.modelValue) {
        this.$refs.input.classList.add("changed")
      } else {
        this.$refs.input.classList.remove("changed")
      }
    }
  }

  focus(preventScroll = false) {
    if (this.$refs.input) {
      this.$refs.input.focus({ preventScroll: preventScroll })
    }
  }

  emitWithDependents(e: Event) {
    const element = e.target as HTMLSelectElement
    //console.log("emitWithDependents 1 fieldId=" + this.fieldId, element.value, JSON.parse(element.selectedOptions[0].getAttribute("data-dependents") as string))
    this.$emit("update:modelValue", element.value)

    this.$emit("selectedDependents", this.fieldId, JSON.parse(element.selectedOptions[0].getAttribute("data-dependents") as string))
  }

  dropdownDependentEmit() {
    //console.log("dropdownDependentEmit start", this.fieldId)
    if(this.currentPossibleValues) {
      if (this.type === "DROPDOWN_DEPENDENT") {
        this.$emit("selectedDependents", this.fieldId, this.currentPossibleValues[0].dependent)
      }
      this.$emit("update:modelValue", Constants.FIELD_ENUM_ID_PREFIX + this.currentPossibleValues[0].id)
    }

  }

  emitUpdate(value: string) {
    if (
        this.type !== "DECIMAL" &&
        this.type !== "INPUT" &&
        this.type !== "SCOPE1_OWN" &&
        this.type !== "SCOPE2_OWN" &&
        this.type !== "SCOPE3_OWN" &&
        this.type !== "OUTSIDE_SCOPE_OWN"
    ) {
      this.$emit("update:modelValue", value)
      return
    }

    // Specifically fixes https://jira.erst.dk/browse/KK2-719
    if (this.blankSetsDefaultValue && !value) {
      this.$emit("update:modelValue", "")
      return
    }

    if (isNaN(Number(unprettify(value)))) {
      return
    }

    this.$emit("update:modelValue", unprettify(value))
  }

  /*
  @Watch("modelValue")
  watchModelValue(first: any[], second: any[]) {
    console.error("watchModelValue fieldId="+this.fieldId,this.modelValue, first, second)
  }
*/

  @Watch("possibleValues")
  watchPossibleValues(first: any[], second: any[]) {
    //console.log("watchPossibleValues start fieldId=" + this.fieldId + " modelValue=["+this.modelValue+"]", first, second)
    if (this.currentPossibleValues && this.currentPossibleValues.length === 1) {
      this.dropdownDependentEmit()
    }
    this.selectedFieldEnumId = this.currentPossibleValues?.find((e: any) => e.name === this.modelValue)?.id
    //console.log("this.selectedFieldEnumId", this.selectedFieldEnumId)
    if (
        this.currentPossibleValues &&
        this.currentPossibleValues.length > 1 &&
        first &&
        second &&
        first[0] !== second[0] &&
        !this.currentPossibleValues.find((e: any) => (Constants.FIELD_ENUM_ID_PREFIX + e.id) === this.modelValue)
    ) {
      this.$emit("update:modelValue", "")
    }
  }

  get optionalLabel() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.optionalLabel')
  }

}
</script>

<style scoped lang="scss">
.double-line-height {
  min-height: 5rem;
}

.form-label {
  white-space: pre-line;
}

.p-3 {
  margin-top: 8px;
}

.form-input.changed {
  border: 2px solid $color-primary !important;
}

.form-group.form-error .form-input {
  border: 2px solid $color-validation-error !important;
}
</style>
