import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cf04676"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "aria-checked", "checked"]
const _hoisted_3 = { class: "toggle__switch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    for: _ctx.fieldId,
    class: "toggle"
  }, [
    _createElementVNode("input", {
      id: _ctx.fieldId,
      "aria-checked": _ctx.checked,
      checked: _ctx.checked,
      type: "checkbox",
      onInput: _cache[0] || (_cache[0] = 
        (event) => {
          if (!_ctx.countryLocked) {
            _ctx.$emit('update:checked', event.target.checked)
          }
        }
      )
    }, null, 40, _hoisted_2),
    _createElementVNode("span", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.checked ? 'toggle__button--left' : 'toggle__button--right', "toggle__button"])
      }, null, 2),
      _createElementVNode("span", {
        class: _normalizeClass([_ctx.checked && 'active', "toggle__label toggle__label--left"])
      }, _toDisplayString(_ctx.leftLabel), 3),
      _createElementVNode("span", {
        class: _normalizeClass([!_ctx.checked && 'active', "toggle__label toggle__label--right"])
      }, _toDisplayString(_ctx.rightLabel), 3)
    ])
  ], 8, _hoisted_1))
}