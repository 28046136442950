<template>
  <Navigation />
  <router-view />
</template>

<script lang="ts">
import { Options } from "vue-class-component"
import Navigation from "@/components/guides/Navigation.vue"
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import SharedHeader from '@/components/calculator/shared/SharedHeader.vue'

export interface GuideHeader {
  title: string
  description: string | null
  buttonPrimaryLink: string | null
  buttonPrimaryText: string | null
  buttonSecondaryLink: string | null
  buttonSecondaryText: string | null
  imageUrl: string | null
}

@Options({
  components: {
    Navigation,
    SharedHeader
  }
})
export default class Guides extends BaseComponent {

}
</script>
