<template>
  <div class="container py-9"></div>
</template>

<script lang="ts">
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import { Vue, Options, mixins } from "vue-class-component"
import { version } from "../../package.json"

@Options({
  emits: ["loggedIn"]
})
export default class Redirect extends Vue {
  eventHub = require('tiny-emitter/instance')
  store = useStore()
  router = useRouter()

  async beforeMount() {
    // Just making absolutely sure
    await this.store.dispatch("loadConsent")
    const consent = this.store.getters.hasConsented

    if (!consent) {
      await this.router.push("/accept-terms")
    }

    await this.store.dispatch("loadContactPerson")

    if (!this.store.getters.getContactPerson.firstName) {
      await this.router.push("/contact-information")
    }

    this.eventHub.emit('loggedIn')
    await this.store.dispatch("loadCalculations").then(async () => {
      if (this.store.getters.getCalculations.length) {
        await this.router.push("/overview")
      } else {
        await this.router.push("/guides")
      }
    })
  }
}
</script>
