import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f9d8cd0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container px-0 pb-6" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleText = _resolveComponent("SimpleText")!
  const _component_AccordionComponent = _resolveComponent("AccordionComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, [
      _createVNode(_component_SimpleText, { "text-key": "cookie.header.title" })
    ]),
    _createElementVNode("div", {
      class: "w-percent-100 w-percent-100-child",
      innerHTML: _ctx.getRenderedContentfulRT('cookie.header.description')
    }, null, 8, _hoisted_2),
    _createVNode(_component_AccordionComponent, {
      "accordion-list": _ctx.getAccordions('cookie')
    }, null, 8, ["accordion-list"])
  ]))
}