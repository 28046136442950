<template>
  <CalculatorTabComponent>
    <template v-slot:calculator-primary-1="slotProps">
      <UseCalculator
        :subgroupingId="constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
        :expanded="true"
      >
      </UseCalculator>
    </template>
    <template v-slot:calculator-secondary-1="slotProps">
      <OtherSoldProductsCalculator
        :subgroupingId="constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
      >
      </OtherSoldProductsCalculator>
    </template>
  </CalculatorTabComponent>
</template>

<script lang="ts">
import { Options, Prop, Watch } from "vue-property-decorator"
import BaseComponent from "../base-components/BaseComponent"
import CalculatorTabComponent from "../CalculatorTabComponent.vue"
import OtherSoldProductsCalculator
  from "@/components/calculator/sold-products/calculators/OtherSoldProductsCalculator.vue"
import UseCalculator from "@/components/calculator/sold-products/calculators/UseCalculator.vue"

@Options({
  components: { UseCalculator, OtherSoldProductsCalculator, CalculatorTabComponent }
})
export default class UseOfProductsView extends BaseComponent {

}
</script>

<style scoped>

</style>