<template>
  <SharedHeader>
    <template #firstColumn>
      <div id='guides-first-column-header'>
        <h1>{{ currentPageHeader.title }}</h1>
        <span v-html='currentPageHeader.description'></span>
      </div>
      <div class='mt-5'>
        <a v-if='currentPageHeader.buttonPrimaryLink' :href='currentPageHeader.buttonPrimaryLink'
           class='button button-primary mr-4' target='_blank'>{{ currentPageHeader.buttonPrimaryText }}</a>
        <a v-if='currentPageHeader.buttonSecondaryLink' :href='currentPageHeader.buttonSecondaryLink'
           class='button button-secondary' target='_blank'>{{ currentPageHeader.buttonSecondaryText }}</a>
      </div>
    </template>
    <template #secondColumn>
      <img v-if='currentPageHeader.imageUrl' :src='currentPageHeader.imageUrl'
           alt='' />
    </template>
  </SharedHeader>
  <div class='container px-0 pb-6'>
    <h2>
      {{ pageTitle }}
    </h2>
    <span v-html='pageDescription'></span>
    <AccordionComponent :accordion-list='getAccordions("guides.terms")'></AccordionComponent>
  </div>
</template>

<script lang='ts'>
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import * as DKFDS from 'dkfds'
import { Options } from 'vue-class-component'
import { GuideHeader } from '@/components/guides/Guides.vue'
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import AccordionComponent from '@/components/AccordionComponent.vue'
import SharedHeader from '@/components/calculator/shared/SharedHeader.vue'

@Options({
  components: { AccordionComponent, SharedHeader }
})
export default class Terms extends BaseComponent {
  currentPageHeader: GuideHeader = {} as GuideHeader
  pageTitle = ''
  pageDescription = ''

  get documentToHtmlString() {
    return documentToHtmlString
  }

  mounted() {
    DKFDS.init()
  }

  updated() {
    DKFDS.init()
  }

  initPage() {
    this.pageTitle = this.getContentfulString('guides.terms.page.title')
    this.pageDescription = this.getRenderedContentfulRT('guides.terms.page.description')

    this.currentPageHeader.title = this.getContentfulString('guides.terms.header.title')
    this.currentPageHeader.description = this.getRenderedContentfulRT('guides.terms.header.description')
    this.currentPageHeader.buttonPrimaryLink = this.Contentful.findSimpleTextOrNull('guides.terms.header.buttonprimary.link')
    this.currentPageHeader.buttonPrimaryText = this.Contentful.findSimpleTextOrNull('guides.terms.header.buttonprimary.text')
    this.currentPageHeader.buttonSecondaryLink = this.Contentful.findSimpleTextOrNull('guides.terms.header.buttonsecondary.link')
    this.currentPageHeader.buttonSecondaryText = this.Contentful.findSimpleTextOrNull('guides.terms.header.buttonsecondary.text')
    this.currentPageHeader.imageUrl = this.Contentful.findSimpleTextOrNull('guides.terms.header.imageurl')
  }

  async beforeMount() {
    this.initPage()
    this.setDocumentTitle(this.pageTitle)
  }
}
</script>

<style scoped>
.accordion-button {
  border: 1px solid grey;
}
</style>
