import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { id: "guides-first-column-header" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "mt-5" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "container px-0 pb-6" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedHeader = _resolveComponent("SharedHeader")!
  const _component_AccordionComponent = _resolveComponent("AccordionComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SharedHeader, null, {
      firstColumn: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.currentPageHeader.title), 1),
          _createElementVNode("span", {
            innerHTML: _ctx.currentPageHeader.description
          }, null, 8, _hoisted_2)
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.currentPageHeader.buttonPrimaryLink)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: _ctx.currentPageHeader.buttonPrimaryLink,
                class: "button button-primary mr-4",
                target: "_blank"
              }, _toDisplayString(_ctx.currentPageHeader.buttonPrimaryText), 9, _hoisted_4))
            : _createCommentVNode("", true),
          (_ctx.currentPageHeader.buttonSecondaryLink)
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                href: _ctx.currentPageHeader.buttonSecondaryLink,
                class: "button button-secondary",
                target: "_blank"
              }, _toDisplayString(_ctx.currentPageHeader.buttonSecondaryText), 9, _hoisted_5))
            : _createCommentVNode("", true)
        ])
      ]),
      secondColumn: _withCtx(() => [
        (_ctx.currentPageHeader.imageUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.currentPageHeader.imageUrl,
              alt: ""
            }, null, 8, _hoisted_6))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.pageTitle), 1),
      _createElementVNode("span", { innerHTML: _ctx.pageDescription }, null, 8, _hoisted_8),
      _createVNode(_component_AccordionComponent, {
        "accordion-list": _ctx.getAccordions("guides.background")
      }, null, 8, ["accordion-list"])
    ])
  ], 64))
}