import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import { Options, Prop } from "vue-property-decorator"
import BaseCalculator from "@/components/calculator/BaseCalculator.vue"
import GenericInput from "@/components/calculator/GenericInput.vue"

@Options({
  components: {
    BaseCalculator,
    GenericInput
  }
})
export default class BaseCalculatorComponent extends BaseComponent {
  @Prop()
  subgroupingId?: number

  @Prop()
  title?: string

  @Prop()
  tooltip?: string

  @Prop()
  expanded?: boolean

  beforeMount() {
    if (!this.calculation?.id) {
      this.$router.push("/overview") // Hop direkte til oversigts-siden, hvis der ikke er valgt en beregning, da der så ikke kan oprettes nye udledninger.
    }
  }
}