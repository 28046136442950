<template>
  <div ref="chartContainer" :style="{ width: adjustedWidth + 'px' }">
    <Chart
        v-if="chartOptions.series && chartOptions.series.length > 0"
        ref="netZeroComparePieChart"
        type="pie"
        :options="chartOptions"
        :series="chartOptions.series"
         width="100%"
    ></Chart>
  </div>
</template>

<script lang="ts">
import { Options } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import VueApexCharts, { VueApexChartsComponent } from "vue3-apexcharts";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";

@Options({
  components: {
    Chart: VueApexCharts
  }
})
export default class NetZeroComparePieChart extends BaseComponent {
  @Prop({ default: 0 }) adjustedWidth!: number | undefined;

  width = this.adjustedWidth?.toFixed(1)

  // Initial reactive chart options
  chartOptions: any = {
    series: [100],  // Initial series data
    chart: {
      type: 'pie'
    },
    labels: ['Samlet'],
    colors: ['grey'],  // Ensure the color is set as an array
    legend: {
      show: false  // Hide the legend
    },
    plotOptions: {
      pie: {
        width: this.adjustedWidth, // Dynamic width for the pie chart based on prop
      }
    },
    dataLabels: {
      enabled: false  // Disable the data labels to hide the percentage
    },
    stroke: {
      width: 0,  // Set stroke width to 0
      colors: ['grey']  // Set stroke color to match the pie color
    }
  };

  $refs!: {
    netZeroComparePieChart: VueApexChartsComponent;
    chartContainer: HTMLElement;
  };

  mounted() {
    this.$nextTick(() => {
      this.updateChart();
    });
  }

  // Method to get updated chart options
  updateChart() {
    if (this.$refs.netZeroComparePieChart) {
      const updatedOptions = this.getChart();
      this.chartOptions.series = updatedOptions.series;
      this.chartOptions.labels = updatedOptions.labels;
      this.chartOptions.colors = updatedOptions.colors;
      this.chartOptions.legend = updatedOptions.legend;
      this.chartOptions.plotOptions = updatedOptions.plotOptions;

      this.$refs.netZeroComparePieChart.updateOptions({
        labels: this.chartOptions.labels,
        colors: this.chartOptions.colors,
        legend: this.chartOptions.legend,
        plotOptions: this.chartOptions.plotOptions
      }, false, true, true);

      this.$refs.netZeroComparePieChart.updateSeries(this.chartOptions.series);
    }
  }

  @Watch('adjustedWidth')
  onAdjustedWidthChanged(newWidth: number) {
    console.log('New width:', newWidth);
    this.chartOptions.plotOptions.pie.width = newWidth;
    this.updateChart();

    // Update the width of the chart container
    if (this.$refs.chartContainer) {
      (this.$refs.chartContainer as HTMLElement).style.width = newWidth + 'px';
    }
  }

  // Method to get original chart settings
  getChart() {
    return {
      series: [100],  // Update with new series data if needed
      labels: ['Samlet'],
      colors: ['grey'],  // Ensure the color is set as an array
      legend: {
        show: false
      },
      plotOptions: {
        pie: {
          width: this.adjustedWidth,  // Dynamic width for the pie chart based on prop
        }
      },
      dataLabels: {
        enabled: false  // Disable the data labels to hide the percentage
      },
      stroke: {
        width: 0,  // Set stroke width to 0
        colors: ['grey']  // Set stroke color to match the pie color so we don't get the diagonal split-line due to single series.
      }
    };
  }
}
</script>
