import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EndOfLifeCalculator = _resolveComponent("EndOfLifeCalculator")!
  const _component_OtherSoldProductsCalculator = _resolveComponent("OtherSoldProductsCalculator")!
  const _component_CalculatorTabComponent = _resolveComponent("CalculatorTabComponent")!

  return (_openBlock(), _createBlock(_component_CalculatorTabComponent, null, {
    "calculator-primary-1": _withCtx((slotProps) => [
      _createVNode(_component_EndOfLifeCalculator, {
        subgroupingId: _ctx.constants.GROUP_END_OF_LIFE_BEHANDLING,
        title: slotProps.title,
        tooltip: slotProps.tooltip,
        expanded: true
      }, null, 8, ["subgroupingId", "title", "tooltip"])
    ]),
    "calculator-secondary-1": _withCtx((slotProps) => [
      _createVNode(_component_OtherSoldProductsCalculator, {
        subgroupingId: _ctx.constants.GROUP_END_OF_LIFE_BEHANDLING_MED_EGNE_EMISSIONSFAKTORER,
        title: slotProps.title,
        tooltip: slotProps.tooltip
      }, null, 8, ["subgroupingId", "title", "tooltip"])
    ]),
    _: 1
  }))
}