<template>
  <CalculatorTabComponent>
    <template v-slot:calculator-primary-1="slotProps">
      <ElectricityCalculator
        :subgroupingId="constants.GROUP_FORBRUG_AF_ELEKTRICITET"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
        :expanded="true"
      >
      </ElectricityCalculator>
    </template>
    <template v-slot:calculator-secondary-1="slotProps">
      <OtherCalculator
        :subgroupingId="constants.GROUP_FORBRUG_AF_ELEKTRICITET_MED_EGNE_EMISSIONSFAKTORER"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
      >
      </OtherCalculator>
    </template>
  </CalculatorTabComponent>
</template>

<script lang="ts">
import { Options, Prop } from "vue-property-decorator"
import BaseComponent from "../base-components/BaseComponent"
import CalculatorTabComponent from "../CalculatorTabComponent.vue"
import ElectricityCalculator from "./calculators/ElectricityCalculator.vue"
import OtherCalculator from "./calculators/OtherCalculator.vue"

@Options({
  components: { OtherCalculator, ElectricityCalculator, CalculatorTabComponent }
})
export default class ElectricityView extends BaseComponent {

}
</script>

<style scoped>

</style>