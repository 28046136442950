<template>
  <Navigation />
  <router-view />
</template>

<script lang="ts">
import Navigation from "@/components/calculator/shared/Navigation.vue"
import { Vue, Options, mixins } from "vue-class-component"

@Options({
  components: {
    Navigation
  }
})
export default class Calculator extends Vue {
  //Empty
}
</script>
