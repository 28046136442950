<template>
  <div id="legend-wrapper-div">
    <div>
      <div class="row justify-content-center">
        <div class="column invisible"></div>

        <div v-if="this.upstreamCategories.length > 0 || (this.selectedCategories.length === 0 && this.possibleUpstreamCategories.length > 0)" class="flex-md-column text-center" style="padding-right: 20px">
          <h6 style="margin-right: 8px">
            <SimpleText text-key="calculate-forecast.category-graph.upstream-label"/>
          </h6>

          <!-- Category 1 -->
          <p v-if="upstreamCategories.some(category => category === constants.SCOPE3_CATEGORY_1) ||
             (this.selectedCategories.length === 0 && this.possibleUpstreamCategories.some(categoriesForSelect => categoriesForSelect.categoryId === constants.SCOPE3_CATEGORY_1))"
             class="category-box upstream-color-cat-one" style="font-size: small; margin-bottom: 0; margin-top: 0;">
            {{ this.getContentfulString('calculation.dropdown.possiblevalue.unit.category1') }}
          </p>

          <!-- Category 2 -->
          <p v-if="upstreamCategories.some(category => category === constants.SCOPE3_CATEGORY_2) ||
             (this.selectedCategories.length === 0 && this.possibleUpstreamCategories.some(categoriesForSelect => categoriesForSelect.categoryId === constants.SCOPE3_CATEGORY_2))"
             class="category-box upstream-color-cat-two" style="font-size: small; margin-bottom: 0; margin-top: 0;">
            {{ this.getContentfulString('calculation.dropdown.possiblevalue.unit.category2') }}
          </p>

          <!-- Category 3 -->
          <p v-if="upstreamCategories.some(category => category === constants.SCOPE3_CATEGORY_3) ||
             (this.selectedCategories.length === 0 && this.possibleUpstreamCategories.some(categoriesForSelect => categoriesForSelect.categoryId === constants.SCOPE3_CATEGORY_3))"
             class="category-box upstream-color-cat-three" style="font-size: small; margin-bottom: 0; margin-top: 0;">
            {{ this.getContentfulString('calculation.dropdown.possiblevalue.unit.category3') }}
          </p>

          <!-- Category 4 -->
          <p v-if="upstreamCategories.some(category => category === constants.SCOPE3_CATEGORY_4) ||
             (this.selectedCategories.length === 0 && this.possibleUpstreamCategories.some(categoriesForSelect => categoriesForSelect.categoryId === constants.SCOPE3_CATEGORY_4))"
             class="category-box upstream-color-cat-four" style="font-size: small; margin-bottom: 0; margin-top: 0;">
            {{ this.getContentfulString('calculation.dropdown.possiblevalue.unit.category4') }}
          </p>

          <!-- Category 5 -->
          <p v-if="upstreamCategories.some(category => category === constants.SCOPE3_CATEGORY_5) ||
             (this.selectedCategories.length === 0 && this.possibleUpstreamCategories.some(categoriesForSelect => categoriesForSelect.categoryId === constants.SCOPE3_CATEGORY_5))"
             class="category-box upstream-color-cat-five" style="font-size: small; margin-bottom: 0; margin-top: 0;">
            {{ this.getContentfulString('calculation.dropdown.possiblevalue.unit.category5') }}
          </p>

          <!-- Category 6 -->
          <p v-if="upstreamCategories.some(category => category === constants.SCOPE3_CATEGORY_6) ||
             (this.selectedCategories.length === 0 && this.possibleUpstreamCategories.some(categoriesForSelect => categoriesForSelect.categoryId === constants.SCOPE3_CATEGORY_6))"
             class="category-box upstream-color-cat-six" style="font-size: small; margin-bottom: 0; margin-top: 0;">
            {{ this.getContentfulString('calculation.dropdown.possiblevalue.unit.category6') }}
          </p>

          <!-- Category 7 -->
          <p v-if="upstreamCategories.some(category => category === constants.SCOPE3_CATEGORY_7) ||
             (this.selectedCategories.length === 0 && this.possibleUpstreamCategories.some(categoriesForSelect => categoriesForSelect.categoryId === constants.SCOPE3_CATEGORY_7))"
             class="category-box upstream-color-cat-seven" style="font-size: small; margin-bottom: 0; margin-top: 0;">
            {{ this.getContentfulString('calculation.dropdown.possiblevalue.unit.category7') }}
          </p>

          <!-- Category 8 -->
          <p v-if="upstreamCategories.some(category => category === constants.SCOPE3_CATEGORY_8) ||
             (this.selectedCategories.length === 0 && this.possibleUpstreamCategories.some(categoriesForSelect => categoriesForSelect.categoryId === constants.SCOPE3_CATEGORY_8))"
             class="category-box upstream-color-cat-eight" style="font-size: small; margin-bottom: 0; margin-top: 0;">
            {{ this.getContentfulString('calculation.dropdown.possiblevalue.unit.category8') }}
          </p>

          <!-- Special case for values NOT included - Inserted into upstreams, if no downstream categories exist-->
          <p v-if="(downstreamCategories.length === 0 && this.upstreamCategories.length > 0) || (this.possibleDownstreamCategories.length === 0 && this.possibleUpstreamCategories.length > 0)"
             class="category-box downstream-color-not-included" style="font-size: small; margin-bottom: 0; margin-top: 20px;">
            {{ this.getContentfulString('forecast.columnchart.label.not-in-target-scope3') }}
          </p>
        </div>

        <!-- DOWNSTREAM Categories -->

        <div v-if="this.downstreamCategories.length > 0 || (this.selectedCategories.length === 0 && this.possibleDownstreamCategories.length > 0)"
             class="flex-md-column text-center" style="padding-right: 20px">
          <h6>
            <SimpleText text-key="calculate-forecast.category-graph.downstream-label"/>
          </h6>

          <!-- Category 9 -->
          <p v-if="downstreamCategories.some(category => category === constants.SCOPE3_CATEGORY_9) ||
             (this.selectedCategories.length === 0 && this.possibleDownstreamCategories.some(categoriesForSelect => categoriesForSelect.categoryId === constants.SCOPE3_CATEGORY_9))"
             class="category-box downstream-color-cat-nine" style="font-size: small; margin-bottom: 0; margin-top: 0;">
            {{ this.getContentfulString('calculation.dropdown.possiblevalue.unit.category9') }}
          </p>

          <!-- Category 10 -->
          <p v-if="downstreamCategories.some(category => category === constants.SCOPE3_CATEGORY_10) ||
             (this.selectedCategories.length === 0 && this.possibleDownstreamCategories.some(categoriesForSelect => categoriesForSelect.categoryId === constants.SCOPE3_CATEGORY_10))"
             class="category-box downstream-color-cat-ten" style="font-size: small; margin-bottom: 0; margin-top: 0;">
            {{ this.getContentfulString('calculation.dropdown.possiblevalue.unit.category10') }}
          </p>

          <!-- Category 11 -->
          <p v-if="downstreamCategories.some(category => category === constants.SCOPE3_CATEGORY_11) ||
             (this.selectedCategories.length === 0 && this.possibleDownstreamCategories.some(categoriesForSelect => categoriesForSelect.categoryId === constants.SCOPE3_CATEGORY_11))"
             class="category-box downstream-color-cat-eleven" style="font-size: small; margin-bottom: 0; margin-top: 0;">
            {{ this.getContentfulString('calculation.dropdown.possiblevalue.unit.category11') }}
          </p>

          <!-- Category 12 -->
          <p v-if="downstreamCategories.some(category => category === constants.SCOPE3_CATEGORY_12) ||
             (this.selectedCategories.length === 0 && this.possibleDownstreamCategories.some(categoriesForSelect => categoriesForSelect.categoryId === constants.SCOPE3_CATEGORY_12))"
             class="category-box downstream-color-cat-twelve" style="font-size: small; margin-bottom: 0; margin-top: 0;">
            {{ this.getContentfulString('calculation.dropdown.possiblevalue.unit.category12') }}
          </p>

          <!-- Category 13 -->
          <p v-if="downstreamCategories.some(category => category === constants.SCOPE3_CATEGORY_13) ||
             (this.selectedCategories.length === 0 && this.possibleDownstreamCategories.some(categoriesForSelect => categoriesForSelect.categoryId === constants.SCOPE3_CATEGORY_13))"
             class="category-box downstream-color-cat-thirteen" style="font-size: small; margin-bottom: 0; margin-top: 0;">
            {{ this.getContentfulString('calculation.dropdown.possiblevalue.unit.category13') }}
          </p>

          <!-- Category 14 -->
          <p v-if="downstreamCategories.some(category => category === constants.SCOPE3_CATEGORY_14) ||
             (this.selectedCategories.length === 0 && this.possibleDownstreamCategories.some(categoriesForSelect => categoriesForSelect.categoryId === constants.SCOPE3_CATEGORY_14))"
             class="category-box downstream-color-cat-fourteen" style="font-size: small; margin-bottom: 0; margin-top: 0;">
            {{ this.getContentfulString('calculation.dropdown.possiblevalue.unit.category14') }}
          </p>

          <!-- Category 15 -->
          <p v-if="downstreamCategories.some(category => category === constants.SCOPE3_CATEGORY_15) ||
             (this.selectedCategories.length === 0 && this.possibleDownstreamCategories.some(categoriesForSelect => categoriesForSelect.categoryId === constants.SCOPE3_CATEGORY_15))"
             class="category-box downstream-color-cat-fifteen" style="font-size: small; margin-bottom: 0; margin-top: 0;">
            {{ this.getContentfulString('calculation.dropdown.possiblevalue.unit.category15') }}
          </p>

          <!-- Special case for values NOT included -->
          <p v-if="downstreamCategories.length > 0 || (this.selectedCategories.length === 0 && this.possibleDownstreamCategories.length > 0)"
             class="category-box downstream-color-not-included" style="font-size: small; margin-bottom: 0; margin-top: 20px;">
            {{ this.getContentfulString('forecast.columnchart.label.not-in-target-scope3') }}
          </p>
        </div>


        <div class="column invisible"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options } from "vue-class-component"
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import {Prop, Watch} from "vue-property-decorator";
import SimpleText from "@/components/SimpleText.vue";
import {SelectedCategory} from "../../../openapi/generated-clients/climatecompass";

class CategoriesForSelect {
  categoryId: string
  categoryPresentationName: string

  constructor(categoryId: string, categoryPresentationName: string) {
    this.categoryId = categoryId
    this.categoryPresentationName = categoryPresentationName
  }
}

@Options({
  components: {SimpleText

  },
  watch: {
    possibleUpstreamCategories: {
      handler(newVal: CategoriesForSelect[]) {
        console.log('WATCHER Updated Upstream Categories in legend: ', newVal);
        this.upstreamCategories = newVal.map(category => category.categoryId);
      },
      immediate: true,
      deep: true
    },
    possibleDownstreamCategories: {
      handler(newVal: CategoriesForSelect[]) {
        console.log(' WATCHER Updated Downstream Categories in legend: ', newVal);
        this.downstreamCategories = newVal.map(category => category.categoryId);
      },
      immediate: true,
      deep: true
    }
  }
})


export default class ReductionCategoryChartLegend extends BaseComponent {

  @Prop()
  selectedCategories = [] as SelectedCategory[]

  @Prop()
  possibleUpstreamCategories = [] as CategoriesForSelect[]

  @Prop()
  possibleDownstreamCategories = [] as CategoriesForSelect[]

  upstreamCategories: string [] = []
  downstreamCategories: string [] = []


  /** LIFECYCLE HOOKS **/
  mounted() {
    if(this.selectedCategories && this.selectedCategories.length > 0) {
      //console.log(JSON.stringify(this.selectedCategories, null, 2))

      this.filterCategories(this.selectedCategories)
    }
  }

  /** WATCHERS **/
  // Detects changes more immediate that lifecycle hooks - ensures values get updated for use.
  @Watch('selectedCategories', { immediate: true, deep: true })
  onSelectedCategoriesChange(newVal: SelectedCategory[], oldVal: SelectedCategory[]) {
    //console.log('Watcher - selectedCategories changed:', newVal)
    if (newVal && newVal.length > 0) {
      this.filterCategories(newVal)
    } else {
      console.log('No Selected Categories Detected or Array is empty.')
    }
  }

  /** FUNCTIONS **/
  filterCategories(selectedCategories: SelectedCategory[]) {
    const upstreamCategories: string[] = [];
    const downstreamCategories: string[] = [];

    selectedCategories.forEach(categoryObj => {
      // Ensures the category object has a code and that it's a string
      if (categoryObj.code && typeof categoryObj.code === 'string') {
        // Split the string on '_' and extract the category number
        const categoryNumber = parseInt(categoryObj.code.split('_')[1])

        // Checks category number and categorize && push according to upstream/downstream
        if (categoryNumber >= 1 && categoryNumber <= 8) {
          upstreamCategories.push(categoryObj.code)
        } else if (categoryNumber >= 9 && categoryNumber <= 15) {
          downstreamCategories.push(categoryObj.code)
        }
      } else {
        console.error('Invalid or missing category code:', categoryObj)
      }
    })

    this.upstreamCategories = upstreamCategories;
    this.downstreamCategories = downstreamCategories;
  }
}
</script>

<style lang="scss" scoped>
.invisible {
  visibility: hidden;
}

//** Upstream color code classes for category boxes  **//

.category-box.upstream-color-cat-one:before {
  background-color: #08306b !important;
}

.category-box.upstream-color-cat-two:before {
  background-color: #2171b5 !important;
}

.category-box.upstream-color-cat-three:before {
  background-color: #6baed6 !important;
}

.category-box.upstream-color-cat-four:before {
  background-color: #9ecae1 !important;
}

.category-box.upstream-color-cat-five:before {
  background-color: #c6dbef !important;
}

.category-box.upstream-color-cat-six:before {
  background-color: #acceda !important;
}

.category-box.upstream-color-cat-seven:before {
  background-color: #3a464a !important;
}

.category-box.upstream-color-cat-eight:before {
  background-color: #5e8d9c !important;
}

//** Downstream color code classes for category boxes  **//

.category-box.downstream-color-cat-nine:before {
  background-color: #154c79 !important;
}

.category-box.downstream-color-cat-ten:before {
  background-color: #063970 !important;
}

.category-box.downstream-color-cat-eleven:before {
  background-color: #abdbe3 !important;
}

.category-box.downstream-color-cat-twelve:before {
  background-color: #873e23 !important;
}

.category-box.downstream-color-cat-thirteen:before {
  background-color: #76b5c5 !important;
}

.category-box.downstream-color-cat-fourteen:before {
  background-color: #eab676 !important;
}

.category-box.downstream-color-cat-fifteen:before {
  background-color: #e28743 !important;
}

.category-box.downstream-color-not-included:before {
  background-color: #d3d3d3 !important;
}

.category-box {
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
}
.category-box:before {
  position: absolute;
  left: -18px;
  content: "";
  height:15px;
  width:15px;
  margin-bottom:5px;
  margin-top: 5px;
  margin-right: 5px;
  border:1px solid black;

}
</style>